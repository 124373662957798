import React, { useState } from "react";
import { Button, Form, Modal } from 'react-bootstrap';
import { apiCall } from './../../helpers/api';
import { getSupportOptions } from "../../helpers/dropdowns";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import AlertModal from "../../components/alerts/alert";

export default function SupportModal(props){
    const animatedComponents = makeAnimated();
    const supportOptions = getSupportOptions(props.supportModal.context,props.supportModal.multipleContext)
    const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
    const [ isSubmitting, setIsSubmitting ] = useState(false)
    var error = null;
    const handleClose = e => {
      props.setSupportModal({"visible":false,"type":"","message":"","context":""})
    }
    const handleUpdates = (id,value) => {
      var modalData = props.supportModal;
      if (id == "type"){
          modalData.type = value;
      }
      else if (id == "message"){
          modalData.message = value;
      }
      props.setSupportModal(modalData)
    }
    const handleSubmit = async e => {
      e.preventDefault();
      setIsSubmitting(true)
      var response = await apiCall("supportMessage",{ "method" : "POST", "data" : {"context":props.supportModal.context,"message":props.supportModal.message,"type":props.supportModal.type,"relatedIDs":props.relatedIDs,"isInternal":props.isInternal} });
      if (response["success"]) {
        if (props.isInternal) {
          setAlert({"display":"toast","visible":true,"title":"Support Case Raised","content":"A support case has been raised. You'll be able to communicate with The Influence Room via the inbox functionality within the platform","className":"bg-success text-white"})
        }
        else {
          setAlert({"display":"toast","visible":true,"title":"Support Case Raised","content":"A support case has been raised. You'll be able to communicate with The Influence Room via your email","className":"bg-success text-white"})
        }
        handleClose()
      }
      else if (response["failure"]) {
        setAlert({"display":"toast","visible":true,"title":"Failed to Raise Support Case","content":response["failure"]["error"],"className":"bg-danger text-white"})
      };
      setIsSubmitting(false)
    }
    if (props.supportModal.visible){
      return (
        <>
        <AlertModal modal={showAlert} onClose={() => setAlert({"display":"toast","visible":false,"title":"","content":""})} />
        {props.isPopup && (
        <Modal show={props.supportModal.visible} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{ "Message Support" }</Modal.Title>
          </Modal.Header>
          <Form>
            <Modal.Body>
              <Form.Group className="mb-3" >
                  <b>Message Reason</b>
                  <Form.Label>Please specify what the support message is about</Form.Label>
                  <Form.Group className="mb-3">
                    <Select closeMenuOnSelect={true} components={animatedComponents} defaultValue={[{label:props.supportModal.type,value:props.supportModal.type}]} options={supportOptions} id="type" onChange={ (choice) => handleUpdates("type",choice.value) } />
                  </Form.Group>
                  <b>Please write your message below</b>
                  <Form.Label>Please don't include any sensitive information such as bank details or passwords.</Form.Label>
                  <Form.Control 
                    onChange={e => handleUpdates("message",e.target.value)}
                    as="textarea"
                    id="message"
                    placeholder="Write your message here..."
                    rows={5}
                  />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary" disabled={isSubmitting} type="submit" onClick={handleSubmit}>
                {isSubmitting ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Sending...</> : "Send Support Message"}
              </Button>
              {error && <div className="error text-danger small ms-2">{error}</div>}
            </Modal.Footer>
          </Form>
        </Modal>
        )}
        {!props.isPopup && (
          <div className="mx-md-4">
                              
          <div className="fw-500">Reason</div>
          <Form.Label className="text-muted">Please specify what the support message is about</Form.Label>
          <Form.Group className="mb-3">
            <Select closeMenuOnSelect={true} components={animatedComponents} defaultValue={[{label:props.supportModal.type,value:props.supportModal.type}]} options={supportOptions} id="type" onChange={ (choice) => handleUpdates("type",choice.value) } />
          </Form.Group>

          <div className="mb-3">
            <div className="fw-500">Message</div>
            <div className="text-muted small">Please don't include any sensitive information such as bank details or passwords.</div>
          </div>

          <Form.Group className="mb-3">
            <Form.Control 
                onChange={e => handleUpdates("message",e.target.value)}
                as="textarea"
                id="message"
                placeholder="Write your message here..."
                rows={5}
              />
          </Form.Group>

          <div className="d-flex justify-content-end mb-0 mb-md-4">
            <button className="btn btn-primary" disabled={isSubmitting} type="submit" onClick={handleSubmit}>{isSubmitting ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Sending...</> : "Send"}
            </button>
          </div>
        </div>
        )}
        </>
      )}
    else{
      return ( <AlertModal modal={showAlert} onClose={() => setAlert({"display":"toast","visible":false,"title":"","content":""})} /> )
    }
  }