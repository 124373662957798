import React, { Component, useState, useEffect, useRef } from "react";
import { Button, Container, Row } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useSearchParams, Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Fade from 'react-bootstrap/Fade';
import Carousel from 'react-bootstrap/Carousel';
import { useAuthContext } from "../../hooks/useAuthContext";
import { useImageContext } from "../../hooks/useImageContext";
import { getImageUrl, validateImage, compressImage } from "../../helpers/images";
import { getFileUrl, getVideoURL, validateFile } from "../../helpers/files";
import { getSocialBadge } from "../../helpers/socials";
import { getSmallNumber } from "../../helpers/stats";
import { apiCall } from "../../helpers/api";
import { getFilter, setFilter } from './../../helpers/filters'
import { updateURLArgument } from './../../helpers/url'
import { getStatusPill } from "../../helpers/status";
import InboxLoader from "../../components/spinner/InboxLoader"
import { isToday, datePickerToEpoch, relativeTimeFormat } from './../../helpers/dates'
import { formatText } from "../../helpers/format";

import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import './Inbox.css';
import NoAccess from "../../images/no-access.png";
import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";
import Search from './../../images/search-dark.svg';
import blueTick from './../../images/blue-tick.svg';
import social from './../../images/social.svg';
import moreVertical from './../../images/more-vertical.svg';
import grayDoubleChecks from './../../images/gray-double-check.svg';
import whiteDoubleChecks from './../../images/white-double-check.svg';
import backArrow from './../../images/back-arrow-blue.svg';
import rightArrow from './../../images/blue-right-arrow.svg';
import sendDark from './../../images/send-dark.svg';
import paperclip from './../../images/paperclip.svg';
import mute from './../../images/mute.svg';
import Cross from "../../images/cross.svg";
import Document from "../../images/document.svg";
import Video from "../../images/video.svg";
import archive from "../../images/archive.svg";
import unread from "../../images/unread.svg";
import read from "../../images/read.svg";
import filter from "../../images/messageFilter.svg";

class SendMessageForm extends Component {
  constructor() {
    super()
    this.state = {
        message: '',
        attachments: [],
        errors: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async sendMessage(message) {
    var data = {"sender":this.props.sender,"message":message,"messageType":"text"}
    if (this.props.unblockChat){
      data["unblockChat"] = true
    }
    var response = await apiCall(`conversation?id=${this.props.conversationID}`,{ "method" : "PUT", "data" : data });
    if (response["success"]) {
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
  }

  async uploadAttachments(attachments){
    var failedAttachments = [];
    for (const attachment of attachments){
      const formData = new FormData();
      formData.append("file",attachment.data)
      formData.append("public_id",attachment.metadata.public_id)
      formData.append("api_key",attachment.metadata.api_key)
      formData.append("timestamp",attachment.metadata.timestamp)
      formData.append("signature",attachment.metadata.signature)
      if (attachment.metadata.folder != ""){
        formData.append("folder",attachment.metadata.folder)
      }
      fetch(`${attachment.metadata.target}/${attachment.metadata.resource_type}/upload`, {
        method: "POST",
        body: formData
      }).then(async response => {
        try {
          let responseJSON = await response.json();
          if (response.ok) {
            var response = await apiCall(`conversation?id=${this.props.conversationID}`,{ "method" : "PUT", "data" : {"sender":this.props.sender,"message":{"id":responseJSON.public_id,"source":attachment.metadata.source,"name":attachment.data.name},"messageType":attachment.type} });
            if (response["success"]) {
            }
            else {
              this.setState({
                error: response["failure"]["error"]
              });
            }
          } 
          else {
            failedAttachments.push(attachment)
          }
        } catch (error) {
          failedAttachments.push(attachment)
        }
      })
    }
  }

  handleChange(e) {
      this.setState({
          message: e.target.value
      })
  }

  async attachFiles(newFiles) {
    var errors = [];
    var files = newFiles.target.files;
    var fileArray = this.state.attachments;
    if (files.length + fileArray.length){
        files = Object.entries(files).slice(0,5-fileArray.length).map(file => file[1]);
    }
    for (var file of files) {
      if (["doc","pdf","docx","rtf","odt"].includes(file.name.split(".").pop())){
        var [validationResult, validationReason] = validateFile(file)
        var tempFile = {"data":file,"metadata":null,"type":"file"}
      }
      else {
        var [validationResult, validationReason] = validateImage(file)
        file = await compressImage(file)
        var tempFile = {"url":URL.createObjectURL(file),"data":file,"metadata":null,"type":"image"}
      }
      if (validationResult){
          var response = await apiCall(`getUploadSignature?folder=attachments&asset_type=${tempFile.type}`,{ "method" : "GET" });
          if (response["success"]) {
            tempFile.metadata = response["success"]["metadata"]
            fileArray.push(tempFile);
          }
      }
      else {
          errors.push(`${file.name} could not be uploaded: ${validationReason}`)
      }
    }
    this.setState({ attachments: fileArray, errors: errors })
}

  removeFile(targetFile){
      var fileArray = this.state.attachments.filter(function(file) {return file.url != targetFile.url });
      this.setState({ attachments: fileArray })
  }

  handleSubmit(e) {
      e.preventDefault()
      if (this.state.attachments){
        this.uploadAttachments(this.state.attachments)
        this.setState({
          attachments: []
      })
      }
      if (this.state.message.length > 0){
        this.sendMessage(this.state.message)
        this.setState({
            message: ''
        })
      }
      this.props.showConversation(this.props.conversationID,null,false)
      this.props.reloadConversations();
  }

  // Required for hitting enter into the textarea
  handleKeyPress(e,handleSubmit) {
    if(e.keyCode == 13 && e.shiftKey == false) {
      handleSubmit(e)
    }
    else {
      e.target.style.height = "0px"
      e.target.style.height = `${Math.min(e.target.scrollHeight, 120)}px`
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        
        {this.props.active ? (
          <>

          <div className="attachedImages">
            {this.state.attachments.map(attachment => (
              <>
              {attachment.type == "image" && (
                <span key={attachment.url} className="imageWrapper" >
                  <img id={attachment.url} height="60" width="60" className="object-fit-cover rounded border" src={attachment.url} alt={attachment.data.name} title={attachment.data.name} />
                  <img className="overlayTopRight" title="Remove attachment" onClick={(e) => this.removeFile(attachment)} src={Cross}/>
                </span>
              )}
              {attachment.type == "file" && (
                <span key={attachment.data.name} className="imageWrapper" >
                  <img id={attachment.data.name} height="60" width="60" className="object-fit-cover" src={Document} alt={attachment.data.name} title={attachment.data.name} />
                  <img className="overlayTopRight" title="Remove attachment" onClick={(e) => this.removeFile(attachment)} src={Cross}/>
                </span>
              )}
              </>
            ))}
          </div>

          <InputGroup>
            <div className="grow-wrap">
              <textarea className="form-control border-0" rows="1" placeholder={this.props.recipient.name ? `Send message to ${this.props.recipient.name}` : `Send message to ${this.props.recipient.firstName} ${this.props.recipient.lastName}`} autoFocus onChange={this.handleChange} onKeyDown={(e) => this.handleKeyPress(e,this.handleSubmit)} name="text" id="text" value={this.state.message}></textarea>
            </div>
            {/* <Form.Control placeholder={"Send message to " + this.props.recipient.name} value={this.state.message} onChange={this.handleChange} className="border-0" autoFocus />             */}
            {this.state.message.length == 0 && this.state.attachments.length == 0 ? <button onClick={this.handleSubmit} disabled className="btn border-0 bg-white"><img src={sendDark} /></button> : <button onClick={this.handleSubmit} className="btn border-0 bg-white"><img src={sendDark} /></button>}
            <label htmlFor="attachment-upload" className="mb-0 d-flex align-items-center">
              <a className="btn border-0 bg-white cursor-pointer">
                <img src={paperclip} />
              </a>
            </label>    
            {this.state.errors && <div className={"text-muted small"}><strong>{this.state.errors}</strong></div>}   
            <Form.Control type="file" id="attachment-upload" multiple accept=".png, .jpg, .jpeg, .webp, .heic, .pdf, .doc, .docx, .rtf" onChange={(e) => this.attachFiles(e)} variant="default" className="fileUploadBtn d-none" />   
          </InputGroup>
          </>
          ) : (
          <>
          <InputGroup>
            <Form.Control value={this.state.message} disabled onChange={this.handleChange} className="border-0" />
            <Button onClick={this.handleSubmit} disabled className="btn border-0 bg-white"><img src={sendDark} /></Button>
            <Button variant="default" disabled className="border-0 bg-white"><img src={paperclip} /></Button>        
          </InputGroup>
          </>
        )}
      </form>
    )
  }
}

class Conversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      isConversationLoaded: false,
      conversation: null,
      messages: [],
      conversations: [],
      readCooldown: 0,
      loadingInBackground: false,
      loadingConversationInBackground: false,
      paging: {},
      messagePaging: {}
    };
    this.showConversation = this.showConversation.bind(this);
    this.reloadConversations = this.reloadConversations.bind(this);
    if (getFilter("conversation")) {
      props.setInboxFilters(JSON.parse(getFilter("conversation")))
    }
    this.getConversations(true,null)
  }
  
  componentDidMount() {
    this.intervalID = setInterval(() => {
      if (this.props.conversationID && this.state.isConversationLoaded && !this.state.loadingInBackground){
        if (this.state.readCooldown > 0) {
          this.setState({
            readCooldown: this.state.readCooldown - 1
          })
        }
        else if (this.state.conversation.unreadMessages > 0){
          this.markConversationAsRead(this.props.conversationID)
        }
        this.setState({loadingInBackground : true})
        this.getConversation(this.props.conversationID,false,true)
      }
      if (this.state.isLoaded && !this.state.loadingInBackground){
        this.setState({loadingInBackground : true})
        this.reloadConversations()
      }
    }, 10000);
  }

  componentWillUnmount(){
    clearInterval(this.intervalID);
  }

  reloadConversations(){
    if (this.state.conversations.length > 0){
      var lastMessageTime = Math.max.apply(Math, this.state.conversations.map(conversation => conversation.lastMessageTime))
      this.getConversations(false,lastMessageTime)
    }
    else {
      this.getConversations(false)
    }
  }

  showConversation(id,setChatBox=null,showLoading=true){
    if (setChatBox){
      setChatBox(true)
    }
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('conversation_id', id);
    let newUrl = window.location.origin + window.location.pathname + '?' + currentUrlParams.toString();
    window.history.replaceState({path:newUrl},'',newUrl);
    this.props.setAutoScroll(true)
    this.getConversation(id,showLoading)
    if (this.state.readCooldown > 0) {
      this.setState({
        readCooldown: this.state.readCooldown - 1
      })
    } else if (this.state.conversation && this.state.conversation.unreadMessages > 0) {
      this.markConversationAsRead(this.props.conversationID)
    }
  }

  updateConversations(newConversations){
    var existingConversations = this.state.conversations;
    newConversations.forEach(conversation => {
      var matchingIndex = existingConversations.findIndex(existingConversation => existingConversation._id == conversation._id)
      if (matchingIndex >= 0) {
        existingConversations[matchingIndex] = conversation
      }
      else {
        existingConversations.push(conversation)
      }
    })
    existingConversations.sort((a,b) => b.lastMessageTime - a.lastMessageTime);
    this.setState({
      conversations : existingConversations
    });
  }

  updateConversation(newMessages,addAfter=false){
    var existingMessages = this.state.messages;
    newMessages.forEach(message => {
      var matchingIndex = existingMessages.findIndex(existingMessage => existingMessage._id == message._id)
      if (matchingIndex >= 0) {
        existingMessages[matchingIndex] = message
      }
      else {
        if (addAfter){
          existingMessages.push(message)
        }
        else {
          existingMessages.unshift(message)
        }
      }
    })
    existingMessages.sort((a,b) => b.messageTime - a.messageTime);
    this.setState({
      messages : existingMessages
    });
    this.props.setOldestMessage({"scroll":false,"id":existingMessages.slice().reverse()[0]._id})
  }

  async getConversations(loadConversation=true,after=null) {
    if (!this.state.loadingInBackground) {
      this.setState({
        loadingInBackground : true
      });
      let urlParams = new URLSearchParams(window.location.search);
      urlParams.set('limit', 250);
      if (after && Number.isInteger(after)) {
        urlParams.set('after',after);
      }
      var response = await apiCall(`conversations?`+urlParams.toString(),{ "method" : "GET" });
      if (response["success"]) {
        if (after){ this.updateConversations(response["success"]["conversations"]) }
        else { this.setState({ conversations : response["success"]["conversations"], paging : response["success"]["_paging"] }) }
        if (loadConversation){
          var conversationID = this.props.conversationID;
          if (conversationID && response["success"]["conversations"].filter(function(conversation) {return conversation._id == conversationID}).length > 0){
            this.getConversation(this.props.conversationID)
          }
          else if (response["success"]["conversations"].length > 0){
            if (this.props.conversationID != response["success"]["conversations"][0]._id){
              this.getConversation(response["success"]["conversations"][0]._id)
              this.props.setConversationID(response["success"]["conversations"][0]._id)
              this.setState({
                readCooldown: 0
              })
            }           
          }
        }
      }
      else {
        this.setState({
          error: response["failure"]["error"]
      });
      }
      this.setState({
        isLoaded : true,
        loadingInBackground : false
      });
    }
  }

  async getMoreConversations() {
    if (!this.state.loadingInBackground) {
      this.setState({
        loadingInBackground : true
      });
      let urlParams = new URLSearchParams(window.location.search);
      urlParams.set('limit', 10);
      urlParams.set('skip', this.state.conversations.length)
      var response = await apiCall(`conversations?${urlParams.toString()}`,{ "method" : "GET" });
      if (response["success"]) {
        this.updateConversations(response["success"]["conversations"])
        { this.setState({ paging : response["success"]["_paging"] }) }
      }
      else {
        this.setState({
          error: response["failure"]["error"]
      });
      }
      this.setState({
        loadingInBackground : false
      });
    }
  }

  async getConversation(id,showLoading=true,after=null) {
    if (this.props.conversationID != id){
      this.setState({
        readCooldown: 0
      })
    }
    this.props.setConversationID(id)
    if (showLoading){
      this.setState({
        isConversationLoaded : false
      });
    }
    if (after) {
      var response = await apiCall(`conversation?id=${id}&limit=50&after=${this.state.conversation.lastMessageTime}`,{ "method" : "GET" });
    }
    else {
      var response = await apiCall(`conversation?id=${id}&limit=50`,{ "method" : "GET" });
    }
    if (response["success"]) {
      if (after){ this.updateConversation(response["success"]["messages"]) }
      else { 
        this.setState({
          messagePaging : response["success"]["_paging"],
          messages : response["success"]["messages"],
          conversation : response["success"]["conversation"]
        });
      }
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
    this.setState({
      isConversationLoaded : true
    })
  }

  async getMoreConversation() {
    if (!this.state.loadingConversationInBackground) {
      this.setState({
        loadingConversationInBackground : true
      });
      var response = await apiCall(`conversation?id=${this.state.conversation._id}&limit=50&skip=${this.state.messages.length}`,{ "method" : "GET" });
      if (response["success"]) {
        this.updateConversation(response["success"]["messages"],true)
        { this.setState({ messagePaging : response["success"]["_paging"] }) }
      }
      else {
        this.setState({
          error: response["failure"]["error"]
      });
      }
      this.setState({
        loadingConversationInBackground: false
      });
    }
  }

  async markConversationAsRead(id){
    var response = await apiCall(`conversation/read?id=${id}`,{ "method" : "PUT", "data" : {"reader":this.state.conversation.participants.filter(function(participant) {return participant.hasOwnProperty("me") && participant.me})[0].id} });
    if (response["success"]) {
      this.showConversation(this.props.conversationID,null,false)
      this.reloadConversations(this.props.conversationID);
      var existingConversations = this.state.conversations;
      var existingConversation = this.state.conversation
      var matchingIndex = existingConversations.findIndex(existingConversation => existingConversation._id == id)
      existingConversation["unreadMessages"] = 0
      existingConversations[matchingIndex]["unreadMessages"] = 0
      this.setState({
        conversation : existingConversation,
        conversations : existingConversations
      })
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
  }

  async markAllConversationsAsRead(){
    var response = await apiCall(`conversations/read/all`,{ "method" : "PUT" });
    if (response["success"]) {
      this.props.setChangeCounter(Math.floor(Math.random() * 10000))
      this.getConversations(true,null)
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
    this.props.setViewMarkAllAsReadModal(false)
  }

  async markConversationAsUnread(id){
    var response = await apiCall(`conversation/unread?id=${id}`,{ "method" : "PUT", "data" : {"reader":this.state.conversation.participants.filter(function(participant) {return participant.hasOwnProperty("me") && participant.me})[0].id} });
    if (response["success"]) {
      this.showConversation(this.props.conversationID,null,false)
      this.reloadConversations();
      this.setState({
        readCooldown: 3
      })
      var existingConversations = this.state.conversations;
      var matchingIndex = existingConversations.findIndex(existingConversation => existingConversation._id == id)
      existingConversations[matchingIndex]["unreadMessages"] = 1
      this.setState({
        conversations : existingConversations
      })
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
  }

  async muteConversation(id){
    var response = await apiCall(`conversation/mute?id=${id}`,{ "method" : "PUT", "data" : {"muter":this.state.conversation.participants.filter(function(participant) {return participant.hasOwnProperty("me") && participant.me})[0].id} });
    if (response["success"]) {
      this.reloadConversations();
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
  }

  async unmuteConversation(id){
    var response = await apiCall(`conversation/unmute?id=${id}`,{ "method" : "PUT", "data" : {"muter":this.state.conversation.participants.filter(function(participant) {return participant.hasOwnProperty("me") && participant.me})[0].id} });
    if (response["success"]) {
      this.reloadConversations();
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
  }

  updateDueDate(bid,dueDate=null,propClass=null){
    if (dueDate){
      propClass.props.setDueDate(new Date(dueDate*1000).toISOString().slice(0,16));
    }
    else{
      propClass.props.setDueDate(new Date().toISOString().slice(0,16));
    }
    propClass.props.setDueDateModal({"visible":true,"bids":[bid],"multiple":false,"isChat":true,"selectedBids":[bid.details.id]});
  }

  ChangeDueDateModal(props){
    var error = null;
    const handleClose = e => {
      props.class.props.setDueDateModal({"visible":false,"bidID":null,"multiple":false})
    }
    const handleSubmit = async e => {
      e.preventDefault();
      props.class.props.setIsSubmitting(true)
      var response = await apiCall(`campaign/bid/dueDate?id=${props.class.props.dueDateModal.bids[0].details.campaignID}`,{ "method" : "PUT", "data" : {"bids":props.class.props.dueDateModal.selectedBids,"message":props.class.props.reason,"dueDate":datePickerToEpoch(props.class.props.dueDate)} });
      if (response["success"]) {
        props.class.props.setReason(null)
        handleClose()
        props.class.getConversation(props.class.props.conversationID,false,true)
      }
      else {
        error = response["failure"]["error"]
      };
      props.class.props.setIsSubmitting(false)
    }
    if (props.class.props.dueDateModal.visible){
      return (
        <Modal show={props.class.props.dueDateModal.visible} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{ "Change Due Date for " + props.class.props.dueDateModal.bids[0].profile.name }</Modal.Title>
          </Modal.Header>
          <Form>
            <Modal.Body>
              <Form.Group className="mb-3" >
                  <b>Content to be completed by:</b>
                  <Form.Label>Please select the new due date to complete and share content by. This date can be changed again later if needed.</Form.Label>
                  <Form.Group className="mb-3">
                    <Form.Control 
                      type="datetime-local"
                      id="dueDate"
                      defaultValue={props.class.props.dueDate}
                      onChange={e => props.setDueDate(e.target.value)}
                    />
                    {/* <Form.Check 
                      type="checkbox"
                      label="Set date range"
                      id="isRange"
                    /> */}
                  </Form.Group>
                  <b>Enter a message to send to the influencer</b>
                  <Form.Control 
                    onChange={e => props.class.props.setReason(e.target.value)}
                    as="textarea"
                    id="message"
                    placeholder="Write your message here..."
                    rows={5}
                  />
                  {props.class.props.dueDateModal.multiple && <p>Please note that the date selected and message written will be sent to all selected bidders.</p>}
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary" disabled={props.class.props.isSubmitting || props.class.props.dueDateModal.selectedBids.length == 0} type="submit" onClick={handleSubmit}>
                {props.class.props.isSubmitting ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Updating...</> : "Update Due Date"}
              </Button>
              {error && <div className="error text-danger small ms-2">{error}</div>}
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    else{
      return (<></>)
    }
  }

  ConversationListItem(conversation){
    var recipient = {"name":"unknown","profileImage":"None"}
    var isMuted = false
    conversation.participants.forEach(function (participant) {
      if (!participant.hasOwnProperty("me")){
        recipient = participant.details // We'll need to handle maybe group conversations in the future? Not sure if really needed...
      }
      else {
        isMuted = participant.muted
      }
    })
    let query = new URLSearchParams(window.location.search);
    var autoLoadConversationID = query.get('conversation_id');
    return (
      <div className={conversation.id == conversation.selectedConversation ? "p-3 inboxCard selected" : "p-3 inboxCard" } onLoad={conversation.id == conversation.selectedConversation ? (event) => { conversation.class.showConversation(conversation.id,conversation.setChatBox); event.target.scrollIntoView({ block : "center" }) } : null } onClick={()=>conversation.class.showConversation(conversation.id,conversation.setChatBox)}>
        <div className="pe-3">
          <img src={getImageUrl(conversation.class.props.providers,recipient.profileImage)} width="46px" height="46px" className="object-fit-cover rounded border bg-white" alt={recipient.name} />
          <div className="mt-3 text-center">
            {isMuted && <img src={mute} width="20px" height="20px" />}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="brandDetails">
            <div className="d-flex align-items-center pe-4 me-4 mb-1">
              <h6 className="fw-700 mb-0 me-2 text-truncate">
                {recipient.name || `${recipient.firstName} ${recipient.lastName}`}
              </h6>
              {conversation.bidDetails && (getStatusPill(conversation.bidDetails.state))}
            </div>
            {conversation.conversationType == "support" && (
                <Badge bg="primary" className="smaller fw-normal">{conversation.conversationTitle}</Badge>
            )}
            <div className="d-flex align-items-center justify-content-between">
              <div className="text-truncate text-muted small">{typeof(conversation.lastMessage.contents) === "string" ? conversation.lastMessage.contents : <i>An attachment was sent</i>} </div>
              {conversation.unreadMessages > 0 && <Badge bg="primary" className="smaller ms-2 fw-normal">{conversation.unreadMessages}</Badge>}
            </div>
            {conversation.bidDetails && (
              <div className="bg-white border-gray rounded text-muted small mt-2 p-1 text-truncate text-nowrap">
                <img src={getImageUrl(conversation.class.props.providers,conversation.bidDetails.image)} className="rounded me-1" width="30px" height="30px" /> {conversation.bidDetails.title}
              </div>
            )}
          </div>
          <div>
            <div className="text-muted smaller position-absolute mt-3 me-3 end-0 top-0 text-nowrap">{relativeTimeFormat(conversation.lastMessage.messageTime*1000,true)}</div>
          </div>
        </div>
      </div>
    );}

  ConversationDetailItem(props){
      var socialStats = props.class.props.socialStats;
      var socials = []
      var recipient = {"name":"unknown","profileImage":"None"};
      var me = {"id":"","name":"unknown","profileImage":"None"};
      var active = false;
      var muted = false;
      var archived = false;
      var unblockChat = false;
      var participantType = null
      props.participants.forEach(function (participant) {
        if (!participant.hasOwnProperty("me")){
          recipient = participant.details // We'll need to handle maybe group conversations in the future? Not sure if really needed...
          if (props.conversationType == "bids" && props.bidDetails.state == "Pending" && participant.type == "influencer" && participant.active == false) {
            unblockChat = true;
          }
        }
        else {
          me = participant.details
          active = participant.active
          archived = participant.archived
          muted = participant.muted
          participantType = participant.type
        }
      })
      if (recipient.socials != null && participantType == "brand"){
        socials = recipient.socials.filter(function(social) { return social.name != "meta" });
      }

      const AlwaysScrollToBottom = () => {
        const elementRef = useRef();
        useEffect(() => { 
          if (props.class.props.autoScroll)
          {
            elementRef.current.scrollIntoView()
          }
        });
        return <div ref={elementRef} />;
      }

      const KeepLastMessageVisible = () => {
        const elementRef = useRef()
        useEffect(() => { 
          if (props.class.props.oldestMessage.scroll)
          {
            elementRef.current.scrollIntoView()
            props.class.props.setOldestMessage({"scroll":false,"id":null})
          }
        });
        return <span ref={elementRef} />;
      }

      const chatAreaScroll = e => {
        const bottom = e.target.scrollHeight - e.target.scrollTop >= e.target.clientHeight - 10 & e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 10;
        if (bottom) {
          props.class.props.setAutoScroll(true)
        } 
        else if (e.target.scrollTop == 0 && props.class.state.messages.length < props.class.state.messagePaging.total) {
          props.class.props.setOldestMessage({"scroll":true,"id":props.class.state.messages.slice().reverse()[0]._id})
          props.class.getMoreConversation()
        } 
        else {
          props.class.props.setAutoScroll(false)
        }
      }

      return (
        <div className={`chatBox rounded-end h-100 ${!props.chatBox ? "hide": ""}`}>
          <div className="px-3 chatHeader">
            <div className="py-2 mb-2 d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center text-truncate" onClick={()=>(props.class.props.setSocialStats(false))}>
                <a className="mobileView pe-3" onClick={()=>props.setChatBox(false)}><img src={backArrow} /></a>
                <div className="pe-3">
                  <img src={getImageUrl(props.class.props.providers,recipient.profileImage)} width="46px" height="46px" className="object-fit-cover rounded border bg-white" alt={recipient.name || `${recipient.firstName} ${recipient.lastName}`} />
                </div>
                <div className="text-truncate pe-4">
                  <h6 className="mb-0 mw-100 text-truncate">{props.conversationType == "support" ? props.conversationTitle : recipient.name || `${recipient.firstName} ${recipient.lastName}`}</h6>
                  {props.bidDetails && (
                  <Link className="text-blue small" to={participantType == "brand" ? "/Campaign/Preview?id="+props.bidDetails.campaignID : "/Campaign?id="+props.bidDetails.campaignID} ><img className="mb-1 me-1" src={rightArrow} /> {props.bidDetails.title}</Link> 
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="d-none d-xl-block me-3">
                  <div className="d-flex align-items-start">
                    {socials.map(social => (
                      <div key={social.name} className="ms-3 d-flex align-items-start">
                        {getSocialBadge(social.name,null,"20px","colour",social.handle)}
                        {(social.hasOwnProperty("data") && social.data.isVerified) && <img src={blueTick} className="blueTick" />}
                        <div className="ms-1 small">
                          <div className="text-muted smaller">{(social.hasOwnProperty("data") && social.data.followers > 0 && getSmallNumber(social.data.followers)) || ""}</div>      
                          {social.hasOwnProperty("data") && social.data.hasOwnProperty("engagementRate") && social.data.engagementRate > 0 ? <Badge bg="info">{social.data.engagementRate.toFixed(2)}%</Badge>  : ""}    
                        </div>
                      </div> 
                    ))}
                  </div>
                </div>
                <div className="socialStats d-block d-xl-none me-3">
                  <button type="button" className="btn btn-light bg-white border px-2" onClick={()=>(props.class.props.setSocialStats((socialStats) => !socialStats))}><img src={social} /></button>
                  <div className={`socialDropDown ${!socialStats ? "hide" : ""}`}>
                    {socials.map(social => (
                      <div key={social.name} className="mt-3 d-flex align-items-start">
                        {getSocialBadge(social.name,null,"20px","colour",social.handle)}
                        {(social.hasOwnProperty("data") && social.data.isVerified) && <img src={blueTick} className="blueTick" />}
                        <div className="ms-1">
                          <div className="text-muted smaller">{(social.hasOwnProperty("data") && social.data.followers > 0 && getSmallNumber(social.data.followers)) || ""}</div>      
                          {social.hasOwnProperty("data") && social.data.hasOwnProperty("engagementRate") && social.data.engagementRate > 0 ? <Badge bg="info">{social.data.engagementRate.toFixed(2)}%</Badge>  : ""}    
                        </div>
                      </div> 
                    ))}
                  </div>
                </div>
                <DropdownButton drop="start" variant="light" className="moreVertical" title="" onClick={()=>(props.class.props.setSocialStats(false))}>
                  {props.unreadMessages == 0 ? <Dropdown.Item className="small" onClick={() => props.class.markConversationAsUnread(props.id)}>Mark as Unread</Dropdown.Item> : <Dropdown.Item className="small" onClick={() => props.class.markConversationAsRead(props.id)}>Mark as Read</Dropdown.Item>}
                  {muted ? <Dropdown.Item className="small" onClick={() => props.class.unmuteConversation(props.id)}>Unmute Notifications</Dropdown.Item> : <Dropdown.Item className="small" onClick={() => props.class.muteConversation(props.id)}>Mute Notifications</Dropdown.Item>}
                  {/* <Dropdown.Divider />
                  <Dropdown.Item className="small text-danger">Report & Block</Dropdown.Item> */}
                </DropdownButton>
              </div>
            </div>
          </div>

          <div className="chatBody" onClick={()=>(props.class.props.setSocialStats(false))}>
            <div className="chatArea" onScroll={chatAreaScroll}>
              {props.messages.length >= props.class.state.messagePaging.total ? <div className="text-center text-muted py-3 small">This is the start of your conversation with {recipient.name || `${recipient.firstName} ${recipient.lastName}`}</div> : <div className="text-center my-2"><span className="spinner-border text-primary" role="status" aria-hidden="true"></span></div> }
              {[...props.messages].reverse().map(message => (
                <div key={message._id}>
                  {message._id == props.class.props.oldestMessage.id && <KeepLastMessageVisible />}
                  {message.messageType == "update" ? (
                    <div className="text-center text-muted py-3 small">
                      {message.contents}
                      <span className="smaller ps-2">({relativeTimeFormat(message.messageTime*1000)})</span>
                      {/* <span className="smaller ps-2">({(new Date(message.messageTime*1000)).toLocaleString('en-US', { weekday:"long", day:"numeric", month:"short", year:"numeric", hour:"2-digit", minute:"2-digit" })})</span> */}
                    </div>
                  ) :
                  (
                    <>
                    {/* <div className="mb-4 text-center border-bottom h-15px">
                      <span className="fw-normal border text-muted smaller badge text-dark bg-gray">{isToday(new Date(message.messageTime*1000)) ? "Today" : (new Date(message.messageTime*1000)).toLocaleDateString('en-GB', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}</span>
                    </div> */}
                    <div className={message.sender != me._id ? "bubbleLeft" : "bubbleRight"}>
                      <div className={message.sender != me._id ? "bubbleLeftBoxes" : "bubbleRightBoxes"}>
                        <div className={message.sender != me._id ? "bubbleLeftText" : "bubbleRightText"}>
                          {message.messageType == "image" && <div className="imagesBoxView h-auto cursor-pointer"><img src={getImageUrl(props.class.props.providers,message.contents,["w_250","c_scale"])} className="object-fit-cover rounded-xl mt-2 me-2 mb-2 w-100" height="100px" alt={`Image from ${recipient.name}`} onClick={() => {props.class.props.setShowCarousel({visible:true,image:message.contents})}} aria-controls="imageViewer" /></div>}
                          {message.messageType == "file" && <div><a href={getFileUrl(props.class.props.providers,message.contents)} download={message.contents.name}><img src={Document} height="60px" className="object-fit-contain rounded" alt={`Document from ${recipient.name}`} /></a>{message.contents.name}</div>}
                          {message.messageType == "video" && <div><a href={getVideoURL(props.class.props.providers,message.contents)}><img src={Video} height="60px" className="object-fit-contain rounded" alt={`Document from ${recipient.name}`} /></a></div>}
                          {message.messageType == "text" && formatText(message.contents)}
                          <div className={message.sender != me._id ? "smaller text-muted d-flex align-items-center pt-1 text-nowrap" : "smaller d-flex align-items-center pt-1 text-nowrap" }>
                          {relativeTimeFormat(message.messageTime*1000)} {(message.read && message.sender == me._id) && <img className="ms-1" src={message.sender != me._id ? grayDoubleChecks : whiteDoubleChecks }/>}
                          </div>
                        </div>
                      </div>
                      {message.sender == me._id && (
                        <div>
                          <img src={getImageUrl(props.class.props.providers,me.profileImage)} className="rounded" width="30px" height="30px" alt={me.name} />
                        </div>
                      )}
                    </div>
                    </>
                  )}
                  <AlwaysScrollToBottom />
                </div>
              ))} 
            </div>
          </div>

          {props.class.props.showCarousel.visible && (
            <Fade in={props.class.props.showCarousel.visible}>
              <div id="imageViewer">
                <div className="carouselControl">
                <a href={getImageUrl(props.class.props.providers, props.class.props.showCarousel.image,null,true)} download target="_blank" className="btn btn-sm btn-success">Download</a>
                  <button type="button" onClick={() => props.class.props.setShowCarousel({visible:false,image:null})} className="btn btn-sm btn-light closeImageViewer">Close</button>
                </div>
                <Carousel>
                  <Carousel.Item>
                    <img id="0" className="d-block rounded-xl" src={getImageUrl(props.class.props.providers, props.class.props.showCarousel.image,["w_500","c_scale"])} alt="First slide" />
                  </Carousel.Item>
                </Carousel>
              </div>
            </Fade> 
          )}        

          <div className="chatFooter border-top">
            <div className="position-relative d-flex justify-content-center">
            {props.conversationType == "bids" && (
              <>
              {(props.bidDetails.state == "Accepted" || props.bidDetails.state == "In Progress") && (
                <Alert variant="light" className="d-flex align-items-center justify-content-between border-gray px-3 p-2 alertBar shadow-sm border">
                  {participantType == "influencer" ? ( 
                  <>
                    <div className="smaller">Reminder: Please create and share your content by <strong>{new Date(props.bidDetails.dueDate*1000).toLocaleDateString('en-GB', { year:"numeric", month:"long", day:"numeric"})}</strong></div>
                    <Link to={`/MyBid?id=${props.bidDetails.id}`} className="text-primary ms-3 fw-500 small text-nowrap">Complete</Link>
                  </>
                  ) : (
                    <>
                      <div className="smaller">Due date for content to be shared is: <strong>{new Date(props.bidDetails.dueDate*1000).toLocaleDateString('en-GB', { year:"numeric", month:"long", day:"numeric"})}</strong></div>
                      <a onClick={() => props.updateDueDate({"details":props.bidDetails,"profile":recipient},props.bidDetails.dueDate,props.class)} className="text-primary ms-3 fw-500 small text-nowrap">Change date</a>
                    </>
                  )  
                }
                </Alert>
              )}
              {props.bidDetails.state == "Pending" && (
                <Alert variant="light" className="d-flex align-items-center justify-content-between border-gray px-3 p-2 alertBar shadow-sm border">
                  {participantType == "influencer" ? ( 
                  <>
                    {active == true ? (
                      <div className="smaller">Your bid is still pending approval from <strong>{recipient.name || `${recipient.firstName} ${recipient.lastName}`}</strong>, but you can message them as they've initiated a conversation.</div>
                    ) : (
                      <div className="smaller">Your bid is still pending approval from <strong>{recipient.name || `${recipient.firstName} ${recipient.lastName}`}</strong>, so you won't be able to send any more messages to them at this moment.</div>
                    )}
                  </>
                  ) : (
                    <>
                      <div className="smaller">This bid from <strong>{recipient.name || `${recipient.firstName} ${recipient.lastName}`}</strong> is still pending, don't forget to approve/reject their bid!</div>
                      <Link to={`/Campaign/Manage?id=${props.bidDetails.campaignID}&view=bids`} className="text-primary ms-3 fw-500 small text-nowrap">Review bids</Link>
                    </>
                  )  
                }
                </Alert>
              )}
              {props.bidDetails.state == "Rejected" && (
                <Alert variant="light" className="d-flex align-items-center justify-content-between border-gray px-3 p-2 alertBar shadow-sm border">
                  {participantType == "influencer" && ( 
                    <div className="smaller">Your bid is was rejected by <strong>{recipient.name || `${recipient.firstName} ${recipient.lastName}`}</strong>, so you won't be able to send any messages to them at this moment about this campaign.</div>
                  )}
                </Alert>
              )}
              {props.bidDetails.state == "Cancelled" && (
                <Alert variant="light" className="d-flex align-items-center justify-content-between border-gray px-3 p-2 alertBar shadow-sm border">
                  {participantType == "influencer" && ( 
                    <div className="smaller">Your bid with <strong>{recipient.name || `${recipient.firstName} ${recipient.lastName}`}</strong> has been cancelled, so you won't be able to send any messages to them at this moment about this campaign.</div>
                  )}
                  {participantType == "brand" && ( 
                    <div className="smaller"><strong>{recipient.name || `${recipient.firstName} ${recipient.lastName}`}'s</strong> bid has been cancelled, so they won't be able to send any messages to you at this moment about this campaign.</div>
                  )}
                </Alert>
              )}
              </>
            )}
            </div>
            <SendMessageForm sendMessage={props.sendMessage} recipient={recipient} conversationID={props.id} sender={me._id} showConversation={props.showConversation} active={active && !archived} reloadConversations={props.reloadConversations} unblockChat={unblockChat}/>
          </div>
        </div>
      );
    }

  pageOnScroll(e){
    const bottom = e.target.scrollHeight - e.target.scrollTop >= e.target.clientHeight - 10 & e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 10;
    if (bottom && this.state.conversations.length < this.state.paging.total) {
      this.getMoreConversations(this.state.conversations.length)
    }
    this.state.scrollTop = e.target.scrollTop
  }

  render() {
    const handleFilterUpdate = (value,added,type) => {
      var filters = this.props.inboxFilters;
      if (type == "search") {
        filters.search = value.target.value.toLowerCase();
      }
      // else if (type == "archived") {
      //   filters.archived = value
      // }
      // else if (type == "unread") {
      //   filters.unread = value
      // }
      else if (type === "bidType") {
        if (value === "All") {
          filters.unread = false
          filters.archived = false
          delete filters["bidType"]
        }
        else if (value === "Unread") {
          filters.unread = true
          filters.archived = false
        }
        else if (value === "Archived") {
          filters.archived = true
          filters.unread = false
        }
        else {
          filters.bidType = value
          filters.unread = false
          filters.archived = false

        }
      }
      this.props.setChangeCounter(Math.floor(Math.random() * 10000))
      this.props.setInboxFilters(filters)
      setFilter(filters,"conversation")
      this.getConversations()
    }
      const { error, isLoaded, conversations, conversation, messages, isConversationLoaded, paging } = this.state;
      const searchFilter = this.props.inboxFilters.search;
      const conversationLength = conversations.length;
      if (error) {
        return <div className="text-danger text-center h-100 d-flex align-items-center justify-content-start px-4 px-md-0 py-5 flex-column fs-5 bg-secondary"><img src={NoAccess} className="noAccessImg my-5 rounded-xl shadow" /><div>Error: {error}</div></div>;
      } else if (!isLoaded) {
        return <FullPageSpinner />;
      } else {
      return (
        <Row className="mx-0 px-md-2 pb-md-1 pb-lg-0">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 senderList rounded-start shadow-sm" onScroll={(e) => this.pageOnScroll(e)}>
            <div className="mb-2 mt-4 mt-md-0 py-2 text-center d-block d-md-none">
              <div className="sectionTitle">Inbox</div>
            </div>

            <div className="inboxSearch pt-1 pt-md-0">
              <InputGroup className="py-1">
                <InputGroup.Text className="bg-white">
                  <img src={Search} />
                </InputGroup.Text>
                <Form.Control placeholder="Search" className="border-start-0" defaultValue={this.props.inboxFilters.search} onChange={e => { handleFilterUpdate(e,true,"search") } } />
                <Button variant="default" size="sm" className="border border-start-0 border-end-0" onClick={() => {this.props.setViewMarkAllAsReadModal(true)}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-open" viewBox="0 0 16 16">
                    <path d="M8.47 1.318a1 1 0 0 0-.94 0l-6 3.2A1 1 0 0 0 1 5.4v.817l5.75 3.45L8 8.917l1.25.75L15 6.217V5.4a1 1 0 0 0-.53-.882zM15 7.383l-4.778 2.867L15 13.117zm-.035 6.88L8 10.082l-6.965 4.18A1 1 0 0 0 2 15h12a1 1 0 0 0 .965-.738ZM1 13.116l4.778-2.867L1 7.383v5.734ZM7.059.435a2 2 0 0 1 1.882 0l6 3.2A2 2 0 0 1 16 5.4V14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5.4a2 2 0 0 1 1.059-1.765z"/>
                    <title>Select to mark all conversations as read</title>
                  </svg>
                </Button>
                {this.props.viewMarkAllAsReadModal &&
                  <Modal centered show>
                    <Modal.Header className="bg-primary text-white">
                      <Modal.Title className="h6">Mark all conversations as read</Modal.Title>
                    </Modal.Header>
                    <Form>
                      <Modal.Body className="pt-0">
                        <div className="pt-3">
                          <p className="fw-bold mb-2">Are you sure you want to mark all conversations as read?</p>
                        </div>
                      </Modal.Body>
                      <Modal.Footer className="p-3 pt-0 border-0">
                        <Button variant="outline-secondary" size="sm" className="m-0 me-2" onClick={() => {this.props.setViewMarkAllAsReadModal(false)}}>
                          Cancel
                        </Button>
                        <Button variant="success" size="sm" className="m-0" type="submit" onClick={(e) => {e.preventDefault(); this.markAllConversationsAsRead()}}>
                          Mark as read
                        </Button>
                      </Modal.Footer>
                    </Form>
                  </Modal>
                }
                <Dropdown onSelect={e => { handleFilterUpdate(e,true,"bidType") }}>
                  <Dropdown.Toggle variant="white" className="messageFilterBtn border border-start-0">
                    <img src={filter} height="18px" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow">
                    <Dropdown.Item eventKey={"All"} active={!this.props.inboxFilters.bidType}>All</Dropdown.Item>
                    <Dropdown.Item eventKey={"Accepted"} active={this.props.inboxFilters.bidType === "Accepted"}>Accepted</Dropdown.Item>
                    <Dropdown.Item eventKey={"Pending"} active={this.props.inboxFilters.bidType === "Pending"}>Pending</Dropdown.Item>
                    <Dropdown.Item eventKey={"In Progress"} active={this.props.inboxFilters.bidType === "In Progress"}>In Progress</Dropdown.Item>
                    <Dropdown.Item eventKey={"Marked as Complete"} active={this.props.inboxFilters.bidType === "Marked as Complete"}>Marked as Complete</Dropdown.Item>
                    <Dropdown.Item eventKey={"Completed"} active={this.props.inboxFilters.bidType === "Completed"}>Completed</Dropdown.Item>
                    <Dropdown.Item eventKey={"Rejected"} active={this.props.inboxFilters.bidType === "Rejected"}>Rejected</Dropdown.Item>
                    <Dropdown.Item eventKey={"Cancelled"} active={this.props.inboxFilters.bidType === "Cancelled"}>Cancelled</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item eventKey={"Unread"} active={this.props.inboxFilters.unread}>Unread</Dropdown.Item>
                    <Dropdown.Item eventKey={"Archived"} active={this.props.inboxFilters.archived}>Archived</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <Button variant="primary" size="sm" className="bg-white border-top border-bottom border-end border-start-0"><img title={this.props.filterUnreadMessages ? "Show all messages" : "Show only unread messages"} tool src={this.props.filterUnreadMessages ? unread : read} onClick={() => { this.props.setFilterUnreadMessages(!this.props.filterUnreadMessages); updateURLArgument("unread",!this.props.filterUnreadMessages); handleFilterUpdate(null,null,null); }} /></Button>
                <Button variant="primary" size="sm" className="bg-white border-top border-bottom border-end border-start-0"><img src={archive} onClick={() => handleFilterUpdate(!this.props.inboxFilters.archived,true,"archived")} /></Button> */}
              </InputGroup>
            </div>
            <this.ChangeDueDateModal class={this} setDueDate={this.props.setDueDate}/>
            <div className="conversationListItem" onScroll={(e) => this.pageOnScroll(e)}>
              {this.props.inboxFilters.bidType ? 
              <div className="text-center text-muted my-2"><strong>
                Showing {this.props.inboxFilters.archived && " Archived "} {this.props.inboxFilters.unread && " Unread "} {this.props.inboxFilters.bidType} Messages
              </strong></div>
              :
              <div className="text-center text-muted my-2"><strong>
                Showing All {this.props.inboxFilters.archived && " Archived "} {this.props.inboxFilters.unread && " Unread "} Messages
              </strong></div>
              }
              {conversations.filter(function(conversation) { var valid=false; conversation.participants.forEach(function (participant) {
                if (!participant.hasOwnProperty("me")){
                  try {
                    if (participant.details.name.toLowerCase().includes(searchFilter)) {
                      valid = true;
                    }
                  }
                  catch { if ((participant.details.firstName && participant.details.firstName.toLowerCase().includes(searchFilter)) || participant.details.lastName && participant.details.lastName.toLowerCase().includes(searchFilter)) { valid = true } }
                } }) ; if (valid) { return conversation }  }).map(conversation => <this.ConversationListItem key={conversation._id} id={conversation._id} participants={conversation.participants} lastMessage={conversation.lastMessage} unreadMessages={conversation.unreadMessages} setChatBox={this.props.setChatBox} chatBox={this.props.chatBox} bidDetails={conversation.bidDetails} class={this} selectedConversation={this.props.conversationID} conversationType={conversation.conversationType} conversationTitle={conversation.title} />)
              }
              <div className="text-center my-2">
                {conversationLength < paging.total && <span className="spinner-border text-primary" role="status" aria-hidden="true"></span>}
              </div> 
            </div> 
          </div>
          {conversationLength > 0 ? (
            <>
            {isConversationLoaded ? (
              <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9 px-0 conversationDetail">
                <this.ConversationDetailItem key={conversation._id} loading={false} messages={messages} id={conversation._id} participants={conversation.participants} setChatBox={this.props.setChatBox} chatBox={this.props.chatBox} bidDetails={conversation.bidDetails} sendMessage={this.props.sendMessage} showConversation={this.showConversation} conversationType={conversation.conversationType} conversationTitle={conversation.title} reloadConversations={this.reloadConversations} unreadMessages={conversation.unreadMessages} updateDueDate={this.updateDueDate} class={this} />
              </div>
            ) :
            (
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9 px-0 conversationDetail">
              <div className={`chatBox rounded-end h-100`}>  
                <InboxLoader key={"empty"}/>
              </div>
            </div>
            )
            }
            </>
            ) : 
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9 px-0 conversationDetail">
                  <div className="text-center py-5">No conversations available</div>
            </div>
          }
        </Row>
      )
    }
  }
}

export default function (props) {
  let [searchParams] = useSearchParams();
  const { providers } = useImageContext();
  const conversation = searchParams.get('conversation_id');
  const [conversationID, setConversationID] = useState(conversation);
  const [chatBox, setChatBox] = useState(false);
  const [message, sendMessage] = useState(null);
  const [reason, setReason] = useState(null)
  const [dueDate, setDueDate] = useState(new Date().toISOString().slice(0,16));
  const [dueDateModal, setDueDateModal] = useState({"visible":false,"bidID":null,"multiple":false})
  const [socialStats, setSocialStats] = useState(false);
  const [ autoScroll, setAutoScroll ] = useState(true);
  const [ oldestMessage, setOldestMessage ] = useState({"scroll":false,"id":null});
  const [ changeCounter, setChangeCounter ] = useState(0);
  const { currentProfile } = useAuthContext();
  const [inboxFilters, setInboxFilters] = useState({"search":"","archived":false});
  const [ filterUnreadMessages, setFilterUnreadMessages ] = useState(false);
  const [showCarousel, setShowCarousel] = useState({"visible":false,"image":null});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [viewMarkAllAsReadModal, setViewMarkAllAsReadModal] = useState(false);

  return (
    <div className={`bg-page inboxPage ${currentProfile.type == "admins" && "adminInbox"}`}>
      <Topbar />
      <div className="pageName">Inbox</div>
      <Container className="fullscreenWithTopBar px-0" fluid>
        <Container fluid className="pb-0">
          <div className="px-0 pt-0 pt-md-4">
            <Conversation chatBox={chatBox} setChatBox={setChatBox} sendMessage={sendMessage} conversationID={conversationID} setConversationID={setConversationID} reason={reason} setReason={setReason} dueDate={dueDate} setDueDate={setDueDate} dueDateModal={dueDateModal} setDueDateModal={setDueDateModal} providers={providers} socialStats={socialStats} setSocialStats={setSocialStats} autoScroll={autoScroll} setAutoScroll={setAutoScroll} inboxFilters={inboxFilters} setInboxFilters={setInboxFilters} showCarousel={showCarousel} setShowCarousel={setShowCarousel} oldestMessage={oldestMessage} setOldestMessage={setOldestMessage} setChangeCounter={setChangeCounter} filterUnreadMessages={filterUnreadMessages} setFilterUnreadMessages={setFilterUnreadMessages} isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} viewMarkAllAsReadModal={viewMarkAllAsReadModal} setViewMarkAllAsReadModal={setViewMarkAllAsReadModal} />
          </div>
        </Container>      
      </Container>
      <Footer />
    </div>
  );
}