import React, { useState } from "react";
import { Container, Row } from 'react-bootstrap';
import SupportModal from "../../components/support/support";

import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";

export default function NoViews({ }) {
  const [supportModal, setSupportModal] = useState({"visible":true,"type":"No Associated Profiles","message":"","context":"user"})
  return(
    <div className="bg-page">
    <Topbar />
    <div className="pageName">No associated profiles</div>
    <Container className="fullscreenWithTopBar px-0 bg-white" fluid>
      <Container className="px-0 h-100 pb-5">
        <Row className="d-flex align-items-center justify-content-center h-100">
          <div className="col-md-6 col-12 d-flex flex-column align-items-center justify-content-center h-100">
            <p>It looks like you don't have any influencers or brands associated with your account. Please contact support via the form below.</p>
            <SupportModal supportModal={supportModal} setSupportModal={setSupportModal} isInternal={false} isPopup={false} relatedIDs={[]}/>
          </div>
        </Row>
      </Container>
    </Container>
    <Footer />
    </div>
  )
}