import React from 'react';
import { NavLink } from 'react-router-dom'
import { Component } from 'react';
import { useAuthContext } from "../../hooks/useAuthContext";

import './AdminNav.css';

class AdminNav extends Component {
  state = { show: false }
  toggle = () => this.setState((currentState) => ({show: !currentState.show}));

  render() {
    return (
      <div className="leftSideMenuShift col-xxl-2 pt-0 pb-0 pt-xl-4 pb-xl-4 border-end bg-white">

        <div className="leftSideMenu pb-xl-4 px-md-3">
          {this.props.currentProfile.type == "admins" && 
            <NavLink to="/Administration/Dashboard" activeclassname="active">
              <span className="me-1 fill stroke">
                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.6304 5.51032L12.6306 5.51018L12.6253 5.50545L7.81641 1.21297C7.34998 0.766217 6.59813 0.763937 6.14537 1.19684L1.32031 5.50551L1.32029 5.50548L1.31809 5.50754C1.03306 5.77468 0.875 6.16356 0.875 6.55114V12.7177C0.875 13.6746 1.65736 14.457 2.61428 14.457L5.2133 14.4569C5.45991 14.4569 5.66113 14.2557 5.66113 14.0091V10.3931C5.66113 10.2846 5.75042 10.1953 5.85895 10.1953H8.08669C8.19523 10.1953 8.28452 10.2846 8.28452 10.3931V14.0252C8.28452 14.2719 8.48574 14.4731 8.73234 14.4731H11.3313C12.2883 14.4731 13.0706 13.6907 13.0706 12.7338V6.5511C13.0706 6.16117 12.911 5.79089 12.6304 5.51032ZM2.61626 13.5773C2.15301 13.5622 1.77609 13.1853 1.77074 12.7274V12.7177V6.5511C1.77074 6.41189 1.83419 6.26517 1.92869 6.15466L6.74938 1.8499L6.74951 1.85005L6.75451 1.84505C6.86417 1.73539 7.05938 1.72934 7.19118 1.86115L7.19104 1.86129L7.19633 1.86602L12.0041 6.15753C12.1112 6.26576 12.175 6.41137 12.175 6.55106V12.7176C12.175 13.1813 11.7951 13.5612 11.3314 13.5612L9.18023 13.5611V10.393C9.18023 9.79125 8.68838 9.29937 8.0866 9.29937H5.85898C5.25724 9.29937 4.76536 9.79121 4.76536 10.393V13.5773H2.61626Z" fill="#A7A7A7" stroke="#A7A7A7" strokeWidth="0.25"/>
                </svg>  
              </span> Dashboard
            </NavLink>
          }
          {this.props.currentProfile.type == "admins" && 
            <NavLink to="/Administration/Applications" activeclassname="active">
              <span className="me-1 fill stroke">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.75702 8.44391C9.34958 8.14446 8.90024 7.8991 8.41949 7.71876C9.44876 7.06524 10.134 5.91579 10.134 4.60824C10.134 2.57733 8.48174 0.925 6.45073 0.925C4.41983 0.925 2.76749 2.57723 2.76749 4.60824C2.76749 5.92607 3.46346 7.08327 4.50634 7.73426C2.41502 8.53582 0.925 10.5693 0.925 12.9469V13.0219H1H1.77314H1.84814V12.9469C1.84814 10.3795 3.92772 8.29128 6.48317 8.29128C7.38458 8.29128 8.22575 8.55184 8.93806 9.00103C8.35384 9.55581 7.98839 10.3394 7.98839 11.2071C7.98839 12.8853 9.35367 14.2503 11.0318 14.2503C12.71 14.2503 14.075 12.8853 14.075 11.2071C14.075 9.52897 12.71 8.16355 11.032 8.16355H10.9899L10.9896 8.16405C10.5504 8.17006 10.1331 8.26961 9.75702 8.44391ZM3.69087 4.60799C3.69087 3.08617 4.92903 1.848 6.45086 1.848C7.97256 1.848 9.21073 3.08616 9.21073 4.60799C9.21073 6.12983 7.97256 7.36799 6.45086 7.36799C4.92917 7.36799 3.69087 6.12982 3.69087 4.60799ZM11.0318 13.3268C9.86286 13.3268 8.91166 12.3757 8.91166 11.2069C8.91166 10.038 9.863 9.08671 11.0318 9.08671C12.2006 9.08671 13.1517 10.038 13.1517 11.2069C13.1517 12.3757 12.2007 13.3268 11.0318 13.3268Z" fill="#A7A7A7" stroke="#A7A7A7" strokeWidth="0.15" />
                  <path d="M11.4934 9.78888V9.71388H11.4184H10.6453H10.5703V9.78888V11.625V11.656L10.5922 11.678L11.6939 12.7797L11.7469 12.8327L11.8 12.7797L12.3466 12.233L12.3996 12.18L12.3466 12.127L11.4934 11.2738V9.78888Z" fill="#A7A7A7" stroke="#A7A7A7" strokeWidth="0.15" />
                </svg>
              </span> Applications
            </NavLink>
          }
          {(this.props.currentProfile.type == "admins" || this.props.views.includes("influencersMultiple")) && 
            <NavLink to="/Administration/Influencers" activeclassname="active">
              <span className="me-1 fill">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M6.14782 1.05392C4.69269 1.05392 3.51302 2.23358 3.51302 3.68871C3.51302 5.14384 4.69269 6.32351 6.14782 6.32351C7.60295 6.32351 8.78262 5.14384 8.78262 3.68871C8.78262 2.23358 7.60295 1.05392 6.14782 1.05392ZM2.45911 3.68871C2.45911 1.65146 4.11057 0 6.14782 0C8.18507 0 9.83653 1.65146 9.83653 3.68871C9.83653 5.72597 8.18507 7.37743 6.14782 7.37743C4.11057 7.37743 2.45911 5.72597 2.45911 3.68871Z" fill="#A7A7A7"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M1.08087 11.9445H11.215C10.9513 9.37877 8.78338 7.37743 6.14811 7.37743C3.51283 7.37743 1.34465 9.37871 1.08117 11.9445H1.08087ZM0 12.4715C0 9.07605 2.75253 6.32352 6.14796 6.32352C9.54338 6.32352 12.2959 9.07605 12.2959 12.4715C12.2959 12.7625 12.06 12.9984 11.769 12.9984H0.526959C0.235954 12.9984 0 12.7625 0 12.4715Z" fill="#A7A7A7"/>
                </svg>
              </span> Influencers
            </NavLink>
          }
            {this.props.currentProfile.type == "admins" && 
              <NavLink to="/Administration/Profiles" activeclassname="active">
                <span className="me-1 fill">                   
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14"/>
                </svg>
                </span> Profiles
              </NavLink>
            }
          {(this.props.currentProfile.type == "admins" || this.props.views.includes("brandsMultiple")) && 
            <NavLink to="/Administration/Brands" activeclassname="active">
              <span className="me-1 fill">
                <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.854489 14.1345C0.627521 14.1332 0.410102 14.0422 0.249999 13.8812C0.089893 13.7203 0 13.5025 0 13.2754V1.73911C0 1.46059 0.110591 1.19355 0.307531 0.996618C0.504465 0.799684 0.771502 0.689087 1.05002 0.689087H8.94998C9.22849 0.689087 9.49553 0.799678 9.69246 0.996618C9.8894 1.19355 10 1.46059 10 1.73911V13.2754C10.0008 13.5595 9.86028 13.8254 9.62519 13.9849C9.39001 14.1444 9.09101 14.1767 8.8273 14.0708L5.1681 12.6072C5.06278 12.5666 4.9461 12.5666 4.84078 12.6072L1.17249 14.0708C1.07143 14.112 0.963478 14.1337 0.854307 14.1345L0.854489 14.1345ZM5.00018 11.6663C5.17134 11.6665 5.34098 11.6988 5.50018 11.7618L9.09107 13.1981V1.73921C9.09107 1.66139 9.02796 1.59828 8.95014 1.59828H1.05018C0.972361 1.59828 0.909252 1.66139 0.909252 1.73921V13.1981L4.50014 11.7617V11.7618C4.65933 11.6988 4.82897 11.6664 5.00014 11.6663L5.00018 11.6663Z" fill="#A7A7A7"/>
                  <path d="M3.18184 4.23009H6.81818C7.06919 4.23009 7.27272 4.4811 7.27272 4.68463C7.27272 4.93564 7.06919 5.13917 6.81818 5.13917H3.18184C2.93082 5.13917 2.72729 4.88816 2.72729 4.68463C2.72729 4.43362 2.93082 4.23009 3.18184 4.23009Z" fill="#A7A7A7"/>
                </svg>
              </span> Brands
            </NavLink>
          }
            {/* <NavLink to="/Administration/Campaigns" activeclassname="active">
              <span className="me-1">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.455 3.70655H9.79777C9.52525 3.70655 9.3072 3.9246 9.3072 4.19712C9.3072 4.46963 9.52525 4.68768 9.79777 4.68768H11.2695L8.08078 7.87636C7.75372 7.64472 7.37217 7.48123 6.96334 7.41307L6.96343 1.67618L7.99905 2.71179C8.09448 2.80723 8.2171 2.86169 8.33972 2.86169C8.46234 2.86169 8.58495 2.80723 8.68039 2.71179C8.87116 2.52102 8.87116 2.20762 8.68039 2.01685L6.81354 0.1499C6.73179 0.0545609 6.60917 0 6.47287 0C6.33656 0 6.21393 0.054466 6.13219 0.1499L4.25167 2.01685C4.0609 2.20762 4.0609 2.52102 4.25167 2.71179C4.44244 2.90256 4.75584 2.90256 4.94661 2.71179L5.98223 1.67618V7.41307C5.5734 7.48123 5.19187 7.64472 4.86478 7.87636L1.6761 4.68768H3.1478C3.42031 4.68768 3.63836 4.46963 3.63836 4.19712C3.63836 3.9246 3.42031 3.70655 3.1478 3.70655H0.490566C0.218054 3.70655 0 3.9246 0 4.19712V6.85435C0 7.12686 0.218051 7.34491 0.490566 7.34491C0.763078 7.34491 0.981132 7.12686 0.981132 6.85435V5.38265L4.16981 8.57133C3.84274 9.03462 3.66564 9.57974 3.66564 10.1792C3.66564 11.7327 4.93289 13 6.48639 13C8.03976 13.0001 9.29352 11.7328 9.29352 10.1792C9.29352 9.57964 9.10275 9.02092 8.78935 8.57133L11.978 5.38265V6.85435C11.978 7.12686 12.1961 7.34491 12.4686 7.34491C12.7411 7.34491 12.9592 7.12686 12.9592 6.85435L12.9591 4.19712C12.9455 3.9246 12.7274 3.70655 12.4549 3.70655H12.455ZM6.47282 12.0189C5.46441 12.0189 4.63315 11.2013 4.63315 10.1792C4.63315 9.17084 5.45081 8.33958 6.47282 8.33958C7.49483 8.33958 8.31249 9.15723 8.31249 10.1792C8.31249 11.1877 7.48125 12.0189 6.47282 12.0189Z" fill="#A7A7A7"/>
                </svg>
              </span> Campaigns
            </NavLink> */}
          {this.props.currentProfile.type == "admins" && 
            <NavLink to="/Administration/Users" activeclassname="active">
              <span className="me-1 fill stroke">
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.05281 5.21552C4.05281 4.02352 5.01916 3.05717 6.21115 3.05717C7.40325 3.05717 8.3695 4.02352 8.3695 5.21552C8.3695 6.40752 7.40325 7.37396 6.21115 7.37396C5.01916 7.37396 4.05281 6.40752 4.05281 5.21552ZM6.21115 2.01384C4.44299 2.01384 3.00947 3.44735 3.00947 5.21552C3.00947 6.98369 4.44299 8.4172 6.21115 8.4172C7.97942 8.4172 9.41284 6.98379 9.41284 5.21552C9.41284 3.44735 7.97942 2.01384 6.21115 2.01384Z" fill="#A7A7A7" stroke="#ABABAB" strokeWidth="0.15"/>
                  <path d="M10.4221 12.1384H2.0001C2.25701 10.0414 4.04457 8.41746 6.21109 8.41746C8.37762 8.41746 10.165 10.0413 10.4221 12.1384ZM6.21097 7.37413C3.29162 7.37413 0.925 9.74059 0.925 12.6601C0.925 12.9482 1.15847 13.1818 1.44667 13.1818H10.9755C11.2636 13.1818 11.4972 12.9482 11.4972 12.6601C11.4972 9.74074 9.13046 7.37413 6.21097 7.37413Z" fill="#A7A7A7" stroke="#ABABAB" strokeWidth="0.15"/>
                  <path d="M9.66279 3.13124C9.66279 3.41933 9.89637 3.65291 10.1845 3.65291H13.5534C13.8415 3.65291 14.075 3.41933 14.075 3.13124C14.075 2.84315 13.8415 2.60957 13.5534 2.60957H10.1845C9.89637 2.60957 9.66279 2.84315 9.66279 3.13124Z" fill="#A7A7A7" stroke="#ABABAB" strokeWidth="0.15"/>
                  <path d="M11.3474 4.81557C11.3474 5.10366 11.5809 5.33724 11.869 5.33724C12.1571 5.33724 12.3907 5.10366 12.3907 4.81557V1.44667C12.3907 1.15847 12.1571 0.925 11.869 0.925C11.581 0.925 11.3474 1.15847 11.3474 1.44667V4.81557Z" fill="#A7A7A7" stroke="#ABABAB" strokeWidth="0.15"/>
                </svg>
              </span> Users
            </NavLink>
          }
          {this.props.currentProfile.type == "admins" && 
            <NavLink to="/Administration/Reporting" activeclassname="active">
              <span className="me-1 fill">                   
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><rect fill="none" height="24" width="24"/><path d="M22,22H2v-2h20V22z M10,2H7v16h3V2z M17,8h-3v10h3V8z"/></svg>
              </span> Reporting
            </NavLink>
          }
          {this.props.currentProfile.type == "admins" && 
            <NavLink to="/Administration/Referrals" activeclassname="active">
              <span className="me-1 fill">                   
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-people" viewBox="0 0 16 16">
                  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"/>
                </svg>
              </span> Referrals
            </NavLink>
          }
          {this.props.currentProfile.type == "admins" && 
            <NavLink to="/Administration/Settings" activeclassname="active">
              <span className="me-1 fill">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.455 3.70655H9.79777C9.52525 3.70655 9.3072 3.9246 9.3072 4.19712C9.3072 4.46963 9.52525 4.68768 9.79777 4.68768H11.2695L8.08078 7.87636C7.75372 7.64472 7.37217 7.48123 6.96334 7.41307L6.96343 1.67618L7.99905 2.71179C8.09448 2.80723 8.2171 2.86169 8.33972 2.86169C8.46234 2.86169 8.58495 2.80723 8.68039 2.71179C8.87116 2.52102 8.87116 2.20762 8.68039 2.01685L6.81354 0.1499C6.73179 0.0545609 6.60917 0 6.47287 0C6.33656 0 6.21393 0.054466 6.13219 0.1499L4.25167 2.01685C4.0609 2.20762 4.0609 2.52102 4.25167 2.71179C4.44244 2.90256 4.75584 2.90256 4.94661 2.71179L5.98223 1.67618V7.41307C5.5734 7.48123 5.19187 7.64472 4.86478 7.87636L1.6761 4.68768H3.1478C3.42031 4.68768 3.63836 4.46963 3.63836 4.19712C3.63836 3.9246 3.42031 3.70655 3.1478 3.70655H0.490566C0.218054 3.70655 0 3.9246 0 4.19712V6.85435C0 7.12686 0.218051 7.34491 0.490566 7.34491C0.763078 7.34491 0.981132 7.12686 0.981132 6.85435V5.38265L4.16981 8.57133C3.84274 9.03462 3.66564 9.57974 3.66564 10.1792C3.66564 11.7327 4.93289 13 6.48639 13C8.03976 13.0001 9.29352 11.7328 9.29352 10.1792C9.29352 9.57964 9.10275 9.02092 8.78935 8.57133L11.978 5.38265V6.85435C11.978 7.12686 12.1961 7.34491 12.4686 7.34491C12.7411 7.34491 12.9592 7.12686 12.9592 6.85435L12.9591 4.19712C12.9455 3.9246 12.7274 3.70655 12.4549 3.70655H12.455ZM6.47282 12.0189C5.46441 12.0189 4.63315 11.2013 4.63315 10.1792C4.63315 9.17084 5.45081 8.33958 6.47282 8.33958C7.49483 8.33958 8.31249 9.15723 8.31249 10.1792C8.31249 11.1877 7.48125 12.0189 6.47282 12.0189Z" fill="#A7A7A7"/>
                </svg>
              </span> Settings
            </NavLink>
          }
        </div>

        <button onClick={this.toggle} className="adminFooterBtn">
          {this.state.show ? 
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="darkred" className="bi bi-x" viewBox="0 0 16 16">
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
            </svg> :
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="var(--primaryColor)" className="bi bi-list" viewBox="0 0 16 16">
              <path d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
            </svg> 
          }
        </button>

        {this.state.show && 
          <div className="adminFooter">
          {this.props.currentProfile.type == "admins" && 
              <NavLink to="/Administration/Dashboard" activeclassname="active">
                <span className="fill stroke">
                  <svg width="20" height="20" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.6304 5.51032L12.6306 5.51018L12.6253 5.50545L7.81641 1.21297C7.34998 0.766217 6.59813 0.763937 6.14537 1.19684L1.32031 5.50551L1.32029 5.50548L1.31809 5.50754C1.03306 5.77468 0.875 6.16356 0.875 6.55114V12.7177C0.875 13.6746 1.65736 14.457 2.61428 14.457L5.2133 14.4569C5.45991 14.4569 5.66113 14.2557 5.66113 14.0091V10.3931C5.66113 10.2846 5.75042 10.1953 5.85895 10.1953H8.08669C8.19523 10.1953 8.28452 10.2846 8.28452 10.3931V14.0252C8.28452 14.2719 8.48574 14.4731 8.73234 14.4731H11.3313C12.2883 14.4731 13.0706 13.6907 13.0706 12.7338V6.5511C13.0706 6.16117 12.911 5.79089 12.6304 5.51032ZM2.61626 13.5773C2.15301 13.5622 1.77609 13.1853 1.77074 12.7274V12.7177V6.5511C1.77074 6.41189 1.83419 6.26517 1.92869 6.15466L6.74938 1.8499L6.74951 1.85005L6.75451 1.84505C6.86417 1.73539 7.05938 1.72934 7.19118 1.86115L7.19104 1.86129L7.19633 1.86602L12.0041 6.15753C12.1112 6.26576 12.175 6.41137 12.175 6.55106V12.7176C12.175 13.1813 11.7951 13.5612 11.3314 13.5612L9.18023 13.5611V10.393C9.18023 9.79125 8.68838 9.29937 8.0866 9.29937H5.85898C5.25724 9.29937 4.76536 9.79121 4.76536 10.393V13.5773H2.61626Z" fill="#A7A7A7" stroke="#A7A7A7" strokeWidth="0.25"/>
                  </svg>  
                </span>
                <span className='adminNavName'>Dashboard</span>
              </NavLink>
            }
            {this.props.currentProfile.type == "admins" && 
              <NavLink to="/Administration/Applications" activeclassname="active">
                <span className="fill stroke">
                  <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.75702 8.44391C9.34958 8.14446 8.90024 7.8991 8.41949 7.71876C9.44876 7.06524 10.134 5.91579 10.134 4.60824C10.134 2.57733 8.48174 0.925 6.45073 0.925C4.41983 0.925 2.76749 2.57723 2.76749 4.60824C2.76749 5.92607 3.46346 7.08327 4.50634 7.73426C2.41502 8.53582 0.925 10.5693 0.925 12.9469V13.0219H1H1.77314H1.84814V12.9469C1.84814 10.3795 3.92772 8.29128 6.48317 8.29128C7.38458 8.29128 8.22575 8.55184 8.93806 9.00103C8.35384 9.55581 7.98839 10.3394 7.98839 11.2071C7.98839 12.8853 9.35367 14.2503 11.0318 14.2503C12.71 14.2503 14.075 12.8853 14.075 11.2071C14.075 9.52897 12.71 8.16355 11.032 8.16355H10.9899L10.9896 8.16405C10.5504 8.17006 10.1331 8.26961 9.75702 8.44391ZM3.69087 4.60799C3.69087 3.08617 4.92903 1.848 6.45086 1.848C7.97256 1.848 9.21073 3.08616 9.21073 4.60799C9.21073 6.12983 7.97256 7.36799 6.45086 7.36799C4.92917 7.36799 3.69087 6.12982 3.69087 4.60799ZM11.0318 13.3268C9.86286 13.3268 8.91166 12.3757 8.91166 11.2069C8.91166 10.038 9.863 9.08671 11.0318 9.08671C12.2006 9.08671 13.1517 10.038 13.1517 11.2069C13.1517 12.3757 12.2007 13.3268 11.0318 13.3268Z" fill="#A7A7A7" stroke="#A7A7A7" strokeWidth="0.15" />
                    <path d="M11.4934 9.78888V9.71388H11.4184H10.6453H10.5703V9.78888V11.625V11.656L10.5922 11.678L11.6939 12.7797L11.7469 12.8327L11.8 12.7797L12.3466 12.233L12.3996 12.18L12.3466 12.127L11.4934 11.2738V9.78888Z" fill="#A7A7A7" stroke="#A7A7A7" strokeWidth="0.15" />
                  </svg>
                </span>
                <span className='adminNavName'>Applications</span>
              </NavLink>
            }
            {(this.props.currentProfile.type == "admins" || this.props.views.includes("influencersMultiple")) && 
              <NavLink to="/Administration/Influencers" activeclassname="active">
                <span className="fill">
                  <svg width="17" height="17" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.14782 1.05392C4.69269 1.05392 3.51302 2.23358 3.51302 3.68871C3.51302 5.14384 4.69269 6.32351 6.14782 6.32351C7.60295 6.32351 8.78262 5.14384 8.78262 3.68871C8.78262 2.23358 7.60295 1.05392 6.14782 1.05392ZM2.45911 3.68871C2.45911 1.65146 4.11057 0 6.14782 0C8.18507 0 9.83653 1.65146 9.83653 3.68871C9.83653 5.72597 8.18507 7.37743 6.14782 7.37743C4.11057 7.37743 2.45911 5.72597 2.45911 3.68871Z" fill="#A7A7A7"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.08087 11.9445H11.215C10.9513 9.37877 8.78338 7.37743 6.14811 7.37743C3.51283 7.37743 1.34465 9.37871 1.08117 11.9445H1.08087ZM0 12.4715C0 9.07605 2.75253 6.32352 6.14796 6.32352C9.54338 6.32352 12.2959 9.07605 12.2959 12.4715C12.2959 12.7625 12.06 12.9984 11.769 12.9984H0.526959C0.235954 12.9984 0 12.7625 0 12.4715Z" fill="#A7A7A7"/>
                  </svg>
                </span>
                <span className='adminNavName'>Influencers</span>
              </NavLink>
            }
            {this.props.currentProfile.type == "admins" && 
              <NavLink to="/Administration/Profiles" activeclassname="active">
                <span className="fill">                   
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14"/>
                </svg>
                </span> 
                <span className='adminNavName'>Profiles</span>
              </NavLink>
            }
            {(this.props.currentProfile.type == "admins" || this.props.views.includes("brandsMultiple")) && 
              <NavLink to="/Administration/Brands" activeclassname="active">
                <span className="fill">
                  <svg width="13" height="18" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.854489 14.1345C0.627521 14.1332 0.410102 14.0422 0.249999 13.8812C0.089893 13.7203 0 13.5025 0 13.2754V1.73911C0 1.46059 0.110591 1.19355 0.307531 0.996618C0.504465 0.799684 0.771502 0.689087 1.05002 0.689087H8.94998C9.22849 0.689087 9.49553 0.799678 9.69246 0.996618C9.8894 1.19355 10 1.46059 10 1.73911V13.2754C10.0008 13.5595 9.86028 13.8254 9.62519 13.9849C9.39001 14.1444 9.09101 14.1767 8.8273 14.0708L5.1681 12.6072C5.06278 12.5666 4.9461 12.5666 4.84078 12.6072L1.17249 14.0708C1.07143 14.112 0.963478 14.1337 0.854307 14.1345L0.854489 14.1345ZM5.00018 11.6663C5.17134 11.6665 5.34098 11.6988 5.50018 11.7618L9.09107 13.1981V1.73921C9.09107 1.66139 9.02796 1.59828 8.95014 1.59828H1.05018C0.972361 1.59828 0.909252 1.66139 0.909252 1.73921V13.1981L4.50014 11.7617V11.7618C4.65933 11.6988 4.82897 11.6664 5.00014 11.6663L5.00018 11.6663Z" fill="#A7A7A7"/>
                    <path d="M3.18184 4.23009H6.81818C7.06919 4.23009 7.27272 4.4811 7.27272 4.68463C7.27272 4.93564 7.06919 5.13917 6.81818 5.13917H3.18184C2.93082 5.13917 2.72729 4.88816 2.72729 4.68463C2.72729 4.43362 2.93082 4.23009 3.18184 4.23009Z" fill="#A7A7A7"/>
                  </svg>
                </span>
                <span className='adminNavName'>Brands</span>
              </NavLink>
            }
            {this.props.currentProfile.type == "admins" && 
              <NavLink to="/Administration/Users" activeclassname="active">
                <span className="fill stroke">
                  <svg width="20" height="20" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.05281 5.21552C4.05281 4.02352 5.01916 3.05717 6.21115 3.05717C7.40325 3.05717 8.3695 4.02352 8.3695 5.21552C8.3695 6.40752 7.40325 7.37396 6.21115 7.37396C5.01916 7.37396 4.05281 6.40752 4.05281 5.21552ZM6.21115 2.01384C4.44299 2.01384 3.00947 3.44735 3.00947 5.21552C3.00947 6.98369 4.44299 8.4172 6.21115 8.4172C7.97942 8.4172 9.41284 6.98379 9.41284 5.21552C9.41284 3.44735 7.97942 2.01384 6.21115 2.01384Z" fill="#A7A7A7" stroke="#ABABAB" strokeWidth="0.15"/>
                    <path d="M10.4221 12.1384H2.0001C2.25701 10.0414 4.04457 8.41746 6.21109 8.41746C8.37762 8.41746 10.165 10.0413 10.4221 12.1384ZM6.21097 7.37413C3.29162 7.37413 0.925 9.74059 0.925 12.6601C0.925 12.9482 1.15847 13.1818 1.44667 13.1818H10.9755C11.2636 13.1818 11.4972 12.9482 11.4972 12.6601C11.4972 9.74074 9.13046 7.37413 6.21097 7.37413Z" fill="#A7A7A7" stroke="#ABABAB" strokeWidth="0.15"/>
                    <path d="M9.66279 3.13124C9.66279 3.41933 9.89637 3.65291 10.1845 3.65291H13.5534C13.8415 3.65291 14.075 3.41933 14.075 3.13124C14.075 2.84315 13.8415 2.60957 13.5534 2.60957H10.1845C9.89637 2.60957 9.66279 2.84315 9.66279 3.13124Z" fill="#A7A7A7" stroke="#ABABAB" strokeWidth="0.15"/>
                    <path d="M11.3474 4.81557C11.3474 5.10366 11.5809 5.33724 11.869 5.33724C12.1571 5.33724 12.3907 5.10366 12.3907 4.81557V1.44667C12.3907 1.15847 12.1571 0.925 11.869 0.925C11.581 0.925 11.3474 1.15847 11.3474 1.44667V4.81557Z" fill="#A7A7A7" stroke="#ABABAB" strokeWidth="0.15"/>
                  </svg>
                </span>
                <span className='adminNavName'>Users</span>
              </NavLink>
            }
            {this.props.currentProfile.type == "admins" && 
              <NavLink to="/Administration/Reporting" activeclassname="active">
                <span className="fill">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><rect fill="none" height="24" width="24"/><path d="M22,22H2v-2h20V22z M10,2H7v16h3V2z M17,8h-3v10h3V8z"/></svg>
                </span>
                <span className='adminNavName'>Reporting</span>
              </NavLink>
            }
            {this.props.currentProfile.type == "admins" && 
              <NavLink to="/Administration/Referrals" activeclassname="active">
                <span className="fill">           
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-people" viewBox="0 0 16 16">
                    <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"/>
                  </svg>
                </span>
                <span className='adminNavName'>Referrals</span>
              </NavLink>
            }
            {this.props.currentProfile.type == "admins" && 
              <NavLink to="/Administration/Settings" activeclassname="active">
                <span className="fill">
                  <svg width="17" height="17" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.455 3.70655H9.79777C9.52525 3.70655 9.3072 3.9246 9.3072 4.19712C9.3072 4.46963 9.52525 4.68768 9.79777 4.68768H11.2695L8.08078 7.87636C7.75372 7.64472 7.37217 7.48123 6.96334 7.41307L6.96343 1.67618L7.99905 2.71179C8.09448 2.80723 8.2171 2.86169 8.33972 2.86169C8.46234 2.86169 8.58495 2.80723 8.68039 2.71179C8.87116 2.52102 8.87116 2.20762 8.68039 2.01685L6.81354 0.1499C6.73179 0.0545609 6.60917 0 6.47287 0C6.33656 0 6.21393 0.054466 6.13219 0.1499L4.25167 2.01685C4.0609 2.20762 4.0609 2.52102 4.25167 2.71179C4.44244 2.90256 4.75584 2.90256 4.94661 2.71179L5.98223 1.67618V7.41307C5.5734 7.48123 5.19187 7.64472 4.86478 7.87636L1.6761 4.68768H3.1478C3.42031 4.68768 3.63836 4.46963 3.63836 4.19712C3.63836 3.9246 3.42031 3.70655 3.1478 3.70655H0.490566C0.218054 3.70655 0 3.9246 0 4.19712V6.85435C0 7.12686 0.218051 7.34491 0.490566 7.34491C0.763078 7.34491 0.981132 7.12686 0.981132 6.85435V5.38265L4.16981 8.57133C3.84274 9.03462 3.66564 9.57974 3.66564 10.1792C3.66564 11.7327 4.93289 13 6.48639 13C8.03976 13.0001 9.29352 11.7328 9.29352 10.1792C9.29352 9.57964 9.10275 9.02092 8.78935 8.57133L11.978 5.38265V6.85435C11.978 7.12686 12.1961 7.34491 12.4686 7.34491C12.7411 7.34491 12.9592 7.12686 12.9592 6.85435L12.9591 4.19712C12.9455 3.9246 12.7274 3.70655 12.4549 3.70655H12.455ZM6.47282 12.0189C5.46441 12.0189 4.63315 11.2013 4.63315 10.1792C4.63315 9.17084 5.45081 8.33958 6.47282 8.33958C7.49483 8.33958 8.31249 9.15723 8.31249 10.1792C8.31249 11.1877 7.48125 12.0189 6.47282 12.0189Z" fill="#A7A7A7"/>
                  </svg>
                </span>
                <span className='adminNavName'>Settings</span>
              </NavLink>
            }
          </div>
        }

      </div>
    )
  }
}

export default function (props) {
    const { views, currentProfile } = useAuthContext();
    return <AdminNav views={views} currentProfile={currentProfile} />;
  }
  