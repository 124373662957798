import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import AlertModal from "../../../components/alerts/alert";
import { apiCall } from './../../../helpers/api'
import './Signup.css';
import { getIndustries, getCountries } from "../../../helpers/dropdowns";
import SignupFooter from "../../../components/footer/SignupFooter";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import blueTheRoomLogo from './../../../images/blueTheRoomLogo.svg';
import logoWhite from './../../../images/room-white-logo.svg';
import adBox1 from './../../../images/adBox1.png';
import adBox2 from './../../../images/adBox2.png';
import adBox3 from './../../../images/adBox3.png';

export default function BrandSignup() {

    const animatedComponents = makeAnimated();
    const [signupData, setSignupData] = useState({
        "terms": false
    });
    const industries = getIndustries();
    const countries = getCountries();
    const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});

    const requiredFields = ["firstName","lastName","email","phoneNumber","brandName","terms","website","industry","country"];

    async function submit(event) {
        event.preventDefault();
        // Validate data
        var valid = true;
        var validationIssues = [];
        requiredFields.forEach(field => {
            if (!(field in signupData)){
                validationIssues.push(`${field} is missing`)
                valid = false;
            }
            else if (signupData[field].length == 0){
                validationIssues.push(`${field} is empty`)
                valid = false;
            }
        });
        if (!signupData["terms"]){
            validationIssues.push("terms have not been accepted")
            valid = false;
        }
        if (valid){
            var tempSignupData = Object.assign({},signupData);
            var response = await apiCall('application?type=brand',{ "method" : "POST", "data" : tempSignupData });
            if (response["success"]){
                setAlert({"display":"centered","visible":true,"title":"Application Submitted!","content":`Your application has been submitted, we'll be in touch! (You will be redirected to the login page in ${timeLeft}s)`,"className":"bg-success text-white"})
                var timeLeft = 10;
                var redirectTimer = setInterval(function(){
                    if(timeLeft <= 0){
                        clearInterval(redirectTimer);
                        window.location.href="/Login";
                    }
                    setAlert({"display":"centered","visible":true,"title":"Application Submitted!","content":`Your application has been submitted, we'll be in touch! (You will be redirected to the login page in ${timeLeft}s)`,"className":"bg-success text-white"})
                    timeLeft -= 1;
                }, 1000);
            }
            else if (response["failure"]){
                setAlert({"display":"toast","visible":true,"title":"Application Failed to Submit","content":"There was an issue with your application. Please double check your submission and try again. Error: "+response["failure"]["error"],"className":"bg-danger text-white"})
            }
        }
        else {
            setAlert({"display":"toast","visible":true,"title":"Application Invalid","content":`Your application is invalid due to the following reasons: ${validationIssues.join(", ")}`,"className":"bg-danger text-white"})
        }
    }

    async function updateSignupDataState(props, id=null) {
        var tempUserData = Object.assign({},signupData);
        if (id != null){
            tempUserData[id] = props.map(item => item.value);
            if (id == "otherInterests" && tempUserData[id].includes(signupData["primaryInterest"])) {
                tempUserData[id] = tempUserData[id].filter(function(interest) { return interest != signupData["primaryInterest"] })
            }
        }
        else if ( props.target.type == "checkbox" ) {
            tempUserData[props.target.id] = props.target.checked;
        } 
        else {
            tempUserData[props.target.id] = props.target.value;
        }
        setSignupData(tempUserData);    
    }   
 
  return (
    <div>
        <Container fluid className="signupBody brandRegistration">
            <AlertModal modal={showAlert} onClose={() => setAlert({"display":"toast","visible":false,"title":"","content":""})} />
            <Row className='h-100'>
                <div className='col-lg-5 h-100 position-relative signupSummaryText'>
                    <img src={blueTheRoomLogo} height="40px" className='mt-4 mt-lg-5 ms-md-5 ms-4 mb-5' />
                    <div className="p-xl-5 ps-xl-0 p-3 p-md-0 mx-md-5 mx-3 position-overlay">
                        <h3 className="text-navy mb-4 position-overlay fw-700">Look inside The Influence Room</h3>
                        <p>The Influence Room empowers brands to find and engage with advocates who truly love them.</p>
                        <p>See our influencer marketing platform in action by booking your free demo now. </p>
                        <p className="fw-500">Complete the form and we'll be in touch within one business day.</p>
                        <div className="row mt-5">
                            <div className="col-12 col-md-4 mb-5">
                                <img src={adBox1} className="w-100" />
                            </div>
                            <div className="col-12 col-md-4 mb-5">
                                <img src={adBox2} className="w-100" />
                            </div>
                            <div className="col-12 col-md-4 mb-5">
                                <img src={adBox3} className="w-100" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 bg-white p-5 p-lg-4 p-xl-5 pb-0 signupRight">                    
                    <form className="m-md-5 m-0 me-lg-0 mx-xl-5 ms-lg-3 my-md-4">
                        <h5 className="mb-4 text-navy">Brand Registration</h5>
                        <Row>
                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label>First Name <span className="text-danger">*</span></label>
                                    <input required className="form-control" type="text" id="firstName" onChange={ updateSignupDataState } />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label>Last Name <span className="text-danger">*</span></label>
                                    <input required className="form-control" type="text" id="lastName" onChange={ updateSignupDataState } />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label>Email <span className="text-danger">*</span></label>
                                    <input required className="form-control" type="email" id="email" onChange={ updateSignupDataState } />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label>Contact Number <span className="text-danger">*</span></label>
                                    <input required className="form-control" type="tel" id="phoneNumber" onChange={ updateSignupDataState } />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label>Company Name <span className="text-danger">*</span></label>
                                    <input required type="text" className="form-control" id="brandName" onChange={ updateSignupDataState } />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label>Website URL <span className="text-danger">*</span></label>
                                    <input required className="form-control" type="url" id="website" onChange={ updateSignupDataState } />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6 mb-3">
                                <div className="form-group">
                                    <label>Company Industry <span className="text-danger">*</span></label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        defaultValue={[]}
                                        options={industries} 
                                        id="industry" 
                                        onChange={ (choice) => updateSignupDataState({"target":{"id":"industry","value":choice.value}}) } 
                                        />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6 mb-4">
                                <div className="form-group">
                                    <label>Country <span className="text-danger">*</span></label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        defaultValue={[]}
                                        options={countries} 
                                        id="country" 
                                        onChange={ (choice) => updateSignupDataState({"target":{"id":"country","value":choice.value}}) }  
                                        />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-group">
                                    <p className="smaller mb-2">The Influence Room needs the contact information you provide to us to contact you about our products and services. You may unsubscribe from these communications at any time. For information on how to unsubscribe as well as our privacy, practices and commitments to protecting your privacy, please review our <a href="https://www.theinfluenceroom.com/privacy-policy/" target='_blank'>Privacy Policy</a></p>

                                    <label className="small"><input required type="checkbox" className="form-check-input me-1" id="terms" onChange={ updateSignupDataState } /> I have read and agree to the <a href="https://www.theinfluenceroom.com/terms-of-use/">Terms & Conditions</a> and <a href="https://www.theinfluenceroom.com/privacy-policy/" target='_blank'>Privacy Policy</a></label>

                                    <button type='submit' className="link btn btn-primary mt-3" onClick={submit}>Get Started</button>
                                </div>
                            </div>
                        </Row>
                
                        <div className='mt-4 mb-5'>
                            <p>Want to change your mind? <Link to={"/Signup"} title="Signup">Go Back!</Link></p>
                        </div>
                    </form>
                </div>
            </Row>
        </Container>
      
        <SignupFooter />
    </div>    
  );
}