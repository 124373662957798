import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import logoWhite from '../../images/room-white-logo.svg';
import footerFacebook from '../../images/footerFacebook.svg';
import footerTwitter from '../../images/footerTwitter.svg';
import footerInstagram from '../../images/footerInstagram.svg';
import footerTikTok from '../../images/footerTikTok.svg';
import footerLinkedIn from '../../images/footerLinkedIn.svg';
import footerAppStore from '../../images/footerAppStore.svg';
import footerPlayStore from '../../images/footerPlayStore.svg';

class SignupFooter extends Component {
    render() {
      return (
        <Container fluid className="signupFooter desktopView">
            <div className="d-flex justify-content-between align-items-center px-3 small h-100 py-4">
                <div className='d-flex justify-content-start align-items-center flex-wrap'>
                    <a href="https://theinfluenceroom.com/" target="_blank"><img src={logoWhite} height="40px" className='footerBrandLogo me-xl-4 pe-xl-0 me-xxl-5 pe-xxl-5' /></a>
                    <div className='d-flex justify-content-start align-items-center'>
                        <div>
                            <a className='me-2' href="https://www.facebook.com/theinfluenceroom" target='_blank'><img src={footerFacebook} height="32px" /></a>
                            <a className='me-2' href="https://x.com/influence_room" target='_blank'><img src={footerTwitter} height="32px" /></a>
                            <a className='me-2' href="https://www.instagram.com/theinfluenceroomofficial" target='_blank'><img src={footerInstagram} height="32px" /></a>
                            <a className='me-2' href="https://www.tiktok.com/@theinfluenceroomofficial" target='_blank'><img src={footerTikTok} height="32px" /></a>
                            <a className='me-4' href="https://uk.linkedin.com/company/theinfluenceroom" target='_blank'><img src={footerLinkedIn} height="32px" /></a>
                        </div>
                        <div>
                            <a className='me-2' href="https://apps.apple.com/gb/app/the-influence-room/id1575729105" target='_blank'><img src={footerAppStore} height="32px" /></a>
                            <a href="https://play.google.com/store/apps/details?id=com.roomunlocked.dev1923.twa&pli=1" target='_blank'><img src={footerPlayStore} height="32px" /></a>
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-end align-items-center flex-wrap ms-4'>
                    <div className='my-2 my-xl-0 text-secondary text-nowrap'>Company Reg No: 05997734</div>
                    <div className='my-2 my-xl-0 ms-4 text-nowrap'>
                        <a className='text-secondary' href="https://www.theinfluenceroom.com/privacy-policy/" target='_blank'>Privacy Policy</a>
                    </div>
                    <div className='my-2 my-xl-0 ms-4 text-nowrap'>
                        <a className='text-secondary' href="https://www.theinfluenceroom.com/terms-of-use/" target='_blank'>Terms & Conditions</a>
                    </div>
                    <div className='my-2 my-xl-0 ms-4 text-nowrap text-secondary'>© <span>{(new Date().getFullYear())}</span> The Influence Room</div>                 
                </div>
            </div>
        </Container>
      )
    }
}

export default function (props) {
    return <SignupFooter />;
  }