export function getDifference(epoch) {
    const now = Math.round(Date.now() / 1000);
    var difference = 0;
    var timeUnit = "minutes";
    var future = true;
    var timeUnits = [{"name":"hours","unit":60},{"name":"days","unit":24},{"name":"weeks","unit":7},{"name":"months","unit":5},{"name":"years","unit":12}]
    if (epoch > now){
        difference = (epoch - now)/60;
    }
    else{
        difference = (now - epoch)/60;
        future = false;
    }
    for (var unit in timeUnits){
        if (difference < timeUnits[unit]["unit"]){
            difference = Math.ceil(difference)
            break
        }
        else{
            difference = difference/timeUnits[unit]["unit"];
            timeUnit = timeUnits[unit]["name"];
        }
    }
    if (difference == 1){
        timeUnit = timeUnit.slice(0,-1)
    }
    return {"difference" : Math.ceil(difference), "future" : future, "unit": timeUnit}
}

export function getAge(dob) {
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export function withinAgeRange(dob,ranges=[],splitBy="-") {
    if (ranges.includes("Any")){
        return {"match":true,"range":"Any"}
    }
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    var match = null
    ranges.forEach(range => {
        var min = parseInt(range.split("-")[0]);
        var max = parseInt(range.split("-")[1]);
        if (min <= age && max >= age) {
            match = range
        }
    })
    if (match){
        return {"match":true,"range":match}
    }
    return {"match":false,"range":`${age} years old`}
}

export function datePickerToEpoch(dateTime,seconds=true) {
    var epoch = new Date(dateTime).getTime();
    if (seconds && epoch.toString().length == 13){
        epoch = epoch / 1000
    }
    return epoch;
}

export function relativeTimeFormat(epoch,shortDate=false) {
    var date = new Date(epoch);
    if (isToday(epoch)) {
        return date.toLocaleString('en-US', { hour:"2-digit", minute:"2-digit" })
    }
    else {
        var difference = getDifference(epoch/1000);
        if (difference.unit != "minutes" && difference.unit != "hours"){
            if ((difference.unit == "days" && difference.difference > 6) || difference.unit != "days"){
                if (shortDate){
                    return date.getFullYear() + "-" + ('0' + (date.getMonth()+1)).slice(-2) + "-" + ('0' + date.getDate()).slice(-2)
                }
                else {
                    return date.toLocaleString('en-US', { day:"numeric", month:"long", year:"numeric", hour:"2-digit", minute:"2-digit" })
                }
            }
            else {
                if (shortDate){
                    return date.toLocaleString('en-US', { weekday:"long"})
                }                
                else {
                    return date.toLocaleString('en-US', { hour:"2-digit", minute:"2-digit" }) + " on " + date.toLocaleString('en-US', { weekday:"long"})
                }

            }
        }
        else {
            if (shortDate){
                return "Yesterday"
            }
            else {
                return "Yesterday, at " + (new Date(epoch*1000)).toLocaleString('en-US', { hour:"2-digit", minute:"2-digit" })
            }
        }
    }
}

export function isToday(someDate) {
    const today = new Date()
    someDate = new Date(someDate);
    return someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
  }