import React from 'react';
import { createContext, useContext, useReducer, useEffect, useState } from "react";
import { apiCall } from '../helpers/api';

export const ImageContext = createContext();

async function getImageProviders() {
  var response = await apiCall("integrations/imageProviders",{ "method" : "GET" });
  if (response["success"]) {
    return response["success"]["providers"]
  } else {
    return null
  }
}

export const providerReducer = (state, providers) => {
  return { providers: providers }
}

export const ImageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(providerReducer, {
    providers: null
  })
  const [providersLoading, setProvidersLoading] = useState(true)

  useEffect(() => {
    const getImageProviderData = async () => { 
      const providers = await getImageProviders() 
      
      if (providers) {
        dispatch(providers)
      }
      setProvidersLoading(false)
    }
    
    getImageProviderData();
  }, [])

  // console.log("Image Providers:", state)

  return ( 
  <ImageContext.Provider value={{...state, dispatch, providersLoading}}>
    { children }
    </ImageContext.Provider>
  )
};

export const useImageProviders = () => {
  return useContext(ImageContext);
};