import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { apiCall } from '../helpers/api';
import { useNavigate } from 'react-router-dom';

async function killSession(username,password) {
    var response = await apiCall("logout",{ "method" : "GET"})
    return response;
}

export const useLogout = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const { dispatch } = useAuthContext()
    const navigate = useNavigate()


    const logout = async () => {
        setIsLoading(true)
        setError(null)

        const response = await killSession()

        if (response["success"]) {
            dispatch({type: "logout"})

            if ("redirect" in response["success"]){
                window.location.href = response["success"]["redirect"];
            }
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            setError(response["failure"]["error"])
            dispatch({type: "logout"})
            return navigate("/") //Default to home if it can't kill session
        }
    }

    return { logout, isLoading, error }
}