export function getMinMax(dataArray) {
    var min = 0;
    var max = 0;
    dataArray.forEach(item => {
        if (item[2] < min){
            min = item[2]
        }
        if (item[2] > max){
            max = item[2]
        }
    })
    return {min:min,max:max}
}
