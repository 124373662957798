export function getSmallNumber(number,decimals=1) {
    var units = [{"name":"","threshold":1000,"divisor":1},{"name":"k","threshold":1000000,"divisor":1000},{"name":"m","threshold":1000000000,"divisor":1000000},{"name":"b","threshold":1000000000000,"divisor":1000000000}]
    var matchingUnit = "";
    for (var unit in units){
        if (number < units[unit]["threshold"]){
            matchingUnit = units[unit]
            break
        }
    }
    if (matchingUnit.name == ""){
        var newNumber = parseFloat(number.toFixed(decimals))
        if (newNumber % 1 == 0){
            return number.toFixed(0)
        }
        return newNumber
    }
    var newNumber = parseFloat((number/matchingUnit.divisor).toFixed(decimals))
    if (newNumber % 1 == 0){
        return newNumber.toFixed(0) + matchingUnit.name
    }
    return newNumber + matchingUnit.name
}

export function getPercentage(number,decimals=0) {
    return (number * 100).toFixed(decimals) + "%"
}