

export function serviceWorker(){
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
    navigator.serviceWorker.register('/serviceWorker.js').then(function(registration) {
      // Registration was successful
      console.log('Registered Service Worker');
    }, function(err) {
      // registration failed :(
      console.log('ServiceWorker registration failed: ', err);
    }).catch(function(err) {
      console.log(err);
    });
  });
  } else {
    console.log('Service worker is not supported');
  }
}