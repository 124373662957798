import React, { useRef, useEffect, useState } from "react";
import { Container, Row, Accordion, Tooltip, Form } from 'react-bootstrap';
import AdminNav from '../../components/adminnav/AdminNav';
import { apiCall } from "../../helpers/api";
import ReactEChartsCore from "echarts-for-react/lib/core"
import * as echarts from 'echarts/core';
import { HeatmapChart, BarChart, PieChart, LineChart } from "echarts/charts";
import { TooltipComponent, GridComponent, VisualMapComponent, LegendComponent, TitleComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { LabelLayout } from 'echarts/features';
import { datePickerToEpoch } from "../../helpers/dates";
import { getMinMax } from "../../helpers/charts";

import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import Topbar from "../../components/topbar/Topbar";
import './Reporting.css';
import noGraph from './../../images/graph-black.svg';

function HeatMapChart(props) {
  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    VisualMapComponent,
    HeatmapChart,
    CanvasRenderer
  ]);

  const data = props.data.map(function (item) { return [item[1], item[0], item[2] || '-'];});
  const {min, max} = getMinMax(props.data)
  const option = {
    tooltip: {
      position: 'top'
    },
    grid: {
      height: '75%',
      top: '5%'
    },
    title: {
      left: 'center',
      text: props.meta.title
    },
    xAxis: {
      type: 'category',
      data: props.xAxisLabel,
      splitArea: {
        show: true
      }
    },
    yAxis: {
      type: 'category',
      data: props.yAxisLabel,
      splitArea: {
        show: true
      }
    },
    visualMap: {
      min: min,
      max: max,
      calculable: true,
      orient: 'horizontal',
      left: 'center',
      bottom: '5%',
      show: props.meta.showRangeSlider ? true : false
    },
    series: [
      {
        name: props.meta.series,
        type: 'heatmap',
        data: data,
        label: {
          show: true
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };

  return <ReactEChartsCore echarts={echarts} option={option} style={{height:`calc(${props.heightRatio}vh - 300px)`}} />;
}

function Barchart(props) {
  echarts.use([
    TitleComponent,
    GridComponent,
    BarChart,
    CanvasRenderer,
    TooltipComponent
  ]);

  const option = {
    tooltip: {
      position: 'top'
    },
    title: {
      left: 'center',
      text: props.meta.title
    },
    xAxis: {
      type: 'category',
      data: props.xAxisLabel
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        name: props.meta.series,
        data: props.data,
        type: 'bar'
      }
    ]
  };

  return <ReactEChartsCore echarts={echarts} option={option} style={{height:`calc(${props.heightRatio}vh - 300px)`}} />;
}

function MultilineChart(props) {
  echarts.use([
    TitleComponent,
    GridComponent,
    LegendComponent,
    BarChart,
    LineChart,
    CanvasRenderer,
    TooltipComponent
  ]);

  var seriesArray = []
  var yAxisArray = []
  props.data.map((dataSet,index) => {
    yAxisArray.push({
      type: 'value',
      alignTicks: true,
      axisLabel: {
        formatter: dataSet.format
      }
    })
    seriesArray.push({
      name: dataSet.series,
      data: dataSet.data,
      type: dataSet.type,
      yAxisIndex: index
    })
  })

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        animation: false,
        label: {
          backgroundColor: '#505765'
        }
      }
    },
    title: {
      left: 'center',
      text: props.meta.title
    },
    xAxis: {
      type: 'category',
      data: props.xAxisLabel
    },
    yAxis: yAxisArray,
    series: seriesArray
  };

  return <ReactEChartsCore echarts={echarts} option={option} style={{height:`calc(${props.heightRatio}vh - 300px)`}} />;
}

function Piechart(props) {
  echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout
  ]);

  const option = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      left: 'center',
      show: props.meta.showLegend ? true : false
    },
    title: {
      left: 'center',
      text: props.meta.title
    },
    series: [
      {
        name: props.meta.title,
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: true
        },
        data: props.data
      }
    ]
  };

  return <ReactEChartsCore echarts={echarts} option={option} style={{height:`calc(${props.heightRatio}vh - 300px)`}} />;
}

function DataTable(props){
  return (
    <table>
      <thead>
        <tr>
          {props.headers.map(header => (
            <th>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
      {props.data.map(row => (
        <tr key={row}>
          {row.map(column => (
            <td key={`${row}-${column}`}>{column}</td>
          ))}
        </tr>
      ))}
      </tbody>
    </table>
  )
}

export default function Reporting() {  

  const [ isLoaded, setIsLoaded ] = useState(true);
  const [ reportData, setReportData ] = useState({});
  const [ startDate, setStartDate ] = useState(new Date().setDate(new Date().getDay() - 30));
  const [ endDate, setEndDate ] = useState(Date.now());
  const [ timeSelection, setTimeSelection ] = useState("Last 30 Days")
  const [ selectedReport, setSelectedReport ] = useState(null);
  const [ selectedReportType, setSelectedReportType ] = useState(null);
  const [ selectedReportName, setSelectedReportName ] = useState('');

  function updateTimeSelection(selection){
    console.log(startDate,endDate,new Date(startDate).toISOString().slice(0,16),new Date(endDate).toISOString().slice(0,16))
    if (selection == "Last 30 Days"){
      setStartDate(new Date().setDate(new Date().getDay() - 30))
      setEndDate(Date.now())
    }
    else if (selection == "This Month"){
      setStartDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(new Date().getTimezoneOffset() > 0 ? 24 - new Date().getTimezoneOffset()/60 : 0 - new Date().getTimezoneOffset()/60))
      setEndDate(Date.now())
    }
    else if (selection == "Last 3 Months"){
      setStartDate(new Date(new Date().getFullYear(), new Date().getMonth() - 3, 1).setHours(new Date().getTimezoneOffset() > 0 ? 24 - new Date().getTimezoneOffset()/60 : 0 - new Date().getTimezoneOffset()/60))
      setEndDate(Date.now())
    }
    setTimeSelection(selection)
  }

  function onChangeReport(value) {
    var valueArray = value.split(',');
    var reportName = valueArray[0]
    var reportType = valueArray[1]
    setSelectedReportName(value);
    getReport(reportName,reportType)
  }

  async function getReport(reportName,reportType) {
    setIsLoaded(false)
    var response = await apiCall(`admin/reporting?name=${reportName}&startTime=${parseInt(datePickerToEpoch(new Date(startDate)))}&endTime=${parseInt(datePickerToEpoch(endDate))}&format=${reportType}`,{ "method" : "GET", "isFile" : reportType == "download" });
    if (response["success"] && reportType != "download"){
        setReportData(response["success"])
        setSelectedReport(reportName)
        setSelectedReportType(reportType)
    } 
    setIsLoaded(true)
  }

  return (
    <div className="bg-page dashboard adminSettings">
      <Topbar />
      <div className="pageName">Administrative Reporting</div>
      <Container id="applicant-list-container" className="fullscreenWithTopBar top-0" fluid>
        <Row>
          <AdminNav />
          <div className="col-xl-12 col-xxl-10 px-0">
            <div className="px-4 py-3 py-xl-4 bg-white shadow-sm position-relative">
              <div className="d-flex align-items-center justify-content-between mx-xl-4">
                <h1 className="pageTitle text-nowrap text-primary tragedyRegularFont fw-700 mb-0 fs-3">Reporting</h1>
              </div>
            </div>
            <div className="px-4 py-3 py-xl-4 reportingArea">
              <div className="mx-xl-4">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4 form-group mb-4">
                      <label className="text-muted">Select Report</label>
                      <Form.Select className="form-select" value={selectedReportName} onChange={(e) => onChangeReport(e.target.value)}>
                        <option value='' disabled>Select Any</option>
                        <option value='averageBids,heatmap'>Get Average Bids (Split by Category)</option>
                        <option value='averageBids,barchart'>Get Average Bids</option>
                        <option value='totalChatMessages,barchart'>Get Total Chat Messages</option>
                        <option value='publishedCampaigns,barchart'>Get Published Campaigns</option>
                        <option value='bidAcceptanceRate,piechart'>Get Bid Acceptance Rate</option>
                        <option value='bidAcceptanceRate,multiline'>Get Bid Acceptance Over Time</option>
                        <option value='influencerAcceptanceRate,multiline'>Get Influencer Acceptance Rate</option>
                        <option value='mostActiveInfluencers,download'>Get Active Influencers</option>
                        {/* <option disabled={!isLoaded} onClick={() => getReport("influencerDemographics","multi")}>Get Member Demographics 🐌</option> */}
                      </Form.Select>
                  </div>
                  <div className="col-12 col-md-6 col-lg-2 form-group mb-4">
                      <label className="text-muted">Select Duration</label>
                      <Form.Select className="form-select" value={timeSelection} onChange={(e) => updateTimeSelection(e.target.value)}>
                        <option>Last 30 Days</option>
                        <option>This Month</option>
                        <option>Last 3 Months</option>
                        <option>Custom Dates</option>
                      </Form.Select>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 form-group mb-4">
                      <label className="text-muted">Start Date</label>
                      <input disabled={timeSelection != "Custom Dates"} type="date" className="form-control" onKeyDown={(e) => e.preventDefault()} onChange={(e) => setStartDate(e.target.value)} value={new Date(startDate).toISOString().slice(0,10)} id="startDate"/>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 form-group mb-4">
                      <label className="text-muted">End Date</label>
                      <input disabled={timeSelection != "Custom Dates"} type="date" className="form-control" onKeyDown={(e) => e.preventDefault()} onChange={(e) => setEndDate(e.target.value)} value={new Date(endDate).toISOString().slice(0,10)} id="endDate"/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12">
                    { !isLoaded ? <FullPageSpinner /> : 
                      <> 
                      {!selectedReport ? <div className="text-muted text-center my-5 py-5"><img src={noGraph} className="mb-2 mt-4" height="66px" /><div className="small">Choose a report above to see it displayed here</div></div> : (  
                        <>      
                        {selectedReport == "averageBids" && selectedReportType == "heatmap" && (
                          <div className="pt-3 border rounded bg-white shadow-sm">
                            <HeatMapChart xAxisLabel={reportData.xLabels} yAxisLabel={reportData.yLabels} data={reportData.data} heightRatio="100" meta={{title: "Average Bids per Category",series:"Average Bids"}} />
                          </div>
                        )}
                        {selectedReport == "averageBids" && selectedReportType == "barchart" && (
                          <div className="pt-3 border rounded bg-white shadow-sm">
                            <Barchart xAxisLabel={reportData.xLabels} data={reportData.data} heightRatio="100" meta={{title: "Average Bids",series:"Average Bids"}} />
                          </div>
                        )}
                        {selectedReport == "totalChatMessages" && (
                          <div className="pt-3 border rounded bg-white shadow-sm">
                            <Barchart xAxisLabel={reportData.xLabels} data={reportData.data} heightRatio="100" meta={{title: "Total Chat Messages",series:"Total Messages"}} />
                          </div>
                        )}
                        {selectedReport == "publishedCampaigns" && (
                          <div className="pt-3 border rounded bg-white shadow-sm">
                            <Barchart xAxisLabel={reportData.xLabels} data={reportData.data} heightRatio="100" meta={{title: "Published Campaigns",series:"Published Campaigns"}} />
                          </div>
                        )}
                        {selectedReport == "bidAcceptanceRate" && selectedReportType == "piechart" && (
                          <div className="pt-3 border rounded bg-white shadow-sm">
                            <Piechart data={reportData.data} heightRatio="100" meta={{title: "Bid Acceptance Rate",series:"Bid State"}} />
                          </div>
                        )}
                        {selectedReport == "bidAcceptanceRate" && selectedReportType == "multiline" && (
                          <div className="pt-3 border rounded bg-white shadow-sm">
                            <MultilineChart data={[{"data":reportData.data.accepted,"series":"Accepted Bids","type":"line","format":"{value} %"},{"data":reportData.data.total,"series":"Total Bids","type":"bar","format":"{value}"}]} xAxisLabel={reportData.xLabels} heightRatio="100" meta={{title: "Bid Acceptance Rate"}} />
                          </div>
                        )}
                        {selectedReport == "influencerAcceptanceRate" && (
                          <div className="pt-3 border rounded bg-white shadow-sm">
                            <MultilineChart data={[{"data":reportData.data.accepted,"series":"Accepted Influencers","type":"line","format":"{value} %"},{"data":reportData.data.total,"series":"Total Applications","type":"bar","format":"{value}"}]} xAxisLabel={reportData.xLabels} heightRatio="100" meta={{title: "Application Acceptance Rate"}} />
                          </div>
                        )}
                        {selectedReport == "influencerDemographics" && (
                          <>
                          <div className="row">
                           <div className="col-md-6 col-sm-12">
                              <div className="pt-3 border rounded bg-white shadow-sm">
                                <Piechart data={reportData.data.ages} heightRatio="75" meta={{title: "Ages",series:"Age Range"}} />
                              </div>
                            </div>
                           <div className="col-md-6 col-sm-12">
                              <div className="pt-3 border rounded bg-white shadow-sm">
                                <Piechart data={reportData.data.genders} heightRatio="75" meta={{title: "Gender Split",series:"Gender"}} />
                              </div>
                            </div>
                           <div className="col-md-6 col-sm-12">
                              <div className="pt-3 border rounded bg-white shadow-sm">
                                <Barchart xAxisLabel={reportData.data.socials.xLabels} heightRatio="75" data={reportData.data.socials.data} meta={{title: "Social Platforms",series:"Platform"}} />
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <div className="pt-3 border rounded bg-white shadow-sm">
                              <HeatMapChart xAxisLabel={[]} yAxisLabel={reportData.data.followerRanges.yLabels} data={reportData.data.followerRanges.data} heightRatio="75" meta={{title: "Follower Count",series:"Influencers"}} />
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <div className="pt-3 border rounded bg-white shadow-sm">
                              <HeatMapChart xAxisLabel={[]} yAxisLabel={reportData.data.engagementRate.yLabels} data={reportData.data.engagementRate.data} heightRatio="75" meta={{title: "Engagement Rate",series:"Influencers"}} />
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <div className="pt-3 border rounded bg-white shadow-sm">
                              <DataTable headers={reportData.data.locations.headers} data={reportData.data.locations.data} heightRatio="75" meta={{title: "Top Locations",series:"Locations"}} />
                              </div>
                            </div>
                          </div>
                          </>
                        )}
                        </>
                      )}
                      </> 
                    }
                  </div>
                </div>                
              </div>
                
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
}