import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import SignupFooter from "../../components/footer/SignupFooter";

import './signup.css';
import theRoom from './../../images/theRoom.svg';
import logoWhite from './../../images/room-white-logo.svg';

export default function SignUp({ }) {
  return(
    <div>
      <Container fluid className="signupBody overflow-hidden signupSelection">
        <Row className='h-100'>
          <div className='col-lg-5 h-100 desktopView'>
            <div className='d-flex h-100 align-items-center justify-content-end'>
            <a href="https://theinfluenceroom.com/" target="_blank"><img src={theRoom} height="80px" className='me-0 me-xl-5 pe-5' /></a>
            </div>
          </div>
          <div className='col-12 mobileView text-center py-4 h-25'>
            <div className='d-flex h-100 align-items-center justify-content-center'>
            <a href="https://theinfluenceroom.com/" target="_blank"><img src={theRoom} height="50px" /></a>
            </div>
          </div>
          <div className='col-lg-7 bg-white h-100 desktopView'>
            <div className='d-flex h-100 align-items-center justify-content-start'>
              <div className='d-block ms-5 ps-5'>
                <h3 className='mb-4 pb-2'>Sign Up to <span className='text-blue'>The Influence Room</span></h3>
                <div>
                  <Link to={"/Signup/Brand"} className="btn btn-outline-primary me-2" title="Brand Signup">I'm a brand</Link>
                  <Link to={"/Signup/Agency"} className="btn btn-outline-primary me-2" title="Agency Signup">I'm an agency</Link>
                  <Link to={"/Signup/Influencer"} className="btn btn-outline-primary" title="Influencer Signup">I'm an influencer</Link>
                </div>                
                <div className='mt-4'>
                  <p>Do you have an account? <Link to={"/Login"} title="Login">Login here!</Link></p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 bg-white h-75 mobileView rounded-top-xl shadow'>
            <div className='d-flex h-100 align-items-center justify-content-center'>
              <div className='d-flex flex-column text-center'>
                <h3 className='mb-4 pb-2 text-muted fs-4'>Sign Up to <span className='text-primary mt-2 d-block fs-1'>The Influence Room</span></h3>
                <div>
                  <Link to={"/Signup/Brand"} className="btn btn-primary d-block mb-3" title="Brand Signup">I'm a brand</Link>
                  <Link to={"/Signup/Influencer"} className="btn btn-primary d-block" title="Influencer Signup">I'm an influencer</Link>
                </div>
                <div className='mt-4'>
                  <p>Do you have an account? <Link to={"/Login"} title="Signup">Login here!</Link></p>
                </div>
              </div>
            </div>
          </div>
        </Row>    
      </Container>
      
      <SignupFooter />
    </div>
  )
}