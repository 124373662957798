import React, { Component, useRef, useEffect, useState } from "react";

import { parameters } from './../helpers/globalSettings'

export const PageScroller = ({endpoint=null,next=null,setState=null,pageSize=100,filter=null}) => {
    const [ filterTimer, setFilterTimer ] = useState(null)
    const [ intersecting, setIntersecting ] = useState(false)
    const [ forceRefresh, setForceRefresh ] = useState(false)
    const [ pageData, setPageData ] = useState({ total: -1, pageSize: pageSize, page: 0, loading: false, filter: filter })
    const viewBottom = useRef();
    useEffect(() => {
        const observer = new IntersectionObserver((entries, observer) => {
            if (entries[0].isIntersecting) {
                setIntersecting(true)
            } else {
                setIntersecting(false)
            }
        });
        observer.observe(viewBottom.current);
    }, []);
    if ( filter != pageData.filter ) {
        if (filterTimer) {
            clearTimeout(filterTimer)
        }
        setFilterTimer(setTimeout(() => {
            setForceRefresh(true);
        }, 500))
        setPageData(Object.assign({}, pageData, { page: 0, filter: filter }));
    }
    if ( (intersecting && !pageData.loading && ( ( pageData.total != 0 && pageData.page * pageData.pageSize <= pageData.total ) || pageData.total == -1 ) ) || forceRefresh ) {
        setForceRefresh(false)
        var e = { 
            pageData: pageData,
            setPageData: setPageData,
            endpoint: endpoint
        }
        if (next) {
            next(e)
        } else {
            setState(e,PagedAPICall(e))
        }
    }
    return (
        <>
            <div className={`text-center py-5 reachedBottom ${ pageData.loading ? "hide" : "" }`} ref={viewBottom}>
                { pageData.total > 0 && (
                    <>
                        {pageData.page * pageData.pageSize >= pageData.total ? <p className="mb-0">You've reached the bottom of the list!</p> : <span className="spinner-border text-primary" role="status" aria-hidden="true"></span>}
                    </>
                ) }
            </div>
            <div className={`text-center my-4 py-4 ${ pageData.loading ? "" : "hide" }`}>
                <span className="spinner-border text-primary" role="status" aria-hidden="true"></span>
            </div>
        </>
    )
}

export async function PagedAPICall(event) {
    if (!event.pageData.loading) {
        event.setPageData(Object.assign({}, event.pageData, { loading: true }))
        let urlParams = new URLSearchParams(window.location.search);
        urlParams.set('limit', event.pageData.pageSize);
        urlParams.set('skip', event.pageData.page * event.pageData.pageSize);
        var uri = event.endpoint
        if (uri.includes("?")) {
            uri = uri + "&" + urlParams.toString()
        } else {
            uri = uri + "?" + urlParams.toString()
        }
        var response = await apiCall(uri,{ "method" : "GET" });
        if (response["success"]) {
            event.pageData.total = response["success"]["_paging"]["total"]
            event.setPageData(Object.assign({}, event.pageData, { page: event.pageData.page + 1, pageSize: event.pageData.pageSize, loading: false }))
            return response["success"]
        }
    }
}

export async function apiCall(uri,options={}) {
    var kwargs = {
        "credentials" : "include"
    };
    if (!options.hasOwnProperty("method")) {
        kwargs["method"] = "GET";
    } else {
        kwargs["method"] = options["method"];
    }

    if (!options.hasOwnProperty("headers")) {
        if (!options["form"]){
            kwargs["headers"] = {
                "Content-Type" : "application/json"
            }
        }
        else {
            kwargs["headers"] = {}
        }
    } else {
        kwargs["headers"] = options["headers"];
    }

    if (options.hasOwnProperty("data")) {
        if (options["data"] instanceof Object && !options["form"]) {
            kwargs["body"] = JSON.stringify(options["data"])
        } else {
            kwargs["body"] = options["data"];
        }
    }

    kwargs["headers"]["Access-Control-Allow-Origin"] = "*";
    // kwargs["headers"]["Access-Control-Allow-Methods"] = "HEAD, GET, POST, PUT, PATCH, DELETE";
    // kwargs["headers"]["Access-Control-Allow-Headers"] = "Origin, Content-Type, X-Auth-Token"

    var url = parameters.backend + "/api/1.0/" + uri;
    var response = await fetch(url,kwargs).then(async response => {
        try {
            if (options["isFile"]){
                let responseFile = await response.blob();
                if (response.ok){
                    var file = window.URL.createObjectURL(responseFile);
                    window.open(file, "_blank");
                    return { "success" : {}, "failure" : null}
                } 
                else {
                    return { "success" : null, "failure" : response };
                }
            }
            else {
                let responseJSON = await response.json();
                if (response.ok) {
                    return { "success" : responseJSON, "failure" : null };
                } 
                else {
                    if (responseJSON.hasOwnProperty("redirect")) {
                        window.location.assign(responseJSON["redirect"])
                    }
                    return { "success" : null, "failure" : responseJSON };
                }
            }
        } catch (error) {
            return { "success" : null, "failure" : { "error" : error } };;
        }
    })
    return response;
}
