import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { apiCall } from "../helpers/api";

import errorMessage from "../images/404.png"

export default class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    componentDidCatch(error, info) {
      this.setState({ hasError: true });
      apiCall('exception',{ "method" : "PUT", "data" : { "error" : JSON.stringify(error,Object.getOwnPropertyNames(error)), "info": info, "location": window.location.href } })
    }
  
    render() {
      if (this.state.hasError) {
        return(
          <div className="bg-page">
          <Container className="fullscreenWithTopBar px-4 px-md-0 bg-white pageNotFound" fluid>
            <Container className="px-0 h-100 pb-5">
              <Row className="d-flex align-items-center justify-content-center h-100">
                <div className="col-11 col-md-6 d-flex flex-column align-items-center justify-content-center h-100">
                  <img src={errorMessage} className="w-100" />
                  <div className="mt-3 fs-5 text-center">Something Unexpected Occurred</div>
                  <p className='mt-1 text-center text-muted small'>This has been reported to our technical team, please try again later.</p>
                  <a href="/" className="btn btn-primary btn-sm">Back to Home</a>
                </div>
              </Row>
            </Container>
          </Container>
          </div>
        )
      }
      return this.props.children;
    }
}
