import React, { useEffect, useState } from "react";
import { Container, Col, Row, Tab, Nav, Card, Button, Table, Dropdown } from 'react-bootstrap';
import { capitalise } from "../../helpers/format";
import { useSearchParams } from "react-router-dom";
import { getDifference } from "../../helpers/dates";
import { apiCall } from "../../helpers/api";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useImageContext } from "../../hooks/useImageContext";
import { getImageUrl } from "../../helpers/images";
import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import { getPercentage, getSmallNumber } from "../../helpers/stats";
import AlertModal from "../../components/alerts/alert";
import './Brands.css';
import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";
import blackDownload from "./../../images/download-black.svg";
import blackEmailSnapshot from "./../../images/snapshot-black.svg";
import clock from "./../../images/clock.svg";
import { Piechart, MultilineChart } from "../../components/charts/charts";
import NoAccess from "../../images/no-access.png";


export default function BrandDashboard() {
  let [searchParams] = useSearchParams();
  const [ dashboardData, setDashboardData ] = useState({"contract":{"loaded":false,"data":{},"graphs":{}},"campaign":{"loaded":false,"data":{}},"influencers":{"loaded":false,"data":{}},"meta":{}})
  const [ error, setError ] = useState(null);
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ isScreenshotting, setIsScreenshotting ] = useState(false)
  const { currentProfile } = useAuthContext();
  const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
  const { providers } = useImageContext();
  const [ availableCampaigns, setAvailableCampaigns ] = useState([])
  var brandID = searchParams.get('brandID')
  if (brandID == null){
    brandID = currentProfile.profile.id
  }
  var view = searchParams.get('view');
  if (view == null){
    view = "contract"
  }
  const [tabID, setTabID] = useState(view);
  var campaign = searchParams.get('campaign');
  if (campaign == null){
    campaign = "all"
  }
  const [ selectedCampaign, setSelectedCampaign ] = useState(campaign)
  var chosenTimePeriod = searchParams.get('timePeriod');
  if (chosenTimePeriod == null){
    chosenTimePeriod = "all"
  }
  const [ timePeriod, setTimePeriod ] = useState(chosenTimePeriod)

  function changeTab (tabName) {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('view', tabName);
    let newUrl = window.location.origin + window.location.pathname + '?' + currentUrlParams.toString();
    window.history.replaceState({path:newUrl},'',newUrl);
  }

  async function changeCampaign (campaignID) {
    setSelectedCampaign(campaignID)
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('campaign', campaignID);
    let newUrl = window.location.origin + window.location.pathname + '?' + currentUrlParams.toString();
    window.history.replaceState({path:newUrl},'',newUrl);
    getDashboard(null,campaignID)
  }

  async function changeTimePeriod (selectedTimePeriod) {
    setTimePeriod(selectedTimePeriod)
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('timePeriod', selectedTimePeriod);
    let newUrl = window.location.origin + window.location.pathname + '?' + currentUrlParams.toString();
    window.history.replaceState({path:newUrl},'',newUrl);
    getDashboard(selectedTimePeriod,null)
  }

  async function snapshotDashboard(){
    setIsScreenshotting(true)
    var response = await apiCall(`brand/dashboardSnapshot?id=${brandID}&timePeriod=${timePeriod}&campaign=${selectedCampaign}`,{ "method" : "GET" });
    if (response["success"]){
      setAlert({"display":"toast","visible":true,"title":"Emailed Dashboard Snapshot","content":"A snapshot of the dashboard has been emailed to you","className":"bg-success text-white"})
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to snapshot dashboard","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
    setIsScreenshotting(false)
  }

  async function getDashboard(selectedTimePeriod=null,chosenCampaign=null){
    var selectedTimePeriod = selectedTimePeriod ? selectedTimePeriod : timePeriod
    var chosenCampaign = chosenCampaign ? chosenCampaign : selectedCampaign
    setDashboardData({"contract":{"loaded":false,"data":{},"graphs":{}},"campaign":{"loaded":false,"data":{}},"influencers":{"loaded":false,"data":{}},"meta":{"loaded":false,"data":{}}})
    var response = await apiCall(`brand/dashboard?id=${brandID}&timePeriod=${selectedTimePeriod}&campaign=${chosenCampaign}`,{ "method" : "GET" });
    if (response["success"]){
      var tempDashboardData = dashboardData
      if (response["success"]["state"].toLowerCase() != "completed"){
        tempDashboardData["meta"] = response["success"]
        tempDashboardData["meta"]["loaded"] = true
      }
      else {
        tempDashboardData["contract"] = {"loaded":true,"data":response["success"]["roi"]}
        tempDashboardData["campaign"] = {"loaded":true,"data":response["success"]["dashboard"],"graphs":response["success"]["graphs"]}
        tempDashboardData["influencers"] = {"loaded":true,"data":response["success"]["topInfluencers"]}
        tempDashboardData["meta"] = {"loaded":true,"state":response["success"]["state"].toLowerCase(),"brandPages":response["success"]["brandPages"],"visiblePagesMetrics":response["success"]["visiblePagesMetrics"]}
        if (searchParams.get('view') !== null) {
          setTabID(searchParams.get('view'))
        }
        else {
          setTabID(["contract", "campaigns", "influencers"].filter((pages) => (response["success"]["brandPages"].includes(pages)))[0])
        }
        setAvailableCampaigns(response["success"]["campaigns"])
      }
      setDashboardData(tempDashboardData)
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to retrieve brand dashboard","content":response["failure"]["error"],"className":"bg-danger text-white"})
      setError(response["failure"]["error"])
    }
    setIsLoaded(true)
  }

  useEffect(() => {
    getDashboard();
  }, []);
  
  if (error) {
    return <div className="text-danger text-center h-100 d-flex align-items-center justify-content-start px-4 px-md-0 py-5 flex-column fs-5"><img src={NoAccess} className="noAccessImg mt-5" /><div>Error: {error}</div></div>;
  }

  return (
    <div className="bg-page BrandDashboard">
      <Topbar />
      <div className="pageName">My Dashboard</div>
      <AlertModal modal={showAlert} onClose={() => setAlert({"display":"toast","visible":false,"title":"","content":""})} /> 
      <Container className="fullscreenWithTopBar px-0" id="screenshotCapture" fluid>
        <Tab.Container id="dashboard-tab" activeKey={tabID} onSelect={(key) => ["contract","campaigns","influencers"].includes(key) && setTabID(key)}>
          <Container className="pt-4 px-0 bg-white d-none">
            <div className="mb-2 py-2 text-center">
              <div className="sectionTitle">My Dashboard</div>
            </div>
            <Nav variant="tabs" className="flex-nowrap px-3" id="nav-tabMobile">
                <Nav.Item>
                  <Nav.Link eventKey="contract" onClick={()=>changeTab("contract")}>Contract ROI</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="campaigns" onClick={()=>changeTab("campaigns")}>Campaign Results</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="influencers" onClick={()=>changeTab("influencers")}>Influencer Analysis</Nav.Link>
                </Nav.Item>
            </Nav>        
          </Container>

          {isLoaded ? 
          (<>
            <Container className="pageTitleContainer px-0 shadow-sm pb-0 d-flex" fluid>
              <Container className="border-bottom pb-0" fluid>
                <Container className="px-0">
                  <Row className="px-4 px-md-3 px-xl-4 row">
                    <Col xs={12} md={12} lg={12} xl={12} xxl={3} className="mb-3 mb-lg-4 pt-4 d-none d-md-flex">
                      <h1 className="pageTitle text-white mt-lg-2 tragedyRegularFont ps-md-0">My Dashboard</h1>
                    </Col>
                    <Col xs={12} md={12} lg={12} xl={12} xxl={9} className="mb-xxl-4 pt-xxl-4 mt-3 mt-md-0">
                      <Row>
                    {((dashboardData.meta.loaded && dashboardData.meta.state == "completed") || !dashboardData.meta.loaded) && 
                    (<>
                      <Col>
                        <Nav variant="tabs" className="nav nav-tabs border-0" id="nav-tabMobile">
                          {dashboardData.meta.brandPages && dashboardData.meta.brandPages.includes("contract") && 
                            <Nav.Item>
                                <Nav.Link eventKey="contract" onClick={()=>changeTab("contract")} className="bg-transparent">Contract ROI</Nav.Link>
                            </Nav.Item>
                          }
                          {dashboardData.meta.brandPages && dashboardData.meta.brandPages.includes("campaigns") && 
                            <Nav.Item>
                              <Nav.Link eventKey="campaigns" onClick={()=>changeTab("campaigns")} className="bg-transparent">Campaign Results</Nav.Link>
                            </Nav.Item>
                          }
                          {dashboardData.meta.brandPages && dashboardData.meta.brandPages.includes("influencers") && 
                          <Nav.Item>
                            <Nav.Link eventKey="influencers" onClick={()=>changeTab("influencers")} className="bg-transparent">Influencer Analysis</Nav.Link>
                          </Nav.Item>
                          }
                        </Nav>
                      </Col>
                      <Col xs={6}  className="d-none d-lg-flex justify-content-end">
                        <div className="d-flex align-items-center justify-content-end">                  
                          <Dropdown className="me-2">
                            <Dropdown.Toggle variant="secondary" disabled={tabID != "campaigns"} className="border small px-2 py-1">
                              {selectedCampaign == "all" || tabID != "campaigns" ? "All Campaigns" : availableCampaigns[selectedCampaign]}
                            </Dropdown.Toggle>

                                <Dropdown.Menu className="small">
                                  <Dropdown.Item key="all" onClick={() => changeCampaign("all")}>All Campaigns</Dropdown.Item>
                                  {Object.keys(availableCampaigns).map(campaignID => 
                                    <Dropdown.Item key={campaignID} onClick={() => changeCampaign(campaignID)}>{availableCampaigns[campaignID]}</Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                              
                              <Dropdown className="me-2">
                                <Dropdown.Toggle variant="secondary" disabled={tabID != "campaigns"} className="small px-2 py-1 d-flex align-items-center">
                                  <img src={clock} height="16px" className="me-1" /> {timePeriod == "all" ? "Full Contract" : timePeriod}
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="small">
                                  <Dropdown.Item key="all" onClick={() => changeTimePeriod("all")}>Full Contract</Dropdown.Item>
                                  <Dropdown.Item key="Last Three Months" onClick={() => changeTimePeriod("Last Three Months")}>Last Three Months</Dropdown.Item>
                                  <Dropdown.Item key="This Year" onClick={() => changeTimePeriod("This Year")}>This Year</Dropdown.Item>
                                  <Dropdown.Item key="Last Year" onClick={() => changeTimePeriod("Last Year")}>Last Year</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>

                              <Button  variant="secondary" size="sm" className="small px-2 py-1 d-flex align-items-center me-2" onClick={() => snapshotDashboard()}>
                                {isScreenshotting ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Taking Snapshot...</> : <><img src={blackEmailSnapshot} height="16px" className="me-1" /> Email Snapshot</>}</Button>
                              {/* <Button variant="light" size="sm" className="border small px-2 py-1 d-flex align-items-center">
                                <img src={blackDownload} height="16px" className="me-1" /> Download Data
                              </Button> */}

                            </div>
                          </Col>
                        </>)}
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </Container>
            </Container>

            <Container fluid className="dashboardContent" id="screenshotSizer">
              <Container className="px-3 px-md-0">
                <Row className="px-md-3">
                  <div className="col-12">
                    <Tab.Content className="">
                      {(dashboardData.meta.loaded && dashboardData.meta.state != "completed") ? (
                        <Col xs={12} className="my-4">
                            <Card className="h-100">
                              <Card.Body className="px-4">
                                <Card.Title className="mb-3">This dashboard isn't ready yet</Card.Title>
                                <div>This dashboard is not available to view yet. It's current status is: <b>{capitalise(dashboardData.meta.state)}</b>.<br />This dashboard is due to be analysed: <b>{(getDifference(dashboardData.meta.nextUpdate).future ? `${getDifference(dashboardData.meta.nextUpdate).difference} ${getDifference(dashboardData.meta.nextUpdate).unit} from now` : "Now")}</b></div>
                              </Card.Body>
                            </Card>
                        </Col>
                      ) : 
                      (<>
                        {dashboardData.meta.brandPages && dashboardData.meta.brandPages.includes("campaigns") &&
                          <>
                          {dashboardData.campaign.loaded ?
                            <Tab.Pane eventKey="campaigns">
                              <Row className="mt-4">
                                <Col xs={12}>
                                  <div className="d-flex align-items-center justify-content-start flex-wrap">
                                    {dashboardData.meta.visiblePagesMetrics.includes("bids") &&
                                      <Card className="text-center me-4 mb-4">
                                        <Card.Body className="px-4">
                                          <Card.Title as="h1" className="mb-0">{dashboardData.campaign.data.bids}</Card.Title>
                                          <Card.Text className="small text-muted">Total Bids</Card.Text>
                                        </Card.Body>
                                      </Card>
                                    }

                                    {dashboardData.meta.visiblePagesMetrics.includes("acceptedInfluencers") &&
                                      <Card className="text-center me-4 mb-4">
                                        <Card.Body className="px-4">
                                          <Card.Title as="h1" className="mb-0">{dashboardData.campaign.data.acceptedInfluencers}</Card.Title>
                                          <Card.Text className="small text-muted">Accepted Influencers</Card.Text>
                                        </Card.Body>
                                      </Card>
                                    }

                                    {dashboardData.meta.visiblePagesMetrics.includes("assets") &&
                                      <Card className="text-center me-4 mb-4">
                                        <Card.Body className="px-4">
                                          <Card.Title as="h1" className="mb-0">{dashboardData.campaign.data.assets}</Card.Title>
                                          <Card.Text className="small text-muted">Created Assets</Card.Text>
                                        </Card.Body>
                                      </Card>
                                    }
                                  </div>
                                </Col>

                                <Col xs={12}>
                                  <div className="d-flex align-items-center justify-content-start flex-wrap">
                                    {dashboardData.meta.visiblePagesMetrics.includes("stories") &&
                                      <Card className="text-center me-4 mb-4">
                                        <Card.Body className="px-4">
                                          <Card.Title as="h1" className="mb-0">{getSmallNumber(dashboardData.campaign.data.stories)}</Card.Title>
                                          <Card.Text className="small text-muted">Story Posts</Card.Text>
                                        </Card.Body>
                                      </Card>
                                    }

                                    {dashboardData.meta.visiblePagesMetrics.includes("posts") &&
                                      <Card className="text-center me-4 mb-4">
                                        <Card.Body className="px-4">
                                          <Card.Title as="h1" className="mb-0">{getSmallNumber(dashboardData.campaign.data.posts)}</Card.Title>
                                          <Card.Text className="small text-muted">Video/Static Posts</Card.Text>
                                        </Card.Body>
                                      </Card>
                                    }

                                    {dashboardData.meta.visiblePagesMetrics.includes("reach") &&
                                      <Card className="text-center me-4 mb-4">
                                        <Card.Body className="px-4">
                                          <Card.Title as="h1" className="mb-0">{getSmallNumber(dashboardData.campaign.data.reach)}</Card.Title>
                                          <Card.Text className="small text-muted">Total Reach</Card.Text>
                                        </Card.Body>
                                      </Card>
                                    }

                                    {dashboardData.meta.visiblePagesMetrics.includes("impressions") &&
                                      <Card className="text-center me-4 mb-4">
                                        <Card.Body className="px-4">
                                          <Card.Title as="h1" className="mb-0">{getSmallNumber(dashboardData.campaign.data.impressions)}</Card.Title>
                                          <Card.Text className="small text-muted">Total Impressions</Card.Text>
                                        </Card.Body>
                                      </Card>
                                    }

                                    {dashboardData.meta.visiblePagesMetrics.includes("engagement") &&
                                      <Card className="text-center me-4 mb-4">
                                        <Card.Body className="px-4">
                                          <Card.Title as="h1" className="mb-0">{getSmallNumber(dashboardData.campaign.data.engagement)}</Card.Title>
                                          <Card.Text className="small text-muted">Total Engagements</Card.Text>
                                        </Card.Body>
                                      </Card>
                                    }

                                    {dashboardData.meta.visiblePagesMetrics.includes("engagementRate") &&
                                      <Card className="text-center me-4 mb-4">
                                        <Card.Body className="px-4">
                                          <Card.Title as="h1" className="mb-0">{getPercentage(dashboardData.campaign.data.engagementRate ? dashboardData.campaign.data.engagementRate : 0.0)}</Card.Title>
                                          <Card.Text className="small text-muted">Engagement Rate</Card.Text>
                                        </Card.Body>
                                      </Card>
                                    }

                                    {dashboardData.meta.visiblePagesMetrics.includes("postReach") &&
                                      <Card className="text-center me-4 mb-4">
                                        <Card.Body className="px-4">
                                          <Card.Title as="h1" className="mb-0">{getPercentage(dashboardData.campaign.data.postReach ? dashboardData.campaign.data.postReach/dashboardData.campaign.data.reach : 0.0)}</Card.Title>
                                          <Card.Text className="small text-muted">Post Reach Rate</Card.Text>
                                        </Card.Body>
                                      </Card>
                                    }
                                  </div>
                                </Col>

                                {dashboardData.meta.visiblePagesMetrics.includes("postTypes") &&
                                  <Col xs={12} md={12} lg={6} className="mb-4">
                                    <Card className="h-100">
                                      <Card.Body className="px-4 pb-0">
                                        <Card.Title className="mb-0 mt-2">Distribution of Page Post Types</Card.Title>
                                        <Piechart data={dashboardData.campaign.graphs.postTypes} heightRatio="100" meta={{title: null,series:"Post Type"}} />
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                }

                                {dashboardData.meta.visiblePagesMetrics.includes("postFrequency") &&
                                  <Col xs={12} md={12} lg={6} className="mb-4">
                                    <Card className="h-100">
                                      <Card.Body className="px-4 pb-0">
                                        <Card.Title className="mb-0 mt-2">Post Frequency</Card.Title>
                                        <MultilineChart data={[{"data":dashboardData.campaign.graphs.postFrequency.postDataArray,"series":"Video/Static Posts","type":"bar","format":"{value}"},{"data":dashboardData.campaign.graphs.postFrequency.storiesDataArray,"series":"Story Posts","type":"bar","format":"{value}"}]} xAxisLabel={dashboardData.campaign.graphs.postFrequency.xLabels} heightRatio="100" meta={{title: null, differentY: false}} />
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                }

                                {dashboardData.meta.visiblePagesMetrics.includes("impressionsReachFrequency") &&
                                  <Col xs={12} md={12} lg={6} className="mb-4">
                                    <Card className="h-100">
                                      <Card.Body className="px-4 pb-0">
                                        <Card.Title className="mb-0 mt-2">Impressions/Reach Frequency</Card.Title>
                                        <MultilineChart data={[{"data":dashboardData.campaign.graphs.impressionsReachFrequency.impressionsDataArray,"series":"Impressions","type":"bar","format":"{value}"},{"data":dashboardData.campaign.graphs.impressionsReachFrequency.reachDataArray,"series":"Reach","type":"bar","format":"{value}"}]} xAxisLabel={dashboardData.campaign.graphs.impressionsReachFrequency.xLabels} heightRatio="100" meta={{title: null, differentY: false}} />
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                }

                                {dashboardData.meta.visiblePagesMetrics.includes("impressionsOverTime") &&
                                  <Col xs={12} md={6} lg={3} className="mb-4">
                                    <Card className="text-center h-100">
                                      <Card.Body className="px-4 pb-0">
                                        <Card.Text className="mb-0 mt-2">Total Impressions by Campaign</Card.Text>
                                        <Card.Title as="h1" className="mb-0">{getSmallNumber(dashboardData.campaign.data.impressions)}</Card.Title>
                                            <MultilineChart data={[{"data":dashboardData.campaign.graphs.impressionsOverTime.impressionsDataArray,"series":"Impressions","type":"line","format":"{value}"}]} xAxisLabel={dashboardData.campaign.graphs.impressionsOverTime.xLabels} heightRatio="100" meta={{title: null, differentY: false}} />
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                }

                                {dashboardData.meta.visiblePagesMetrics.includes("bidsOverTime") &&
                                  <Col xs={12} md={6} lg={3} className="mb-4">
                                    <Card className="text-center h-100">
                                      <Card.Body className="px-4">
                                        <Card.Text className="mb-0 mt-2">Total Bids by Campaign</Card.Text>
                                        <Card.Title as="h1" className="mb-0">{getSmallNumber(dashboardData.campaign.data.bids)}</Card.Title>
                                        <MultilineChart data={[{"data":dashboardData.campaign.graphs.bidsOverTime.bidsDataArray,"series":"Bids","type":"line","format":"{value}"}]} xAxisLabel={dashboardData.campaign.graphs.bidsOverTime.xLabels} heightRatio="100" meta={{title: null, differentY: false}} />
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                }
                              </Row>
                            </Tab.Pane>
                          : <FullPageSpinner /> }
                          </>
                        }
                        {dashboardData.meta.brandPages && dashboardData.meta.brandPages.includes("contract") &&
                          <>
                          {dashboardData.contract.loaded ?
                          <Tab.Pane eventKey="contract">
                            <Row className="mt-4">
                              <Col xs={12}>
                                <div className="d-flex align-items-center justify-content-start flex-wrap">
                                  {dashboardData.meta.visiblePagesMetrics.includes("savingsRoi") &&
                                    <Card className="text-center me-4 mb-4">
                                      <Card.Body className="px-4">
                                        <Card.Title as="h1" className="mb-0">{new Intl.NumberFormat('en-GB',{style:'currency',currency:'GBP',minimumFractionDigits:2}).format(dashboardData.contract.data.savingsRoi)}</Card.Title>
                                        <Card.Text className="small text-muted">Savings ROI</Card.Text>
                                      </Card.Body>
                                    </Card>
                                  }

                                  {dashboardData.meta.visiblePagesMetrics.includes("roi") &&
                                    <Card className="text-center me-4 mb-4">
                                      <Card.Body className="px-4">
                                        <Card.Title as="h1" className="mb-0">{getSmallNumber(dashboardData.contract.data.roi,1)}:1</Card.Title>
                                        <Card.Text className="small text-muted">ROI</Card.Text>
                                      </Card.Body>
                                    </Card>
                                  }

                                  {dashboardData.meta.visiblePagesMetrics.includes("contentSavings") &&
                                    <Card className="text-center me-4 mb-4">
                                      <Card.Body className="px-4">
                                        <Card.Title as="h1" className="mb-0">{new Intl.NumberFormat('en-GB',{style:'currency',currency:'GBP',minimumFractionDigits:2}).format(dashboardData.contract.data.contentSavings)}</Card.Title>
                                        <Card.Text className="small text-muted">Content Savings</Card.Text>
                                      </Card.Body>
                                    </Card>
                                  }
                                </div>
                              </Col>

                              <Col xs={12}>
                                <div className="d-flex align-items-center justify-content-start flex-wrap">
                                  {dashboardData.meta.visiblePagesMetrics.includes("costPerEngagement") &&
                                    <Card className="text-center me-4 mb-4">
                                      <Card.Body className="px-4">
                                        <Card.Title as="h1" className="mb-0">{new Intl.NumberFormat('en-GB',{style:'currency',currency:'GBP',minimumFractionDigits:2,maximumFractionDigits:4}).format(dashboardData.contract.data.costPerEngagement)}</Card.Title>
                                        <Card.Text className="small text-muted">Cost Per Engagement (CPE)</Card.Text>
                                      </Card.Body>
                                    </Card>
                                  }

                                  {dashboardData.meta.visiblePagesMetrics.includes("costPerImpression") &&
                                    <Card className="text-center me-4 mb-4">
                                      <Card.Body className="px-4">
                                        <Card.Title as="h1" className="mb-0">{new Intl.NumberFormat('en-GB',{style:'currency',currency:'GBP',minimumFractionDigits:2}).format(dashboardData.contract.data.costPerImpression*1000)}</Card.Title>
                                        <Card.Text className="small text-muted">Cost Per 1,000 Impressions (CPM)</Card.Text>
                                      </Card.Body>
                                    </Card>
                                  }

                                  {dashboardData.meta.visiblePagesMetrics.includes("costPerInfluencer") &&
                                    <Card className="text-center me-4 mb-4">
                                      <Card.Body className="px-4">
                                        <Card.Title as="h1" className="mb-0">{new Intl.NumberFormat('en-GB',{style:'currency',currency:'GBP',minimumFractionDigits:2}).format(dashboardData.contract.data.costPerInfluencer)}</Card.Title>
                                        <Card.Text className="small text-muted">Cost Per Influencer</Card.Text>
                                      </Card.Body>
                                    </Card>
                                  }

                                  {dashboardData.meta.visiblePagesMetrics.includes("costPerAsset") &&
                                    <Card className="text-center me-4 mb-4">
                                      <Card.Body className="px-4">
                                        <Card.Title as="h1" className="mb-0">{new Intl.NumberFormat('en-GB',{style:'currency',currency:'GBP',minimumFractionDigits:2}).format(dashboardData.contract.data.costPerAsset)}</Card.Title>
                                        <Card.Text className="small text-muted">Cost Per Asset</Card.Text>
                                      </Card.Body>
                                    </Card>
                                  }

                                  {dashboardData.meta.visiblePagesMetrics.includes("hoursSaved") &&
                                    <Card className="text-center me-4 mb-4">
                                      <Card.Body className="px-4">
                                        <Card.Title as="h1" className="mb-0">{dashboardData.contract.data.hoursSaved}</Card.Title>
                                        <Card.Text className="small text-muted">Hours Saved</Card.Text>
                                      </Card.Body>
                                    </Card>
                                  }

                                  {dashboardData.meta.visiblePagesMetrics.includes("fteCost") &&
                                    <Card className="text-center me-4 mb-4">
                                      <Card.Body className="px-4">
                                        <Card.Title as="h1" className="mb-0">{new Intl.NumberFormat('en-GB',{style:'currency',currency:'GBP'}).format(dashboardData.contract.data.fteCost)}</Card.Title>
                                        <Card.Text className="small text-muted">Cost of Time Saved</Card.Text>
                                      </Card.Body>
                                    </Card>
                                  }
                                </div>
                              </Col>

                              {dashboardData.meta.visiblePagesMetrics.includes("roiPerMonth") &&
                                <Col xs={12} md={12} lg={6} className="mb-4">
                                  <Card className="h-100">
                                    <Card.Body className="px-4 pb-0">
                                      <Card.Title className="mb-0 mt-2">Savings ROI per Month</Card.Title>
                                      <Piechart data={dashboardData.campaign.graphs.roiPerMonth} heightRatio="100" meta={{title: null,series:"Savings ROI"}} />
                                    </Card.Body>
                                  </Card>
                                </Col>
                              }

                              {dashboardData.meta.visiblePagesMetrics.includes("contractHoursSavedOverTime") &&
                                <Col xs={12} md={12} lg={6} className="mb-4">
                                  <Card className="h-100">
                                    <Card.Body className="px-4 pb-0">
                                      <Card.Title className="mb-0 mt-2">Hours Saved per Month</Card.Title>
                                      <MultilineChart data={[{"data":dashboardData.campaign.graphs.hoursSavedOverTime.hoursSavedDataArray,"series":"Hours Saved","type":"line","format":"{value}"}]} xAxisLabel={dashboardData.campaign.graphs.hoursSavedOverTime.xLabels} heightRatio="100" meta={{title: null, differentY: false}} />
                                    </Card.Body>
                                  </Card>
                                </Col>
                              }

                              {dashboardData.meta.visiblePagesMetrics.includes("influencerSavingsOverTime") &&
                                <Col xs={12} md={12} lg={12} className="mb-4">
                                  <Card className="h-100">
                                    <Card.Body className="px-4 pb-0">
                                      <Card.Title className="mb-0 mt-2">Influencer Fee Savings</Card.Title>
                                      <MultilineChart data={[{"data":dashboardData.campaign.graphs.influencerSavingsOverTime.influencerSavingsDataArray,"series":"Savings","type":"line","format":"£{value}"}]} xAxisLabel={dashboardData.campaign.graphs.influencerSavingsOverTime.xLabels} heightRatio="100" meta={{title: null, differentY: false}} />
                                    </Card.Body>
                                  </Card>
                                </Col>
                              }
                            </Row>
                          </Tab.Pane>
                          : <FullPageSpinner /> }
                          </>
                        }
                        {dashboardData.meta.brandPages && dashboardData.meta.brandPages.includes("influencers") &&
                          <>
                          {dashboardData.influencers.loaded ?
                          <Tab.Pane eventKey="influencers">
                            <Row className="mt-4">
                            {dashboardData.influencers.data.length > 0 ?
                              <>
                                <Col xs={12}>                                  
                                  {dashboardData.meta.visiblePagesMetrics.includes("influencers") &&
                                    <Card className="mb-4 d-none d-lg-block table-responsive">
                                      <Table striped hover className="mb-0">
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th className="text-nowrap">Influencer</th>
                                            <th className="text-nowrap">Following</th>
                                            <th className="text-nowrap">Posts</th>
                                            <th className="text-nowrap">Reach</th>
                                            <th className="text-nowrap">Impressions</th>
                                            <th className="text-nowrap">Engagement Rate</th>
                                            <th className="text-nowrap">Saving</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {dashboardData.influencers.data.map((influencer,index) => (
                                            <tr key={index}>
                                              <td>{index+1}</td>
                                              <td className="text-nowrap"><img src={getImageUrl(providers,influencer.profileImage,["w_80","h_80","c_scale"])} height="40px" className="border rounded me-1"></img> {influencer.name}</td>
                                              <td>{getSmallNumber(influencer.following)}</td>
                                              <td>{getSmallNumber(influencer.posts)}</td>
                                              <td>{getSmallNumber(influencer.reach)}</td>
                                              <td>{getSmallNumber(influencer.impressions)}</td>
                                              <td>{getSmallNumber(influencer.engagementRate)}%</td>
                                              <td>{new Intl.NumberFormat('en-GB',{style:'currency',currency:'GBP',minimumFractionDigits:2}).format(influencer.savings)}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                    </Card>
                                  }
                                                                  
                                <div className="mb-4 d-block d-lg-none">
                                  {dashboardData.influencers.data.map((influencer,index) => (
                                    <Card className="pt-4 px-4 mb-1 pb-4" key={index}>
                                      <div className="d-flex align-items-start text-nowrap w-auto">
                                        <div className="me-3 fw-bold">{index+1}</div>
                                        <div className="text-nowrap w-100">
                                          <div className="d-flex align-items-center mb-3">
                                            <img src={getImageUrl(providers,influencer.profileImage,["w_80","h_80","c_scale"])} height="80px" className="border rounded me-3"></img> 
                                            <div>
                                            <div className="fw-bold fs-3">{influencer.name}</div>
                                              <div className="text-muted small">Influencer</div>
                                            </div>
                                          </div>
                                          <div className="d-flex align-item-center justify-content-between mb-3">
                                            <div>
                                              <div className="fw-bold fs-4 text-start">{getSmallNumber(influencer.following)}</div>
                                              <div className="text-muted small text-start">Following</div>
                                            </div>
                                            <div>
                                              <div className="fw-bold fs-4 text-center">{getSmallNumber(influencer.posts)}</div>
                                              <div className="text-muted small text-center">Posts</div>
                                            </div>
                                            <div>
                                              <div className="fw-bold fs-4 text-center">{getSmallNumber(influencer.reach)}</div>
                                              <div className="text-muted small text-center">Reach</div>
                                            </div>
                                            <div>
                                              <div className="fw-bold fs-4 text-end">{getSmallNumber(influencer.impressions)}</div>
                                              <div className="text-muted small text-end">Impressions</div>
                                            </div>
                                          </div>
                                          <div className="d-flex align-item-center justify-content-between">
                                            <div>
                                              <div className="fw-bold fs-4 text-start">{getSmallNumber(influencer.engagementRate)}</div>
                                              <div className="text-muted small text-start">Engagement Rate</div>
                                            </div>
                                            <div>
                                              <div className="fw-bold fs-4 text-end">{new Intl.NumberFormat('en-GB',{style:'currency',currency:'GBP',minimumFractionDigits:2}).format(influencer.savings)}</div>
                                              <div className="text-muted small text-end">Saving</div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Card>
                                  ))}
                                </div>

                                </Col>
                                
                                {dashboardData.meta.visiblePagesMetrics.includes("followerLocations") &&
                                  <Col xs={12} md={6} lg={6} className="mb-4">
                                    <Card className="h-100">
                                      <Card.Body className="px-4">
                                        <Card.Title className="mb-3 mt-2">Follower Locations</Card.Title>
                                        <Piechart data={dashboardData.campaign.graphs.followerLocations} heightRatio="100" meta={{title: null,series:"Location"}} />
                                      </Card.Body>
                                    </Card>
                                  </Col>        
                                }

                                {dashboardData.meta.visiblePagesMetrics.includes("followerGenders") &&
                                  <Col xs={12} md={6} lg={6} className="mb-4">
                                    <Card className="h-100">
                                      <Card.Body className="px-4">
                                        <Card.Title className="mb-3 mt-2">Follower Genders</Card.Title>
                                        <Piechart className="text-capitalize" data={dashboardData.campaign.graphs.followerGenders} heightRatio="100" meta={{title: null,series:"Location"}} />
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                }
                              </>
                              :
                              <Col xs={12} className="mb-4">
                                  <Card className="h-100">
                                    <Card.Body className="px-4">
                                      <Card.Title className="mb-2">No Influencers Available to Analyse</Card.Title>
                                      <div className="text-muted">No influencers were found to perform detailed analysis on. This could be due to the fact that no applicable campaigns have been run, or that no influencers have been picked up yet.</div>
                                    </Card.Body>
                                  </Card>
                              </Col>
                              }

                              {dashboardData.meta.visiblePagesMetrics.includes("influencersHoursSavedOverTime") &&
                                <Col xs={12} md={12} lg={6} className="mb-4">
                                  <Card className="h-100">
                                    <Card.Body className="px-4 pb-0">
                                      <Card.Title className="mb-0 mt-2">Hours Saved Per Month</Card.Title>
                                      <MultilineChart data={[{"data":dashboardData.campaign.graphs.hoursSavedOverTime.hoursSavedDataArray,"series":"Hours Saved","type":"line","format":"{value}"}]} xAxisLabel={dashboardData.campaign.graphs.hoursSavedOverTime.xLabels} heightRatio="100" meta={{title: null, differentY: false}} />
                                    </Card.Body>
                                  </Card>
                                </Col>
                              }

                              {dashboardData.influencers.data.length > 0 && dashboardData.meta.visiblePagesMetrics.includes("followerAges") &&
                                <Col xs={12} md={12} lg={6} className="mb-4">
                                  <Card className="h-100">
                                    <Card.Body className="px-4 pb-0">
                                      <Card.Title className="mb-0 mt-2">Demographics by age</Card.Title>
                                      <MultilineChart data={[{"data":dashboardData.campaign.graphs.followerAges.agesDataArray,"series":"Ages","type":"bar","format":"{value}"}]} xAxisLabel={dashboardData.campaign.graphs.followerAges.xLabels} heightRatio="100" meta={{title: null, differentY: false}} />
                                    </Card.Body>
                                  </Card>
                                </Col>
                              }

                              {dashboardData.meta.visiblePagesMetrics.includes("returningInfluencers") &&
                                <Col xs={12} md={12} lg={6} className="mb-4">
                                  <Card className="h-100">
                                    <Card.Body className="px-4 pb-0">
                                      <Card.Title className="mb-0 mt-2">Returning vs New Influencers</Card.Title>                                  
                                  <Piechart data={dashboardData.campaign.graphs.returningInfluencers} heightRatio="100" meta={{title: null,series:"Returning Influencers"}} />
                                    </Card.Body>
                                  </Card>
                                </Col>
                              }
                            </Row>
                          </Tab.Pane>
                          : <FullPageSpinner /> }
                          </>
                        }
                      </>)}
                    </Tab.Content>
                  </div>
                </Row>
              </Container>
            </Container>
          </>
          )
          : <FullPageSpinner /> }
        </Tab.Container>
      </Container>
      <Footer />
    </div>
  )
}