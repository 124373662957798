export function setFilter(filter,type,exclusions=[]) {
    let currentUrlParams = new URLSearchParams(window.location.search);
    if (exclusions.length > 0){
        filter = Object.fromEntries(Object.entries(filter).filter(([key]) => !exclusions.includes(key)))
    }
    currentUrlParams.set(`${type}Filter`, JSON.stringify(filter));
    let newUrl = window.location.origin + window.location.pathname + '?' + currentUrlParams.toString();
    window.history.replaceState({path:newUrl},'',newUrl);
}

export function getFilter(type) {
    let query = new URLSearchParams(window.location.search);
    return query.get(`${type}Filter`)
}