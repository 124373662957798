import React, { useState } from "react";
import { Container, Row } from 'react-bootstrap';
import { Link, useSearchParams } from "react-router-dom";
import SupportModal from "../../../components/support/support";
import SignupFooter from "../../../components/footer/SignupFooter";

import './Signup.css';
import theRoom from './../../../images/theRoom.svg';
import logoWhite from './../../../images/room-white-logo.svg';
import warning from './../../../images/warning-alert.svg';

export default function PaymentFailed() { 
    let [searchParams] = useSearchParams();
    const [supportModal, setSupportModal] = useState({"visible":false,"type":"Payment Unsuccessful","message":"","context":"brand"});
    const paymentID = searchParams.get("id")

    if (paymentID) {
        return (
            <div>
                <SupportModal supportModal={supportModal} setSupportModal={setSupportModal} isInternal={true} isPopup={true} relatedIDs={[{"name":"paymentID","value":paymentID}]}/>
                <Container fluid className="mainPlansSelection">
                    <Row className='h-100'>
                        <div className='col-lg-5 h-100 d-flex align-items-center justify-content-end'>
                            <img src={theRoom} height="80px" className='me-5 pe-5 position-fixed' />
                        </div>
                        <div className='col-lg-7 bg-white d-flex flex-column align-items-center justify-content-center'>
                            <img src={warning} height="40px" className='mb-4 mt-5' />
                            <h4 className="text-navy mb-3 fw-bold">Payment unsuccessful</h4>
                            <Row className="d-flex align-items-start justify-content-center mb-5">
                                <div className="col-12 text-center">
                                    <span>Your payment request has failed to process</span>
                                </div>
                                <div className="col-8 my-4">
                                    <div className="border-bottom mb-5 pb-5"></div>
                                    <h5 className="text-center mb-4">What's next?</h5>
                                    <div className="text-center mt-5">
                                        <button onClick={() => setSupportModal({"visible":true,"type":"Payment Unsuccessful","message":"","context":"brand"})} className="btn btn-primary px-5">Contact Support</button>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </Row>
                </Container>
            
                <SignupFooter />
            </div>    
        );
    }
}