import React from "react";
import "./spinner.css";

export default function FullPageSpinner() {
  return (
    <div className="spinner-container">
      <div className="spinner">
          <svg width="50" height="50" viewBox="0 0 550 603" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M306.614 288.552C306.325 286.101 304.998 283.868 303.037 282.234C297.614 277.712 296.057 265.129 303.21 261.044C317.748 252.328 327.381 237.13 327.381 219.645C327.381 192.354 303.902 170.238 275.001 170.238C246.099 170.238 222.62 192.354 222.62 219.645C222.62 237.13 232.253 252.383 246.791 261.044C253.944 265.184 252.387 277.767 246.964 282.234C245.003 283.868 243.733 286.101 243.387 288.552L226.6 424.462C226.081 428.547 229.484 432.143 233.869 432.143H316.132C320.517 432.143 323.92 428.547 323.401 424.462L306.614 288.552Z"/>
              <path className="path" d="M274.783 602.381C254.676 602.381 234.568 597.094 216.466 586.575L58.5337 494.849C22.3295 473.811 0 434.951 0 392.876V209.478C0 167.402 22.3295 128.542 58.5337 107.505L216.466 15.7783C252.67 -5.25942 297.33 -5.25942 333.534 15.7783L491.466 107.505C527.67 128.542 550 167.402 550 209.478V392.876C550 433.643 529.405 470.868 494.935 492.614L469.299 508.747C464.205 511.962 457.538 510.382 454.341 505.313L422.31 453.809C419.112 448.686 420.684 441.982 425.724 438.767L451.36 422.634C461.712 416.148 467.89 405.03 467.89 392.931V209.478C467.89 196.888 461.224 185.279 450.384 179.011L292.452 87.2846C281.666 81.0169 268.279 81.0169 257.44 87.2846L99.5073 179.011C88.6677 185.279 82.0014 196.888 82.0014 209.478V392.876C82.0014 405.466 88.6677 417.075 99.5073 423.343L257.44 515.069C267.846 521.119 280.528 521.282 291.314 515.56L317.979 501.335C323.29 498.501 329.848 500.517 332.612 505.858L360.958 559.543C363.776 564.884 361.771 571.478 356.459 574.258L329.794 588.483C312.451 597.748 293.536 602.381 274.729 602.381H274.783Z" />
          </svg>
      </div>
    </div>
  )
}