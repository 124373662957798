import React, { useState } from "react";
import { apiCall } from '../../helpers/api';
import { Container, Row, FloatingLabel, Col } from 'react-bootstrap';
import './Unsubscribe.css';
import SignupFooter from "../../components/footer/SignupFooter";
import theRoom from './../../images/theRoom.svg';
import theRoomIcon from './../../images/theRoom-icon.svg';

export default function Unsubscribe() {
    let urlParams = new URLSearchParams(window.location.search);
    const [email, setEmail] = useState(urlParams.get("email") ? urlParams.get("email") : "");
    const [errors, setErrors] = useState("")
    const [viewState, setViewState] = useState("form")

    async function submit() {
        var response = await apiCall('unsubscribe?'+urlParams.toString(),{ "method" : "POST", "data" : {"email":email} } );
        if (response["success"]){
            setViewState("completed")
        }
        else if (response["failure"]){
            setErrors(response["failure"]["error"])
        }
    } 

    return (
    <div className="login-wrapper">
        <Container fluid className="loginBody">
            <Row className='h-100'>
                <div className='col-md-6 col-lg-5 h-100 desktopView'>
                    <div className='d-lg-flex align-items-center justify-content-end h-100'>
                    <img src={theRoom} height="80px" className='me-5 pe-5' />
                    </div>
                </div>
                <div className="col-md-12 col-lg-7 loginBox bg-white h-100 d-lg-flex align-items-center justify-content-start">     
                    <div className="mb-4 py-2 px-0 mt-4 position-relative mobileView">
                    <div className='w-100 text-center position-relative'>
                        <img src={theRoomIcon} height="50px" />
                    </div>
                    <div className="sectionTitle text-truncate"></div>
                    </div>       
                    {viewState == "form" && (
                        <div className='d-block ms-lg-5 ps-lg-5 mx-lg-0 mx-4'>     
                            <form>
                                <h3 className='mb-4 pb-2 text-navy'>Unsubscribe from all notifications</h3>    
                                <div className="text-start">
                                    <label className='mb-3'>
                                        <div className='text-start'>Your Email</div>
                                        <input type="text" className='form-control bg-gray border-gray' id="password" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </label>
                                    <button type="button" onClick={() => submit()} disabled={email.length < 5} className="btn btn-primary w-auto">Unsubscribe</button>
                                </div>
                            </form>
                            {errors && (
                                <p>
                                    <br />
                                    <b>There was an error unsubcribing you.</b>
                                    <br />
                                    <i>Please make sure to use the link in an email we've sent you and double-check your email address is still correct.</i>
                                </p>
                            )}
                        </div>
                    )}
                    {viewState == "completed" && (
                        <div className='d-block ms-lg-5 ps-lg-5 mx-lg-0 mx-4'>     
                            <form>
                                <h3 className='mb-4 pb-2 text-navy'>You have successfully unsubscribed from all notifications</h3>    
                                <p>We're sorry to see you go! If you want to enable email notifications in the future, just login to your account and visit your account settings.</p>
                            </form>
                        </div>
                    )}
                </div>
            </Row>
        </Container>    
        <SignupFooter />
    </div>
    );
}