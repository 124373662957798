import React, { useEffect, useState, Component } from "react";
import { apiCall } from './../../../helpers/api';
import { Container, Row, FloatingLabel, Col } from 'react-bootstrap';
import './Signup.css';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { useSearchParams, useNavigate } from "react-router-dom";
import { validateImage, getImageUrl } from "../../../helpers/images";
import { useImageContext } from "../../../hooks/useImageContext";
import SupportModal from "../../../components/support/support";
import SignupFooter from "../../../components/footer/SignupFooter";
import AlertModal from "../../../components/alerts/alert";

import blueTheRoomLogo from './../../../images/blueTheRoomLogo.svg';
import logoWhite from './../../../images/room-white-logo.svg';
import pinkCurveLine from './../../../images/pinkCurveLine.svg';
import polygonalPics from './../../../images/polygonalPics.svg';
import { passwordMeetsStandard } from "../../../helpers/user"

export default function CompleteSignup() {
    const navigate = useNavigate()
    let [searchParams] = useSearchParams();
    const userID = searchParams.get('id');
    const code = searchParams.get('code');
    const { providers } = useImageContext();
    const [signupData, setSignupData] = useState({
        "firstName":searchParams.get('firstName'),
        "lastName":searchParams.get('lastName'),
        "password":"",
        "passwordConfirm":""
    });
    const [changeCounter, setChangeCounter] = useState(0);
    const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
    const [profileImage, setProfileImage] = useState(null)
    const [uploadedImage, setUploadedImage] = useState(null)

    async function updateImage(props){
        const file = props.target.files[0];
        var [validationResult, validationReason] = validateImage(file)
        if (validationResult){
            var tempFile = {"url":URL.createObjectURL(file),"data":file,"metadata":null,"type":"image"}
            setProfileImage(tempFile)
            var response = await apiCall(`getUploadSignature?id=${userID}&code=${code}&folder=profilePictures&asset_type=${tempFile.type}`,{ "method" : "GET" });
            if (response["success"]) {
                tempFile.metadata = response["success"]["metadata"]
                setProfileImage(tempFile)
                setUploadedImage(null)
            }
        }
        else {
          setAlert({"display":"toast","visible":true,"title":"Invalid Image","content":validationReason,"className":"bg-danger text-white"})
        }
      }

    async function submit() {
        // Validate data
        var valid = true;
        if (!profileImage){
            setAlert({"display":"toast","visible":true,"title":"Missing Profile Picture","content":"Please upload a profile picture to use on the platform.","className":"bg-danger text-white"})
            valid = false;
        }
        if (valid){
            // Upload photo to cloudinary then pass into signup
            const formData = new FormData();
            formData.append("file",profileImage.data)
            formData.append("public_id",profileImage.metadata.public_id)
            formData.append("api_key",profileImage.metadata.api_key)
            formData.append("timestamp",profileImage.metadata.timestamp)
            formData.append("signature",profileImage.metadata.signature)
            if (profileImage.metadata.folder != ""){
                formData.append("folder",profileImage.metadata.folder)
            }
            if (!uploadedImage){
                var uploadedProfileImage = await fetch(`${profileImage.metadata.target}/${profileImage.metadata.resource_type}/upload`, {
                method: "POST",
                body: formData
                }).then(async response => {
                var responseJSON = {}
                try {
                    let responseJSON = await response.json();
                    if (response.ok) {
                        setUploadedImage({"id":responseJSON.public_id,"source":profileImage.metadata.source,"name":profileImage.data.name});
                        return {"id":responseJSON.public_id,"source":profileImage.metadata.source,"name":profileImage.data.name};
                    }
                }
                catch {
                    setAlert({"display":"toast","visible":true,"title":"Could not upload Profile Picture","content":JSON.stringify(responseJSON),"className":"bg-danger text-white"})
                    return null
                }});
            }
            else {
                uploadedProfileImage = uploadedImage;
            }
            if (uploadedProfileImage) {
                var response = await apiCall('influencer/completeSignup',{ "method" : "POST", "data" : {"profileImage":uploadedProfileImage,"password":signupData["password"],"firstName":signupData["firstName"],"lastName":signupData["lastName"],"id":userID,"code":code} } );
                if (response["success"]){
                    setAlert({"display":"toast","visible":true,"title":"Profile Completed!","content":`You've completed your profile, so now you can start making bids. We'll take you to the home page in 10 seconds.`,"className":"bg-success text-white"})
                    var timeLeft = 9;
                    var redirectTimer = setInterval(function(){
                        if(timeLeft <= 0){
                            clearInterval(redirectTimer);
                            window.location.href="/Home";
                        }
                        setAlert({"display":"toast","visible":true,"title":"Profile Completed!","content":`You've completed your profile, so now you can start making bids. We'll take you to the home page in ${timeLeft} seconds.`,"className":"bg-success text-white"})
                        timeLeft -= 1;
                    }, 1000);
                }
                else if (response["failure"]){
                    setAlert({"display":"toast","visible":true,"title":"Could not complete signup","content":response["failure"]["error"],"className":"bg-danger text-white"})
                }
            }
            else {
                setAlert({"display":"toast","visible":true,"title":"Could not upload Profile Picture","content":"Could not upload your profile picture. Please try again.","className":"bg-danger text-white"})
            }
            
        }
    }

    function updateSignupDataState(props, id=null) {
        var tempSignupData = Object.assign({},signupData);
        tempSignupData[props.target.id] = props.target.value;
        setSignupData(tempSignupData); 
        setChangeCounter(changeCounter + 1)
    }   

    if (signupData) {
        return (
        <div>
            <Container fluid className="signupBody">
                <AlertModal modal={showAlert} onClose={() => setAlert({"display":"toast","visible":false,"title":"","content":""})} />
                <Row className='h-100'>
                    <div className='col-lg-5 position-relative bg-gray'>
                        <img src={blueTheRoomLogo} height="36px" className='mt-4 ms-5 mb-5' />
                        <div className="p-5 mx-5 position-overlay">
                            <h2 className="text-navy mb-4 position-overlay fw-700">Hi {signupData.firstName}, Welcome to The Influence Room</h2>
                            <img src={pinkCurveLine} className="pinkCurveLine" />
                            <p>We are so excited to have you on board!</p>
                            <p>Complete your profile to get the most out of your time here and be eligible to place bids on opportunities.</p>
                        </div>
                        <img src={polygonalPics} className="polygonalPics" />
                    </div>
                    <div className="col-lg-7 bg-white p-5 pb-0 signupRight">                    
                        <form className="m-5 my-4">
                            <h5 className="mb-4 text-navy">Complete Your Registration</h5>                         
                            <div className='col-12 col-md-12'>
                                <div className="d-inline-block position-relative ">
                                    <img src={profileImage ? profileImage.url : getImageUrl(providers,{})} width="150px" height="150px" className="object-fit-cover rounded m-3 border" />
                                    <label htmlFor="profile-upload">
                                        <a className="position-absolute end-0 bottom-0">
                                            <svg width="36" height="36" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="11.5" cy="11.5" r="11" fill="white" stroke="#E3E3E3"/>
                                            <path d="M15.9851 8.90875L14.0912 7.01483C14.0707 6.99506 14.0383 6.99506 14.0179 7.01483L7.74132 13.2916C7.73366 13.2992 7.72864 13.309 7.72688 13.3197L7.72073 13.3128L7 16L9.68722 15.2793L9.68038 15.2724H9.68032C9.69099 15.2707 9.70085 15.2656 9.70844 15.258L15.9852 8.98207C16.0049 8.9616 16.0049 8.92921 15.9852 8.90874L15.9851 8.90875Z" fill="#797979"/>
                                            <path d="M7.5 13L10 15.5" stroke="white"/>
                                            </svg>
                                        </a>
                                    </label>
                                </div>
                                <Form.Control type="file" id="profile-upload" className="fileUploadBtn position-absolute end-0 bottom-0" accept=".png, .jpg, .jpeg, .webp" onChange={updateImage}/>
                                <Row className="d-flex align-items-start justify-content-center px-md-5">
                                    <Col sm={12} md={6}>
                                        <FloatingLabel label="First Name" className="mb-3" >
                                            <Form.Control type="text" value={signupData.firstName} id="firstName" className="rounded-0 border-0 border-bottom" onChange={(e) => updateSignupDataState(e)} />
                                        </FloatingLabel>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <FloatingLabel label="Last Name" className="mb-3" >
                                            <Form.Control type="text" value={signupData.lastName} id="lastName" className="rounded-0 border-0 border-bottom" onChange={(e) => updateSignupDataState(e)} />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Row className="d-flex align-items-start justify-content-center mb-5 px-md-5">
                                    <Col sm={12} md={6}>
                                        <FloatingLabel label="Your New Password" className="mb-4" >
                                        <Form.Control type="password" className="rounded-0 border-0 border-bottom" id="password" value={signupData.password} onChange={(e) => updateSignupDataState(e)} />
                                        </FloatingLabel>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <FloatingLabel label="Retype Your New Password" className="mb-4" >
                                        <Form.Control type="password" className="rounded-0 border-0 border-bottom" id="passwordConfirm" value={signupData.passwordConfirm} onChange={(e) => updateSignupDataState(e)} />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                {signupData.password && (
                                    <>
                                    {passwordMeetsStandard(signupData.password).result == false ? (
                                        <div className="mb-4 mx-4 text-center">
                                        <strong>Password is invalid for the following reasons:</strong><br />
                                        <ul>
                                        {passwordMeetsStandard(signupData.password).reasons.map(reason => (
                                            <li key={reason}>
                                                {reason}
                                            </li>
                                        ))}
                                        </ul>
                                        </div>
                                    ) : (
                                        <>
                                        {signupData.passwordConfirm != signupData.password && (
                                        <div className="mb-4 mx-4 text-center">
                                            Passwords do not match
                                        </div>
                                        )}
                                        </>
                                    )}
                                    </>
                                    )}
                                <Row className="d-flex align-items-start justify-content-center mb-5 px-md-5">
                                    <div className="bottom-0 w-100 bg-white p-3 d-flex justify-content-between align-items-center">
                                        <button type="button" onClick={() => submit()} disabled={passwordMeetsStandard(signupData.password).result == false || signupData.passwordConfirm == "" || signupData.password == "" || signupData.passwordConfirm != signupData.password || signupData.firstName == "" || signupData.lastName == ""} className="btn btn-primary">Complete Your Profile</button>
                                    </div>    
                                </Row>
                            </div>
                        </form>
                    </div>
                </Row>
            </Container>
    
            <SignupFooter />
        </div>
        );
    }
}