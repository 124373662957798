import React, { useState } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { apiCall } from '../../helpers/api';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import SignupFooter from "../../components/footer/SignupFooter";

import './login.css';
import theRoom from './../../images/theRoom.svg';
import theRoomIcon from './../../images/theRoom-icon.svg';
import logoWhite from './../../images/room-white-logo.svg';

const useLogin = () => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const { dispatch } = useAuthContext()
  const navigate = useNavigate()

  const login = async (username, password) => {
      setIsLoading(true)
      setError(null)

      var response = await apiCall("login",{ "method" : "POST", "data" : {"username":username,"password":password} })

      if (response["success"]) {
          dispatch({type: "login", payload: response["success"]["data"]})

          setIsLoading(false)
          if ("redirect" in response["success"]){
              sessionStorage.removeItem("browsewallState")
              return navigate(response["success"]["redirect"])
          }
      }
      else {
          setIsLoading(false)
          setError(response["failure"]["error"])
      }
  }

  return { login, isLoading, error }
}

export default function Login({ }) {
  const [ password, setPassword ] = useState("");
  const { login, error, isLoading } = useLogin();
  const [submittingReset, setSubmittingReset] = useState(false)
  const [resetError, setResetError] = useState(null)
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [showResetSuccess, setShowResetSuccess] = useState(false)
  const navigate = useNavigate()
  let [searchParams] = useSearchParams();
  var defaultUsername = ""
  if (searchParams.get("username")){
    defaultUsername = searchParams.get("username");
  }
  const [ username, setUserName ] = useState(defaultUsername);

  const { user } = useAuthContext();

  const handlePasswordReset = async () => {
    setSubmittingReset(true)
    var response = await apiCall("forgotPassword",{ "method" : "POST", "data" : {"username":username} })
    if (response["success"]) {
      setShowPasswordReset(false);
      setShowResetSuccess(true);
      setResetError(null)
    }
    else if (response["failure"]) {
      setResetError(response["failure"]["error"])
    }
    setSubmittingReset(false)
  }

  const handleSubmit = async e => {
    e.preventDefault();
    await login(username, password);
  }

  if (user){
    return navigate("/")
  }

  return(
    <div className="login-wrapper">
      {user && (
        <div><p>You are already logged in!</p></div>
      )}
      <Container fluid className="loginBody bg-secondary">
        <Row className='h-100'>
          <div className='col-md-6 col-lg-5 h-100 desktopView'>
            <div className='d-lg-flex align-items-center justify-content-end h-100'>
            <a href="https://theinfluenceroom.com/" target="_blank"><img src={theRoom} height="80px" className='me-0 me-xl-5 pe-5' /></a>
            </div>
          </div>
          <div className='col-12 mobileView text-center py-4 h-25'>
            <div className='d-flex h-100 align-items-center justify-content-center'>
            <a href="https://theinfluenceroom.com/" target="_blank"><img src={theRoom} height="50px" /></a>
            </div>
          </div>
          <div className='col-md-12 col-lg-7 loginBox bg-white h-100 d-lg-flex align-items-center justify-content-start'>            
            {!showPasswordReset && !showResetSuccess && (
              <div className='d-block ms-lg-5 ps-lg-5 mx-lg-0 mx-4'>
                <h3 className='mb-4 pb-2 text-navy'>Login</h3>
                <form onSubmit={handleSubmit}>
                  <label className='mb-3'>
                    <div className='text-start'>Email</div>
                    <input type="text" tabIndex="1" className='form-control' value={username} onChange={e => setUserName(e.target.value.toLowerCase())} />
                  </label>
                  <label className='mb-3'>      
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className='text-start'>Password</div>
                      <Link tabIndex="4" onClick={() => setShowPasswordReset(true)} title="Reset Password">Forgotten Password?</Link>
                    </div>
                    <input type="password" tabIndex="2" className='form-control' autoComplete='password' onChange={e => setPassword(e.target.value)} />            
                  </label>

                  <div className='d-flex align-items-center'>
                    <button className='btn btn-primary w-auto' tabIndex="3" disabled={isLoading || username == "" || password == ""} type="submit">
                      {isLoading ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Logging In...</> : "Login"}
                    </button>
                    <div className='position-relative ms-3'>
                      {error && <div className="error text-nowrap">{error}</div>}
                    </div>
                  </div>
                  
                  <div className='mt-4'>
                    <p>Don't have an account? <Link tabIndex="5" to={"/Signup"} title="Signup">Sign up here!</Link></p>
                  </div>
                </form> 
              </div>
            )}
            {showPasswordReset && (
            <div className='d-block ms-lg-5 ps-lg-5 mx-lg-0 mx-4'>
              <h3 className='mb-4 pb-2 text-navy'>Reset Your Password</h3>
                <label className='mb-3'>
                  <div className='text-start'>Email</div>
                  <input type="text" className='form-control' value={username} onChange={e => setUserName(e.target.value)} />
                  {resetError && <div className="error w-100 mt-1">{resetError}</div>}
                </label>
                <div className='d-flex align-items-center justify-content-between'>
                  <button className='btn btn-primary w-auto' disabled={isLoading} type="submit" onClick={() => handlePasswordReset()}>
                    {submittingReset ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Submitting...</> : "Reset"}
                  </button>
                  <Link onClick={() => setShowPasswordReset(false)} title="Login" className="btn btn-outline-primary">Back to Login</Link>
                </div>
                <div className='mt-4'>
                  <p>Don't have an account? <Link to={"/Signup"} title="Signup">Sign up here!</Link></p>
                </div>
            </div>
            )}
            {showResetSuccess && (
            <div className='d-block ms-lg-5 ps-lg-5 mx-lg-0 mx-4'>
              <h3 className='mb-4 pb-2 text-navy'>Request Submitted</h3>
                <p>
                    If you submitted an email that matches one we have on our records, you should receive an email shortly with your reset link. <strong>This link will expire in one day.</strong>
                </p>
                <div className='d-flex align-items-center'>
                  <Link onClick={() => setShowResetSuccess(false)} title="Login" className="btn btn-outline-primary">Back to Login</Link>
                </div>
                <div className='mt-4'>
                  <p>Don't have an account? <Link to={"/Signup"} title="Signup">Sign up here!</Link></p>
                </div>
            </div>
            )}
          </div>
        </Row>    
      </Container>
      <SignupFooter />
    </div>
  )
}