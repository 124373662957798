import React from 'react';
import instagram from '../images/instagram.svg';
import facebook from '../images/facebook.svg';
import youtube from '../images/youtube.svg';
import tiktok from '../images/tiktok.svg';
import twitter from '../images/twitter.svg';
import avatar from '../images/avatar.svg';

export function getAverageEngagement(socials) {
    socials = socials.filter(function(social) { return social.name != "meta" });
    var averageEngagement = 0
    var engagementDataFound = 0
    socials.forEach(social => {
        if (social.hasOwnProperty("data")){
          averageEngagement += social.data.engagementRate
          engagementDataFound += 1
        }
    });
    if (engagementDataFound == 0){
      return 0
    }
    return averageEngagement / engagementDataFound;
}

export function getTotalFollowers(socials) {
    socials = socials.filter(function(social) { return social.name != "meta" });
    var totalFollowers = 0
    socials.forEach(social => {
      if (social.hasOwnProperty("data")){
        totalFollowers += social.data.followers
      }
    });
    return totalFollowers;
}

export function getMaxFollowers(socials) {
  socials = socials.filter(function(social) { return social.name != "meta" });
  var maxFollowers = 0
  socials.forEach(social => {
    if (social.hasOwnProperty("data")){
      if (maxFollowers < social.data.followers){
        maxFollowers = social.data.followers
      }
    }
  });
  return maxFollowers;
}

export function getSocialBadge(name,className=null,width="20px",style="colour",handle=null) {
    if (!className){
      className = "me-1"
    }
    switch(name.toLowerCase()) {
      case 'instagram':
        if (handle) {
          return <a target="_blank" rel="noreferrer noopener" href={`https://www.instagram.com/${handle}`}><img src={instagram} className={className} width={width} /></a>
        }
        return <img src={instagram} className={className} width={width} />
      case 'facebook':
        if (handle){
          return <a target="_blank" rel="noreferrer noopener" href={`https://facebook.com/${handle}`}><img src={facebook} className={className} width={width} /></a>
        }        
        return <img src={facebook} className={className} width={width} />
      case 'youtube':
        if (handle){
          return <a target="_blank" rel="noreferrer noopener" href={`https://www.youtube.com/${handle}`}><img src={youtube} className={className} width={width} /></a>
        }
        return <img src={youtube} className={className} width={width} />
      case 'tiktok':
        if (handle){
          return <a target="_blank" rel="noreferrer noopener" href={`https://www.tiktok.com/@${handle}`}><img src={tiktok} className={className} width={width} /></a>
        }
        return <img src={tiktok} className={className} width={width} />
      case 'twitter':
        if (handle){
          return <a target="_blank" rel="noreferrer noopener" href={`https://twitter.com/${handle}`}><img src={twitter} className={className} width={width} /></a>
        }
        return <img src={twitter} className={className} width={width} />
      default:
        return <img src={avatar} className={className} width={width} />
    }
  }

export function getContributionList(contributions){
  var contributionList = []
  var contributionDetails = {
    stories : {
      instagram : 0,
      youtube : 0,
      twitter : 0,
      tiktok : 0,
      facebook : 0
    },
    posts : {
      instagram : 0,
      youtube : 0,
      twitter : 0,
      tiktok : 0,
      facebook : 0,
      self : 0
    }
  };
  contributions.forEach(contribution => {
    if (contribution.assetType == "story"){
      contributionDetails.stories[contribution.platform] += 1
      contributionDetails.stories.total += 1
    }
    else {
      contributionDetails.posts[contribution.platform] += 1
      contributionDetails.posts.total += 1
    }
  })

  Object.entries(contributionDetails).forEach(contributionType => {
    Object.entries(contributionType[1]).forEach(platform => {
      var tempType = contributionType[0];
      if (platform[1] > 0){
        if (contributionType[0] == "stories" && platform[1] == 1){
          tempType = "story"
        } 
        else if (contributionType[0] == "posts" && platform[1] == 1){
          tempType = "post"
        }
        contributionList.push({"type":tempType,"platform":platform[0],"count":platform[1]})
      }
    })
  })
  return contributionList
}