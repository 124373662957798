import React, { useEffect, useState } from "react";
import { Container, Row, Form, FloatingLabel, Col, Card, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { apiCall } from "../../../helpers/api";
import { validateImage, getImageUrl } from "../../../helpers/images";
import SignupFooter from "../../../components/footer/SignupFooter";
import AlertModal from "../../../components/alerts/alert";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useImageContext } from "../../../hooks/useImageContext";
import SupportModal from "../../../components/support/support";

import './Welcome.css';
import theRoom from './../../../images/theRoom.svg';
import welcomeCarousel from './../../../images/welcomeCarousel.svg';
import bookmarkBlue from './../../../images/bookmark-blue.svg';
import starBlue from './../../../images/star-blue.svg';


export default function CompleteBrandSignup() {
    const [signupData, setSignupData] = useState({});
    const [profileImage, setProfileImage] = useState(null)
    const [formStep, setFormStep] = useState(0)
    const [userData, setUserData] = useState(null)
    const [brandData, setBrandData] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [changeCounter, setChangeCounter] = useState(0);
    const { data } = useAuthContext();
    const { providers } = useImageContext();
    const [planDetails, setPlanDetails] = useState({"plan":"","package":"","managed":true})
    const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
    const [supportModal, setSupportModal] = useState({"visible":false,"type":"Discuss Plans","message":"","context":"brand"});
    
    function updateImage(props){
        const file = props.target.files[0];
        var [validationResult, validationReason] = validateImage(file)
        if (validationResult){
            setProfileImage({"url":URL.createObjectURL(file),"data":file})
        }
        else {
          setAlert({"display":"toast","visible":true,"title":"Invalid Image","content":validationReason,"className":"bg-danger text-white"})
        }
      }
    
    async function getUser() {
        var response = await apiCall('mySettings',{ "method" : "GET" });
        if (response["success"]){
            var user = response["success"]["user"]
            var brand = response["success"]["brands"][0]
            setUserData(user)
            setBrandData(brand)
            setSignupData({
                "firstName":user.firstName,
                "lastName":user.lastName,
                "email":user.email,
                "brandName":brand.name,
                "employeeCount":"1-10",
                "workedWithInfluencers":"yes",
                "numberOfInfluencers":"1-10",
                "password":"",
                "passwordConfirm":""
            })
            if (user.signupComplete){
                if (!brand.subscription.hasOwnProperty("plan")){
                    setFormStep(3)
                    setIsLoaded(true)
                }
                else {
                    window.location.href="/Campaigns";
                }
            }
            else {
                setIsLoaded(true)
            }
            
        } 
    }

    async function submitUserDetails() {
        var imageUploadSuccess = true;
        if (profileImage) {
            var formData = new FormData();
            formData.append("images[]",profileImage.data)
            var imageResponse = await apiCall(`brand/myImage?id=${brandData._id}`,{ "method" : "POST", "data" : formData, "form": true });
            if (imageResponse["failure"]) {
              setAlert({"display":"toast","visible":true,"title":"Could not update profile picture","content":response["failure"]["error"],"className":"bg-danger text-white"})
              imageUploadSuccess = false
            }
        }
        if (imageUploadSuccess) {
            var response = await apiCall(`brand/completeSignup?id=${brandData._id}`,{ "method" : "POST", "data" : {"user":{firstName:signupData.firstName,lastName:signupData.lastName,password:signupData.password},"brand":{details:{workedWithInfluencers:signupData.workedWithInfluencers,numberOfInfluencers:signupData.numberOfInfluencers,employeeCount:signupData.employeeCount},name:signupData.brandName}} });
            if (response["success"]){
                setAlert({"display":"toast","visible":true,"title":"Signup Complete","content":"Signup Complete! Please choose a plan to continue.","className":"bg-success text-white"})
                setFormStep(3)
            }
            else if (response["failure"]) {
                setAlert({"display":"toast","visible":true,"title":"An Error Occurred","content":response["failure"]["error"],"className":"bg-danger text-white"})
            }
        }
    }

    async function startPurchase() {
        var response = await apiCall(`brand/startPurchase?id=${brandData._id}`,{ "method" : "POST", "data" : planDetails });
        if (response["success"]) {
            window.location.href=response["success"]["url"];
        }
        if (response["failure"]) {
            setAlert({"display":"toast","visible":true,"title":"An Error Occurred","content":response["failure"]["error"],"className":"bg-danger text-white"})
        }
    }

    function updatePlanDataState(value, id){
        var tempPlan = planDetails;
        tempPlan[id] = value;
        if (id == "package" && value == "self-serve") {
            tempPlan.managed = false
        }
        else {
            tempPlan.managed = true
        }
        setPlanDetails(tempPlan);
        setChangeCounter(changeCounter + 1);
    }

    function updateSignupDataState(props, id=null) {
        var tempSignupData = Object.assign({},signupData);
        tempSignupData[props.target.id] = props.target.value;
        setSignupData(tempSignupData); 
        setChangeCounter(changeCounter + 1)
    }       
 
    if (userData == null) {
        getUser()
      }
    if (isLoaded) {
        return (
            <div>
                <AlertModal modal={showAlert} onClose={() => setAlert({"display":"toast","visible":false,"title":"","content":""})} />
                <SupportModal supportModal={supportModal} setSupportModal={setSupportModal} isInternal={true} isPopup={true} relatedIDs={[]}/>
                <Container fluid className={`welcomePage ${formStep != 0 ? "hide" : ""}`}>
                <Row>
                    <div className='col-12 text-center'>
                        <img src={theRoom} height="40px" className='my-5' />
                        <Container>
                            <Row className="justify-content-md-center">                            
                                <div className='col-6 bg-white shadow-sm rounded border'>
                                    <img src={welcomeCarousel} className='mb-4 mt-5' />
                                    <h4 className="text-navy mb-4 fw-bold">Hi {userData.firstName}! Welcome to The Influence Room</h4>
                                    <Row className="d-flex align-items-start justify-content-center mb-5">
                                        <div className="col-8 text-center small">
                                            <h6>We are so excited to have you on board! </h6>
                                            <p>There's just a few things we need to know before you can access the platform to help you get the most out of your account.</p>
                                        </div>
                                        <div className="bottom-0 w-100 bg-white p-3 justify-content-between align-items-center">
                                            <button type="button" onClick={()=>setFormStep(1)} className="btn btn-primary">Continue</button>
                                        </div>    
                                    </Row>
                                </div>
                            </Row>
                        </Container>
                    </div>
                </Row>
                </Container>

                <Container fluid className={`welcomePage ${formStep != 1 ? "hide" : ""}`}>
                    <Row>
                        <div className='col-12 text-center'>
                            <img src={theRoom} height="40px" className='my-5' />
                            <Container>
                                <Row className="justify-content-md-center">                            
                                    <div className='col-12 col-md-6 bg-white shadow-sm rounded border'>
                                        <div className="d-inline-block position-relative">
                                            <img src={profileImage ? profileImage.url : getImageUrl(providers,data.profileImage,["w_100","c_scale"])} width="100px" height="100px" className="object-fit-cover rounded m-3 border" />
                                            <label htmlFor="profile-upload">
                                                <a className="position-absolute end-0 bottom-0">
                                                    <svg width="36" height="36" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="11.5" cy="11.5" r="11" fill="white" stroke="#E3E3E3"/>
                                                    <path d="M15.9851 8.90875L14.0912 7.01483C14.0707 6.99506 14.0383 6.99506 14.0179 7.01483L7.74132 13.2916C7.73366 13.2992 7.72864 13.309 7.72688 13.3197L7.72073 13.3128L7 16L9.68722 15.2793L9.68038 15.2724H9.68032C9.69099 15.2707 9.70085 15.2656 9.70844 15.258L15.9852 8.98207C16.0049 8.9616 16.0049 8.92921 15.9852 8.90874L15.9851 8.90875Z" fill="#797979"/>
                                                    <path d="M7.5 13L10 15.5" stroke="white"/>
                                                    </svg>
                                                </a>
                                            </label>
                                        </div>
                                        <Form.Control type="file" id="profile-upload" className="fileUploadBtn position-absolute end-0 bottom-0" accept=".png, .jpg, .jpeg, .webp" onChange={updateImage}/>
                                        <h4 className="text-navy mb-4 fw-bold">Tell us about you</h4>
                                        <Row className="d-flex align-items-start justify-content-center px-md-5">
                                            <Col sm={12} md={6}>
                                                <FloatingLabel label="First Name" className="mb-3" >
                                                    <Form.Control type="text" value={signupData.firstName} id="firstName" className="rounded-0 border-0 border-bottom" onChange={(e) => updateSignupDataState(e)} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <FloatingLabel label="Last Name" className="mb-3" >
                                                    <Form.Control type="text" value={signupData.lastName} id="lastName" className="rounded-0 border-0 border-bottom" onChange={(e) => updateSignupDataState(e)} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex align-items-start px-md-5">
                                            <Col sm={12} md={12}>
                                                <FloatingLabel label="Email" className="mb-4">
                                                    <Form.Control type="email" id="email" value={signupData.email} className="rounded-0 border-0 border-bottom" readOnly />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex align-items-start justify-content-center mb-5 px-md-5">
                                            <Col sm={12} md={6}>
                                                <FloatingLabel label="Your New Password" className="mb-4" >
                                                <Form.Control type="password" className="rounded-0 border-0 border-bottom" id="password" value={signupData.password} onChange={(e) => updateSignupDataState(e)} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <FloatingLabel label="Retype Your New Password" className="mb-4" >
                                                <Form.Control type="password" className="rounded-0 border-0 border-bottom" id="passwordConfirm" value={signupData.passwordConfirm} onChange={(e) => updateSignupDataState(e)} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex align-items-start justify-content-center mb-5 px-md-5">
                                            <div className="bottom-0 w-100 bg-white p-3 d-flex justify-content-between align-items-center">
                                                <button type="button" onClick={()=>setFormStep(0)} className="btn btn-outline-primary">Back</button>
                                                <button type="button" onClick={()=>setFormStep(2)} disabled={signupData.passwordConfirm == "" || signupData.password == "" || signupData.passwordConfirm != signupData.password || signupData.firstName == "" || signupData.lastName == "" || signupData.email == ""} className="btn btn-primary">Continue</button>
                                            </div>    
                                        </Row>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                    </Row>
                </Container>

                <Container fluid className={`welcomePage ${formStep != 2 ? "hide" : ""}`}>
                    <Row>
                        <div className='col-12 text-center'>
                            <img src={theRoom} height="40px" className='my-5' />
                            <Container>
                                <Row className="justify-content-md-center">                            
                                    <div className='col-12 col-md-6 bg-white shadow-sm rounded border'>
                                        <img src={profileImage ? profileImage.url : getImageUrl(providers,data.profileImage,["w_100","c_scale"])} width="100px" height="100px" className="object-fit-cover rounded m-3" />
                                        <h4 className="text-navy mb-4 fw-bold">Tell us about your Brand</h4>
                                        <Row className="d-flex align-items-start justify-content-center mb-5 px-md-5">
                                            <Col sm={12} md={6} mb={4}>
                                                <FloatingLabel label="Brand Name" className="mb-3" >
                                                    <Form.Control type="text" value={signupData.brandName} id="brandName" className="rounded-0 border-0 border-bottom" onChange={(e) => updateSignupDataState(e)} />
                                                </FloatingLabel>
                                            </Col>
                                            <div className="col-12 col-md-6 mb-4">
                                                <FloatingLabel label="Employee Count">
                                                    <Form.Select aria-label="Employee Count" id="employeeCount" onChange={(e) => updateSignupDataState(e)}>
                                                        <option selected={signupData.employeeCount == "1-10"} value="1-10">1 - 10</option>
                                                        <option selected={signupData.employeeCount == "11-100"} value="11-100">11 - 100</option>
                                                        <option selected={signupData.employeeCount == "101-1000"} value="101-1000">101 - 1000</option>
                                                        <option selected={signupData.employeeCount == "1001+"} value="1001+">1001+</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </div>
                                            <div className="col-12 col-md-6 mb-4">
                                                <FloatingLabel label="Worked with influencers before">
                                                    <Form.Select aria-label="Have you worked with influencers before" id="workedWithInfluencers" onChange={(e) => updateSignupDataState(e)}>
                                                        <option selected={signupData.workedWithInfluencers == "yes"} value="yes">Yes</option>
                                                        <option selected={signupData.workedWithInfluencers == "no"} value="no">No</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </div>
                                            {signupData.workedWithInfluencers == "yes" && (
                                            <div className="col-12 col-md-6 mb-4">
                                                <FloatingLabel label="How many influencers?">
                                                    <Form.Select aria-label="How many influencers?" id="numberOfInfluencers" onChange={(e) => updateSignupDataState(e)}>
                                                        <option selected={signupData.numberOfInfluencers == "1-10"} value="1-10">1 - 10</option>
                                                        <option selected={signupData.numberOfInfluencers == "11-50"} value="11-50">11 - 50</option>
                                                        <option selected={signupData.numberOfInfluencers == "51+"} value="51+">51+</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </div>
                                            )}
                                            <div className="bottom-0 w-100 bg-white p-3 d-flex justify-content-between align-items-center">
                                                <button type="button" onClick={()=>setFormStep(1)} className="btn btn-outline-primary">Back</button>
                                                <button type="button" onClick={()=>submitUserDetails()} className="btn btn-primary">Complete Profile</button>
                                            </div>    
                                        </Row>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                    </Row>
                </Container>

                <Container fluid className={`mainPlansSelection ${formStep != 3 ? "hide" : ""}`}>
                    <Row className='h-100'>
                        <div className='col-lg-5 h-100 d-flex align-items-center justify-content-end'>
                            <img src={theRoom} height="80px" className='me-5 pe-5' />
                        </div>
                        <div className='col-lg-7 bg-white h-100 d-flex flex-column align-items-center justify-content-center'>              
                            <h4 className="text-navy mb-4 pb-2 fw-bold">Choose a plan that works for you</h4>
                            <Row className="d-flex align-items-start justify-content-center mb-5">
                                <div className="col-4 text-center">
                                    <Card>
                                        <Card.Header className="bg-gray"><img src={bookmarkBlue} height="40px" className="py-2" /></Card.Header>
                                        <Card.Body className="d-flex flex-column justify-content-between">
                                            <div>
                                                <Card.Title className="text-navy">Subscription</Card.Title>
                                                <Card.Text className="smaller">
                                                    <p className="mb-2">By choosing any of our three Subscription options you immediately become a VIP member of The Room.</p>
                                                    <p>You'll be in the safe hands of our Customer Success team, who will help you to manage your campaigns.</p>
                                                </Card.Text>
                                            </div>
                                            <a onClick={()=>{setFormStep(4); updatePlanDataState("subscription","plan")}} className="btn btn-outline-primary w-100">Choose Subscription</a>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-4 text-center">
                                    <Card>
                                        <Card.Header className="bg-gray"><img src={starBlue} height="40px" className="py-2" /></Card.Header>
                                        <Card.Body className="d-flex flex-column justify-content-between">
                                            <div>
                                                <Card.Title className="text-navy">Lifetime Membership</Card.Title>
                                                <Card.Text className="smaller">
                                                    <p className="mb-2">Lifetime membership gives you access to The Room <strong>forever</strong>.</p>
                                                    <p className="mb-2">You have the option to buy campaigns as you go, at a time that suits your marketing calendar.</p>
                                                    <p>As a welcome gift, we'll give you a <strong>FREE</strong> campaign*</p>
                                                </Card.Text>
                                            </div>
                                            <a onClick={()=>{setFormStep(5); updatePlanDataState("lifetime","plan")}} className="btn btn-outline-primary w-100">Choose Lifetime Membership</a>
                                        </Card.Body>
                                    </Card>
                                    <span className="text-muted smaller"><i>*free campaign to be used within 3 months of registration</i></span>
                                </div>
                            </Row>
                            <a className="text-blue d-block fw-500 mb-5" onClick={() => setSupportModal({"visible":true,"type":"Discuss Plans","message":"","context":"brand"})}>Need help? Not sure what plan to choose?</a>
                        </div>
                    </Row>
                </Container>

                <Container fluid className={`packagesSelection ${formStep != 4 ? "hide" : ""}`}>
                    <Row className='h-100'>
                        <div className='col-lg-3 h-100 d-flex align-items-center justify-content-end position-sticky top-0'>
                            <img src={theRoom} height="60px" className='me-5 pe-5 position-fixed' />
                        </div>
                        <div className='col-lg-9 bg-white d-flex flex-column align-items-center justify-content-center px-5'>              
                            <h4 className="text-navy mb-5 mt-5 fw-bold">You have selected Subscription, please choose a package below</h4>
                            <Row className="d-flex align-items-center justify-content-center mb-5">
                                <div className="col-4 h-100">
                                    <Card className="h-100">
                                        <Card.Header className="bg-gray py-3 d-flex align-items-center justify-content-between">
                                            <div>
                                                <span className="text-navy">Subscription</span>
                                                <h6 className="text-navy mb-0">LITE</h6>   
                                            </div>
                                        </Card.Header>
                                        <Card.Body className="d-flex flex-column justify-content-between small">
                                            <Card.Text>
                                                <div className="mb-1"><span className="text-blue">✓</span> 1 Brand</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> 5 Fully Managed Campaigns </div>
                                                <hr className="border-secondary" />
                                                <div className="fw-500 mb-2">Service: Managed</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Account Manager</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Campaign Support</div>
                                                <ul className="mb-1">
                                                    <li>Campaign creation</li>
                                                    <li>Influencer recommendations</li>
                                                    <li>Influencer campaign communication</li>
                                                    <li>Campaign results</li>
                                                </ul>
                                                <div className="mb-1"><span className="text-blue">✓</span> Welcome Meeting</div>
                                            </Card.Text>
                                            <Card.Text>
                                                <div className="fw-500 mb-2">Media & Communication:</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Notifications</div>
                                                <div className="my-3"><span className="fw-500">Data:</span> Basic</div>
                                                <h4 className="text-blue mb-0">£10,000</h4>
                                                <div className="text-muted small mb-4">UPFRONT (Immediate Payment)</div>
                                                <a onClick={()=>{setFormStep(6); updatePlanDataState("lite","package")}} className="btn btn-outline-primary w-100">Choose this plan</a>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-4 h-100">
                                    <Card className="h-100 position-relative">
                                        <div className="recommended">Recommended</div>
                                        <Card.Header className="bg-gray py-3 d-flex align-items-center justify-content-between">
                                            <div>
                                                <span className="text-navy">Subscription</span>
                                                <h6 className="text-navy mb-0">PRO</h6>   
                                            </div>
                                        </Card.Header>
                                        <Card.Body className="d-flex flex-column justify-content-between small">
                                            <Card.Text>
                                                <div className="mb-1"><span className="text-blue">✓</span> 1 Brand</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> 10 Fully Managed Campaigns</div>
                                                <div className="mb-1 text-muted"><span className="text-white">✓</span> 12 Self-Drive Campaigns  </div>
                                                <hr className="border-secondary" />
                                                <div className="fw-500 mb-2">Service: Managed</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Account Manager</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Campaign Support</div>
                                                <ul className="mb-1">
                                                    <li>Campaign creation</li>
                                                    <li>Influencer recommendations</li>
                                                    <li>Influencer campaign communication</li>
                                                    <li>Campaign results</li>
                                                </ul>
                                                <div className="mb-1"><span className="text-blue">✓</span> Welcome Meeting</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Quarterly Business Review</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Marketing Calendar </div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Strategy & Campaign Recommendations</div>
                                            </Card.Text>
                                            <Card.Text>
                                                <div className="fw-500 mb-2">Media & Communication:</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Notifications</div>
                                                <div className="my-3"><span className="fw-500">Data:</span> Full</div>
                                                <h4 className="text-blue mb-0">£18,000</h4>
                                                <div className="text-muted small mb-4">UPFRONT (Immediate Payment)</div>
                                                <a onClick={()=>{setFormStep(6); updatePlanDataState("pro","package")}} className="btn btn-outline-primary w-100">Choose this plan</a>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-4 h-100">
                                    <Card className="h-100">
                                        <Card.Header className="bg-gray py-3 d-flex align-items-center justify-content-between">
                                            <div>
                                                <span className="text-navy">Subscription</span>
                                                <h6 className="text-navy mb-0">ENTERPRISE</h6>   
                                            </div>
                                        </Card.Header>
                                        <Card.Body className="d-flex flex-column justify-content-between small">
                                            <Card.Text>
                                                <div className="mb-1"><span className="text-blue">✓</span> 1 Brand</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> 10 Fully Managed Campaigns</div>
                                                <div className="mb-1 text-muted"><span className="text-white">✓</span> UNLIMITED SELF-DRIVE</div>
                                                <hr className="border-secondary" />
                                                <div className="fw-500 mb-2">Service: Managed</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Account Manager</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Campaign Support</div>
                                                <ul className="mb-1">
                                                    <li>Campaign creation</li>
                                                    <li>Influencer recommendations</li>
                                                    <li>Influencer campaign communication</li>
                                                    <li>Campaign results</li>
                                                </ul>
                                                <div className="mb-1"><span className="text-blue">✓</span> Welcome Meeting</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Quarterly Business Review</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Marketing Calendar </div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Strategy & Campaign Recommendations</div>
                                            </Card.Text>
                                            <Card.Text>
                                                <div className="fw-500 mb-2">Media & Communication:</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Notifications</div>
                                                <div className="my-3"><span className="fw-500">Data:</span> Enhanced</div>
                                                <h4 className="text-blue mb-0">£35,000</h4>
                                                <div className="text-muted small mb-4">UPFRONT (Immediate Payment)</div>
                                                <a onClick={()=>{setFormStep(6); updatePlanDataState("enterprise","package")}} className="btn btn-outline-primary w-100">Choose this plan</a>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Row>
                            <a onClick={()=>{setFormStep(5); updatePlanDataState("lifetime","plan")}}  className="btn border text-navy py-3 px-5 fw-500 mb-5"><u>View Lifetime Membership options</u></a>
                            <a className="text-blue d-block fw-500 mb-5" onClick={() => setSupportModal({"visible":true,"type":"Discuss Plans","message":"","context":"brand"})}>Need help? Not sure what plan to choose?</a>
                            <a onClick={()=>setFormStep(3)} className="btn btn-outline-primary fw-500 mb-5">Back</a>
                        </div>
                    </Row>
                </Container>

                <Container fluid className={`packagesSelection ${formStep != 5 ? "hide" : ""}`}>
                    <Row className='h-100'>
                        <div className='col-lg-3 h-100 d-flex align-items-center justify-content-end position-sticky top-0'>
                            <img src={theRoom} height="60px" className='me-5 pe-5 position-fixed' />
                        </div>
                        <div className='col-lg-9 bg-white d-flex flex-column align-items-center justify-content-center px-5'>              
                            <h4 className="text-navy mb-5 mt-5 fw-bold">You have selected Lifetime Membership, please choose a package below</h4>
                            <Row className="d-flex align-items-center justify-content-center w-100 mb-5">
                                <div className="col-4 h-100">
                                    <Card className="h-100">
                                        <Card.Header className="bg-gray py-3 d-flex align-items-center justify-content-between">
                                            <div>
                                                <span className="text-navy">Lifetime Membership</span>
                                                <h6 className="text-navy mb-0">Self-serve</h6>   
                                            </div>
                                        </Card.Header>
                                        <Card.Body className="d-flex flex-column justify-content-between small">
                                            <Card.Text>
                                                <div className="mb-1"><span className="text-blue">✓</span> 1 Brand</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> 1 Campaign </div>
                                                <div className="mb-1 text-muted"><span className="text-white">✓</span> Campaign must be used within 12 months</div>
                                                <hr className="border-secondary" />
                                                <div className="fw-500 mb-2">Service: Self-Serve</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Email Support</div>
                                            </Card.Text>
                                            <Card.Text>
                                                <div className="fw-500 mb-2">Onboarding:</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Video Enrollment</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Video Campaign Set-Up</div>

                                                <div className="fw-500 mb-2">Media & Communication:</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Notifications</div>

                                                <div className="my-3"><span className="fw-500">Data:</span> Basic</div>
                                                <h4 className="text-blue mb-0">£1,000</h4>
                                                <div className="text-muted small mb-4">UPFRONT (Immediate Payment)</div>
                                                <a onClick={()=>{setFormStep(6); updatePlanDataState("self-serve","package")}} className="btn btn-outline-primary w-100">Choose this plan</a>
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer className="bg-gray small">
                                            <div className="fw-500">WITH THE OPTION TO BUY ADDITIONAL CAMPAIGNS LATER</div>
                                            <span>3 campaigns for £2,500</span>
                                        </Card.Footer>
                                    </Card>
                                </div>
                                <div className="col-4 h-100">
                                    <Card className="h-100 position-relative">
                                        <div className="recommended">Recommended</div>
                                        <Card.Header className="bg-gray py-3 d-flex align-items-center justify-content-between">
                                            <div>
                                                <span className="text-navy">Lifetime Membership</span>
                                                <h6 className="text-navy mb-0">Managed</h6>   
                                            </div>
                                        </Card.Header>
                                        <Card.Body className="d-flex flex-column justify-content-between small">
                                            <Card.Text>
                                                <div className="mb-1"><span className="text-blue">✓</span> 1 Brand</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> 1 Campaign</div>
                                                <div className="mb-1 text-muted"><span className="text-white">✓</span> Campaign must be used within 12 months</div>

                                                <hr className="border-secondary" />
                                                <div className="fw-500 mb-2">Service: Managed</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Account Manager</div>
                                                <ul className="mb-1">
                                                    <li>Campaign creation</li>
                                                    <li>Influencer recommendations</li>
                                                    <li>Influencer campaign communication</li>
                                                    <li>Campaign results</li>
                                                </ul>
                                                <div className="mb-1"><span className="text-blue">✓</span> Welcome Meeting</div>
                                            </Card.Text>
                                            <Card.Text>
                                                <div className="fw-500 mb-2">Onboarding:</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Video Enrollment</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Video Campaign Set-Up</div>

                                                <div className="fw-500 mb-2">Media & Communication:</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Notifications</div>
                                                <div className="mb-1"><span className="text-blue">✓</span> Campaign Category Targeting</div>

                                                <div className="my-3"><span className="fw-500">Data:</span> Full</div>
                                                <h4 className="text-blue mb-0">£4,000</h4>
                                                <div className="text-muted small mb-4">ANNUAL (Pay Monthly / 12 months access)</div>
                                                <a onClick={()=>{setFormStep(6); updatePlanDataState("managed","package")}} className="btn btn-outline-primary w-100">Choose this plan</a>
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer className="bg-gray small">
                                            <div className="fw-500">WITH THE OPTION TO BUY ADDITIONAL CAMPAIGNS LATER</div>
                                            <span>3 campaigns for £2,500</span>
                                        </Card.Footer>
                                    </Card>
                                </div>
                            </Row>
                            <a onClick={()=>{setFormStep(4); updatePlanDataState("subscription","plan")}}  className="btn border text-navy py-3 px-5 fw-500 mb-5"><u>View Subscription options</u></a>
                            <a className="text-blue d-block fw-500 mb-5" onClick={() => setSupportModal({"visible":true,"type":"Discuss Plans","message":"","context":"brand"})}>Need help? Not sure what plan to choose?</a>
                            <a onClick={()=>setFormStep(3)} className="btn btn-outline-primary fw-500 mb-5">Back</a>
                        </div>
                    </Row>
                </Container>

                <Container fluid className={`packagesSelection ${formStep != 6 ? "hide" : ""}`}>
                    <Row className='h-100'>
                        <div className='col-lg-3 h-100 d-flex align-items-center justify-content-end position-sticky top-0'>
                            <img src={theRoom} height="60px" className='me-5 pe-5 position-fixed' />
                        </div>
                        <div className='col-lg-9 bg-white d-flex flex-column align-items-center justify-content-center px-5'>              
                            <h4 className="text-navy mb-5 mt-5 fw-bold">Confirm your choice</h4>
                            <Row className="w-100 mb-5 d-flex justify-content-center">
                                <div className="col-8">
                                    <Card className="h-100 border-primary">
                                        <Card.Header className="bg-gray py-3 d-flex align-items-center justify-content-between">
                                            <div>
                                                <span className="text-navy">{planDetails.plan == "lifetime" ? "Lifetime Membership" : "Subscription" } </span>
                                                <h6 className="text-navy mb-0">{planDetails.managed ? "Managed" : "Self-Serve" }</h6>   
                                            </div>
                                        </Card.Header>
                                        {planDetails.plan == "lifetime" && (
                                            <>
                                            {planDetails.package == "self-serve" && (
                                            <Card.Body className="d-flex flex-column justify-content-between small">
                                                <Card.Text>
                                                    <div className="mb-1"><span className="text-blue">✓</span> 1 Brand</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> 1 Campaign </div>
                                                    <div className="mb-1 text-muted"><span className="text-white">✓</span> Campaign must be used within 12 months</div>
                                                    <hr className="border-secondary" />
                                                    <div className="fw-500 mb-2">Service: Self-Serve</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Email Support</div>
                                                </Card.Text>
                                                <Card.Text>
                                                    <div className="fw-500 mb-2">Onboarding:</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Video Enrollment</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Video Campaign Set-Up</div>

                                                    <div className="fw-500 mb-2">Media & Communication:</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Notifications</div>

                                                    <div className="my-3"><span className="fw-500">Data:</span> Basic</div>
                                                    <h4 className="text-blue mb-0">£1,000</h4>
                                                    <div className="text-muted small mb-4">UPFRONT (Immediate Payment)</div>
                                                </Card.Text>
                                            </Card.Body>
                                            )}
                                            {planDetails.package == "managed" && (
                                            <Card.Body className="d-flex flex-column justify-content-between small">
                                                <Card.Text>
                                                    <div className="mb-1"><span className="text-blue">✓</span> 1 Brand</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> 1 Campaign</div>
                                                    <div className="mb-1 text-muted"><span className="text-white">✓</span> Campaign must be used within 12 months</div>

                                                    <hr className="border-secondary" />
                                                    <div className="fw-500 mb-2">Service: Managed</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Account Manager</div>
                                                    <ul className="mb-1">
                                                        <li>Campaign creation</li>
                                                        <li>Influencer recommendations</li>
                                                        <li>Influencer campaign communication</li>
                                                        <li>Campaign results</li>
                                                    </ul>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Welcome Meeting</div>
                                                </Card.Text>
                                                <Card.Text>
                                                    <div className="fw-500 mb-2">Onboarding:</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Video Enrollment</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Video Campaign Set-Up</div>

                                                    <div className="fw-500 mb-2">Media & Communication:</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Notifications</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Campaign Category Targeting</div>

                                                    <div className="my-3"><span className="fw-500">Data:</span> Full</div>
                                                    <h4 className="text-blue mb-0">£4,000</h4>
                                                    <div className="text-muted small mb-4">ANNUAL (Pay Monthly / 12 months access)</div>
                                                </Card.Text>
                                            </Card.Body>
                                            )}
                                            </>
                                        )}
                                        {planDetails.plan == "subscription" && (
                                            <>
                                            {planDetails.package == "lite" && (
                                            <Card.Body className="d-flex flex-column justify-content-between small">
                                                <Card.Text>
                                                    <div className="mb-1"><span className="text-blue">✓</span> 1 Brand</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> 5 Fully Managed Campaigns </div>
                                                    <hr className="border-secondary" />
                                                    <div className="fw-500 mb-2">Service: Managed</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Account Manager</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Campaign Support</div>
                                                    <ul className="mb-1">
                                                        <li>Campaign creation</li>
                                                        <li>Influencer recommendations</li>
                                                        <li>Influencer campaign communication</li>
                                                        <li>Campaign results</li>
                                                    </ul>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Welcome Meeting</div>
                                                </Card.Text>
                                                <Card.Text>
                                                    <div className="fw-500 mb-2">Media & Communication:</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Notifications</div>
                                                    <div className="my-3"><span className="fw-500">Data:</span> Basic</div>
                                                    <h4 className="text-blue mb-0">£10,000</h4>
                                                    <div className="text-muted small mb-4">UPFRONT (Immediate Payment)</div>
                                                </Card.Text>
                                            </Card.Body>
                                            )}
                                            {planDetails.package == "pro" && (
                                            <Card.Body className="d-flex flex-column justify-content-between small">
                                                <Card.Text>
                                                    <div className="mb-1"><span className="text-blue">✓</span> 1 Brand</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> 10 Fully Managed Campaigns</div>
                                                    <div className="mb-1 text-muted"><span className="text-white">✓</span> 12 Self-Drive Campaigns  </div>
                                                    <hr className="border-secondary" />
                                                    <div className="fw-500 mb-2">Service: Managed</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Account Manager</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Campaign Support</div>
                                                    <ul className="mb-1">
                                                        <li>Campaign creation</li>
                                                        <li>Influencer recommendations</li>
                                                        <li>Influencer campaign communication</li>
                                                        <li>Campaign results</li>
                                                    </ul>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Welcome Meeting</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Quarterly Business Review</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Marketing Calendar </div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Strategy & Campaign Recommendations</div>
                                                </Card.Text>
                                                <Card.Text>
                                                    <div className="fw-500 mb-2">Media & Communication:</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Notifications</div>
                                                    <div className="my-3"><span className="fw-500">Data:</span> Full</div>
                                                    <h4 className="text-blue mb-0">£18,000</h4>
                                                    <div className="text-muted small mb-4">UPFRONT (Immediate Payment)</div>
                                                </Card.Text>
                                            </Card.Body>
                                            )}
                                            {planDetails.package == "enterprise" && (
                                            <Card.Body className="d-flex flex-column justify-content-between small">
                                                <Card.Text>
                                                    <div className="mb-1"><span className="text-blue">✓</span> 1 Brand</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> 10 Fully Managed Campaigns</div>
                                                    <div className="mb-1 text-muted"><span className="text-white">✓</span> UNLIMITED SELF-DRIVE</div>
                                                    <hr className="border-secondary" />
                                                    <div className="fw-500 mb-2">Service: Managed</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Account Manager</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Campaign Support</div>
                                                    <ul className="mb-1">
                                                        <li>Campaign creation</li>
                                                        <li>Influencer recommendations</li>
                                                        <li>Influencer campaign communication</li>
                                                        <li>Campaign results</li>
                                                    </ul>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Welcome Meeting</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Quarterly Business Review</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Marketing Calendar </div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Strategy & Campaign Recommendations</div>
                                                </Card.Text>
                                                <Card.Text>
                                                    <div className="fw-500 mb-2">Media & Communication:</div>
                                                    <div className="mb-1"><span className="text-blue">✓</span> Notifications</div>
                                                    <div className="my-3"><span className="fw-500">Data:</span> Enhanced</div>
                                                    <h4 className="text-blue mb-0">£35,000</h4>
                                                    <div className="text-muted small mb-4">UPFRONT (Immediate Payment)</div>
                                                </Card.Text>
                                            </Card.Body>
                                            )}
                                            </>
                                        )}
                                    </Card>
                                </div>
                            </Row>
                            <Row className="w-100 mb-5 d-flex justify-content-center">
                            <div className="col-4">
                                <div className="w-100 d-flex justify-content-between mb-3">
                                    <a onClick={()=>setFormStep(planDetails.plan == "lifetime" ? 5 : 4)} className="btn btn-outline-primary fw-500">Back</a>
                                    <a onClick={()=>startPurchase()} className="btn btn-primary fw-500">Pay Now</a>
                                </div>
                            </div>
                            </Row>
                        </div>
                    </Row>
                </Container>

                <SignupFooter />
            </div>
        );
    }
}