import React, { useState, useEffect, Component } from "react";
import { Container, Row, Dropdown, Badge, Form, InputGroup, Table, Modal, Button } from 'react-bootstrap';
import AdminNav from '../../components/adminnav/AdminNav';
import { Link } from "react-router-dom";
import { useAuthContext } from '../../hooks/useAuthContext';
import { useImageContext } from "../../hooks/useImageContext";
import { getImageUrl } from "../../helpers/images";
import { apiCall, PageScroller } from "../../helpers/api";
import AlertModal from "../../components/alerts/alert";
import Topbar from "../../components/topbar/Topbar";
import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import './Dashboard.css';
import { getFilter, setFilter } from './../../helpers/filters'
import searchBlue from './../../images/search-blue.svg'
import { getSubscriptionBadge } from './../../helpers/status.js'
import settings from './../../images/settings.svg';
import archive from './../../images/dropdown-archive.svg';
import plus from './../../images/plus.svg'
import edit from './../../images/dropdown-edit.svg';

export default function Dashboard() {  
  const [isLoaded, setIsLoaded] = useState(true);
  const { data } = useAuthContext();
  const { providers } = useImageContext();
  const [brands, setBrands] = useState([]);
  const [brandFilters, setBrandFilters] = useState({"states":[],"subscriptions":[],"managers":[],"industries":[],"search":""});
  const [showAddCreditsModal,setAddCreditsModal] = useState({"visible":false,"brandID":null});
  const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
  const hideAlert = () => setAlert({"display":"toast","visible":false,"title":"","content":""});
  const [pageStats, setPageStats] = useState({})
  const [credits, setCredits] = useState(1);
  const [filterTimer, setFilterTimer] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (getFilter("brand")) {
      setBrandFilters(JSON.parse(getFilter("brand")))
    }
  }, []);

  const loginAsBrand = async (brandID) => {
    var response = await apiCall(`impersonate`,{ "method" : "POST", "data" : {"id":brandID,"type":"brands","returnPage":window.location.pathname+window.location.search} });
    if (response["success"]){
      window.location.href="/Campaigns";
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to login as brand","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  };

  const addCredits = async (brandID) => {
    setAddCreditsModal({"visible":true,"brandID":brandID})
  }

  const AddCreditsModal = () => {
    var error = null;
    const handleClose = e => {
      setCredits(1);
      setAddCreditsModal({"visible":false,"brandID":null})
    }
    const handleSubmit = async e => {
      e.preventDefault();
      setIsSubmitting(true)
      var response = await apiCall(`admin/addCredits`,{ "method" : "POST", "data" : {"id":showAddCreditsModal.brandID,"credits":credits} });
      if (response["success"]) {
        setAlert({"display":"toast","visible":true,"title":"Credits Added","content":"Added credits to brand","className":"bg-success text-white"})
        for (var x in brands) {
          if (brands[x]["_id"] === showAddCreditsModal.brandID) {
            brands[x]["credits"] += 1;
            setBrands([...brands])
            break
          }
        }
        handleClose()
      }
      else {
        error = response["failure"]["error"]
        setAlert({"display":"toast","visible":true,"title":"Failed to add credits","content":error,"className":"bg-danger text-white"})
      };
      setIsSubmitting(false)
    }
    if (showAddCreditsModal.visible){
      return (
        <Modal show={showAddCreditsModal.visible} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="h5">Add Campaign Credits to Brand</Modal.Title>
          </Modal.Header>
          <Form>
            <Modal.Body className="pb-0">
              <Form.Group>
                  <Form.Label className="mb-1">Number of Credits to Apply</Form.Label>
                  <Form.Control 
                    onChange={(e) => setCredits(e.target.value)}
                    type="number"
                    id="credits"
                    value={credits}
                  />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className="border-top-0">
              <Button variant="outline-secondary" onClick={handleClose}>Cancel</Button>
              <Button variant="primary" disabled={isSubmitting || credits < 1 || credits > 100 } type="submit" onClick={handleSubmit}>
                {isSubmitting ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Adding...</> : "Add Credits"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    else{
      return (<></>)
    }
  }

  const handleFilterUpdate = (value,added,type,delay=0) => {
    var filters = brandFilters;
    if (type == "search") {
      filters.search = value.target.value.toLowerCase();
    }
    setBrandFilters(Object.assign({}, brandFilters, filters));
    setFilter(filters,"brand")
  }

  const setState = async (event,result) => { 
    result = await result;
    if (event.pageData.page >= 1) {
        for (var x in result["brands"]) {
            brands.push(result["brands"][x])  
        }
        setBrands([...brands])
    } else {
      setBrands(result["brands"])
    }
    setPageStats(result["_paging"])
  }

  return (
    <div className="bg-page dashboard">
      <Topbar />
      <div className="pageName">Dashboard</div>
      <Container id="applicant-list-container" className="fullscreenWithTopBar top-0" fluid>
        <AlertModal modal={showAlert} onClose={hideAlert} />
        <AddCreditsModal showAddCreditsModal={showAddCreditsModal} setAddCreditsModal={setAddCreditsModal} credits={credits} setCredits={setCredits} setAlert={setAlert} />
        <Row>
          <AdminNav />
          <div className="col-xl-12 col-xxl-10 px-0">
            <div className="px-4 py-3 py-xl-4 bg-white shadow-sm">
              <div className="d-flex align-items-center justify-content-between mx-xl-4">
                <h1 className="pageTitle text-nowrap text-primary tragedyRegularFont fw-700 mb-0 fs-3">Hello {data.name}</h1>
                <div className="form-group">
                  <InputGroup>
                    <Form.Control placeholder="Search" className="border-end-0" aria-label="Search" value={brandFilters.search} onChange={e => handleFilterUpdate(e,true,"search",5000)} />
                    <InputGroup.Text id="basic-addon2" className="bg-white border-end">
                      <a><img src={searchBlue} /></a>
                    </InputGroup.Text>
                  </InputGroup>
                </div>
              </div>
            </div>
            { !isLoaded ? <FullPageSpinner /> : (
            <>          
            <div className="p-4 pt-0 dashboardTable mt-2 mt-xl-4">
              <div className="mx-xl-4">
                <div className="tableHeaderFixedBelt"></div>
                <Table className="border-transparent">
                  <thead>
                    <tr>
                      <th>My Brands ({brands.length})</th>
                      <th className="text-center">Subscription Type</th>
                      <th className="text-center">Contract Ends</th>
                      <th className="text-center">Live Campaigns</th>
                      <th className="text-center">Campaigns</th>
                      <th className="text-center">Unread Messages</th>
                      <th className="text-center">Pending Bids</th>
                      <th className="text-center">Credits</th>
                      <th className="text-center">Country</th>
                      <th className="text-nowrap">Settings</th>
                    </tr>
                  </thead>
                  <tbody>
                    {brands.sort((a, b) => a.name > b.name ? 1 : -1).map(brand => (
                      <tr className="small" key={brand._id}>
                      <td className="bg-white rounded-start fw-500 text-nowrap"><div className="d-flex align-items-center"><img src={getImageUrl(providers,brand.profileImage,["w_80","c_scale"])} height="36px" width="36px" className="me-2 object-fit-cover rounded border-gray p-1" /> <div className="text-truncate w-75">{brand.name}</div></div></td>
                      <td className="bg-white text-center">{brand.subscription.hasOwnProperty("plan") ? getSubscriptionBadge(brand.subscription.plan) : getSubscriptionBadge("N/A")}</td>
                      <td className="bg-white text-center"><span className="tags text-nowrap">N/A</span></td>
                      <td className="bg-white text-center"><span className="text-primary h3">{brand.stats.liveCampaigns}</span></td>
                      <td className="bg-white text-center"><span className="text-primary h3">{brand.stats.totalCampaigns}</span></td>
                      <td className="bg-white text-center"><span className="text-primary h3">{brand.stats.unreadMessages}</span></td>
                      <td className="bg-white text-center"><span className="text-primary h3">{brand.stats.pendingBids}</span></td>
                      <td className="bg-white text-center"><span className="text-primary h3">{typeof(brand.credits) == 'number' ? brand.credits : "?"}</span></td>
                      <td className="bg-white text-center"><img src={`https://hatscripts.github.io/circle-flags/flags/${brand.country.toLowerCase()}.svg`} className="border rounded-circle me-2" width="26px" /></td>
                      <td className="bg-white rounded-end">
                        <div className="d-flex align-items-center justify-content-between">
                          <button type="button" onClick={e => loginAsBrand(brand._id)} className="btn btn-sm btn-outline-primary">Login</button>
                          <Dropdown className="brandAction">
                            <Dropdown.Toggle variant="default" className="border-0">
                              <img src={settings} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Link to="#" className="dropdown-item" onClick={e => addCredits(brand._id)}>Add Credits</Link>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <PageScroller endpoint="brands" setState={setState} filter={brandFilters} pageSize={100} />
            </div>
            </>
            ) }
          </div>
        </Row>
      </Container>
    </div>
  );
}