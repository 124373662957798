import React, { Component } from "react";
import { Button, Form, Modal } from 'react-bootstrap';
import PropTypes from "prop-types";
import Toast from 'react-bootstrap/Toast';
import './alert.css'

export default class AlertModal extends Component {
    render() {
        if (this.props.modal.display == "centered"){
            return (
                <Modal centered show={this.props.modal.visible} onHide={this.props.onClose}>
                    <Modal.Header closeButton>
                    <Modal.Title className='h6'>{this.props.modal.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer className={`border-0 rounded ${this.props.modal.className}`}>
                        <Form className='d-flex justify-content-between align-items-center w-100 m-0'>
                            <p className='m-0 ps-2'>{this.props.modal.content}</p>
                        </Form>
                    </Modal.Footer>
                </Modal>
            )
        }
        else if (this.props.modal.display == "toast"){
            return (
                <Toast onClose={this.props.onClose} show={this.props.modal.visible} className={this.props.modal.className} delay={7500} autohide>
                    <Toast.Header>
                        <strong className="me-auto">{this.props.modal.title}</strong>
                    </Toast.Header>
                    <Toast.Body>{this.props.modal.content}</Toast.Body>
                </Toast>
            )
        }
        else {
            console.log("MISSING DISPLAY",this.props)
        }
    }
}

AlertModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    modal: PropTypes.object.isRequired
};