import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import backArrow from './../../images/back-arrow-blue.svg';

import sendDark from './../../images/send-dark.svg';
import paperclip from './../../images/paperclip.svg';

class InboxLoader extends Component {
    render() {
        const fakeMessages = [{"id":1,"contents":"This is a fake message to use a template for loading","sender":"me"},{"id":2,"contents":"This is a fake message to use a template for loading","sender":"them"},{"id":3,"contents":"This is a fake message to use a template for loading","sender":"me"},{"id":4,"contents":"This is a fake message to use a template for loading","sender":"them"}]
        return (
            <div className={`chatBox rounded-end h-100 ${!this.props.chatBox ? "hide": ""}`}>
            <div className="px-3 chatHeader">
                <div className="py-2 mb-2 d-flex align-items-center">
                <a className="mobileView pe-3" onClick={()=>this.props.setChatBox(false)}><img src={backArrow} /></a>
                <div className="me-3 skeleton-box rounded">
                    <img src="#" width="46px" height="46px" className="object-fit-cover rounded border bg-white" />
                </div>
                <div className='overflow-hidden'>
                    <h6 className="mb-0 skeleton-box">Loading Name</h6>
                    <Link className="text-blue small skeleton-box" to="#" >Loading Campaign Title <img className="mb-1" /></Link>
                </div>
                </div>
            </div>

            <div className="chatBody px-3">
                <div className="text-center text-muted py-3 small">Loading Conversation...</div>
                {fakeMessages.map(message => (
                <span key={message.id}>
                    <div className={message.sender != "me" ? "bubbleLeft skeleton-box" : "bubbleRight"}>
                        <div className={message.sender != "me" ? "bubbleLeftBoxes" : "bubbleRightBoxes"}>
                        <div className={message.sender != "me" ? "bubbleLeftText" : "bubbleRightText skeleton-box"}>
                            <div>{message.contents}</div>
                            <div className={message.sender != "me" ? "smaller text-muted d-flex align-items-center pt-1" : "smaller d-flex align-items-center pt-1" }>
                            {(new Date()).toLocaleString('en-US', { hour:"2-digit", minute:"2-digit" })}
                            </div>
                        </div>
                        </div>
                        {message.sender == "me" && (
                        <div className="skeleton-box rounded">
                            <img src="#" className="rounded" width="30px" height="30px" />
                        </div>
                        )}
                    </div>
                </span>
                ))}          
            </div>

            <div className="chatFooter">
                <form>
                    <InputGroup>
                        <Form.Control className="border-0 hidden-disabled" disabled />
                        <Button onClick={this.handleSubmit} disabled className="btn border-0 bg-white"><img src={sendDark} /></Button>
                        <Button variant="default" className="border-0"><img src={paperclip} /></Button>
                    </InputGroup>
                </form>
            </div>
            </div>
        )
    }
}

export default function (props) {
    return <InboxLoader />;
  }
  