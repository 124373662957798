import React, { useState, useEffect } from "react";
import { Container, Col, Row, Button, Form } from 'react-bootstrap';
import { useSearchParams, Link, useHref } from "react-router-dom";
import Masonry from 'react-masonry-css'
import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import { getDifference } from "../../helpers/dates";
import { apiCall, PageScroller } from './../../helpers/api'
import { getSocialBadge } from "../../helpers/socials";
import { useImageContext } from "../../hooks/useImageContext";
import { getImageUrl } from "../../helpers/images";
import { getFilter, setFilter } from './../../helpers/filters'
import AlertModal from "../../components/alerts/alert";
import './Campaigns.css';
import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";
import NoAccess from "../../images/no-access.png";
import PlayButton from "../../images/play-button.svg"

export default function CampaignCTADashboard() {

  let [searchParams] = useSearchParams();
  const [ error, setError ] = useState(null);
  const [ isLoaded, setIsLoaded ] = useState(true);
  const [ updatingData, setUpdatingData ] = useState(false)
  const [ requestingDownload, setRequestingDownload ] = useState(false)
  const [ campaignData, setCampaignData ] = useState([])
  const [ campaignDataIDs, setCampaignDataIDs ] = useState([])
  const [ campaign, setCampaign ] = useState(null)
  const [ contributors, setContributors ] = useState([])
  const [ filteredContributors, setFilteredContributors ] = useState([])
  const [ selectedInfluencer, setSelectedInfluencer ] = useState(null)
  const [ searchFilters, setSearchFilters ] = useState({"search":"","posts":true,"notRated":false,"showAll":false})
  const [ pageStats, setPageStats ] = useState({})
  const { providers } = useImageContext();
  const [ showFilters, setShowFilters ] = useState(false);
  const [ showAlert, setAlert ] = useState({"display":"toast","visible":false,"title":"","content":""});
  const breakpointColumnsObj = {
    default: 4,
    1100: 5,
    768: 3,
    576: 2
  }

  const hideShowFilters = event => {
    setShowFilters(current => !current);
  };

  var campaignID = searchParams.get('id')

  async function getCampaign(campaignID) {
    setIsLoaded(false);
    var response = await apiCall(`campaign/dashboard?id=${campaignID}`,{ "method" : "GET" });
    if (response["success"]){
        setCampaign(response["success"]["campaign"]);
        setContributors(response["success"]["contributors"]);
        setFilteredContributors(response["success"]["contributors"].slice(0,4))
        setIsLoaded(true);
    }	
  };

  async function updateContent(){
    setUpdatingData(true)
    var response = await apiCall(`campaign/dashboard/update?id=${campaignID}`,{ "method" : "GET" });
    if (response["success"]){
      setAlert({"display":"toast","visible":true,"title":"Pulled latest campaign content","content":"Successfully pulled the latest campaign content","className":"bg-success text-white"})
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to get latest content","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
    setUpdatingData(false)
    window.location.reload()
  }

  async function downloadContent(){
    setRequestingDownload(true)
    var response = await apiCall(`campaign/dashboard/download?id=${campaignID}`,{ "method" : "GET" });
    if (response["success"]){
       setAlert({"display":"toast","visible":true,"title":"Download Requested","content":"You will receive an email with your content shortly.","className":"bg-success text-white"})
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to request download","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
    setRequestingDownload(false)
  }

  async function getMoreContent(event,result) { 
    result = await result;
    var tempCampaignData = structuredClone(campaignData)
    var tempCampaignDataIDs = structuredClone(campaignDataIDs)
    for (var x in result["campaignContent"]) {
      if (!tempCampaignDataIDs.includes(result["campaignContent"][x]["id"])) {
        tempCampaignData.push(result["campaignContent"][x])
        tempCampaignDataIDs.push(result["campaignContent"][x]["id"])
      }
    }
    setCampaignData(tempCampaignData)
    setCampaignDataIDs(tempCampaignDataIDs)
    setPageStats(result["_paging"])
  }

  function openContent(contentID) {
    var tempContent = structuredClone(campaignData)
    tempContent.forEach(content => {
      if (content.id == contentID){
        content.opened = true
      }
    });
    setCampaignData(tempContent)
  }

  function toggleSelectedInfluencer(influencerID) {
    let currentUrlParams = new URLSearchParams(window.location.search); 
    if (selectedInfluencer == influencerID){
      setSelectedInfluencer(null)
      currentUrlParams.delete("influencerID")
    }
    else {
      setSelectedInfluencer(influencerID)
      currentUrlParams.set('influencerID', influencerID);
    }
    setCampaignDataIDs([])
    setCampaignData([])
    let newUrl = window.location.origin + window.location.pathname + '?' + currentUrlParams.toString();
    window.history.replaceState({path:newUrl},'',newUrl);
  }

  function updateSearchFilters(key,value,tempContributors=null) {
    if (tempContributors == null) {
      tempContributors = structuredClone(contributors)
    }
    var tempFilter = structuredClone(searchFilters)
    if (key != "update"){
      tempFilter[key] = value
      setSearchFilters(tempFilter)
    }
    if (key != "search"){
      tempContributors = tempContributors.filter(function(contributor) {
        return contributor.name.toLowerCase().includes(tempFilter.search) && (tempFilter.posts ? contributor.contributions > 0 : true) && (tempFilter.notRated ? contributor.ratings["positive"] == 0 && contributor.ratings["negative"] == 0 : true)
      })
      if (!tempFilter.showAll) {
        tempContributors = tempContributors.splice(0,4)
      }
      setFilteredContributors(tempContributors)
    }
  }

  async function rateContent(contentID,influencerID,rating) {
    var response = await apiCall(`campaign/rateContent?id=${campaignID}&influencerID=${influencerID}&contentID=${contentID}&rating=${rating}`,{ "method" : "PUT" });
    if (response["success"]){
      setAlert({"display":"toast","visible":true,"title":"Rated content","content":"Successfully rated content","className":"bg-success text-white"})
      var tempContributors = structuredClone(contributors)
      if (contentID == "all"){
        tempContributors[contributors.findIndex(contributor => contributor.id == influencerID)]["ratings"] = response["success"]["newRatings"]
      }
      else {
        if (response["success"]["newRatings"]["positive"] > 0) {
          tempContributors[contributors.findIndex(contributor => contributor.id == influencerID)]["ratings"]["positive"] += 1
          tempContributors[contributors.findIndex(contributor => contributor.id == influencerID)]["ratings"]["negative"] -= 1
        }
        else if (response["success"]["newRatings"]["negative"] > 0) {
          tempContributors[contributors.findIndex(contributor => contributor.id == influencerID)]["ratings"]["positive"] -= 1
          tempContributors[contributors.findIndex(contributor => contributor.id == influencerID)]["ratings"]["negative"] += 1
        }
      }
      setContributors(tempContributors)
      updateSearchFilters("update",true,tempContributors)
      var tempCampaignData = campaignData.map(content => {
        if (influencerID == content.influencerID && (contentID == "all" || contentID == content.contentID)){
          if (response["success"]["newRatings"]["positive"] > 0) {
            return { ...content, rating: 1}
          }
          else if (response["success"]["newRatings"]["negative"] > 0) {
            return { ...content, rating: -1}
          }
        }
        return content
      })
      setCampaignData(tempCampaignData)
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to rate content","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  }

  useEffect(() => {
    if (campaign == null) {
      getCampaign(campaignID);
    }
  }, []);

  if (error) {
    return <div className="text-danger text-center h-100 d-flex align-items-center justify-content-start px-4 px-md-0 py-5 flex-column fs-5"><img src={NoAccess} className="noAccessImg mt-5" /><div>Error: {error}</div></div>;
  }

  if (isLoaded) {
    return (
      <div className="bg-page campaignCTADashboard">
        <Topbar />
        <div className="pageName">Campaign CTA Dashboard</div>
          <AlertModal modal={showAlert} onClose={() => setAlert({"display":"toast","visible":false,"title":"","content":""})} /> 
          <Container className="fullscreenWithTopBar px-0" fluid>
            <Container className="pageTitleContainer px-0 shadow-sm pb-0" fluid>
              <Container className="border-bottom pb-0" fluid>
                <Container className="px-0">
                  <Row className="px-3">
                    <Col xs={6} className="pt-3 mb-3">
                      <Link to="/Campaigns" className="text-light"><svg width="15" height="13" className="align-middle" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.157595 5.79154L3.5281 9.88415C3.80704 10.1963 4.25408 10.2261 4.54761 9.98575C4.84116 9.74544 4.88952 9.25978 4.64905 8.96624L2.249 6.05465L12.2777 6.05465C12.6766 6.05465 13 5.73122 13 5.33237C13 4.93353 12.6766 4.6101 12.2777 4.6101L2.249 4.6101L4.64905 1.69851C4.88935 1.40513 4.83528 0.92619 4.54762 0.678997C4.25088 0.424079 3.76842 0.487055 3.52811 0.780596L0.157595 4.87321C-0.0618908 5.1817 -0.0430826 5.4927 0.157595 5.79113L0.157595 5.79154Z" fill="var(--lightColor)"/></svg> My Campaigns</Link>
                      <div>
                        <h1 className="pageTitle tragedyRegularFont text-truncate mb-0 mt-2">{campaign ? campaign.name : "?"}</h1>
                      </div>
                    </Col>
                    <Col xs={6} className="mb-2 mb-lg-4 d-flex justify-content-end align-items-center">
                      <Button  variant="secondary" size="sm" className="px-2 py-1 d-flex align-items-center me-2 mt-4" onClick={() => updateContent()}>
                        {updatingData ? <><span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> Updating Content...</> : "Get Latest Content"}
                      </Button>
                      <Button  variant="secondary" size="sm" className="px-2 py-1 d-flex align-items-center me-2 mt-4" onClick={() => downloadContent()}>
                        {requestingDownload ? <><span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> Requesting... </> : "Download All Content"}
                      </Button>
                      <div className="mt-2 mt-lg-4 fw-normal bg-light py-2 px-3 rounded">
                        <span className="fw-500 fs-6 text-primary">Total Posts: </span>
                        <span className="fs-6 text-primary">{campaign ? campaign.stats.contentCount : "?"}</span>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Container>
            </Container>
            <Container className="campaignPhotoWall" fluid>
              <Container className="px-0 mb-3 mt-3 pt-md-2">
                <Row className="px-md-4 mb-4">
                  <div className="col-12 pe-2">
                    <Row className="pb-4 px-2 ms-0 border border-secondary bg-light rounded shadow-sm bg-secondary position-relative">
                      <div className="col-12 pt-4 mb-2">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex w-75">
                            <input type="text" className="form-control-sm border me-2 w-100" value={searchFilters.search} placeholder="Search.." onKeyDown={(e) => (e.keyCode == 13 && updateSearchFilters("update",true))} onChange={(e) => updateSearchFilters("search",e.target.value.toLowerCase())} />
                            <button type="button" className="btn btn-sm btn-primary" onClick={() => updateSearchFilters("update",true)}>Search</button>
                          </div>
                          <button type="button" className="btn btn-sm btn-primary" onClick={hideShowFilters}>Filters</button>
                        </div>
                        <div id="filtersToggle" className={showFilters ? 'd-block' : ''}>
                          <Form>
                            <Form.Check type="switch" checked={searchFilters.posts} id="has-posts" label="Has Posts" onChange={(e) => updateSearchFilters("posts",e.target.checked)} />
                            <Form.Check type="switch" checked={searchFilters.notRated} id="not-rated" label="Not Rated" onChange={(e) => updateSearchFilters("notRated",e.target.checked)} />
                          </Form>
                        </div>
                      </div>
                      <Row id="influencerList" className={searchFilters.showAll ? 'influencerListModal' : 'influencerList'}>
                        {filteredContributors.length == 0 && <p>No influencers match this search</p>}
                        {filteredContributors.map(contributor => (
                          <div className={`col-3 d-flex align-items-center influencerFilterSelection ${selectedInfluencer == null || selectedInfluencer == contributor.id ? "" : "unselectedInfluencer"} pt-3`} key={contributor.id}>
                            <img src={getImageUrl(providers,contributor.profileImage,["w_80","h_80","c_scale"])} height="40px" className="rounded border me-2" onClick={() => toggleSelectedInfluencer(contributor.id)} /> 
                            <div className="position-relative" onClick={() => toggleSelectedInfluencer(contributor.id)}>
                              <div className="contributorName"><b>{contributor.name}</b></div>
                              <div className="postCount smaller">{contributor.contributions} {contributor.contributions == 1 ? "Post" : "Posts"}</div>   
                            </div>                     
                            <div className="likeDislikeThumbs">
                            <a onClick={() => rateContent("all", contributor.id, 1)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" className="bi bi-hand-thumbs-up-fill" viewBox="0 0 16 16">
                                  <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a10 10 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733q.086.18.138.363c.077.27.113.567.113.856s-.036.586-.113.856c-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.2 3.2 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.8 4.8 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z"/>
                                </svg> <small className="text-secondary">{contributor.ratings.positive}</small>
                              </a>
                              <a onClick={() => rateContent("all", contributor.id, -1)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" className="bi bi-hand-thumbs-down-fill" viewBox="0 0 16 16">
                                  <path d="M6.956 14.534c.065.936.952 1.659 1.908 1.42l.261-.065a1.38 1.38 0 0 0 1.012-.965c.22-.816.533-2.512.062-4.51q.205.03.443.051c.713.065 1.669.071 2.516-.211.518-.173.994-.68 1.2-1.272a1.9 1.9 0 0 0-.234-1.734c.058-.118.103-.242.138-.362.077-.27.113-.568.113-.856 0-.29-.036-.586-.113-.857a2 2 0 0 0-.16-.403c.169-.387.107-.82-.003-1.149a3.2 3.2 0 0 0-.488-.9c.054-.153.076-.313.076-.465a1.86 1.86 0 0 0-.253-.912C13.1.757 12.437.28 11.5.28H8c-.605 0-1.07.08-1.466.217a4.8 4.8 0 0 0-.97.485l-.048.029c-.504.308-.999.61-2.068.723C2.682 1.815 2 2.434 2 3.279v4c0 .851.685 1.433 1.357 1.616.849.232 1.574.787 2.132 1.41.56.626.914 1.28 1.039 1.638.199.575.356 1.54.428 2.591"/>
                                  </svg> <small className="text-secondary">{contributor.ratings.negative}</small>
                              </a>
                            </div>
                          </div>
                        ))}                        
                      </Row>
                      <button type="button" className="btn btn-sm btn-secondary fw-bold viewMoreBtn" onClick={() => updateSearchFilters("showAll",!searchFilters.showAll)}>{searchFilters.showAll ? 'View Less' : 'View More'}</button>
                    </Row>
                  </div>
                </Row>    
              </Container>
              <Container className="px-0 pt-md-2">
                <Row className="px-md-3">
                  <div className="col-12 mb-5">
                    <Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid" columnClassName="my-masonry-grid_column">

                      {campaignData.filter(function(content) { return selectedInfluencer == null || selectedInfluencer == content.influencerID }).map(content => (
                        <div key={content.id} className="position-relative mb-4 ratingHover">
                          <div className="bg-light p-2 rounded-top d-flex align-items-center justify-content-between">
                            <div className="fw-bold text-truncate pe-3">
                              <img src={getImageUrl(providers,content.profileImage,["w_80","h_80","c_scale"])} height="40px" className="rounded border me-1" /> {content.name}
                            </div>
                              {getSocialBadge(content.platform,"rounded","26px","colour")}
                          </div>
                          {content.assetType == "story" && content.opened && (
                            <video autoPlay src={content.assetMedia} className="w-100" controls />
                          )}
                          {content.assetType == "story" && !content.opened && (
                            <>
                              <img src={PlayButton} onClick={() => openContent(content.id)} className="video-play-button" />
                              <img src={content.assetThumbnail} onClick={() => openContent(content.id)} className="w-100" />
                            </>
                          )}
                          {content.assetType == "video" && (
                            <img src={content.assetThumbnail} className="w-100" />
                          )}
                          {content.assetType == "image" && (
                            <img src={content.assetThumbnail} className="w-100" />
                          )}
                          <div className="likeDislikeThumbs">
                            <a onClick={() => content.rating != 1 && rateContent(content.contentID, content.influencerID, 1)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill={content.rating == 1 ? "lawngreen" : "white"} className="bi bi-hand-thumbs-up-fill" viewBox="0 0 16 16">
                              <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a10 10 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733q.086.18.138.363c.077.27.113.567.113.856s-.036.586-.113.856c-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.2 3.2 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.8 4.8 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z"/>
                            </svg>
                            </a>
                            <a onClick={() => content.rating != -1 && rateContent(content.contentID, content.influencerID, -1)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill={content.rating == -1 ? "lightcoral" : "white"}className="bi bi-hand-thumbs-down-fill" viewBox="0 0 16 16">
                              <path d="M6.956 14.534c.065.936.952 1.659 1.908 1.42l.261-.065a1.38 1.38 0 0 0 1.012-.965c.22-.816.533-2.512.062-4.51q.205.03.443.051c.713.065 1.669.071 2.516-.211.518-.173.994-.68 1.2-1.272a1.9 1.9 0 0 0-.234-1.734c.058-.118.103-.242.138-.362.077-.27.113-.568.113-.856 0-.29-.036-.586-.113-.857a2 2 0 0 0-.16-.403c.169-.387.107-.82-.003-1.149a3.2 3.2 0 0 0-.488-.9c.054-.153.076-.313.076-.465a1.86 1.86 0 0 0-.253-.912C13.1.757 12.437.28 11.5.28H8c-.605 0-1.07.08-1.466.217a4.8 4.8 0 0 0-.97.485l-.048.029c-.504.308-.999.61-2.068.723C2.682 1.815 2 2.434 2 3.279v4c0 .851.685 1.433 1.357 1.616.849.232 1.574.787 2.132 1.41.56.626.914 1.28 1.039 1.638.199.575.356 1.54.428 2.591"/>
                            </svg>
                            </a>
                          </div>
                          <div className="bg-light p-2 small rounded-bottom text-truncate">{content.text}</div>
                        </div>
                      ))}

                    </Masonry>
                  </div>
                </Row>
                <PageScroller filter={selectedInfluencer} endpoint={`campaign/dashboardContent`} setState={getMoreContent} pageSize={10} />
              </Container>
            </Container>
          </Container>
        <Footer />
      </div>
    );
  }
  else {
    return ( 
      <div className="bg-page campaignCTADashboard">
        <Topbar />
        <Container className="fullscreenWithTopBar px-0" fluid>
          <FullPageSpinner />; 
        </Container>
      </div>
    )
  }
}