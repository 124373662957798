import { apiCall } from "./api";

export function getProfilePicture(memberships,id) {
    var profileImage = null;
    for (const membershipType in memberships){
        memberships[membershipType].forEach(membership => {
            if (membership.id == id){
                profileImage = membership.profileImage;
            }
        })
    }
    return profileImage;
}

export async function getDetails() {
    var response = await apiCall("influencer/myDetails",{ "method" : "GET"});
    if (response["success"]) {
        return response["success"]
      }
      else {
        return {"address":null,"phoneNumber":null,"dob":"2000-01-01"}
      };
}

export function validatePhoneNumber(phoneNumber) {
    return RegExp("^((\\+44\\s?[0-9]{10}|[0-9]{11})|(\\+1)?\\s?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4})$").test(phoneNumber.trim().replace(/[\u0000-\u001F\u007F-\u009F\u061C\u200E\u200F\u202A-\u202E\u2066-\u2069]/g, ""))
}

export function validateAddress(address) {
    return (address.address1 && address.postcode)
}

export function passwordMeetsStandard(password){
    var reasons = []
    if (password.length < 12){
        reasons.push("Password must be at least 12 characters")
    }
    if (!"abcdefghijklmnopqrstuvwxyz".split("").some(function(character) {return password.includes(character)})){
        reasons.push("Password must contain at least one lowercase character")
    }
    if (!"abcdefghijklmnopqrstuvwxyz".toUpperCase().split("").some(function(character) {return password.includes(character)})){
        reasons.push("Password must contain at least one uppercase character")
    }
    if (!"1234567890".split("").some(function(character) {return password.includes(character)})){
        reasons.push("Password must contain at least one number")
    }
    if (reasons.length > 0){
        return {result: false, reasons: reasons}
    }
    else{
        return {result: true, reasons: []}
    }
}