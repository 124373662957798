import React, { Component, useState, useEffect } from "react";
import { Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import { apiCall } from './../../helpers/api'
import { useImageContext } from "../../hooks/useImageContext";
import { getImageUrl } from "../../helpers/images";
import { useSearchParams, userHistory, Link } from "react-router-dom";

import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import NoAccess from "../../images/no-access.png";
import './List.css';
import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";
import Event from './../../images/event.svg';

class ListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
    this.getCampaigns(props.category)
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  async getCampaigns(category) {
    var response = await apiCall(`campaigns?category=${category.replace("&","%26")}&active=true&live=true`,{ "method" : "GET" }); //Bit hacky with the replace, not sure why it's not being encoded properly...
    if (response["success"]) {
      this.setState({
        isLoaded: true, 
        campaigns : response["success"]["campaigns"]
      });
    }
    else {
      this.setState({
        isLoaded: true,
        error: response["failure"]["error"]
    });
    }
  }

  CampaignItem(campaign){
    return (
    <div className="col-12 col-md-6 col-lg-4">
      <Card className="flex-row mb-3 mb-md-4 pe-md-2 align-items-center">
        <Link to={"/Campaign?id="+campaign.id}><Card.Img variant="top" className="cardImage rounded" src={getImageUrl(campaign.class.props.providers,campaign.images[0],["w_70","c_scale"])} /></Link>          
        <Card.Body className="pe-0 py-0">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="d-flex align-items-center text-truncate me-2">
            <img src={getImageUrl(campaign.class.props.providers,campaign.brandIcon)} width="22" className="me-2" />
              <div className="cardTitle text-truncate">
                <Link to={"/Campaign?id="+campaign.id} className="text-truncate">{campaign.brandName}</Link>
              </div>                
            </div>
            {campaign.event ? <Badge bg="event"><img src={Event} width="13" className="align-bottom" /> Event</Badge> : null}              
          </div>
          <h6 className="mt-2 text-truncate"><Link to={"/Campaign?id="+campaign.id} title={campaign.name} className="text-black">{campaign.name}</Link></h6>
          <div className="smaller twoLineTruncate text-muted">
            {campaign.offering}
          </div>
        </Card.Body>
      </Card>
    </div>
    )
  }

  render() {
    const { error, isLoaded, campaigns } = this.state;
    if (error) {
      return <div className="text-danger text-center h-100 d-flex align-items-center justify-content-start px-4 px-md-0 py-5 flex-column fs-5 bg-secondary"><img src={NoAccess} className="noAccessImg my-5 rounded-xl shadow" /><div>Error: {error}</div></div>;
    } else if (!isLoaded) {
      return <FullPageSpinner />;
    } else {
      return (
        <div className="ps-3">
          <Row>          
            {campaigns.map(campaign => <this.CampaignItem key={campaign._id} name={campaign.name} event={campaign.event} images={campaign.images} brandIcon={campaign.brandIcon} id={campaign._id} brandName={campaign.brandName} offering={campaign.offering} class={this} />)}
          </Row>
        </div>
      );
    }
  }
}

export default function List() {  
  let [searchParams, setSearchParams] = useSearchParams();
  const { providers } = useImageContext();
  const category = searchParams.get('category');
  return (
    <div className="bg-page">
    <Topbar />
    <div className="pageName">Home</div>
    <Container className="fullscreenWithTopBar px-0 pb-0" fluid>
      <Container className="listView" fluid>
        <Container className="px-0 pt-4">
          <div>
            <div className="mb-4 py-2 px-4 mx-3 position-relative">
              <Link className="backArrow" onClick={() => window.history.back()}>
                <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                </svg>
              </Link>
              <div className="sectionTitle text-truncate">All {category}</div>
            </div>
            <ListView category={category} providers={providers} />
          </div>
        </Container>
      </Container>
    </Container>
    <Footer />
  </div>
  );
}