import React, { useState } from "react";
import { Button, Form, Modal, FloatingLabel } from 'react-bootstrap';
import { apiCall } from '../../helpers/api';
import { Link } from "react-router-dom";
import { getAge } from "../../helpers/dates";

export function NewsModal(props){
  const handleClose = e => {
    props.setNewsModal({"visible":false})
  }
  const handleSubmit = async (redirect) => {
    await apiCall("readNews",{ "method" : "POST", "data" : {} });
    if (redirect) {
      window.location.href = "/Settings?setting=notifications"
    }
    handleClose()
  }
  if (props.newsModal.visible){
    return (
      <>
      <Modal show={props.newsModal.visible} onHide={handleClose}>
        <Modal.Header closeButton onClose={handleClose}>
          <Modal.Title>Are you getting notifications?</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <div>Please check your notifications are working correctly!</div>
            <div>You can do this by going to your <Link onClick={() => handleSubmit(true)}>notification settings here</Link></div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" onClick={() => handleSubmit(true)}>
              Ok
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      </>
      )}
}

export function AgeCheckModal(props){
  const handleClose = e => {
    var tempData = structuredClone(props.ageCheckModal)
    tempData.visible = false
    props.setAgeCheckModal({"visible":false})
  }
  const updateDob = e => {
    var tempData = structuredClone(props.ageCheckModal)
    tempData.newDOB = e
    props.setAgeCheckModal(tempData)
  }
  const handleSubmit = async () => {
    var tempData = structuredClone(props.ageCheckModal)
    if (!tempData.newDOB){
      tempData.newDOB = props.data.details.currentDob
    }
    if (getAge(tempData.newDOB) < 18) {
      tempData.errorMessage = "You must be at least 18 to use this platform."
    }
    else if (getAge(tempData.newDOB) > 99){
      tempData.errorMessage = "Please enter a valid age."
    }
    else {
      var result = await apiCall("influencer/updateInvalidAge",{ "method" : "PUT", "data" : {"dob":tempData.newDOB} });
      if (result["success"]) {
        handleClose()
        window.location.reload()
      }
      else {
        tempData.errorMessage = result["failure"]["error"]
      }
    }
    props.setAgeCheckModal(tempData)
  }
  if (props.ageCheckModal.visible){
    return (
      <>
      <Modal show={props.ageCheckModal.visible}>
        <Modal.Header>
          <Modal.Title>Is your age correct?</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            {props.ageCheckModal.errorMessage && <div><b>{props.ageCheckModal.errorMessage}</b></div>}
            <div>It looks like we might not have the right age for you. Please make sure your age is correct below and hit submit.</div>
            <FloatingLabel label="Date of birth:" className="mb-3" >
                <Form.Control type="date" id="dob" value={props.ageCheckModal.newDOB ? props.ageCheckModal.newDOB : props.data.details.currentDob} onChange={(e) => updateDob(e.target.value)} className="rounded-0 border-0 border-bottom" />
            </FloatingLabel>
            <br />
            <div><i>You won't be able to bid on new campaigns until you've completed this action.</i></div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => handleSubmit()}>
              Ok
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      </>
  )}
}