
import ReactEChartsCore from "echarts-for-react/lib/core"
import * as echarts from 'echarts/core';
import { HeatmapChart, BarChart, PieChart, LineChart } from "echarts/charts";
import { TooltipComponent, GridComponent, VisualMapComponent, LegendComponent, TitleComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { LabelLayout } from 'echarts/features';

export function Piechart(props) {
    echarts.use([
      TitleComponent,
      TooltipComponent,
      LegendComponent,
      PieChart,
      CanvasRenderer,
      LabelLayout
    ]);
  
    const option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: '5%',
        left: 'center',
        show: props.meta.showLegend ? true : false
      },
      title: {
        left: 'center',
        text: props.meta.title,
        show: props.meta.title ? true : false
      },
      series: [
        {
          name: props.meta.title,
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          legend: {
            orient: 'vertical',
            left: 'left'
          },
          label: {
            alignTo: 'edge',
            formatter: '{name|{b}}\n{value|{c}}',
            rich: {
              value: {
                fontSize: 12,
                color: '#999'
              }
            },
            minMargin: 5,
            edgeDistance: 10,
            lineHeight: 15,
          },
          labelLine: {
            length: 15,
            length2: 0,
            maxSurfaceAngle: 80
          },
          labelLayout: function (params) {
            const isLeft = params.labelRect.x < 100;
            const points = params.labelLinePoints;
            // Update the end point.
            points[2][0] = isLeft
              ? params.labelRect.x
              : params.labelRect.x + params.labelRect.width;
            return {
              labelLinePoints: points
            };
          },
          data: props.data
        }
      ]
    };
  
    return <ReactEChartsCore echarts={echarts} option={option} style={{"maxHeight":`calc(${props.heightRatio}vh - 300px)`}} />;
  }

export function Barchart(props) {
    echarts.use([
      TitleComponent,
      GridComponent,
      BarChart,
      CanvasRenderer,
      TooltipComponent
    ]);
  
    const option = {
      tooltip: {
        position: 'top'
      },
      title: {
        left: 'center',
        text: props.meta.title,
        show: props.meta.title ? true : false
      },
      xAxis: {
        type: 'category',
        data: props.xAxisLabel
      },
      legend : {},
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: props.meta.series,
          data: props.data,
          type: 'bar'
        }
      ]
    };
  
    return <ReactEChartsCore echarts={echarts} option={option} style={{"maxHeight":`calc(${props.heightRatio}vh - 300px)`}} />;
  }

export function MultilineChart(props) {
  echarts.use([
    TitleComponent,
    GridComponent,
    LegendComponent,
    BarChart,
    LineChart,
    CanvasRenderer,
    TooltipComponent
  ]);

  var seriesArray = []
  var yAxisArray = []
  props.data.map((dataSet,index) => {
    yAxisArray.push({
      type: 'value',
      alignTicks: true,
      axisLabel: {
        formatter: dataSet.format
      }
    })
    seriesArray.push({
      name: dataSet.series,
      data: dataSet.data,
      type: dataSet.type,
      yAxisIndex: props.meta.differentY ? index : 0
    })
  })

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        animation: false,
        label: {
          backgroundColor: '#505765'
        }
      }
    },
    legend : {},
    title: {
      left: 'center',
      text: props.meta.title,
      show: props.meta.title ? true : false
    },
    xAxis: {
      type: 'category',
      data: props.xAxisLabel
    },
    yAxis: yAxisArray,
    series: seriesArray
  };

  return <ReactEChartsCore echarts={echarts} option={option} style={{"maxHeight":`calc(${props.heightRatio}vh - 300px)`}} />;
}