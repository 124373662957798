export function getFileUrl(providers,file,args=null) {
    if (file.source == "local"){

    }
    if (file.source == "cloudinary"){
        if (args) {
            args = args.join(",")
            return `${providers.cloudinary.url}/raw/upload/${args}/${file.id}`
        }
        return `${providers.cloudinary.url}/raw/upload/${file.id}`
    }
}

export function getVideoURL(providers,file,args=null) {
    if (file.source == "local"){

    }
    if (file.source == "cloudinary"){
        if (args) {
            args = args.join(",")
            return `${providers.cloudinary.url}/video/upload/${args}/${file.id}`
        }
        return `${providers.cloudinary.url}/video/upload/${file.id}`
    }
}

export function validateFile(file) {
    const allowedFileTypes = ["application/pdf","text/rtf","application/vnd.openxmlformats-officedocument.wordprocessingml.document","application/msword","application/vnd.oasis.opendocument.text"];
    const maxSize = 10485760; //10MB

    if (allowedFileTypes.includes(file.type)){
        if (file.size <= maxSize){
            return [true, ""]
        }
        return [false, "File is too large (must be 10MB or under)"]
    }
    return [false, "File is not one of the following: PDF, DOC/DOCX, RTF, ODT"]
}