import React, { Component, useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { useImageContext } from "../../hooks/useImageContext";
import { apiCall } from "../../helpers/api";
import { relativeTimeFormat } from "../../helpers/dates"
import { getImageUrl } from "../../helpers/images";
import { NavLink, Link, useSearchParams } from 'react-router-dom'

import './Notifications.css';
import Footer from "../../components/footer/Footer";
import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import Topbar from "../../components/topbar/Topbar";

export default function Notifications() { 
  const [modalShow, setModalShow] = React.useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { providers } = useImageContext();
  const [notifications, setNotifications] = useState([]);
  const [pageStats, setPageStats] = useState({})
  const [error, setError] = useState(null)
  let [searchParams] = useSearchParams();
  var highlight = searchParams.get('highlight');

  const fetchNotifications = async (showLoading=true) => {
    if (showLoading){
      setIsLoaded(false);
    }
    var response = await apiCall(`notifications?limit=100`,{ "method" : "GET" });
    if (response["success"]){
      setNotifications(response["success"]["notifications"]);
      setPageStats(response["success"]["_paging"])
    }
    setIsLoaded(true);		
  };

  const getMoreNotifications = async (skip) => {
    
    setError(null);
    var existingNotifications = notifications;
    var response = await apiCall(`notifications?limit=100&skip=${skip}`,{ "method" : "GET" });
    if (response["success"]) {
      existingNotifications.push(...response["success"]["notifications"])
      setPageStats(response["success"]["_paging"])
      setNotifications(existingNotifications)
    }
    else {
      setError(response["failure"])
    }
  }

  const pageOnScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop >= e.target.clientHeight - 10 & e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 10;
    if (bottom && notifications.length < pageStats.total && !isLoadingMore) {
      getMoreNotifications(notifications.length)
    }
  }

  useEffect(() => {
    fetchNotifications();
  }, []);

  if (!isLoaded) {
    return <FullPageSpinner />;
  }
  else {
    return (
        <div className="bg-page homePage">
            <Topbar />
            <div className="pageName">Notifications</div>
            <Container className="fullscreenWithTopBar px-0" fluid onScroll={pageOnScroll}>
              <Container className="pageTitleContainer shadow-sm" fluid>
                <Container className="px-0">
                  <Row className="py-1">
                    <div className="col-4">
                      <h1 className="px-3 mb-4 pt-4 pageTitle">Notifications</h1>
                    </div>
                    <div className="col-4 text-end"></div>
                  </Row>
                </Container>
              </Container>
              <Container className="px-0 pt-4">
                {notifications.map(notification => (
                  <div className='d-flex align-items-center p-3'>
                  {notification.data.link ? (
                  <>
                    {notification.data.link.includes("http") ?
                      <a target="_blank" rel="noreferrer noopener" href={notification.data.link}><img src={getImageUrl(providers,notification.data.image,["w_46","c_scale"])} height={46} width={46} className='rounded border object-fit-cover' /></a>
                    :
                      <Link to={notification.data.link}>
                        <img src={getImageUrl(providers,notification.data.image,["w_46","c_scale"])} height={46} width={46} className='rounded border object-fit-cover' />
                      </Link>
                    }
                  </>
                  ) : (
                    <img src={getImageUrl(providers,notification.data.image,["w_46","c_scale"])} height={46} width={46} className='rounded border object-fit-cover' />
                  )}
                  <div className='ms-3 pt-1'>
                  {notification.data.link ? (
                    <>
                      {notification.data.link.includes("http") ?
                        <a target="_blank" rel="noreferrer noopener" href={notification.data.link}><h6 className='mb-0 text-nowrap'>{notification.data.subject}</h6></a>
                      :
                        <Link className="text-black" to={notification.data.link}>
                          <h6 className='mb-0 text-nowrap'>{notification.data.subject}</h6>
                      </Link>
                      }
                    </>
                    ) : (
                      <h6 className='mb-0 text-nowrap'>{notification.data.subject}</h6>
                    )}
                     <div className='d-flex align-items-center justify-content-between'>
                      <span className='small text-nowrap'>{notification.data.details}</span>
                    </div>
                    <div className='d-flex align-items-center justify-content-between'>
                      <span className='text-muted small text-nowrap'>{relativeTimeFormat(notification.notifiedTime*1000,false)}</span>
                    </div>
                  </div>
                  </div>
                ))}
                <div className="text-center my-4 py-4">
                  {notifications.length >= pageStats.total ? <p className="text-muted mb-0">{notifications.length == 0 ? "You don't have any notifications to show" : "You've reached the bottom of the list!"}</p> : <span className="spinner-border text-primary" role="status" aria-hidden="true"></span>}
                </div>    
              </Container>
            </Container>
            <Footer />
        </div>
    )
  }
}