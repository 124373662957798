import React from 'react';
import { createContext, useContext, useReducer, useEffect, useState } from "react";
import { apiCall } from '../helpers/api';
import { getDetails } from '../helpers/user';

export const AuthContext = createContext();

async function getSession() {
  if (process.env.REACT_APP_DISABLE_AUTH == true) {
    return null
  }
  var response = await apiCall("session",{ "method" : "GET" });
  if (response["success"]) {
    return response["success"]["session"]
  } else {
    return null
  }
}

const ACTIONS = {
  LOGIN: "login",
  LOGOUT: "logout"
}

export const authReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.LOGIN:
      var views = [];
      var defaultID = "";
      for (const memberships in action.payload.memberships){
        if (["brands","influencers"].includes(memberships) && action.payload.memberships[memberships].length > 1){
          views.push(`${memberships}Multiple`)
        }
        views.push(memberships)
      }
      return { data: action.payload, views: views, roles: [], currentProfile: action.payload.currentProfile, session: action.payload }
    case ACTIONS.LOGOUT:
      return { data: action.payload, views: views, roles: [], currentProfile: "", session: "" }
    default:
      return state
  }
}

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    data: null,
    views: [],
    roles: [],
    defaultID: ""
  })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    var user = null
    const getSessionData = async () => { 
      user = await getSession() 
      
      if (user) {
        dispatch({type: ACTIONS.LOGIN, payload: user})
      }
      setLoading(false)
    }
    
    getSessionData();
  }, [])

  // console.log("Auth State:", state)

  return ( 
  <AuthContext.Provider value={{...state, dispatch, loading}}>
    { children }
    </AuthContext.Provider>
  )
};

export const useAuth = () => {
  return useContext(AuthContext);
};