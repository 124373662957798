import React, { useEffect } from 'react';
import AppRoutes from './Routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import { serviceWorker } from "./sw.js";
import './Global.css';
import { AuthProvider } from "./context/authContext";
import { ImageProvider } from './context/imageContext';
import ErrorBoundary from './components/Exception';

function App() {
  useEffect(() => {
    document.title = 'The Influence Room';
  }, []);

  return (
      <div className="App">
        <ErrorBoundary>
        <AuthProvider>
          <ImageProvider>
            <AppRoutes />
          </ImageProvider>
        </AuthProvider>
        </ErrorBoundary>
      </div>
  );
}

export default App;
serviceWorker();