import React, { Component, useEffect, useState, useRef } from "react";
import { Container, Col, Row, Tab, Nav, Button, Table, Dropdown, Badge, Form, Modal } from 'react-bootstrap';
import { useSearchParams } from "react-router-dom";
import { useImageContext } from "../../hooks/useImageContext";
import { getImageUrl } from "../../helpers/images";
import InputGroup from 'react-bootstrap/InputGroup';
import { useAuthContext } from "../../hooks/useAuthContext";

import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import './Campaigns.css';
import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";
import settings from './../../images/settings.svg';
import archive from './../../images/dropdown-archive.svg';
import bids from './../../images/dropdown-bids.svg';
import collaborations from './../../images/dropdown-collaborations.svg';
import edit from './../../images/dropdown-edit.svg';
import messages from './../../images/dropdown-messages.svg';
import reporting from './../../images/dropdown-reporting.svg';
import eye from './../../images/eye.svg';
import Search from './../../images/search-dark.svg';
import Edit from './../../images/edit.svg';
import Delete from './../../images/delete.svg';

import { apiCall, PageScroller } from './../../helpers/api'
import AlertModal from "../../components/alerts/alert";
import { Link } from "react-router-dom";
import { getDifference } from './../../helpers/dates'

class CampaignsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      campaigns: [],
      campaignIDs: [],
      notes: []
    };
  }

  async getNotes() {
    var response = await apiCall(`brand/notes`,{ "method" : "GET" });
    if (response["success"]) {
      this.setState({
        notes : response["success"]["notes"]
      });
      if (response["success"]["notes"].length > 0){
        this.props.setSelectedNote(response["success"]["notes"][0])
      }
    }
    else {
      this.setState({
        error: response["failure"]
    });
    }
  }

  selectNote(note){
    this.props.setSelectedNote(note)
    this.props.setNoteContent(note.content)

  }
  updateNoteContent(id,content){
    var existingNotes = []
    this.state.notes.forEach(note => {
      if (note._id == id){
        note.content = content
        this.props.setSelectedNote(note)
      }
      existingNotes.push(note)
    })
    this.setState({
      notes: existingNotes
    })
  }

  ArchiveModal(props){
    var error = null;
    const handleSubmit = async e => {
      e.preventDefault();
      props.class.props.setIsSubmitting(true)
      var response = await apiCall(`campaign/archive?id=${props.show.campaign._id}`,{ "method" : "PUT" });
      if (response["success"]) {
        for (var x in props.class.state.campaigns) {
          if ( props.class.state.campaigns[x]["_id"] === props.show.campaign._id ) {
            props.class.state.campaigns[x]["archived"] = true
            props.class.setState({ campaigns: props.class.state.campaigns })
            break
          }
        }
        props.handleClose();
        props.class.props.setAlert({"display":"toast","visible":true,"title":"Campaign Archived","content":"Campaign Successfully Archived","className":"bg-success text-white"})
      }
      else {
        error = response["failure"]["error"]
        props.class.props.setAlert({"display":"toast","visible":true,"title":"Error Archiving Campaign","content":error,"className":"bg-danger text-white"})
      };
      props.class.props.setIsSubmitting(false)
    }
    if (props.show.visible){
      return (
        <Modal show={props.show.visible} onHide={props.handleClose}>
          <Modal.Header closeButton className="pe-4">
            <Modal.Title className='h5'>Archive Campaign?</Modal.Title>
          </Modal.Header>
          <Form>
            <Modal.Body>
              Are you sure you want to archive <b>"{props.show.campaign.name}?"</b>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-primary" onClick={props.handleClose}>
                No
              </Button>
              <Button variant="danger" disabled={props.class.props.isSubmitting} type="submit" onClick={handleSubmit}>
                {props.class.props.isSubmitting ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Archiving...</> : "Yes"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )
    }
    else { return (<></>) }
  }

  NotesModal(props){
    var error = null;
    const handleClose = () => {
      props.class.props.setShowNotesModal({"visible":false})
    }
    const updateTitle = (title) => {
      var tempDict = props.class.props.showNotesModal;
      tempDict.title = title
      props.class.props.setShowNotesModal(tempDict)
    }
    const handleSubmit = async e => {
      e.preventDefault();
      var response = await apiCall(`brand/notes?id=${props.class.state.brand._id}`,{ "method" : "POST", "data" : {"title" : props.class.props.showNotesModal.title} });
      if (response["success"]) {
        props.class.getNotes()
        handleClose();
        props.class.props.setAlert({"display":"toast","visible":true,"title":"Note Created","content":"Note successfully created","className":"bg-success text-white"})
      }
      else {
        error = response["failure"]["error"]
        props.class.props.setAlert({"display":"toast","visible":true,"title":"Error Creating Note","content":error,"className":"bg-danger text-white"})
      };
    }
    if (props.class.props.showNotesModal.visible){
      return (
        <Modal show centered onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="h5">Note Title</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <Form.Group>
              <Form.Control type="text" value={props.class.props.showNotesModal.title} onChange={(e) => updateTitle(e.target.value)} placeholder="Enter Title" />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Button variant="outline-secondary" size="sm" onClick={handleClose}>Cancel</Button>
            <Button variant="primary" size="sm" onClick={handleSubmit}>Save</Button>
          </Modal.Footer>
        </Modal>
      )
    }
    else { return (<></>) }
  }
  CampaignsHeader(props){
    const changeTab = (tabName) => {
      let currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set('view', tabName);
      let newUrl = window.location.origin + window.location.pathname + '?' + currentUrlParams.toString();
      window.history.replaceState({path:newUrl},'',newUrl);
    }
  
    return (
      <Container className="pageTitleContainer px-0 shadow-sm pb-0" fluid>
        <Container className="border-bottom pb-0" fluid>
          <Container className="px-0">
            <Row className="px-3">
              <Col xs={6} className="mb-4 pt-4">
                <h1 className="pageTitle text-white mt-lg-2 tragedyRegularFont ps-md-0">My Campaigns</h1>
              </Col>
              <Col xs={6} className="mb-2 mb-lg-4 d-flex justify-content-end align-items-center">
                <div className="mt-2 mt-lg-4 fw-normal text-primary bg-light py-2 px-3 rounded">
                  <span className="fw-500 fs-6">Campaigns left: </span>
                  <span className="fs-6">{props.brand && typeof(props.brand.credits) == 'number' ? props.brand.credits : "?"}</span>
                  {/* <div className="text-muted small">Use by 31st August 2022</div> */}
                </div>
              </Col>
              <Col xs={8}>
                <Nav variant="tabs" className="nav nav-tabs border-0" id="nav-tabMobile">
                  <Nav.Item>
                    <Nav.Link className="bg-transparent" eventKey="all" onClick={()=>changeTab("all")}>All</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="bg-transparent" eventKey="live" onClick={()=>changeTab("live")}>Live</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="bg-transparent" eventKey="expired" onClick={()=>changeTab("expired")}>Expired</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="bg-transparent" eventKey="scheduled" onClick={()=>changeTab("scheduled")}>Scheduled</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="bg-transparent" eventKey="drafts" onClick={()=>changeTab("drafts")}>Drafts</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="bg-transparent" eventKey="archive" onClick={()=>changeTab("archive")}>Archive</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="notes">Notes</Nav.Link>
                  </Nav.Item> */}
                </Nav> 
              </Col>
              <Col xs={4} className="mb-lg-4 d-flex justify-content-end">
                {props.brand && typeof(props.brand.credits) == 'number' && props.brand.credits > 0 ? <Link to="/Campaign/Create"><Button variant="secondary" size="sm" className="rounded" >New Campaign</Button></Link> : <Button variant="secondary" size="sm" className="rounded" disabled >New Campaign</Button>}
              </Col>
            </Row>
          </Container>
        </Container>
      </Container>
    )
  }

  CampaignSummary(props){
    const campaignStart = getDifference(props.campaign.startDate)
    const campaignEnd = getDifference(props.campaign.endDate)
    return (
      <tr>
        <td className="bg-white rounded-start">
          <div className="d-flex align-items-center">
            <img height="40px" width="70px" src={getImageUrl(props.class.props.providers,props.campaign.images[0],["w_100","c_scale"])} alt={props.campaign.name} title={props.campaign.name} className="rounded object-fit-cover me-3 myCampaignsTableImage" /> 
            <Link title={props.campaign.name} className="text-black text-wrap fw-500" to={"/Campaign/Manage?id=" + props.campaign._id}>{props.campaign.name}</Link>
          </div>
        </td>
        {["all","live","expired","archive"].includes(props.state) && (
          <>
          {props.campaign.active ? <td align="center" className="bg-white"><Link to={"/Campaign/Manage?id=" + props.campaign._id + "&view=messages"} className="text-primary h3 position-relative">{props.campaign.campaignStats.unreadMessages} {props.campaign.campaignStats.unreadMessages > 0 && (<span className="notify"></span>)}</Link></td> : <td align="center" className="bg-white text-muted">-</td>}
          {props.campaign.active ? <td align="center" className="bg-white"><Link to={"/Campaign/Manage?id=" + props.campaign._id + "&view=bids"} className="text-primary h3 position-relative">{props.campaign.bidStats.total} </Link></td> : <td align="center" className="bg-white text-muted">-</td>}
          {props.campaign.active ? <td align="center" className="bg-white"><Link to={"/Campaign/Manage?id=" + props.campaign._id + "&view=bids"} className="text-primary h3 position-relative">{props.campaign.bidStats.pending} </Link></td> : <td align="center" className="bg-white text-muted">-</td>}
          {props.campaign.active ? <td align="center" className="bg-white"><Link to={"/Campaign/Manage?id=" + props.campaign._id + "&view=collaborations"} className="text-primary h3">{props.campaign.bidStats.accepted}</Link></td> : <td align="center" className="bg-white text-muted">-</td>}
          {props.campaign.active == true & props.campaign.bidStats.accepted > 0 ? <td align="center" className="bg-white"><Link to={"/Campaign/Manage?id=" + props.campaign._id + "&view=collaborations"} className="text-primary h3">{props.campaign.bidStats.contentPosted}/{props.campaign.bidStats.accepted}</Link></td> : <td align="center" className="bg-white text-muted">-</td>}
          <td className="bg-white text-nowrap">
            {props.campaign.bidStats.accepted == 0 ? (<span className="text-muted small">No content submitted yet</span>) : (
              <>
              {props.campaign.bidStats.collaborators.slice(0,3).map(collaborator => <img key={collaborator.id} src={getImageUrl(props.class.props.providers,collaborator.profile,["w_80","h_80","c_scale"])} height="40px" className="border rounded me-1"></img>)}
              {props.campaign.bidStats.accepted == 4 && (
                <img key={props.campaign.bidStats.collaborators[3].id} src={getImageUrl(props.class.props.providers,props.campaign.bidStats.collaborators[3].profile,["w_80","h_80","c_scale"])} height="40px" className="border rounded" />
              )}
              {props.campaign.bidStats.accepted > 4 && (
                <span className="imageOverlay">
                  <span className="textOverlay rounded" onClick={() => props.class.props.setCollaboratorView({"visible":true,"collaborators":props.campaign.bidStats.collaborators})}>+{props.campaign.bidStats.accepted - 3}</span>
                  <img key={props.campaign.bidStats.collaborators[3].id} src={getImageUrl(props.class.props.providers,props.campaign.bidStats.collaborators[3].profile,["w_80","h_80","c_scale"])} height="40px" className="border rounded" />
                </span>
              )}
            </>              
            )}
          </td>
          </>
        )}
        <td className="bg-white text-nowrap">
          {props.campaign.active ? ( 
          <>
            {campaignStart.future && (
            <>
              {props.state == "scheduled" ? null : <Badge pill bg="warning" text="dark">Scheduled</Badge>}
              <div className="text-muted smaller">Starts in {campaignStart.difference} {campaignStart.unit}</div>
            </>
            )}
            {!campaignStart.future && campaignEnd.future && (
            <>
              {props.state == "live" ? null : <Badge pill bg="success" text="dark">Live</Badge>}
              <div className="text-muted smaller">Expires in {campaignEnd.difference} {campaignEnd.unit}</div>
            </>
            )}
            {!campaignEnd.future && (
            <>
              {props.state == "expired" ? null : <Badge pill bg="danger" text="dark">Expired</Badge>}
              <div className="text-muted smaller">Expired {campaignEnd.difference} {campaignEnd.unit} ago</div>
            </>
            )}
          </>
           ): (
            <>
            {props.campaign.archived ? (
              <>
              {props.state == "archive" ? null : <Badge pill bg="danger" text="dark">Inactive</Badge>}
              <div className="text-muted smaller">Archived</div>
              </>
            ) : (
              <>
              {props.state == "drafts" ? null : <Badge pill bg="danger" text="dark">Inactive</Badge>}
              <div className="text-muted smaller">Draft</div>
              </>
            )}
            </>
          )}
        </td>
        <td align="right" className="bg-white rounded-end text-nowrap">
          <Link className="me-2 d-inline-block" to={"/Campaign/Preview?id=" + props.campaign._id}><img src={eye} width="20px"  /></Link>
          {/* <Link to={"/Campaign/Manage?id=" + props.campaign._id}><img src={settings} /></Link> */}
          <Dropdown className="campaignAction d-inline-block">
            <Dropdown.Toggle variant="default" className="border-0">
              <img src={settings} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {!props.campaign.active || props.class.props.session.sessionType == "impersonation" ? 
              (<>
                <Link className="dropdown-item" to={"/Campaign/Create?id=" + props.campaign._id}><img src={edit} height="14px" width="20px" className="me-2" /> Edit</Link>
                <Dropdown.Divider /> 
              </>
              ) : null }
              <Link className="dropdown-item" to={"/Campaign/Manage?id=" + props.campaign._id + "&view=bids"}><img src={bids} height="16px" width="20px" className="me-2 mb-1" /> Bids</Link>
              <Link className="dropdown-item" to={"/Campaign/Manage?id=" + props.campaign._id + "&view=collaborations"}><img src={collaborations} height="14px" width="20px" className="me-2" /> Collaborations</Link>
              <Link className="dropdown-item" to={"/Campaign/Manage?id=" + props.campaign._id + "&view=messages"}><img src={messages} height="14px" width="20px" className="me-2" /> Messages</Link>
              <Link className="dropdown-item" to={"/Campaign/Manage?id=" + props.campaign._id + "&view=reporting"}><img src={reporting} height="14px" width="20px" className="me-2" /> Reporting</Link>
              {props.campaign.archived ? null : 
              (<>
                <Dropdown.Divider /> 
                {props.class.state.brand && typeof(props.class.state.brand.credits) == 'number' && props.class.state.brand.credits > 0 ? <Link className="dropdown-item" to={"/Campaign/Create?cloneTargetID=" + props.campaign._id}><img src={edit} height="14px" width="20px" className="me-2" /> Clone</Link> : <Link className="dropdown-item" onClick={event => props.class.props.setAlert({"display":"toast","visible":true,"title":"Error Cloning Campaign","content":"You have no campaign entitlements available. Please purchase more or speak with Customer Support to create more campaigns.","className":"bg-danger text-white"})}><img src={edit} height="14px" width="20px" className="me-2" /> Clone</Link> }
                <Link className="dropdown-item" onClick={event => props.class.props.setShowArchiveModal({"visible":true,"campaign":props.campaign})} ><img src={archive} height="14px" width="20px" className="me-2" /> Archive</Link>
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    )
  }

  CampaignSummaryMobile(props){
    const campaignStart = getDifference(props.campaign.startDate)
    const campaignEnd = getDifference(props.campaign.endDate)
    return (
      <div className="bg-white">
        <img src={getImageUrl(props.class.props.providers,props.campaign.images[0],["w_500","c_scale"])} height="40vh" width="100%" className="mobileCampaignsList" />
        <div className="p-3">
          <Row>
            <Col xs={9}>
              <div className="fw-500">
                <Link className="text-black" to={"/Campaign/Manage?id=" + props.campaign._id}>{props.campaign.name}</Link>
              </div>
            </Col>
            <Col xs={3} className="text-end">
              <Link className="me-3 d-inline-block" to={"/Campaign/Preview?id=" + props.campaign._id}><img src={eye} width="20px"  /></Link>
              <Dropdown className="campaignAction d-inline-block">
                <Dropdown.Toggle variant="default" className="border-0 px-0 pt-0 pb-1">
                  <img src={settings} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                {!props.campaign.active || props.class.props.session.sessionType == "impersonation" ? 
                (<>
                  <Link className="dropdown-item" to={"/Campaign/Create?id=" + props.campaign._id}><img src={edit} height="14px" width="20px" className="me-2" /> Edit</Link>
                  <Dropdown.Divider /> 
                </>
                ) : null }
                  <Link className="dropdown-item" to={"/Campaign/Manage?id=" + props.campaign._id}><img src={bids} height="16px" width="20px" className="me-2 mb-1" /> Bids</Link>
                  <Link className="dropdown-item" to={"/Campaign/Manage?id=" + props.campaign._id}><img src={collaborations} height="14px" width="20px" className="me-2" /> Collaborations</Link>
                  <Link className="dropdown-item" to={"/Campaign/Manage?id=" + props.campaign._id}><img src={messages} height="14px" width="20px" className="me-2" /> Messages</Link>
                  <Link className="dropdown-item" to={"/Campaign/Manage?id=" + props.campaign._id}><img src={reporting} height="14px" width="20px" className="me-2" /> Reporting</Link>
                  {props.campaign.archived ? null : 
                  (<>
                    <Dropdown.Divider />
                    {props.class.state.brand && typeof(props.class.state.brand.credits) == 'number' && props.class.state.brand.credits > 0 ? <Link className="dropdown-item" to={"/Campaign/Create?cloneTargetID=" + props.campaign._id}><img src={edit} height="14px" width="20px" className="me-2" /> Clone</Link> : <Link className="dropdown-item" onClick={event => props.class.props.setAlert({"display":"toast","visible":true,"title":"Error Cloning Campaign","content":"You have no campaign entitlements available. Please purchase more or speak with Customer Support to create more campaigns.","className":"bg-danger text-white"})}><img src={edit} height="14px" width="20px" className="me-2" /> Clone</Link> }
                    <Link className="dropdown-item"><img src={archive} height="14px" width="20px" className="me-2" /> Archive</Link>
                  </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col xs={12}>
              <div className="d-flex align-items-center justify-content-around text-wrap">
                <div className="text-center my-3 mx-1">
                  {props.campaign.active == true ? <Link to={"/Campaign/Manage?id=" + props.campaign._id + "&view=messages"} className="text-primary h3 position-relative">{props.campaign.campaignStats.unreadMessages} {props.campaign.campaignStats.unreadMessages > 0 && (<span className="notify"></span>)}</Link> : <span className="text-muted">-</span>}
                  <div className="text-muted smaller">Unread messages</div>
                </div>
                <div className="text-center my-3 mx-1">
                  {props.campaign.active == true ? <Link to={"/Campaign/Manage?id=" + props.campaign._id + "&view=bids"} className="text-primary h3 position-relative">{props.campaign.bidStats.total}</Link> : <span className="bg-white text-muted h3">-</span>}
                  <div className="text-muted smaller">Total bids</div>
                </div>
                <div className="text-center my-3 mx-1">
                  {props.campaign.active == true ? <Link to={"/Campaign/Manage?id=" + props.campaign._id + "&view=bids"} className="text-primary h3 position-relative">{props.campaign.bidStats.pending}</Link> : <span className="bg-white text-muted h3">-</span>}
                  <div className="text-muted smaller">Pending bids</div>
                </div>
                <div className="text-center my-3 mx-1">
                  {props.campaign.active == true ? <Link to={"/Campaign/Manage?id=" + props.campaign._id + "&view=collaborations"} className="text-primary h3">{props.campaign.bidStats.accepted}</Link> : <span className="bg-white text-muted h3">-</span>}
                  <div className="text-muted smaller">Accepted bids</div>
                </div>
                <div className="text-center my-3 mx-1">
                  {props.campaign.active == true & props.campaign.bidStats.accepted > 0 ? <Link to={"/Campaign/Manage?id=" + props.campaign._id + "&view=reporting"} className="text-primary h3">{props.campaign.bidStats.contentPosted}/{props.campaign.bidStats.accepted}</Link> : <span className="bg-white text-muted h3">-</span>}
                  <div className="text-muted smaller">Content posted</div>
                </div>
              </div>
            </Col>
            <Col xs={7} className="collaborators">
              {props.campaign.bidStats.accepted == 0 ? (<span className="text-muted small">No content submitted yet</span>) : (
                <>
                {props.campaign.bidStats.collaborators.slice(0,3).map(collaborator => <img key={collaborator.id} src={getImageUrl(props.class.props.providers,collaborator.profile,["w_80","h_80","c_scale"])} height="32px" className="border rounded me-1"></img>)}
                {props.campaign.bidStats.accepted == 4 && (
                  <img key={props.campaign.bidStats.collaborators[3].id} src={getImageUrl(props.class.props.providers,props.campaign.bidStats.collaborators[3].profile,["w_80","h_80","c_scale"])} height="32px" className="border rounded" />
                )}
                {props.campaign.bidStats.accepted > 4 && (
                  <span className="imageOverlay">
                    <span className="textOverlay rounded" onClick={() => props.class.props.setCollaboratorView({"visible":true,"collaborators":props.campaign.bidStats.collaborators})}>+{props.campaign.bidStats.accepted - 3}</span>
                    <img key={props.campaign.bidStats.collaborators[3].id} src={getImageUrl(props.class.props.providers,props.campaign.bidStats.collaborators[3].profile,["w_80","h_80","c_scale"])} height="32px" className="border rounded" />
                  </span>
                )}
                </>
              )}
              <div className="text-muted smaller mt-1">Collaborators</div>
            </Col>
            <Col xs={5} className="text-start">
            {props.campaign.active == true ? ( 
              <>
                {campaignStart.future && (
                <>
                  <Badge pill bg="warning" text="dark">Scheduled</Badge>
                  <div className="text-muted smaller">Starts in {campaignStart.difference} {campaignStart.unit}</div>
                </>
                )}
                {!campaignStart.future && campaignEnd.future && (
                <>
                  <Badge pill bg="success" text="dark">Live</Badge>
                  <div className="text-muted smaller">Expires in {campaignEnd.difference} {campaignEnd.unit}</div>
                </>
                )}
                {!campaignEnd.future && (
                <>
                  <Badge pill bg="danger" text="dark">Expired</Badge>
                  <div className="text-muted smaller">Expired {campaignEnd.difference} {campaignEnd.unit} ago</div>
                </>
                )}
              </>
              ): (
                <>
                  <Badge pill bg="danger" text="dark">Inactive</Badge>
                  <div className="text-muted smaller">Draft</div>
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  CampaignsSummary(props){
    const setState = async (event,result) => { 
      result = await result;
      for (var x in result["campaigns"]) {
        if (!props.class.state.campaignIDs.includes(result["campaigns"][x]["_id"])) {
          props.class.state.campaignIDs.push(result["campaigns"][x]["_id"])
          props.class.state.campaigns.push(result["campaigns"][x])
        }
      }
      props.class.setState({ totalCampaigns: event.pageData.total, brand : result["brand"], campaigns : props.class.state.campaigns, campaignIDs: props.class.state.campaignIDs }) 
    }

    return (
      <Container className="campaignWallTabs" fluid>
        <Container className="px-0 pt-md-2 h-100">
          <Row className="h-100">
            {props.class.props.collaboratorView.visible == true &&
              <Modal show centered onHide={() => props.class.props.setCollaboratorView({"visible":false,"collaborators":[]})}>
                <Modal.Header closeButton className="pe-4">
                  <Modal.Title>
                    Collaborators
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-0 viewCollaborators">
                  <div className="mb-3">
                    {props.class.props.collaboratorView.collaborators.map(collaborator => (
                      <img key={collaborator.id} src={getImageUrl(props.class.props.providers,collaborator.profile,["w_80","h_80","c_scale"])} title={collaborator.name} height="60px" className="border rounded me-1"></img>
                    ))}
                  </div>
                </Modal.Body>
              </Modal>
            }
            <div className="col-12 h-100">
              <Tab.Content className="h-100">
                <Tab.Pane eventKey="all" className="h-100">
                  <Row className="mx-0 px-md-3">
                    {props.campaigns.length > 0 ? (
                    <>
                    <Col xs={12} className="px-0 d-none d-md-block">
                      <Table className="campaignTable allTab">
                        <thead>
                          <tr>
                            <th className="ps-3">Campaigns ({props.class.state.totalCampaigns})</th>
                            <th className="text-center text-nowrap">Unread <span className="d-none d-xl-inline-block">messages</span></th>
                            <th className="text-center text-nowrap">Total <span className="d-none d-xl-inline-block">bids</span></th>
                            <th className="text-center text-nowrap">Pending <span className="d-none d-xl-inline-block">bids</span></th>
                            <th className="text-center text-nowrap">Accepted <span className="d-none d-xl-inline-block">bids</span></th>
                            <th className="text-center text-nowrap">Content <span className="d-none d-xl-inline-block">posted</span></th>
                            <th>Collaborators</th>
                            <th>Status</th>
                            <th className="pe-3"></th>
                          </tr>
                        </thead>
                        <tbody className="w-100">
                          {props.campaigns.filter(function(campaign) { return (!campaign.archived)}).sort((a,b) => { return a.startDate < b.startDate ? 1 : -1 }).map(campaign => <props.class.CampaignSummary key={campaign._id} campaign={campaign} state={"all"} class={props.class} />)}
                        </tbody>
                      </Table>
                    </Col>
                    <Col xs={12} className="d-block d-md-none px-0">
                      {props.campaigns.filter(function(campaign) { return (!campaign.archived)}).sort((a,b) => { return a.startDate < b.startDate ? 1 : -1 }).map(campaign => <props.class.CampaignSummaryMobile key={campaign._id} campaign={campaign} state={"all"} class={props.class} />)}
                    </Col>
                    </>
                    ) : (
                    <div className="me-4 pe-3 text-muted my-5" style={{textAlign: "center"}}>You haven't got any campaigns yet! Why not create one?</div>
                    )}
                  </Row>
                  <PageScroller endpoint="myCampaigns?view=all" responseLists={["brand","campaigns"]} setState={setState} />
                </Tab.Pane>
                <Tab.Pane eventKey="live" className="h-100">
                  <Row className="mx-0 px-md-3">
                    {props.campaigns.filter(function(campaign) { return (campaign.active && !getDifference(campaign.startDate).future && getDifference(campaign.endDate).future) }).length > 0 ? (
                    <>
                    <Col xs={12} className="px-0 d-none d-md-block">
                      <Table className="campaignTable liveTab">
                        <thead>
                          <tr>
                            <th className="ps-3">Campaigns ({props.campaigns.filter(function(campaign) { return (campaign.active && !getDifference(campaign.startDate).future && getDifference(campaign.endDate).future) }).length})</th>
                            <th className="text-center text-nowrap">Unread <span className="d-none d-xl-inline-block">messages</span></th>
                            <th className="text-center text-nowrap">Total <span className="d-none d-xl-inline-block">bids</span></th>
                            <th className="text-center text-nowrap">Pending <span className="d-none d-xl-inline-block">bids</span></th>
                            <th className="text-center text-nowrap">Accepted <span className="d-none d-xl-inline-block">bids</span></th>
                            <th className="text-center text-nowrap">Content <span className="d-none d-xl-inline-block">posted</span></th>
                            <th>Collaborators</th>
                            <th>Status</th>
                            <th className="pe-3"></th>
                          </tr>
                        </thead>
                        <tbody className="w-100">
                          {props.campaigns.filter(function(campaign) { return (campaign.active && !getDifference(campaign.startDate).future && getDifference(campaign.endDate).future) }).sort((a,b) => { return a.startDate < b.startDate ? 1 : -1 }).map(campaign => <props.class.CampaignSummary key={campaign._id} campaign={campaign} state={"live"} class={props.class} />)}
                        </tbody>
                      </Table>
                    </Col>
                    <Col xs={12} className="d-block d-md-none px-0">
                      {props.campaigns.filter(function(campaign) { return (campaign.active && !getDifference(campaign.startDate).future && getDifference(campaign.endDate).future) }).sort((a,b) => { return a.startDate < b.startDate ? 1 : -1 }).map(campaign => <props.class.CampaignSummaryMobile key={campaign._id} campaign={campaign} state={"liv"} class={props.class} />)}
                    </Col>
                    </>
                    ) : (
                    <div className="me-4 pe-3 text-muted my-5" style={{textAlign: "center"}}>No live campaigns to show</div>
                    )}
                  </Row>
                  <PageScroller endpoint="myCampaigns?view=view" setState={setState} />
                </Tab.Pane>
                <Tab.Pane eventKey="expired" className="h-100">
                  <Row className="mx-0 px-md-3">
                    {props.campaigns.filter(function(campaign) { return (campaign.active && !getDifference(campaign.startDate).future && !getDifference(campaign.endDate).future) }).length > 0 ? (
                    <>
                    <Col xs={12} className="px-0 d-none d-md-block">
                      <Table className="campaignTable expiredTab">
                      <thead>
                          <tr>
                            <th className="ps-3">Campaigns ({props.campaigns.filter(function(campaign) { return (campaign.active && !getDifference(campaign.startDate).future && !getDifference(campaign.endDate).future) }).length})</th>
                            <th className="text-center text-nowrap">Unread <span className="d-none d-xl-inline-block">messages</span></th>
                            <th className="text-center text-nowrap">Total <span className="d-none d-xl-inline-block">bids</span></th>
                            <th className="text-center text-nowrap">Pending <span className="d-none d-xl-inline-block">bids</span></th>
                            <th className="text-center text-nowrap">Accepted <span className="d-none d-xl-inline-block">bids</span></th>
                            <th className="text-center text-nowrap">Content <span className="d-none d-xl-inline-block">posted</span></th>
                            <th>Collaborators</th>
                            <th>Status</th>
                            <th className="pe-3"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.campaigns.filter(function(campaign) { return (campaign.active && !getDifference(campaign.startDate).future && !getDifference(campaign.endDate).future) }).sort((a,b) => { return a.startDate < b.startDate ? 1 : -1 }).map(campaign => <props.class.CampaignSummary key={campaign._id} campaign={campaign} state={"expired"} class={props.class} />)}
                        </tbody>
                      </Table>
                    </Col>
                    <Col xs={12} className="d-block d-md-none px-0">
                      {props.campaigns.filter(function(campaign) { return (campaign.active && !getDifference(campaign.startDate).future && !getDifference(campaign.endDate).future) }).sort((a,b) => { return a.startDate < b.startDate ? 1 : -1 }).map(campaign => <props.class.CampaignSummaryMobile key={campaign._id} campaign={campaign} state={"expired"} class={props.class} />)}
                    </Col>
                    </>
                    ) : (
                    <div className="me-4 pe-3 text-muted my-5" style={{textAlign: "center"}}>No expired campaigns to show</div>
                    )}
                  </Row>
                  <PageScroller endpoint="myCampaigns?view=expired" responseLists={["brand","campaigns"]} setState={setState} />
                </Tab.Pane>
                <Tab.Pane eventKey="scheduled" className="h-100">
                  <Row className="mx-0 px-md-3">
                    {props.campaigns.filter(function(campaign) { return (campaign.active && getDifference(campaign.startDate).future && getDifference(campaign.endDate)) }).length > 0 ? (
                      <>
                    <Col xs={12} className="px-0 d-none d-md-block">
                      <Table className="campaignTable">
                      <thead>
                          <tr>
                            <th className="ps-3">Campaigns ({props.campaigns.filter(function(campaign) { return (campaign.active && getDifference(campaign.startDate).future && getDifference(campaign.endDate).future) }).length})</th>
                            <th>Status</th>
                            <th className="pe-3"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.campaigns.filter(function(campaign) { return (campaign.active && getDifference(campaign.startDate).future && getDifference(campaign.endDate).future) }).sort((a,b) => { return a.startDate < b.startDate ? 1 : -1 }).map(campaign => <props.class.CampaignSummary key={campaign._id} campaign={campaign} state={"scheduled"} class={props.class} />)}
                        </tbody>
                      </Table>
                    </Col>
                    <Col xs={12} className="d-block d-md-none px-0">
                      {props.campaigns.filter(function(campaign) { return (campaign.active && getDifference(campaign.startDate).future && getDifference(campaign.endDate).future) }).sort((a,b) => { return a.startDate < b.startDate ? 1 : -1 }).map(campaign => <props.class.CampaignSummaryMobile key={campaign._id} campaign={campaign} state={"scheduled"} class={props.class} />)}
                    </Col>
                    </>
                    ) : (
                    <div className="me-4 pe-3 text-muted my-5" style={{textAlign: "center"}}>No scheduled campaigns to show</div>
                    )}
                  </Row>
                  <PageScroller endpoint="myCampaigns?view=scheduled" responseLists={["brand","campaigns"]} setState={setState} />
                </Tab.Pane>
                <Tab.Pane eventKey="drafts" className="h-100">
                  <Row className="mx-0 px-md-3">
                    {props.campaigns.filter(function(campaign) { return (!campaign.active && !campaign.archived) }).length > 0 ? (
                    <>
                    <Col xs={12} className="px-0 d-none d-md-block">
                      <Table className="campaignTable">
                      <thead>
                          <tr>
                            <th className="ps-3">Campaigns ({props.campaigns.filter(function(campaign) { return (!campaign.active && !campaign.archived) }).length})</th>
                            <th>Status</th>
                            <th className="pe-3"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.campaigns.filter(function(campaign) { return (!campaign.active && !campaign.archived) }).sort((a,b) => { return a.startDate < b.startDate ? 1 : -1 }).map(campaign => <props.class.CampaignSummary key={campaign._id} campaign={campaign} state={"drafts"} class={props.class} />)}
                        </tbody>
                      </Table>
                    </Col>
                    <Col xs={12} className="d-block d-md-none px-0">
                      {props.campaigns.filter(function(campaign) { return (!campaign.active && !campaign.archived) }).sort((a,b) => { return a.startDate < b.startDate ? 1 : -1 }).map(campaign => <props.class.CampaignSummaryMobile key={campaign._id} campaign={campaign} class={props.class} />)}
                    </Col>
                    </>
                    ) : (
                    <div className="me-4 pe-3 text-muted my-5" style={{textAlign: "center"}}>No draft campaigns to show</div>
                    )}
                  </Row>
                  <PageScroller endpoint="myCampaigns?view=drafts" responseLists={["brand","campaigns"]} setState={setState} />
                </Tab.Pane>
                <Tab.Pane eventKey="archive" className="h-100">
                  <Row className="mx-0 px-md-3">
                    {props.campaigns.filter(function(campaign) { return (campaign.archived) }).length > 0 ? (
                    <>
                    <Col xs={12} className="px-0 d-none d-md-block">
                      <Table className="campaignTable archiveTab">
                        <thead>
                          <tr>
                            <th className="ps-3">Campaigns ({props.campaigns.filter(function(campaign) { return (campaign.archived) }).length})</th>
                            <th className="text-center text-nowrap">Unread <span className="d-none d-xl-inline-block">messages</span></th>
                            <th className="text-center text-nowrap">Total <span className="d-none d-xl-inline-block">bids</span></th>
                            <th className="text-center text-nowrap">Pending <span className="d-none d-xl-inline-block">bids</span></th>
                            <th className="text-center text-nowrap">Accepted <span className="d-none d-xl-inline-block">bids</span></th>
                            <th className="text-center text-nowrap">Content <span className="d-none d-xl-inline-block">posted</span></th>
                            <th>Collaborators</th>
                            <th>Status</th>
                            <th className="pe-3"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.campaigns.filter(function(campaign) { return (campaign.archived) }).sort((a,b) => { return a.startDate < b.startDate ? 1 : -1 }).map(campaign => <props.class.CampaignSummary key={campaign._id} campaign={campaign} state={"archive"} class={props.class} />)}
                        </tbody>
                      </Table>
                    </Col>
                    <Col xs={12} className="d-block d-md-none px-0">
                      {props.campaigns.filter(function(campaign) { return (campaign.archived) }).sort((a,b) => { return a.startDate < b.startDate ? 1 : -1 }).map(campaign => <props.class.CampaignSummaryMobile key={campaign._id} campaign={campaign} state={"archive"} class={props.class} />)}
                    </Col>
                    </>
                    ) : (
                    <div className="me-4 pe-3 text-muted my-5" style={{textAlign: "center"}}>No archived campaigns to show</div>
                    )}
                  </Row>
                  <PageScroller endpoint="myCampaigns?view=archive" responseLists={["brand","campaigns"]} setState={setState} />
                </Tab.Pane>
                {/* <Tab.Pane eventKey="notes">
                  <Row className="mt-4 notes">
                    <Col xs={12} sm={4} className="pe-0 position-relative">
                      <div className="position-absolute end-0 bottom-0 mb-3 me-3 z-index-9">
                        <button className="btn btn-sm btn-success shadow" onClick={() => props.class.props.setShowNotesModal({"visible":true})}>Add Note</button>
                      </div>
                      <div className="bg-white rounded-start border border-end-0 notesCardList pb-5">
                        <div className="notesSearch pt-3 pt-md-0">
                          <InputGroup className="py-1">
                            <InputGroup.Text className="bg-white border-0">
                              <img src={Search} />
                            </InputGroup.Text>
                            <Form.Control placeholder="Search" className="border-0" />
                          </InputGroup>
                        </div>
                        {props.notes.map(note => (
                          <div key={note._id} className="notesCard" onClick={() => props.class.selectNote(note)}>
                            <div className="d-flex flex-column text-truncate">
                              <div className="fw-bold text-truncate">{note.title}</div>
                              <span className="text-muted small"><span className="fw-bold me-2">{note.owner.name}</span>Owner</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Col>
                    <Col xs={12} sm={8} className="ps-0">
                      <div className="rounded-end border h-100 position-relative notesEditor">
                        {props.class.props.selectedNote ? (
                          <>
                          <div className="bg-gray rounded-end position-absolute w-100 top-0 start-0 z-index-9">
                            <div className="notesCard border-bottom position-relative">
                              <div className="d-flex flex-column text-truncate">
                                <div className="fw-bold text-truncate">{props.class.props.selectedNote.title}</div>
                                <span className="text-muted small"><span className="fw-bold me-2">{props.class.props.selectedNote.owner.name}</span>Owner</span>
                              </div>
                              <div className="position-absolute end-0">
                                <a className="me-3 p-2" onClick={() => props.class.props.setShowNotesModal({"visible":true})}><img src={Edit} /></a>
                                <a className="me-3 p-2"><img src={Delete} /></a>
                              </div>
                            </div>
                          </div>
                          <div className="bg-transparent w-100 h-100 pt-5 position-relative">
                            <textarea placeholder="Add text here.." className="border-0 py-5 pe-5 ps-3 form-control h-100" value={props.class.props.noteContent} onChange={(e) => props.class.props.setNoteContent(e.target.value)}/>
                            <div className="position-absolute end-0 bottom-0 me-3 mb-3">
                              <button className="btn btn-sm btn-primary shadow">Save</button>
                            </div>
                          </div>
                          </>
                        ) : (
                          <span>No notes created! Why not create one?</span>
                        )}
                      </div>
                    </Col>
                    
                  </Row>
                </Tab.Pane> */}
              </Tab.Content>
            </div>
          </Row>
        </Container>
      </Container>
    )
  }

  render() {
    const { error, isLoaded, campaigns, brand, notes } = this.state;
    const handleArchiveClose = () => this.props.setShowArchiveModal({"visible":false,"campaign":null});

    const changeTab = (tabName) => {
      let currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set('view', tabName);
      let newUrl = window.location.origin + window.location.pathname + '?' + currentUrlParams.toString();
      window.history.replaceState({path:newUrl},'',newUrl);
    }

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <FullPageSpinner />;
    } else {
      return (
        <Container className="fullscreenWithTopBar px-0 campaignWall" fluid>
        <this.ArchiveModal class={this} show={this.props.showArchiveModal} handleClose={handleArchiveClose} />
        {/* <this.NotesModal class={this} /> */}
        <Tab.Container id="campaigns-tab" activeKey={this.props.tabID} onSelect={(key) => this.props.setTabID(key)}>
          <Container className="pt-4 px-0 bg-white d-block d-md-none">
            <div className="mb-2 py-2 text-center">
              <div className="sectionTitle">My Campaigns</div>
            </div>
            <Nav variant="tabs" className="flex-nowrap px-3" id="nav-tabMobile">
              <Nav.Item>
                <Nav.Link eventKey="all" onClick={()=>changeTab("all")}>All</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="live" onClick={()=>changeTab("live")}>Live</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="expired" onClick={()=>changeTab("expired")}>Expired</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="scheduled" onClick={()=>changeTab("scheduled")}>Scheduled</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="drafts" onClick={()=>changeTab("drafts")}>Drafts</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="archive" onClick={()=>changeTab("archive")}>Archive</Nav.Link>
              </Nav.Item>
            </Nav>        
          </Container>
          <this.CampaignsHeader brand={brand}/>

          <this.CampaignsSummary campaigns={campaigns} notes={notes} class={this}/>
        </Tab.Container>
      </Container>
      );
    }
  }
}

export default function MyCampaigns() {
  let [searchParams] = useSearchParams();
  var view = searchParams.get('view');
  if (view == null){
    view = "all";
  }
  const [tabID, setTabID] = useState(view);
  const [showArchiveModal, setShowArchiveModal] = useState({"visible":false,"campaign":null});
  const [showNotesModal, setShowNotesModal] = useState({"visible":false,"title":"","content":""});
  const [selectedNote, setSelectedNote] = useState(null)
  const [noteContent, setNoteContent] = useState("")
  const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
  const hideAlert = () => setAlert({"display":"toast","visible":false,"title":"","content":""});
  const { providers } = useImageContext();
  const { currentProfile, session } = useAuthContext();
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const [ collaboratorView, setCollaboratorView ] = useState({"visible":false,"collaborators":[]})
 
  return (
    <div className="bg-page campaignsBrandFlow">
      <Topbar />
      <div className="pageName">My Campaigns</div>
      <AlertModal modal={showAlert} onClose={hideAlert} />
      <CampaignsView tabID={tabID} setTabID={setTabID} showArchiveModal={showArchiveModal} setShowArchiveModal={setShowArchiveModal} setAlert={setAlert} providers={providers} showNotesModal={showNotesModal} setShowNotesModal={setShowNotesModal} selectedNote={selectedNote} setSelectedNote={setSelectedNote} noteContent={noteContent} setNoteContent={setNoteContent} currentProfile={currentProfile} session={session} setIsSubmitting={setIsSubmitting} isSubmitting={isSubmitting} collaboratorView={collaboratorView} setCollaboratorView={setCollaboratorView} />
      <Footer />
    </div>
  );
}