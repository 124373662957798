import React, { useState } from "react";
import { Container, Row, Accordion } from 'react-bootstrap';
import AdminNav from '../../components/adminnav/AdminNav';
import { apiCall } from "../../helpers/api";
import AlertModal from "../../components/alerts/alert";

import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import Topbar from "../../components/topbar/Topbar";

export default function AdminSettings() {  

  const [settingsData, setSettingsData] = useState([]);
  const [change, setChange] = useState(0)
  const [isLoaded, setIsLoaded] = useState(false);
  const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
  if (settingsData.length == 0){
    getSettings()
  }

  async function getSettings() {
    var response = await apiCall('admin/settings',{ "method" : "GET" });
    if (response["success"]){
        setSettingsData(response["success"]["settings"])
        setIsLoaded(true)
        setChange(change+1)
    } 
    setIsLoaded(true)
  }

  async function submit(event) {
    event.preventDefault();
    var updatedSettings = settingsData.filter(function(setting) {return setting.updated })
    updatedSettings.forEach(setting => {
      delete setting.updated;
    })
    // Validate data
    var valid = true;
    if (valid){
        var response = await apiCall('admin/settings',{ "method" : "PUT", "data" : updatedSettings });
        if (response["success"]){
            getSettings();
            setAlert({"display":"toast","visible":true,"title":"Settings Saved","content":"Settings have been updated","className":"bg-success text-white"})
        }
        else {
          setAlert({"display":"toast","visible":true,"title":"Failed to Save Settings","content":response["failure"]["error"],"className":"bg-danger text-white"})
        }
    }
  }

  function updateSettingsData(props, targetName, targetKey) {
    var tempSettingData = settingsData;
    tempSettingData.forEach(setting => {
      if (setting.name == targetName){
        if ( props.target.type == "checkbox" ) {
          setting["settings"][targetKey] = props.target.checked;
        } 
        else {
          setting["settings"][targetKey] = props.target.value;
        }
        if (!setting.updated){
          setting.updated = true;
        }
      }
    })
    setSettingsData(tempSettingData);
    setChange(change+1)
}

console.log(settingsData.filter(setting => setting.updated))

  return (
    <div className="bg-page dashboard adminSettings">
    <Topbar />
    <div className="pageName">Administrative Settings</div>
    <Container id="applicant-list-container" className="fullscreenWithTopBar top-0" fluid>
      <Row>
        <AdminNav />
        <div className="col-xl-12 col-xxl-10 px-0">
          <div className="px-4 py-3 py-xl-4 bg-white shadow-sm">
            <div className="d-flex align-items-center justify-content-between mx-xl-4">
              <h1 className="pageTitle text-nowrap text-primary tragedyRegularFont fw-700 mb-0 fs-3">Administrative Settings</h1>
              <button type='submit' className="link btn btn-sm btn-primary" disabled={settingsData.filter(setting => setting.updated).length == 0} onClick={submit}>Update</button>
              </div>
            </div>
            <AlertModal modal={showAlert} onClose={() => setAlert({"display":"toast","visible":false,"title":"","content":""})} />
            
            { !isLoaded ? <FullPageSpinner /> : (
            <>          
              <div className="px-4 pt-3 pt-lg-4 mx-xl-4 settingsArea">
                <Accordion className="row" flush defaultActiveKey={['0','1']} alwaysOpen>
                  <div className="col-md-6 col-lg-6 col-xl-6 pb-3">
                    <Accordion.Item eventKey="0" className="shadow">
                      <Accordion.Header>Global Settings</Accordion.Header>
                      <Accordion.Body className="px-3">
                      {settingsData.filter(function(setting) {return setting.scope == "global"}).map((setting) => (
                        <div key={setting.name} className="border-bottom mb-4">
                          <h5 className="text-capitalize">{setting.name}</h5>
                          <div className="mt-3">
                            {Object.keys(setting.settings).map((settingName) => (
                              <div className="form-group mb-3" key={setting.name+"-"+settingName}>
                                <label className="text-capitalize">{settingName}</label>
                                {typeof(setting.settings[settingName]) == "boolean" && (
                                  <input type="checkbox" disabled={setting.readOnly} className="form-check-input" id={settingName} checked={setting.settings[settingName]} onChange={e => updateSettingsData(e,setting.name,settingName)} />
                                )}
                                {["string","number"].includes(typeof(setting.settings[settingName])) && (
                                  <input type={setting.sensitiveFields.includes(settingName) ? "password" : "text"} disabled={setting.readOnly} className="form-control" id={settingName} value={setting.settings[settingName]} onChange={e => updateSettingsData(e,setting.name,settingName)} />
                                )}
                                {typeof(setting.settings[settingName]) == "object" && ( 
                                  <textarea type="text" rows={5} cols={50} disabled={setting.readOnly} className="form-control" id={settingName} value={JSON.stringify(setting.settings[settingName],null,4)} onChange={e => updateSettingsData(e,setting.name,settingName)} />
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                  <div className="col-md-6 col-lg-6 col-xl-6 pb-3">
                    <Accordion.Item eventKey="1" className="shadow">
                      <Accordion.Header>Integration Settings</Accordion.Header>
                      <Accordion.Body className="px-3">
                      {settingsData.filter(function(setting) {return setting.scope == "integration"}).map((setting) => (
                        <div key={setting.name} className="border-bottom mb-4">
                          <h5 className="text-capitalize">{setting.name}</h5>
                          <div className="mt-3">
                            {Object.keys(setting.settings).map((settingName) => (
                              <div className="form-group mb-3" key={setting.name+"-"+settingName}>
                                <label className="text-capitalize">{settingName}</label>
                                {typeof(setting.settings[settingName]) == "boolean" && (
                                  <input type="checkbox" disabled={setting.readOnly} className="form-check-input" id={settingName} checked={setting.settings[settingName]} onChange={e => updateSettingsData(e,setting.name,settingName)} />
                                )}
                                {["string","number"].includes(typeof(setting.settings[settingName])) && (
                                  <input type={setting.hasOwnProperty("sensitiveFields") && setting.sensitiveFields.includes(settingName) ? "password" : "text"} disabled={setting.readOnly} className="form-control" id={settingName} value={setting.settings[settingName]} onChange={e => updateSettingsData(e,setting.name,settingName)} />
                                )}
                                {typeof(setting.settings[settingName]) == "object" && ( 
                                  <textarea type="text" rows={5} cols={50} disabled={setting.readOnly} className="form-control" id={settingName} value={JSON.stringify(setting.settings[settingName],null,4)} onChange={e => updateSettingsData(e,setting.name,settingName)} />
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                </Accordion>
              </div>
            </>
            ) }

          </div>
        </Row>
      </Container>
    </div>
  );
}