import React, { useState, useEffect } from "react";
import { Container, Row, Form, InputGroup, Table, Dropdown } from 'react-bootstrap';
import AdminNav from '../../components/adminnav/AdminNav';
import { apiCall, PageScroller } from "../../helpers/api";
import AlertModal from "../../components/alerts/alert";
import { getFilter, setFilter } from './../../helpers/filters'
import { relativeTimeFormat } from "../../helpers/dates";
import { Link } from "react-router-dom";

import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import Topbar from "../../components/topbar/Topbar";
import searchBlue from './../../images/search-blue.svg'
import settings from './../../images/settings.svg';
import './Referrals.css';

export default function Referrals() {  

  const [ isLoaded, setIsLoaded ] = useState(true);
  const [ loadingButton, setLoadingButton ] = useState(null);
  const [ referrals, setReferrals ] = useState([])
  const [ referralFilters, setReferralFilters ] = useState({"search":""})
  const [ newReferral, setNewReferral ] = useState({"title":"","code":""})
  const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
  const [pageStats, setPageStats] = useState({})

  const updateNewReferral = (type,value) => {
    var tempReferral = newReferral
    tempReferral[type] = value
    setNewReferral(structuredClone(tempReferral))
  }

  const createReferral = async () => {
    setLoadingButton("create")
    var response = await apiCall("admin/referral",{ "method" : "POST", "data" : {"title":newReferral.title.trim(),"code":newReferral.code.trim()} });
    if (response["success"]){
      setAlert({"display":"toast","visible":true,"title":"Added Referral Code","content":"Referral code added","className":"bg-success text-white"})
      referrals.unshift(response["success"]["referral"])
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to Add Referral Code","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
    setLoadingButton(null)
  };

  const deleteReferralID = async (id) => {
    setLoadingButton("delete")
    var response = await apiCall(`admin/referral?id=${id}`,{ "method" : "DELETE" });
    if (response["success"]){
      setAlert({"display":"toast","visible":true,"title":"Deleted Referral Code","content":"Referral code deleted","className":"bg-success text-white"})
      var tempReferral = referrals.filter(function(referral) {return referral._id != id})
      setReferrals(structuredClone(tempReferral))
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to Delete Referral Code","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
    setLoadingButton(null)
  };

  const generateReferralCode = async () => {
    setLoadingButton("generate")
    var response = await apiCall("admin/referral/generate",{ "method" : "GET" });
    if (response["success"]){
      updateNewReferral("code",response["success"]["code"])
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to generate code","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
    setLoadingButton(null)
  }

  const checkReferralAvailability = async () => {
    setLoadingButton("availablity")
    var response = await apiCall(`admin/referrals?match=${newReferral.code.trim()}`,{ "method" : "GET" });
    if (response["success"]){
      if (!response["success"]["match"]) {
        setAlert({"display":"toast","visible":true,"title":"Referral Code Available","content":"This code is available to claim","className":"bg-success text-white"})
      }
      else {
        setAlert({"display":"toast","visible":true,"title":"Referral Code Not Available","content":"This code is already in use","className":"bg-danger text-white"})
      }
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to generate code","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
    setLoadingButton(null)
  }

  const handleFilterUpdate = (value,type,delay=0) => {
    var filters = referralFilters;
    if (type == "search") {
      filters.search = value.target.value.toLowerCase();
    }
    setReferralFilters(structuredClone(filters));
    setFilter(filters,"referral")
  }

  const setState = async (event,result) => { 
    result = await result;
    if (event.pageData.page >= 1) {
        for (var x in result["referrals"]) {
          referrals.push(result["referrals"][x])  
        }
        setReferrals(referrals)
    } else {
      setReferrals(result["referrals"])
    }
    setPageStats(result["_paging"])
  }

  useEffect(() => {
    if (getFilter("referral")) {
      setReferralFilters(JSON.parse(getFilter("referral")))
    }
  }, []);

  return (
    <div className="bg-page referFriend">
      <Topbar />
      <div className="pageName">Referrals</div>
      <Container id="applicant-list-container" className="fullscreenWithTopBar top-0" fluid>
      <AlertModal modal={showAlert} onClose={() => setAlert({"display":"toast","visible":false,"title":"","content":""})} />
        <Row>
          <AdminNav />
          <div className="col-xl-12 col-xxl-10 px-0">
            <div className="ps-4 py-3 py-xl-4 pe-0 bg-white shadow-sm">
              <div className="d-flex align-items-center justify-content-between ms-xl-4">
                <h1 className="pageTitle text-nowrap text-primary tragedyRegularFont fw-700 mb-0 fs-3">Referrals</h1>
                <div className="form-group d-flex">
                  <InputGroup size="sm" className="me-3 d-none d-md-flex">
                    <Form.Control placeholder="Search" className="border-end-0 form-control-sm" aria-label="Search" defaultValue={referralFilters.search} onChange={e => handleFilterUpdate(e,"search",500)} />
                    <InputGroup.Text className="bg-white border-end">
                        <a><img src={searchBlue} /></a>
                    </InputGroup.Text>
                  </InputGroup>
                  <div className="bg-gray pb-1 pt-1 ps-2 pe-3 rounded-start text-nowrap pageDataCount">
                      <span className="fw-500 me-1">Total: </span><span>{pageStats.total} Codes</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="referralCodeList">
              <div className="px-4 pt-3 pt-xl-4 mx-xl-4">
                <div className="row pb-3 pb-xl-4 border-bottom border-light">
                  <div className="col-12">
                    <div className="d-flex align-items-center">                
                      <div className="form-group me-2">
                        <label>Title</label>
                        <input className="form-control border" value={newReferral.title} placeholder="Add Title" onChange={(e) => updateNewReferral("title",e.target.value)} />
                      </div>
                      <div className="form-group me-2">
                        <label>Custom Code</label>
                        <InputGroup>
                          <Form.Control placeholder="Enter Code" className="border border-primary border-end-0" value={newReferral.code} onChange={(e) => updateNewReferral("code",e.target.value)} />
                          <div className="input-group-text btn btn-outline-primary fw-bold" disabled={loadingButton == "availability"} onClick={() => checkReferralAvailability() }>Check Availability</div>
                        </InputGroup>
                      </div>
                      <div className="fw-500 me-2 mt-4">OR</div>
                      <div className="form-group me-4">
                        <label>Generic Code</label>
                        <button type="button" className="btn btn-outline-primary fw-bold" disabled={loadingButton == "generate"} onClick={() => generateReferralCode()}>Generate</button>
                      </div>      

                      <button type="button" className="btn btn-primary border me-2 mt-4" disabled={loadingButton == "create"} onClick={() => createReferral()}>Create Referral Code</button>
                    </div>                    
                  </div>
                </div>                
              </div>
              <div className="px-4 w-auto mt-3 referCodeTable">
                <div className="mx-xl-4">
                  <div className="tableHeaderFixedBelt"></div>
                  <Table className="w-100 border-transparent">
                      <thead>
                        <tr>
                          <th className="ps-3">Title</th>
                          <th className="ps-3">Code</th>
                          <th className="ps-3">Owner</th>
                          <th className="text-center">Active</th>
                          <th className="text-center">Created</th>
                          <th className="text-center">Associated Users</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody className="text-nowrap"> 
                        { referrals.map(referral => (
                            <tr key={referral._id}>
                            <td className="ps-3">{referral.title}</td>
                            <td className="ps-3">{referral.code}</td>
                            <td className="ps-3">{referral.owner}</td>
                            <td className="text-center">{referral.active ? "Yes" : "No"}</td>
                            <td className="text-center">{relativeTimeFormat(referral.created*1000)}</td>
                            <td className="text-center">{referral.count > 0 ? <a href={`/Administration/Influencers?influencerFilter=%7B%22states%22%3A%5B%5D%2C%22interests%22%3A%5B%5D%2C%22genders%22%3A%5B%5D%2C%22ages%22%3A%5B%5D%2C%22socials%22%3A%5B%5D%2C%22referred%22%3A%5Btrue%5D%2C%22followers%22%3A0%2C%22engagementRate%22%3A0%2C%22search%22%3A%22${referral._id}%22%2C%22countries%22%3A%5B%5D%2C%22counties%22%3A%5B%5D%2C%22statesUS%22%3A%5B%5D%7D`} target="_blank">{referral.count}</a> : 0}</td>
                            <td className="text-center">
                              <div className="align-items-center justify-content-between">
                                <Dropdown className="brandAction">
                                  <Dropdown.Toggle variant="default" className="border-0">
                                    <img src={settings} />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {/* <Link to={`/Administration/Influencers/Settings?id=${influencer._id}`} className="dropdown-item">Edit</Link> */}
                                    <Link className="dropdown-item text-danger" disabled={loadingButton == "delete"} onClick={() => deleteReferralID(referral._id)}>Delete</Link>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                  </Table>
                </div>
                <PageScroller endpoint="admin/referrals" setState={setState} filter={referralFilters} pageSize={100} />
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
}