import React, { useState } from "react";
import { Container, Col, Row, Modal, Button, Dropdown } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { apiCall, PageScroller } from "../../helpers/api";
import { useImageContext } from "../../hooks/useImageContext";
import { getImageUrl } from "../../helpers/images";
import { getSocialBadge } from "../../helpers/socials";
import { getSmallNumber } from "../../helpers/stats";

import blueTick from './../../images/blue-tick.svg';

import AlertModal from "../../components/alerts/alert";
import './Brands';
import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";

export default function BrandCommunity() {
    const [brand, setBrand] = useState([])
    const [members, setMembers] = useState([])
    const [allNewMembers, setAllNewMembers] = useState([])
    const [newMembers, setNewMembers] = useState([])
    const [removeMember, setRemoveMember] = useState(null);
    const { providers } = useImageContext();
    const [showNewMemberPopup, setShowNewMemberPopup] = useState(false);
    const [showRemoveMemberModal, setShowRemoveMemberModal] = useState(false);
    const [pageStats, setPageStats] = useState({})
    const [refreshCounter, setRefreshCounter] = useState(0)

    const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
    const hideAlert = () => setAlert({"display":"toast","visible":false,"title":"","content":""});

    const setState = async (event,result) => { 
        result = await result;
        if (event.pageData.page >= 1) {
            for (var x in result["communityMembers"]) {
            members.push(result["communityMembers"][x])  
            }
            setMembers(members)
        } 
        else {
            setMembers(result["communityMembers"])
        }
        setPageStats(result["_paging"])
        setBrand(result["brand"])
    }

    const updateAllNewMembers = async (event,result) => { 
        result = await result;
        if (event.pageData.page >= 1) {
            for (var x in result["newCommunityMembers"]) {
                allNewMembers.push(result["newCommunityMembers"][x])  
            }
            setAllNewMembers([...allNewMembers])
        } else {
            setAllNewMembers(result["newCommunityMembers"])
        }
    }

    function updateNewMembers(member,add) {
        var tempNewMembers = newMembers
        if (add){
            tempNewMembers.push(member)
            setNewMembers(tempNewMembers)
        }
        else {
            tempNewMembers = newMembers.filter(existingMember => member._id !== existingMember._id)
            setNewMembers(tempNewMembers)
        }
        setRefreshCounter(refreshCounter + 1)
    }

    async function removeCommunityMember(){
        var response = await apiCall(`community/removeMember`,{ "method" : "PUT", "data" : {"influencerID":removeMember._id}});
        if (response["success"]){
            setAlert({"display":"toast","visible":true,"title":"Community member removed","content":`Member has been removed sucessfully`,"className":"bg-success text-white"})
            setRemoveMember(null)
        }
        else if (response["failure"]) {
            setAlert({"display":"toast","visible":true,"title":"Could not remove community member","content":response["failure"]["error"],"className":"bg-danger text-white"})
            setRemoveMember(null)
        }
    }

    async function submitUpdates(allMembers=false){
        var response = await apiCall(`community/updatePendingMembers`,{ "method" : "PUT", "data" : {"newMembers": allMembers ? allNewMembers : newMembers}});
        if (response["success"]){
            setAlert({"display":"toast","visible":true,"title":"Community Updated","content":`Community invites sent to selected members successfully`,"className":"bg-success text-white"})
            setShowNewMemberPopup(false); 
            setAllNewMembers([]); 
            setNewMembers([]);
        }
        else if (response["failure"]) {
            setAlert({"display":"toast","visible":true,"title":"Could not update community","content":response["failure"]["error"],"className":"bg-danger text-white"})
            setShowNewMemberPopup(false); 
            setAllNewMembers([]); 
            setNewMembers([]);
        }
    }
 
    return (
    <div className="bg-page campaignsBrandFlow">
        <Topbar />
        <div className="pageName">My Community</div>
        <Container className="fullscreenWithTopBar px-0 campaignWall" fluid>
            <AlertModal modal={showAlert} onClose={hideAlert} />
            {showRemoveMemberModal && (
              <Modal show backdrop="static" centered size="lg" >
              <Modal.Header className="pe-4">
                <Modal.Title className="h5">
                  Are you sure you want to remove this community member?
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="pb-0">
                <Row className="ms-1">
                  Member name: {removeMember.name}
                </Row>
              </Modal.Body>
              <Modal.Footer className="border-0 pt-0">
                <Button variant="outline-secondary" size="sm" onClick={()=>{setRemoveMember(null);setShowRemoveMemberModal(false)}}>Cancel</Button>
                <Button onClick={() => {removeCommunityMember();setShowRemoveMemberModal(false)}} variant="danger" size="sm">Remove</Button>
              </Modal.Footer>
            </Modal>
            )}
            {showNewMemberPopup && (
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show centered >
                    <Modal.Header className="pe-4">
                        <Modal.Title className="h5" id="contained-modal-title-vcenter">
                            Add New Member
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mx-4">
                            <table className="border-transparent table">
                                <thead className="small">
                                <tr>
                                    <th>Name</th>
                                    <th>Primary Category</th>
                                    <th className="text-center">Active Bids</th>
                                    <th className="text-center">Total Bids</th>
                                    <th className="text-center">Country</th>
                                    <th className="text-center" width="60px">Add/Remove</th>
                                </tr>
                                </thead>
                                <tbody className="small text-wrap">
                                    <>
                                    {allNewMembers.map(newMember => (
                                        <tr key={newMember._id}>
                                            <td>
                                            <div className="d-flex align-items-center">
                                                <img src={getImageUrl(providers,newMember.profileImage,["w_40","c_scale"])} width="32px" height="32px" className="object-fit-cover rounded border me-2" />
                                                <div className="text-wrap text-truncate">{newMember.name}</div>
                                            </div>
                                            </td>
                                            <td><span className="tags text-nowrap">{newMember.interests[0]}</span></td>
                                            <td align="center">{newMember.stats.activeBids}</td>
                                            <td align="center">{newMember.stats.totalBids}</td>
                                            <td align="center"><img src={`https://hatscripts.github.io/circle-flags/flags/${newMember.country.toLowerCase()}.svg`} className="border rounded-circle me-2" width="26px" /></td>

                                        {newMembers.some(existingMember => newMember._id === existingMember._id) ? <td align="center"><a href="#" onClick={() => updateNewMembers(newMember,false)}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#dc3545"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/></svg></a></td> : <td align="center"><a href="#" onClick={() => updateNewMembers(newMember,true)}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#198754"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg></a></td>}
                                        </tr>
                                    ))}
                                    </>
                                
                                </tbody>
                            </table>
                            {<PageScroller endpoint="community/getNewMembers" setState={updateAllNewMembers} pageSize={25} />}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="pt-0 border-0">
                        <Button onClick={() => {setShowNewMemberPopup(false); setAllNewMembers([]); setNewMembers([]);}} variant="outline-primary">Cancel</Button>
                        <Button onClick={() => {submitUpdates(true);}} variant="secondary">Add All</Button>
                        <Button onClick={() => {submitUpdates();}} variant="primary">Add Selected</Button>
                    </Modal.Footer>
                </Modal>
            )}
            <Container className="pageTitleContainer px-0 shadow-sm pb-0" fluid>
                <Container className="border-bottom pb-0" fluid>
                    <Container className="px-0">
                        <Row className="px-3">
                            <Col xs={6} className="mb-4 pt-4">
                                <h1 className="pageTitle text-white m-0 tragedyRegularFont ps-md-0">
                                    <div className="d-flex align-items-center">
                                        <img src={getImageUrl(providers,brand.profileImage,["w_100","c_scale"])} height="36px" width="36px" className="object-fit-cover rounded border me-2 border-gray bg-white p-1" />
                                        <div className="text-truncate">{brand && brand.name}</div>
                                    </div>
                                </h1>
                            </Col>
                            <Col xs={6} className="d-flex justify-content-end align-items-stretch my-4">
                                <button type="button" className="me-2 btn btn-secondary" onClick={() => {setShowNewMemberPopup(true);setRefreshCounter(refreshCounter + 1);}}>Add New</button>
                                <div className="fw-normal text-primary bg-light py-2 px-3 rounded">
                                    <span className="fs-6">Total Members: </span>
                                    <span className="fw-500 fs-6">{members && members.length}</span>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Container>
            <Container fluid className="brandCommunity">
                <Container className="px-0">
                    <Row className="px-3">
                        <Col md={12}>
                            <div className="pt-md-4">
                                <Row>
                                    {members && members.map(member => (
                                        <Col xs={12} md={6} lg={4} xl={4} key={member._id}>
                                            <div className="panel w-100 bg-light d-flex align-items-start justify-content-start rounded position-relative p-3">
                                                <div className="me-3">
                                                    <img src={getImageUrl(providers,member.profileImage,["w_100","c_scale"])} height="120px" width="120px" className="object-fit-cover rounded border me-2" />
                                                    <div onClick={() => {setRemoveMember(member); setShowRemoveMemberModal(true);}} className="delete-btn">
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#dc3545">
                                                            <path  d="M0 0h24v24H0z" fill="none"></path><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="w-75">
                                                    <h5 className="mb-2 mt-1 text-capitalize text-truncate text-nowrap me-2">{member.name}</h5>
                                                    <Row>
                                                        <Col>
                                                            <div className="text-muted small mb-2 pb-1">Total Bids - <span className="fw-bold">{member.stats.totalBids}</span></div>
                                                        </Col>
                                                        <Col>
                                                            <div className="text-muted small mb-2 pb-1">Active Bids - <span className="fw-bold">{member.stats.activeBids}</span></div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                    {member.socials.map(social => (
                                                        <React.Fragment key={`${social.name}`}>
                                                            {social.name !== "meta" && (
                                                                <Col>
                                                                    <div className="d-flex align-items-start">
                                                                        {getSocialBadge(social.name,null,"20px","colour",social.handle)}
                                                                        {(social.hasOwnProperty("data") && social.data.isVerified) && <img src={blueTick} className="blueTick" />}
                                                                        <div className="ms-1">
                                                                            <div className="text-muted smaller">{(social.hasOwnProperty("data") && social.data.followers > 0 && getSmallNumber(social.data.followers)) || ""}</div>      
                                                                            {social.hasOwnProperty("data") && social.data.hasOwnProperty("engagementRate") && social.data.engagementRate > 0 ? <Badge bg="info">{social.data.engagementRate.toFixed(2)}%</Badge>  : ""}    
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <PageScroller endpoint="community/getCommunity" setState={setState} pageSize={20} />
                </Container>
            </Container>
        </Container>      
        <Footer />
    </div>
  );
}