import React from "react";
import { Container, Row } from 'react-bootstrap';

import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";
import errorMessage from "../../images/404.png"

export default function FourOhFour({ }) {
  return(
    <div className="bg-page">
    <Topbar />
    <div className="pageName">404</div>
    <Container className="fullscreenWithTopBar px-0 bg-white" fluid>
      <Container className="px-0 h-100 pb-5">
        <Row className="d-flex align-items-center justify-content-center h-100">
          <div className="col-md-6 col-12 d-flex flex-column align-items-center justify-content-center h-100">
            <img src={errorMessage} className="w-100" />
            <a href="/" className="btn btn-primary btn-sm mt-3">Back to Home</a>
          </div>
        </Row>
      </Container>
    </Container>
    <Footer />
    </div>
  )
}