// Web-Push
// Public base64 to Uint
function urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export async function createSubscription(){
    const subscription = await navigator.serviceWorker.getRegistration('serviceWorker.js')
    .then(function (registration) {
        const subscribeOptions = {
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(
            'BEcCTu4pxZ5lmbaxSu47-tNHOxVO5xq2zkkPIoiR_N0MoVNg6zGG9Xn1XImm5oIntu0FmkNYPIih2uesZeqEg9w',
        ),
        };
        return registration.pushManager.subscribe(subscribeOptions)
    })
    return subscription
}

export async function getSubscription(){
    if (await navigator.serviceWorker.ready){
        console.log("checking")
        const subscription = await navigator.serviceWorker.getRegistration('serviceWorker.js').then(function (registration) { return registration.pushManager.getSubscription() } )
        console.log("sub",subscription)
        return subscription
    }
}