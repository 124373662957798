import React, { useState } from "react";
import { Container, Row, Col, Nav, Tabs, Tab, Form, FloatingLabel, Modal, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { Link, useSearchParams } from "react-router-dom";
import { apiCall } from "../../helpers/api";
import { useImageContext } from "../../hooks/useImageContext";
import { getImageUrl, updateImage, clientUploadImage } from "../../helpers/images";
import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import { useLogout } from '../../hooks/useLogout';
import AlertModal from "../../components/alerts/alert";
import { passwordMeetsStandard } from "../../helpers/user"
import { getSocialBadge } from "../../helpers/socials"
import { getSmallNumber } from "../../helpers/stats"
import { relativeTimeFormat } from "../../helpers/dates"
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import SupportModal from "../../components/support/support";
import { useAuthContext } from "../../hooks/useAuthContext";
import { getIndustries } from "../../helpers/dropdowns"
import { createSubscription, getSubscription } from "../../helpers/notifications";
import { getCountries } from "../../helpers/dropdowns"

import './Settings.css';
import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";
import user from './../../images/user-blue.svg';
import notification from './../../images/notification-blue.svg';
import lock from './../../images/lock-blue.svg';
import help from './../../images/help-circle-blue.svg';
import appearance from './../../images/appearance-blue.svg';
import refer from './../../images/refer-friend-blue.svg';
import policyIcon from './../../images/policy-blue.svg';
import termsIcon from './../../images/terms-blue.svg';
import roomRules from './../../images/room-rules-blue.svg';
import warning from './../../images/warning-yellow.svg';

export default function Settings() {
  // Dynamic data values
  const [userData, setUserData] = useState(null);
  const [staticUserData, setStaticUserData] = useState(null);
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [influencers, setInfluencers] = useState([]);
  const [passwordForm, setPasswordForm] = useState({"current":"","new":"","confirm":""});
  const [brands, setBrands] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [termsLoaded, setTermsLoaded] = useState(false);
  const [terms, setTerms] = useState(null);
  const [policy, setPolicy] = useState(null);
  const [policyLoaded, setPolicyLoaded] = useState(false);
  const [rules, setRules] = useState(null);
  const [rulesLoaded, setRulesLoaded] = useState(false);
  const [communities, setCommunities] = useState(null);
  const [communitiesLoaded, setCommunitiesLoaded] = useState(false);
  const [influencerTerms, setInfluencerTerms] = useState(null);
  const [influencerTermsLoaded, setInfluencerTermsLoaded] = useState(false);
  const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
  const [notificationsChecked, setNotificationsChecked] = useState({"checking":false,"checked":false,"subscription":null,"permissions":null})
  const [token, setToken] = useState("");
  const [tabID, setTabID] = useState(null)
  const [tabSet, setTabSet] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0)
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false)
  const [addRemoveSocial, setAddRemoveSocial] = useState({"visible":false,"action":"add","social":null})
  const [newSocial, setNewSocial] = useState({"instagram":"","tiktok":"","facebook":"","twitter":"","youtube":""})
  const [deleteAccount, setDeleteAccount] = useState(false)
  const [supportModal, setSupportModal] = useState({"visible":true,"type":"","message":"","context":"user"});
  const [specifyGender, setSpecifyGender] = useState(false);
  const { currentProfile } = useAuthContext();
  const [consoleText, setConsoleText] = useState("");
  var notificationAnalytics = {"permissions":null}
  const countries = getCountries()

  let [searchParams] = useSearchParams();
  if (searchParams.get("setting") && tabSet == false) {
    setTabID(searchParams.get("setting"))
    setTabSet(true);
  }

  const animatedComponents = makeAnimated();
  // Interests / Industry Options
  const options = getIndustries()

function ToggleButtonGroupInterests() {    
  return (
      <>
      <ToggleButtonGroup type="checkbox" value={selectedInfluencer.interests.slice(1)} onChange={(choices) => updateInfluencerData(choices,"otherInterests")} id="otherInterests">
          <ToggleButton id="tbg-btn-1" className="me-2 mb-2 btn-sm" value="Activities">Activities</ToggleButton>
          <ToggleButton id="tbg-btn-2" className="me-2 mb-2 btn-sm" value="Arts & Culture">Arts & Culture</ToggleButton>
          <ToggleButton id="tbg-btn-3" className="me-2 mb-2 btn-sm" value="Automotive">Automotive</ToggleButton>
          <ToggleButton id="tbg-btn-4" className="me-2 mb-2 btn-sm" value="Beauty, Grooming & Fragrance">Beauty, Grooming & Fragrance</ToggleButton>
          <ToggleButton id="tbg-btn-5" className="me-2 mb-2 btn-sm" value="Books & Magazines">Books & Magazines</ToggleButton>
          <ToggleButton id="tbg-btn-6" className="me-2 mb-2 btn-sm" value="Charity & Nonprofit">Charity & Nonprofit</ToggleButton>
          <ToggleButton id="tbg-btn-7" className="me-2 mb-2 btn-sm" value="Children & Baby">Children & Baby</ToggleButton>
          <ToggleButton id="tbg-btn-8" className="me-2 mb-2 btn-sm" value="Entertainment & Media">Entertainment & Media</ToggleButton>
          <ToggleButton id="tbg-btn-9" className="me-2 mb-2 btn-sm" value="Environment">Environment</ToggleButton>
          <ToggleButton id="tbg-btn-10" className="me-2 mb-2 btn-sm" value="Fashion">Fashion</ToggleButton>
          <ToggleButton id="tbg-btn-11" className="me-2 mb-2 btn-sm" value="Food & Drink">Food & Drink</ToggleButton>
          <ToggleButton id="tbg-btn-12" className="me-2 mb-2 btn-sm" value="Health, Wellness & Personal Care">Health, Wellness & Personal Care</ToggleButton>
          <ToggleButton id="tbg-btn-13" className="me-2 mb-2 btn-sm" value="Holidays & Travel">Holidays & Travel</ToggleButton>
          <ToggleButton id="tbg-btn-14" className="me-2 mb-2 btn-sm" value="Home & Garden">Home & Garden</ToggleButton>
          <ToggleButton id="tbg-btn-15" className="me-2 mb-2 btn-sm" value="Lifestyle">Lifestyle</ToggleButton>
          <ToggleButton id="tbg-btn-16" className="me-2 mb-2 btn-sm" value="Pets">Pets</ToggleButton>
          <ToggleButton id="tbg-btn-17" className="me-2 mb-2 btn-sm" value="Restaurants, Bars & Clubs">Restaurants, Bars & Clubs</ToggleButton>
          <ToggleButton id="tbg-btn-18" className="me-2 mb-2 btn-sm" value="Sport & Fitness">Sport & Fitness</ToggleButton>
          <ToggleButton id="tbg-btn-19" className="me-2 mb-2 btn-sm" value="Technology, Gaming & Electronics">Technology, Gaming & Electronics</ToggleButton>
          <ToggleButton id="tbg-btn-20" className="me-2 mb-2 btn-sm" value="Tickets">Tickets</ToggleButton>
      </ToggleButtonGroup>
      </>
  );
}
  
  // Helper components
  const { providers } = useImageContext();
  const { logout } = useLogout();

  async function getUser() {
    setIsLoaded("loading")
    var response = await apiCall('mySettings',{ "method" : "GET" });
    if (response["success"]){
        var tempContext = ["user"];
        setUserData(response["success"]["user"])
        setStaticUserData({firstName:response["success"]["user"]["firstName"],lastName:response["success"]["user"]["lastName"]})
        setInfluencers(response["success"]["influencers"])
        setBrands(response["success"]["brands"])
        if (response["success"]["influencers"].length == 1 && response["success"]["brands"].length == 0) { 
          tempContext.push("influencer")
          var influencer = response["success"]["influencers"][0]
          if (!["Male","Female","Prefer not to say"].includes(influencer.gender)){
            influencer.specificGender = influencer.gender;
            influencer.gender = "Prefer to self-describe (please specify)";
            setSpecifyGender(true)
          }
          else {
            influencer.specificGender = "";
          }
          setSelectedInfluencer(influencer)
          var tempSocials = newSocial;
          influencer.socials.filter(function(social) {return social.name != "meta"}).forEach(social => (
            tempSocials[social.name] = social.handle
          ))
          await getCommunities()
        }
        else if (response["success"]["influencers"].length == 0 && response["success"]["brands"].length == 1){
          tempContext.push("brand")
          setSelectedBrand(response["success"]["brands"][0])
        }
        setSupportModal({"visible":true,"type":"","message":"","context":tempContext,"multipleContext":true})
        // We need to handle multi-profile here
        setIsLoaded(true)
    } 
    setIsLoaded(true)
  }

  async function getTerms() { 
    setTermsLoaded("loading")
    var response = await apiCall('termsAndConditions',{ "method" : "GET" });
    if (response["success"]){
        setTerms(response["success"]["termsAndConditions"])
    }
    setTermsLoaded(true)
  }

  async function getPolicy() { 
    setPolicyLoaded("loading")
    var response = await apiCall('privacyPolicy',{ "method" : "GET" });
    if (response["success"]){
        setPolicy(response["success"]["privacyPolicy"])
    } 
    setPolicyLoaded(true)
  }

  async function getRules() { 
    setRulesLoaded("loading")
    var response = await apiCall('platformRules',{ "method" : "GET" });
    if (response["success"]){
        setRules(response["success"]["platformRules"])
    } 
    setRulesLoaded(true)
  }

  async function getCommunities() { 
    var response = await apiCall('community/getCommunities',{ "method" : "GET" });
    if (response["success"]){
        setCommunities(response["success"]["communities"])
    } 
  }

  async function getInfluencerTerms() { 
    setInfluencerTermsLoaded("loading")
    var response = await apiCall('influencerTerms',{ "method" : "GET" });
    if (response["success"]){
        setInfluencerTerms(response["success"]["influencerTerms"])
    } 
    setInfluencerTermsLoaded(true)
  }

  function updateUserData(props,id=null){
    var tempUserData = userData;
    if (id == "notifications") {
      if (tempUserData["notificationPreferences"].hasOwnProperty(props.name)){
        if (tempUserData["notificationPreferences"][props.name].includes(props.type) & !props.value){
            tempUserData["notificationPreferences"][props.name] = tempUserData["notificationPreferences"][props.name].filter(function(type) {return type != props.type})
        }
        else if (!tempUserData["notificationPreferences"][props.name].includes(props.type) & props.value){
          tempUserData["notificationPreferences"][props.name].push(props.type)
        }
      } 
      else if (props.value) {
        tempUserData["notificationPreferences"][props.name] = [props.type]
      } 
    }
    else if (id == "password"){
      var tempPassword = passwordForm;
      tempPassword[props.name] = props.value
      setPasswordForm(tempPassword)
    }
    else {
      tempUserData[props.target.id] = props.target.value;
    }
    setUserData(tempUserData)
    setRefreshCounter(refreshCounter + 1)
  }

  function updateInfluencerData(props,id) {
    var influencer = selectedInfluencer;
    if (id == "otherInterests"){
      influencer.interests = influencer.interests.slice(0,1);
      influencer.interests.push(...props);
    }
    else if (id == "primaryInterest"){
      var otherInterests = influencer.interests.slice(1);
      influencer.interests = [props.value];
      influencer.interests.push(...otherInterests);
    }
    else if (id == "gender" || id == "specificGender" ){
      if (["Male","Female","Prefer not to say"].includes(props.target.value)){
        setSpecifyGender(false)
        influencer["specificGender"] = "";
      }
      else {
        setSpecifyGender(true)
      }
      influencer[props.target.id] = props.target.value
    }
    else if (id == "address"){
      influencer.address[props.target.id] = props.target.value;
    }
    else if (id == "social"){
      var tempSocial = newSocial;
      tempSocial[props.name] = props.handle
      setNewSocial(tempSocial)
    }
    else {
      influencer[props.target.id] = props.target.value;
    }
    setRefreshCounter(refreshCounter + 1);
    setSelectedInfluencer(influencer);
  }

  function updateBrandData(props,id) {
    var brand = selectedBrand;
    if (id == "industry"){
      brand["industry"] = props.value
    }
    else {
      brand[props.target.id] = props.target.value;
    }
    setSelectedBrand(brand);
    setRefreshCounter(refreshCounter + 1);
  }

  async function pushUpdates(type,options={}){
    switch (type) {
      case "notifications":
        var response = await apiCall('notifications/preferences',{ "method" : "PUT", "data" : { notificationPreferences : userData.notificationPreferences }});
        if (response["success"]){
          setAlert({"display":"toast","visible":true,"title":"Notification Preferences Saved","content":"Your notification preferences have been updated","className":"bg-success text-white"})
        }
        else if (response["failure"]) {
          setAlert({"display":"toast","visible":true,"title":"Notification Preferences Failed to Save","content":response["failure"]["error"],"className":"bg-danger text-white"})
        }
        break
      case "password":
        var response = await apiCall('myPassword',{ "method" : "PUT", "data" : passwordForm });
        if (response["success"]){
          setAlert({"display":"toast","visible":true,"title":"Password Updated","content":"Your password has been updated","className":"bg-success text-white"})
          setPasswordForm({"current":"","new":"","confirm":""})
        }
        else if (response["failure"]) {
          setAlert({"display":"toast","visible":true,"title":"Could Not Update Password","content":response["failure"]["error"],"className":"bg-danger text-white"})
        }
        break
      case "contactDetails":
        var response = await apiCall('influencer/myContactDetails',{ "method" : "PUT", "data" : {"address":selectedInfluencer.address,"phoneNumber":selectedInfluencer.phoneNumber} });
        if (response["success"]){
          setAlert({"display":"toast","visible":true,"title":"Details Updated","content":"Your contact details has been updated","className":"bg-success text-white"})
          setPasswordForm({"current":"","new":"","confirm":""})
        }
        else if (response["failure"]) {
          setAlert({"display":"toast","visible":true,"title":"Could Not Update Contact Details","content":response["failure"]["error"],"className":"bg-danger text-white"})
        }
        break
      case "brand":
        var profileImage = selectedBrand.profileImage;
        if (!uploadedImage && selectedImage) {
          var profileImage = await clientUploadImage(selectedImage,setAlert)
          setUploadedImage(true)
        }
        var response = await apiCall(`brand/myDetails?id=${selectedBrand._id}`,{ "method" : "PUT", "data" : { name : selectedBrand.name, website : selectedBrand.website, industry : selectedBrand.industry, profileImage : profileImage, terms : selectedBrand.terms }});
        if (response["success"]){
          setAlert({"display":"toast","visible":true,"title":"Details Updated","content":"Brand details have been updated","className":"bg-success text-white"})
          var brand = selectedBrand
          brand.profileImage = profileImage;
          setSelectedBrand(brand)
        }
        else if (response["failure"]) {
          setAlert({"display":"toast","visible":true,"title":"Could Not Update Details","content":response["failure"]["error"],"className":"bg-danger text-white"})
        }
        break
      case "account":
        var profileImage = userData.profileImage;
        if (!uploadedImage && selectedImage) {
          var profileImage = await clientUploadImage(selectedImage,setAlert)
          setUploadedImage(true)
        }
        var response = await apiCall(`myDetails`,{ "method" : "PUT", "data" : { firstName : userData.firstName, lastName : userData.lastName, profileImage : profileImage }});
        if (response["success"]){
          setAlert({"display":"toast","visible":true,"title":"Details Updated","content":"Your details have been updated","className":"bg-success text-white"})
          setStaticUserData({"firstName":userData.firstName,"lastName":userData.lastName})
        }
        else if (response["failure"]) {
          setAlert({"display":"toast","visible":true,"title":"Could Not Update Details","content":response["failure"]["error"],"className":"bg-danger text-white"})
        }
        break
      case "influencer":
        if (options.isMulti) {
          // Handle multi-profie
        }
        else {
          // Will update some account values too 

          var profileImage = selectedInfluencer.profileImage;
          if (!uploadedImage && selectedImage) {
            var profileImage = await clientUploadImage(selectedImage,setAlert)
            setUploadedImage(true)
          }
            var response = await apiCall(`influencer/myDetails?id=${selectedInfluencer._id}&updateAccount=true`,{ "method" : "PUT", "data" : {name : selectedInfluencer.name, gender : specifyGender ? selectedInfluencer.specificGender : selectedInfluencer.gender, interests : selectedInfluencer.interests, profileImage : profileImage }});
            if (response["success"]){
              setAlert({"display":"toast","visible":true,"title":"User Details Updated","content":"Your details have been updated","className":"bg-success text-white"})
              var influencer = selectedInfluencer
              influencer.profileImage = profileImage;
              setSelectedInfluencer(influencer)
              setRefreshCounter(refreshCounter+1)
            }
            else if (response["failure"]) {
              setAlert({"display":"toast","visible":true,"title":"User Details Failed to Save","content":response["failure"]["error"],"className":"bg-danger text-white"})
            }
          }
        break
      case "community":
        var response = await apiCall(`community/updateCommunities`,{ "method" : "PUT", "data" : { options : options }});
        if (response["success"]){
          setAlert({"display":"toast","visible":true,"title":"Communities Updated","content":"Your communities have been updated","className":"bg-success text-white"})
          getCommunities()
        }
        else if (response["failure"]) {
          setAlert({"display":"toast","visible":true,"title":"Could Not Update Communities","content":response["failure"]["error"],"className":"bg-danger text-white"})
        }
        break
    }
  }

  async function checkAndSetNotifications(){
    setNotificationsChecked({"checking":true,"checked":false,"subscription":null,"permissions":null})
    var pushType = window && window.webkit ? "firebase" : "webpush"
    // Check iOS specific notifications
    if (window && window.webkit) {
      console.log("is iOS")
      try {
        window.webkit.messageHandlers.iosDevicePermission.postMessage({"action":"check"});
      }
      catch (error) {
        console.log("feature not implemented!")
        return
      }
      const permissions = await window.webkit.messageHandlers.iosDevicePermission.postMessage({"action":"check"});
      console.log("permissions returned: " + permissions)
      notificationAnalytics = {permissions : permissions, subscription : false, matchingDevices : 0}
      var deviceToken = await window.webkit.messageHandlers.iosDeviceToken.postMessage({"action":"check"});
      console.log("device token returned: " + deviceToken)
      if (permissions == "granted" && deviceToken != ""){
        var subscription = { "endpoint": deviceToken }
        notificationAnalytics.subscription = true
        const matchingDevices = userData.associatedDevices.filter(function(device) {return device["subscription"]["endpoint"].includes(deviceToken)})
        notificationAnalytics.matchingDevices = matchingDevices.length
        if (matchingDevices.length == 0){
          const response = await apiCall("notifications/register",{ "method" : "POST", "data" : { "subscription" : subscription, "pushType" : pushType } });
          if (response["success"]) {
            console.log("Saved subscription to server")
            getUser()
          }
          else {
            console.log("Failed to save subscription to server")
          }
        }
      }
      console.log({"checking":false,"checked":true,"subscription":subscription,"permissions":permissions})
      setNotificationsChecked({"checking":false,"checked":true,"subscription":subscription,"permissions":permissions})
    }
    // Android / Windows / Linux notifications
    else {
      console.log("is not iOS")
      var existingSubscription = await getSubscription()
      const permissions = Notification.permission
      notificationAnalytics = {permissions : permissions, subscription : existingSubscription ? true : false, matchingDevices : 0}
      if (!existingSubscription && permissions == "granted"){
        console.log("creating new sub with existing permissions")
        existingSubscription = await createSubscription()
        .catch((error) => {
          console.log("error creating sub: ",error)
        })
      }
      if (existingSubscription){
        const matchingDevices = userData.associatedDevices.filter(function(device) {return device["subscription"]["endpoint"].includes(existingSubscription["endpoint"])})
        notificationAnalytics.matchingDevices = matchingDevices.length
        if (matchingDevices.length == 0){
          const response = await apiCall("notifications/register",{ "method" : "POST", "data" : { "subscription" : existingSubscription, "pushType" : pushType } });
          if (response["success"]) {
            console.log("Saved subscription to server")
            getUser()
          }
          else {
            console.log("Failed to save subscription to server")
          }
        }
      }
      setNotificationsChecked({"checking":false,"checked":true,"subscription":existingSubscription,"permissions":Notification.permission})
      }
    await apiCall("notifications/analytics",{ "method" : "POST", "data" : notificationAnalytics });
  }

  async function getRegToken(){
    var pushType = window && window.webkit ? "firebase" : "webpush"
    if (notificationsChecked.subscription){
      const matchingDevices = userData.associatedDevices.filter(function(device) {return device["subscription"].includes(notificationsChecked.subscription.endpoint)})
      if (matchingDevices.length == 0){
        const response = await apiCall("notifications/register",{ "method" : "POST", "data" : { "subscription" : notificationsChecked.subscription, "pushType" : pushType } });
        if (response["success"]) {
          console.log("Saved subscription to server")
        }
        console.log("Failed to save subscription to server")
      }
    }
    else if (notificationsChecked.permissions != "blocked" && notificationsChecked.permissions != "denied") {
      console.log("trying to create sub")
      var subError = null
      if (window && window.webkit) {
        console.log("is iOS")
        var currentToken = await window.webkit.messageHandlers.iosDeviceToken.postMessage({"action":"check"});
        const getPermission = await window.webkit.messageHandlers.iosDevicePermission.postMessage({"action":"request"});
        if (getPermission.result && getPermission.state == "granted"){
          if (getPermission.token == currentToken){
            await new Promise(r => setTimeout(r, 2000));
            var deviceToken = await window.webkit.messageHandlers.iosDeviceToken.postMessage({"action":"check"});
          }
          else {
            var deviceToken = getPermission.token;
          }
          var subscriptionDetails = { "endpoint": deviceToken }
        }
      }
      else {
        console.log("is not iOS")
        var subscriptionDetails = await createSubscription()
        .catch((error) => {
          subError = String(error)
        })
      }
      if (subscriptionDetails) {
        const matchingDevices = userData.associatedDevices.filter(function(device) {return device["subscription"]["endpoint"].includes(subscriptionDetails.endpoint)})
        if (matchingDevices.length == 0){
          const response = await apiCall("notifications/register",{ "method" : "POST", "data" : { "subscription" : subscriptionDetails, "pushType" : pushType } });
          if (response["success"]) {
            setNotificationsChecked({"checking":false,"checked":true,"subscription":subscriptionDetails})
            getUser()
            setAlert({"display":"toast","visible":true,"title":"Notifications Enabled","content":"Notifications are now enabled","className":"bg-success text-white"})
          }
          else {
            setAlert({"display":"toast","visible":true,"title":"Notifications Failed","content":"Could not enable notifications","className":"bg-danger text-white"})
          }
        }
      }
      else {
        setAlert({"display":"toast","visible":true,"title":"Notifications Failed","content":subError,"className":"bg-danger text-white"})
      }
    }
    else {
      console.log("Notifications are blocked on this device")
    }  
  }

  async function requestDeletion(){
    var response = await apiCall('requestDeletion',{ "method" : "POST", "data" : {password : passwordForm.current} });
    setPasswordForm({"current":"","new":"","confirm":""});
    if (response["success"]){
      setAlert({"display":"centered","visible":true,"title":"Account Deletion Requested","content":"Your request has been submitted. You will be logged out shortly.","className":"bg-success text-white"})
      setDeleteAccount(false)
      var timeLeft = 5;
      var redirectTimer = setInterval(function(){
          if(timeLeft <= 0){
              clearInterval(redirectTimer);
              window.location.href="/Login";
          }
          timeLeft -= 1;
      }, 1000);
    }
    else if (response["failure"]) {
      setAlert({"display":"toast","visible":true,"title":"Something went wrong with your request","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  }

  if (userData == null && !isLoaded) {
    getUser()
  }
  if (terms == null && !termsLoaded) {
    getTerms()
  }
  if (policy == null && !policyLoaded) {
    getPolicy()
  }
  if (rules == null && !rulesLoaded) {
    getRules()
  }
  if (isLoaded != "loading" && isLoaded) {
    if (influencerTerms == null && !influencerTermsLoaded && influencers.length > 0) {
      getInfluencerTerms()
    }
    if (!notificationsChecked.checked && !notificationsChecked.checking){
      checkAndSetNotifications()
    }
    else {
      return (
          <div className={`bg-page ${currentProfile.type == "admins" && "settings"}`}>
            <Topbar />
            <Container className="fullscreenWithTopBar userSettings" fluid="fluid">
              <AlertModal modal={showAlert} onClose={() => setAlert({"display":"toast","visible":false,"title":"","content":""})} />
              <Tab.Container id="userSettings-tab" activeKey={tabID} onSelect={(key) => setTabID(key)}>
                <Container className="px-0 px-md-3">
                  <Row className="d-flex justify-content-center">
                    <Col sm={12} md={4} lg={4} xl={3}>
                      <div className="pt-lg-4 mt-md-4 mb-lg-5">
                        <Nav variant="tabs" className="border-0">
                          
                          <div className="bg-white rounded-xl border mb-3 mb-md-4 shadow w-100 userSettingsNav">

                            <div className="p-3 border-bottom d-flex align-items-center text-white bg-primary rounded-top-xl">
                              <img src={getImageUrl(providers,userData.profileImage,["w_80","c_scale"])} width="46px" height="46px" className="rounded object-fit-cover" />
                              <div className="ms-3 text-truncate">
                                <h6 className="mb-0 mt-1 fw-bold text-truncate">{staticUserData.firstName} {staticUserData.lastName}</h6>
                                <span className="small">{userData.email}</span>
                              </div>
                            </div>

                            <Nav.Link eventKey="account" className="p-3 d-flex align-items-center justify-content-between w-100">
                              <Nav.Item className="d-flex align-items-center">
                                <img src={user} height="18px" width="18px" /> 
                                <div className="ms-3">Edit {selectedInfluencer && "Profile" || "Account"}</div>
                              </Nav.Item>
                              <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                              </svg>
                            </Nav.Link>
                            {selectedBrand && (
                              <Nav.Link eventKey="brand" className="p-3 d-flex align-items-center justify-content-between w-100">
                              <Nav.Item className="d-flex align-items-center">
                                <img src={user} height="18px" width="18px" /> 
                                <div className="ms-3">Edit Brand</div>
                              </Nav.Item>
                              <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                              </svg>
                            </Nav.Link>
                            )}
                            {selectedInfluencer && (
                            <>
                              {/* <Nav.Link eventKey="socials" className="p-3 d-flex align-items-center justify-content-between w-100">
                                <Nav.Item className="d-flex align-items-center">
                                  <img src={user} height="18px" width="18px" /> 
                                  <div className="ms-3">Social Accounts</div>
                                </Nav.Item>
                                <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                                </svg>
                              </Nav.Link> */}
                              <Nav.Link eventKey="contactDetails" className="p-3 d-flex align-items-center justify-content-between w-100">
                                <Nav.Item className="d-flex align-items-center">
                                  <img src={user} height="18px" width="18px" /> 
                                  <div className="ms-3">Contact Details</div>
                                </Nav.Item>
                                <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                                </svg>
                              </Nav.Link>
                              <Nav.Link eventKey="communities" className="p-3 d-flex align-items-center justify-content-between w-100">
                                <Nav.Item className="d-flex align-items-center">
                                  <img src={user} height="18px" width="18px" /> 
                                  <div className="ms-3">Communities</div>
                                </Nav.Item>
                                <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                                </svg>
                              </Nav.Link>
                            </>
                            )}
                            <Nav.Link eventKey="notifications" className="p-3 d-flex align-items-center justify-content-between w-100">
                              <Nav.Item className="d-flex align-items-center">
                                <img src={notification} height="18px" width="18px" /> 
                                <div className="ms-3">Notifications</div>
                              </Nav.Item>
                              <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                              </svg>
                            </Nav.Link>
                            <Nav.Link eventKey="security" className="p-3 d-flex align-items-center justify-content-between w-100">
                              <Nav.Item className="d-flex align-items-center">
                                <img src={lock} height="18px" width="18px" /> 
                                <div className="ms-3">Security & Login</div>
                              </Nav.Item>
                              <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                              </svg>
                            </Nav.Link>
                            <Nav.Link eventKey="support" className="p-3 d-flex align-items-center justify-content-between w-100">
                              <Nav.Item className="d-flex align-items-center">
                                <img src={help} height="18px" width="18px" /> 
                                <div className="ms-3">Help & Support</div>
                              </Nav.Item>
                              <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                              </svg>
                            </Nav.Link>
                            {/* <Nav.Link eventKey="appearance" className="p-3 d-flex align-items-center justify-content-between w-100">
                              <Nav.Item className="d-flex align-items-center">
                                <img src={appearance} height="18px" width="18px" /> 
                                <div className="ms-3">Appearance</div>
                              </Nav.Item>
                              <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                              </svg>
                            </Nav.Link> */}
                            {selectedInfluencer && (
                              <Nav.Link eventKey="referrals" className="p-3 d-flex align-items-center justify-content-between w-100">
                                <Nav.Item className="d-flex align-items-center">
                                  <img src={refer} height="18px" width="18px" /> 
                                  <div className="ms-3">Refer a Friend</div>
                                </Nav.Item>
                                <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                                </svg>
                              </Nav.Link>
                            )}
                          </div>

                          <div className="bg-white rounded-xl border mb-1 mb-md-0 pt-3 shadow w-100 userSettingsNav">
                            <Nav.Link eventKey="termsOfUse" className="p-3 d-flex align-items-center justify-content-between w-100">
                              <Nav.Item className="d-flex align-items-center">
                                <img src={termsIcon} height="18px" width="18px" /> 
                                <div className="ms-3">Terms of Use</div>
                              </Nav.Item>
                              <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                              </svg>
                            </Nav.Link>
                            <Nav.Link eventKey="privacyPolicy" className="p-3 d-flex align-items-center justify-content-between w-100">
                              <Nav.Item className="d-flex align-items-center">
                                <img src={policyIcon} height="18px" width="18px" /> 
                                <div className="ms-3">Privacy Policy</div>
                              </Nav.Item>
                              <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                              </svg>
                            </Nav.Link>
                            <Nav.Link eventKey="rules" className="p-3 d-flex align-items-center justify-content-between w-100">
                              <Nav.Item className="d-flex align-items-center">
                                <img src={roomRules} height="18px" width="18px" /> 
                                <div className="ms-3">The Influence Room Rules</div>
                              </Nav.Item>
                              <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                              </svg>
                            </Nav.Link>
                            {influencers.length > 0 && 
                              <Nav.Link eventKey="influencerTerms" className="p-3 d-flex align-items-center justify-content-between w-100">
                                <Nav.Item className="d-flex align-items-center">
                                  <img src={termsIcon} height="18px" width="18px" /> 
                                  <div className="ms-3">Influencer Terms</div>
                                </Nav.Item>
                                <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                                </svg>
                              </Nav.Link>
                            }
                            <a className="d-block mx-3 mt-3 mb-4 fw-500" onClick={() => logout()}>
                              <span className="text-primary">Logout</span>
                            </a>
                            <a onClick={() => setDeleteAccount(true)} className="d-block mx-3 mt-3 mb-4 fw-500">
                              <span className="text-danger">Delete My Account</span>
                            </a>
                            <div className="mx-3 mb-3">
                              <span className="text-muted small">Version 1.0</span>
                            </div>
                          </div>
                        </Nav>
                        {deleteAccount && (
                            <Modal centered show>
                              <Modal.Header className="bg-danger text-white" closeButton onHide={() => setDeleteAccount(false)}>
                                <Modal.Title className="h6">Confirm Account Deletion?</Modal.Title>
                              </Modal.Header>
                              <Modal.Body className="pb-0">
                                  <p><strong>Are you sure you want to delete your account? This action is irreversible!</strong></p>
                                  <p className="small text-muted">Your account will be scheduled for deletion after 48 hours, but you will be logged out of the application. An email will also be sent to confirm this action.</p>
                                  <p>Please re-enter your password to confirm.</p>
                                  <FloatingLabel label="Password" className="mb-3" >
                                    <Form.Control type="password" className="rounded-0 border-0 border-bottom" value={passwordForm.current} onChange={(e) => updateUserData({name:"current",value:e.target.value},"password")} />
                                  </FloatingLabel>
                              </Modal.Body>
                              <Modal.Footer className="border-0 pt-0 justify-content-end">
                                  <button type="button" onClick={() => setDeleteAccount(false)} className="btn btn-sm btn-primary">I've changed my mind!</button> 
                                  <button disabled={passwordForm.current.length < 1} type="button" onClick={() => requestDeletion()} className="btn btn-sm btn-danger">I'm sure. Delete my account</button> 
                              </Modal.Footer>
                            </Modal>
                          )}
                      </div>
                    </Col>
                    <Col sm={12} md={8} lg={7} xl={7}>
                      <Tab.Content>
                        {!selectedInfluencer && (
                        <Tab.Pane eventKey="account">
                          <div className="pb-2 d-block d-md-none">
                            <div className="d-flex align-items-center mb-2 py-2 px-3">
                              <a className="backArrow" onClick={() => setTabID(null)}>
                                <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                                </svg>
                              </a>
                              <div className="sectionTitle w-100 text-center">Edit Account</div>
                            </div>
                          </div>
                          <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                            <div className="p-md-4 p-3 bg-white shadow rounded-xl">

                              <h4 className="mt-4 mb-2 ms-4 d-none d-md-block">Edit Account</h4>
                              <div className="text-center mb-2">
                                <div className="d-inline-block position-relative">
                                  <img src={selectedImage ? selectedImage.url : getImageUrl(providers,userData.profileImage,["w_200","c_scale"])} width="150px" height="150px" className="object-fit-cover rounded m-3 border bg-light p-2" />
                                  <label htmlFor="account-upload">
                                    <a className="position-absolute end-0 bottom-0">
                                      {uploadingImage ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <svg width="36" height="36" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="11.5" cy="11.5" r="11" fill="white" stroke="#E3E3E3"/>
                                        <path d="M15.9851 8.90875L14.0912 7.01483C14.0707 6.99506 14.0383 6.99506 14.0179 7.01483L7.74132 13.2916C7.73366 13.2992 7.72864 13.309 7.72688 13.3197L7.72073 13.3128L7 16L9.68722 15.2793L9.68038 15.2724H9.68032C9.69099 15.2707 9.70085 15.2656 9.70844 15.258L15.9852 8.98207C16.0049 8.9616 16.0049 8.92921 15.9852 8.90874L15.9851 8.90875Z" fill="#797979"/>
                                        <path d="M7.5 13L10 15.5" stroke="white"/>
                                      </svg>}
                                    </a>
                                  </label>
                                  <Form.Control type="file" id="account-upload" className="fileUploadBtn position-absolute end-0 bottom-0" accept=".png, .jpg, .jpeg, .webp, .heic" onChange={(e) => {updateImage(e,setSelectedImage,setUploadingImage,setAlert); setUploadedImage(false)}}/>
                                </div>
                              </div>

                              <div className="mx-md-4">

                              <Row>
                                <Col sm={12} md={6}>
                                  <FloatingLabel label="First Name" className="mb-3" >
                                    <Form.Control type="text" value={userData.firstName} id="firstName" className="rounded-0 border-0 border-bottom" onChange={(e) => updateUserData(e)} />
                                  </FloatingLabel>
                                </Col>
                                <Col sm={12} md={6}>
                                  <FloatingLabel label="Last Name" className="mb-3" >
                                    <Form.Control type="text" value={userData.lastName} id="lastName" className="rounded-0 border-0 border-bottom" onChange={(e) => updateUserData(e)} />
                                  </FloatingLabel>
                                </Col>
                              </Row>

                                <div className="d-flex align-items-center justify-content-between mb-lg-4">
                                  {userData.hasOwnProperty("meta") && 
                                    <span className="text-muted small">Member since {new Date(userData.meta.created*1000).toLocaleString('en-US', { month:"long", year:"numeric" })}</span>
                                  }
                                  <button type="button" onClick={() => pushUpdates("account")} className="btn btn-sm btn-primary">Update</button>
                                </div>

                              </div>

                            </div>
                          </div>
                        </Tab.Pane>
                        )}

                      {selectedBrand && (
                        <Tab.Pane eventKey="brand">
                          <div className="pb-2 d-block d-md-none">
                            <div className="d-flex align-items-center mb-2 py-2 px-3">
                              <a className="backArrow" onClick={() => setTabID(null)}>
                                <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                                </svg>
                              </a>
                              <div className="sectionTitle w-100 text-center">Edit Brand</div>
                            </div>
                          </div>
                          <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                            <div className="py-4 px-3 px-lg-4 bg-white shadow rounded-xl">

                              <h4 className="mt-4 mb-2 ms-4 d-none d-md-block">Edit Brand</h4>
                              <div className="text-center mb-2">
                                <div className="d-inline-block position-relative">
                                  <img src={selectedImage ? selectedImage.url : getImageUrl(providers,selectedBrand.profileImage,["w_200","c_scale"])} width="150px" height="150px" className="object-fit-cover rounded m-3 border bg-light p-2" />
                                  <label htmlFor="brand-upload">
                                    <a className="position-absolute end-0 bottom-0">
                                      <svg width="36" height="36" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="11.5" cy="11.5" r="11" fill="white" stroke="#E3E3E3"/>
                                        <path d="M15.9851 8.90875L14.0912 7.01483C14.0707 6.99506 14.0383 6.99506 14.0179 7.01483L7.74132 13.2916C7.73366 13.2992 7.72864 13.309 7.72688 13.3197L7.72073 13.3128L7 16L9.68722 15.2793L9.68038 15.2724H9.68032C9.69099 15.2707 9.70085 15.2656 9.70844 15.258L15.9852 8.98207C16.0049 8.9616 16.0049 8.92921 15.9852 8.90874L15.9851 8.90875Z" fill="#797979"/>
                                        <path d="M7.5 13L10 15.5" stroke="white"/>
                                      </svg>
                                    </a>
                                  </label>
                                  <Form.Control type="file" id="brand-upload" className="fileUploadBtn position-absolute end-0 bottom-0" accept=".png, .jpg, .jpeg, .webp, .heic" onChange={(e) => {updateImage(e,setSelectedImage,setUploadingImage,setAlert); setUploadedImage(false)}}/>
                                </div>
                              </div>

                              <div className="mx-md-4">

                              <Row>
                                <Col sm={12} md={6}>
                                  <FloatingLabel label="Brand Name" className="mb-3" >
                                    <Form.Control type="text" value={selectedBrand.name} id="name" className="rounded-0 border-0 border-bottom" onChange={(e) => updateBrandData(e)} />
                                  </FloatingLabel>
                                </Col>
                                <Col sm={12} md={6}>
                                  <FloatingLabel label="Website" className="mb-3" >
                                    <Form.Control type="text" value={selectedBrand.website} id="website" className="rounded-0 border-0 border-bottom" onChange={(e) => updateBrandData(e)} />
                                  </FloatingLabel>
                                </Col>
                              </Row>

                              <Row>
                                <Col sm={12} md={6}>   
                                  <label className="mb-2">Primary Industry</label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        defaultValue={[{label:selectedBrand.industry,value:selectedBrand.industry}]}
                                        options={options} 
                                        id="industry" 
                                        onChange={ (choice) => updateBrandData(choice,"industry") } 
                                    />
                                  </Col>
                              </Row>

                              <Row>
                                <Col sm={12} md={12}>   
                                  <label className="mb-2 mt-3">Add Terms and Conditions</label>
                                  <textarea type="text" rows="6" className="form-control" id="terms" onChange={ (e) => updateBrandData(e,"terms")} defaultValue={selectedBrand.terms ? selectedBrand.terms : ""}></textarea>
                                  </Col>
                              </Row>

                                <div className="d-flex align-items-center justify-content-between mb-lg-4">
                                  {selectedBrand.hasOwnProperty("meta") && 
                                    <span className="text-muted desktopView small">Member since {new Date(selectedBrand.meta.created*1000).toLocaleString('en-US', { month:"long", year:"numeric" })}</span>
                                  }
                                  <button type="button" onClick={() => pushUpdates("brand")} className="btn btn-sm btn-primary">Update</button>
                                </div>

                              </div>

                            </div>
                          </div>
                        </Tab.Pane>
                        )}

                        {selectedInfluencer && (
                        <>
                        <Tab.Pane eventKey="account">
                          <div className="pb-2 d-block d-md-none">
                            <div className="d-flex align-items-center mb-2 py-2 px-3">
                              <a className="backArrow" onClick={() => setTabID(null)}>
                                <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                                </svg>
                              </a>
                              <div className="sectionTitle w-100 text-center">Edit Profile</div>
                            </div>
                          </div>
                          <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                            <div className="py-4 px-3 px-lg-4 bg-white shadow rounded-xl">

                              <h4 className="mt-4 mb-2 ms-4 d-none d-md-block">Edit Profile</h4>

                              <div className="text-center mb-3">
                                <div className="d-inline-block position-relative">
                                  <img src={selectedImage ? selectedImage.url : getImageUrl(providers,selectedInfluencer.profileImage,["w_200","c_scale"])} width="150px" height="150px" className="object-fit-cover rounded m-3 border" />
                                  <label htmlFor="profile-upload">
                                    <a className="position-absolute end-0 bottom-0">
                                      <svg width="36" height="36" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="11.5" cy="11.5" r="11" fill="white" stroke="#E3E3E3"/>
                                        <path d="M15.9851 8.90875L14.0912 7.01483C14.0707 6.99506 14.0383 6.99506 14.0179 7.01483L7.74132 13.2916C7.73366 13.2992 7.72864 13.309 7.72688 13.3197L7.72073 13.3128L7 16L9.68722 15.2793L9.68038 15.2724H9.68032C9.69099 15.2707 9.70085 15.2656 9.70844 15.258L15.9852 8.98207C16.0049 8.9616 16.0049 8.92921 15.9852 8.90874L15.9851 8.90875Z" fill="#797979"/>
                                        <path d="M7.5 13L10 15.5" stroke="white"/>
                                      </svg>
                                    </a>
                                  </label>
                                  <Form.Control type="file" id="profile-upload" className="fileUploadBtn position-absolute end-0 bottom-0" accept=".png, .jpg, .jpeg, .webp, .heic" onChange={(e) => {updateImage(e,setSelectedImage,setUploadingImage,setAlert); setUploadedImage(false)}}/>
                                </div>
                              </div>

                              <div className="mx-md-4">

                                <Row>
                                  <Col sm={12} md={8}>
                                    <FloatingLabel label="Name" className="mb-4" >
                                      <Form.Control type="text" value={selectedInfluencer.name} id="name" className="rounded-0 border-0 border-bottom" onChange={(e) => updateInfluencerData(e)}  />
                                    </FloatingLabel>
                                  </Col>
                                  <Col sm={12} md={4}>
                                    <FloatingLabel label="Date of Birth" className="mb-4" >
                                      <Form.Control type="text" value={selectedInfluencer.dob} readOnly className="rounded-0 border-0 border-bottom" />
                                    </FloatingLabel>                                  
                                  </Col>
                                </Row>

                                <Row>
                                  <Col sm={12} md={8}>
                                    <FloatingLabel label="Gender"  className="mb-4">
                                      <Form.Select className="rounded-0 border-0 border-bottom" id="gender" value={selectedInfluencer.gender} onChange={(e) => updateInfluencerData(e,"gender")}>
                                        <option>Male</option>
                                        <option>Female</option>
                                        <option>Prefer not to say</option>
                                        <option>Prefer to self-describe (please specify)</option>
                                      </Form.Select>
                                    </FloatingLabel>                                  
                                  </Col>
                                  {specifyGender && (
                                  <Col sm={12} md={4}>
                                    <FloatingLabel label="Specified Gender"  className="mb-4">
                                      <Form.Control className="rounded-0 border-0 border-bottom" type="text" id="specificGender" value={selectedInfluencer.specificGender} onChange={(e) => updateInfluencerData(e,"specificGender")}  />
                                    </FloatingLabel>                                  
                                  </Col>
                                  )}
                                </Row>

                                <Row>
                                  <Col sm={12} md={12}>   
                                      <label className="mb-2">Primary Category</label>
                                      <Select className="mb-4 z-index-9"
                                          closeMenuOnSelect={true}
                                          components={animatedComponents}
                                          defaultValue={[{label:selectedInfluencer.interests[0],value:selectedInfluencer.interests[0]}]}
                                          options={options} 
                                          id="primaryInterest" 
                                          onChange={ (choice) => updateInfluencerData(choice,"primaryInterest") } 
                                      />
                                  </Col>
                                </Row>

                                <Row className="mb-3">
                                  <Col sm={12} md={12}>
                                    <span className="createCampaign">
                                    <label className="mb-2">Other Interests</label>
                                        <ToggleButtonGroupInterests />
                                    </span>
                                  </Col>
                                </Row>

                                <div className="d-flex align-items-center justify-content-between mt-3 mb-lg-4">
                                  {userData.hasOwnProperty("meta") && 
                                    <span className="text-muted desktopView small">Member since {new Date(userData.meta.created*1000).toLocaleString('en-US', { month:"long", year:"numeric" })}</span>
                                  }
                                  <button type="button"  onClick={() => pushUpdates("influencer",{"isMulti":false})} className="btn btn-sm btn-primary">Update Profile</button>
                                </div>

                              </div>

                            </div>
                          </div>
                        </Tab.Pane>
                        
                        {/* <Tab.Pane eventKey="socials">
                          <div className="pb-2 mobileView">
                            <div className="d-flex align-items-center mb-2 py-2 px-3">
                              <a className="backArrow" onClick={() => setTabID(null)}>
                                <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                                </svg>
                              </a>
                              <div className="sectionTitle w-100 text-center">Social Accounts</div>
                            </div>
                          </div>
                          <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                            <div className="py-4 px-3 px-lg-4 bg-white shadow rounded-xl">

                              <h4 className="mt-4 mb-4 ms-4 desktopView">Social Accounts</h4>
                              <p className="text-muted small pb-2 ms-sm-4">{selectedInfluencer.socials.filter(function(social) {return social.name == "meta"})[0].lastUpdate == 0 ? "Not Yet Synced" : (<>Last Synced {relativeTimeFormat(selectedInfluencer.socials.filter(function(social) {return social.name == "meta"})[0].lastUpdate*1000)}</>)}</p>
                              <div className="mx-md-4">
                                <p className="text-muted border-bottom pb-2">Existing Social Accounts</p>
                                <Row className="pt-2">
                                  {selectedInfluencer.socials.filter(function(social) {return social.name != "meta"}).map(social => (
                                    <Col md={12} lg={6} className="mb-3" key={social.name}>
                                      <div className="d-flex align-items-center justify-content-between flex-nowrap">
                                        <div className="d-flex align-items-center flex-nowrap w-75">
                                          {getSocialBadge(social.name,"me-2","30px","colour",social.handle)
                                          <div className="w-75">
                                            <h6 className="mb-0 fw-500 text-truncate">{social.handle}</h6>
                                            {social.hasOwnProperty("data") && (
                                              <span className="text-blue small">{getSmallNumber(social.data.followers)}</span>
                                            )}
                                          </div>
                                        </div>
                                        <a onClick={() => setAddRemoveSocial({"visible":true,"action":"edit","social":social}) }>
                                          <svg width="30" height="30" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="11.5" cy="11.5" r="11" fill="white" stroke="#E3E3E3"/>
                                            <path d="M15.9851 8.90875L14.0912 7.01483C14.0707 6.99506 14.0383 6.99506 14.0179 7.01483L7.74132 13.2916C7.73366 13.2992 7.72864 13.309 7.72688 13.3197L7.72073 13.3128L7 16L9.68722 15.2793L9.68038 15.2724H9.68032C9.69099 15.2707 9.70085 15.2656 9.70844 15.258L15.9852 8.98207C16.0049 8.9616 16.0049 8.92921 15.9852 8.90874L15.9851 8.90875Z" fill="#797979"/>
                                            <path d="M7.5 13L10 15.5" stroke="white"/>
                                          </svg>
                                        </a>
                                      </div>                                   
                                    </Col>
                                  ))}
                                </Row>

                                <p className="text-muted border-bottom pb-2 pt-4">Connect Other Accounts</p>
                                <Row className="pt-2">
                                {["Instagram","TikTok","YouTube","Facebook","Twitter"].filter(function(social) {return selectedInfluencer.socials.filter(function(existingSocial) {return existingSocial.name == social.toLowerCase()}).length == 0}).map(social => (
                                  <Col sm={12} md={6} className="mb-4" key={social}>
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div className="d-flex align-items-center">
                                      {getSocialBadge(social.toLowerCase(),"me-3","32px")}
                                        <h6 className="mb-0 fw-500">{social}</h6>
                                      </div>
                                      <button onClick={() => setAddRemoveSocial({"visible":true,"action":"add","social":{"name":social,"handle":null}})} className="btn btn-sm btn-outline-primary">Add</button>
                                    </div>
                                  </Col>
                                ))}
                                </Row>
                              </div>
                            </div>
                          </div>
                          {addRemoveSocial.visible && (
                            <Modal centered show>
                              <Modal.Header closeButton onHide={() => setAddRemoveSocial({"visible":false,"action":"add","social":null})}>
                                <Modal.Title className="h5">{getSocialBadge(addRemoveSocial.social.name,"me-3","32px")} {addRemoveSocial.action == "add" ? "Add" : "Edit"} {addRemoveSocial.social.name}</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                {addRemoveSocial.action == "add" && (
                                  <>
                                  <p className="text-muted border-bottom pb-2">Link your <strong>{addRemoveSocial.social.name}</strong> account here. Please be aware that any new accounts added will need to go through a reverification process</p>
                                  <FloatingLabel label="Handle" className="mb-0" >
                                  <Form.Control type="text" value={newSocial[addRemoveSocial.social.name]} className="rounded-0 border-0 border-bottom" onChange={(e) => updateInfluencerData({"handle":e.target.value,"name":addRemoveSocial.social.name},"social")} />
                                  </FloatingLabel>
                                </>
                                )}
                                {addRemoveSocial.action == "edit" && (
                                  <>
                                  <p className="text-muted pb-2">Update or remove your <strong>{addRemoveSocial.social.name}</strong> account.</p>
                                  <p className="text-muted pb-2">Please be aware that any modified handles added will need to go through a reverification process.</p>
                                  <p className="text-muted border-bottom pb-2">If you have updated the handle on <strong>{addRemoveSocial.social.name}</strong>, this will automatically update on here so you don't need to do anything!</p>
                                  <FloatingLabel label="Handle" className="mb-0" >
                                    <Form.Control type="text" value={newSocial[addRemoveSocial.social.name]} className="rounded-0 border-0 border-bottom" onChange={(e) => updateInfluencerData({"handle":e.target.value,"name":addRemoveSocial.social.name},"social")} />
                                  </FloatingLabel>
                                </>
                                )}
                              </Modal.Body>
                              <Modal.Footer className="border-0 pt-0 justify-content-between">
                                { addRemoveSocial.action == "edit" && ( 
                                <>
                                  <button type="button" className="btn btn-sm btn-outline-danger">Delete</button> 
                                  <button type="button" className="btn btn-sm btn-primary">Update</button> 
                                </>
                                ) }
                                { addRemoveSocial.action == "add" && ( <button type="button" className="btn btn-sm btn-primary">Add</button> ) }
                              </Modal.Footer>
                            </Modal>
                          )}
                        </Tab.Pane> */}

                        <Tab.Pane eventKey="contactDetails">
                          <div className="pb-2 d-block d-md-none">
                            <div className="d-flex align-items-center mb-2 py-2 px-3">
                              <a className="backArrow" onClick={() => setTabID(null)}>
                                <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                                </svg>
                              </a>
                              <div className="sectionTitle w-100 text-center">Contact Details</div>
                            </div>
                          </div>
                          <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                            <div className="py-4 px-3 px-lg-4 bg-white shadow rounded-xl">

                              <h4 className="mt-4 mb-4 ms-4 d-none d-md-block">Contact Details</h4>

                              <div className="mx-md-4">

                                <h6 className="mt-2 mb-2 fw-500">Delivery Address</h6>
                                <Row className="pt-2">
                                  <Col sm={12} md={6}>
                                    <FloatingLabel label="Address Line 1" className="mb-3" >
                                        <Form.Control type="text" value={selectedInfluencer.address.address1} id="address1" className="rounded-0 border-0 border-bottom" onChange={(e) => updateInfluencerData(e,"address")}  />
                                    </FloatingLabel>
                                  </Col>
                                  <Col sm={12} md={6}>
                                    <FloatingLabel label="Address Line 2" className="mb-3" >
                                        <Form.Control type="text" value={selectedInfluencer.address.address2} id="address2" className="rounded-0 border-0 border-bottom" onChange={(e) => updateInfluencerData(e,"address")}  />
                                    </FloatingLabel>
                                    </Col>
                                </Row>
                                <Row className="pt-2">
                                  <Col sm={12} md={6}>
                                    <FloatingLabel label="Town/City" className="mb-3" >
                                        <Form.Control type="text" value={selectedInfluencer.address.city} id="city" className="rounded-0 border-0 border-bottom" onChange={(e) => updateInfluencerData(e,"address")}  />
                                    </FloatingLabel>
                                    </Col>
                                  <Col sm={12} md={6}>
                                    <FloatingLabel label="Postal / Zip Code" className="mb-3" >
                                        <Form.Control type="text" value={selectedInfluencer.address.postcode} id="postcode" className="rounded-0 border-0 border-bottom" onChange={(e) => updateInfluencerData(e,"address")}  />
                                    </FloatingLabel>
                                    </Col>
                                </Row>
                                <Row className="pt-2">
                                  <Col sm={12} md={6}>
                                    <FloatingLabel label="Region" className="mb-3" >
                                        <Form.Control type="text" value={selectedInfluencer.address.region} id="region" className="rounded-0 border-0 border-bottom" onChange={(e) => updateInfluencerData(e,"address")}  />
                                    </FloatingLabel>
                                    </Col>
                                  <Col sm={12} md={6}>
                                    <div className="form-group customFloating mb-3">
                                      <label>Country</label>
                                      <Select
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        defaultValue={countries.filter(function(country) {return country.value == selectedInfluencer.address.country})}
                                        options={countries} 
                                        id="country" 
                                        onChange={ (choice) => updateInfluencerData({"target":{"value":choice.value,"id":"country"}},"address") }
                                      /> 
                                    </div> 
                                  </Col>
                                </Row>

                                <h6 className="mt-3 mb-2 fw-500">Contact Number</h6>
                                <Row className="pt-2">
                                  <Col sm={12} md={6}>
                                    <FloatingLabel label="Phone Number" className="mb-4" >
                                        <Form.Control type="text" value={selectedInfluencer.phoneNumber} id="phoneNumber" className="rounded-0 border-0 border-bottom" onChange={(e) => updateInfluencerData(e)}  />
                                    </FloatingLabel>
                                  </Col>
                                </Row>
                                <div className="d-flex align-items-center justify-content-between mb-lg-4">
                                  <button type="button" onClick={() => pushUpdates("contactDetails")} className="btn btn-sm btn-primary">Update</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        </>
                        )}

                        <Tab.Pane eventKey="communities">
                          <div className="pb-2 mobileView">
                            <div className="d-flex align-items-center mb-2 py-2 px-3">
                              <a className="backArrow" onClick={() => setTabID(null)}>
                                <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                                </svg>
                              </a>
                              <div className="sectionTitle w-100 text-center">Communities</div>
                            </div>
                          </div>
                          <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                            <div className="py-4 px-3 px-lg-4 bg-white shadow rounded-xl">

                              <h4 className="mt-4 mb-4 ms-4 desktopView">Communities</h4>

                              <div className="mx-md-4">
                                {communities && 
                                  <Row className="pt-2">
                                    <Tabs defaultActiveKey="joined" id="communityTabs" className="mb-3 align-items-center" >
                                      <Tab eventKey="joined" title={communities.joined.length > 0 ? `Joined (${communities.joined.length})` : "Joined"}>
                                        <Row>
                                          {communities.joined.length > 0 ? 
                                          <>
                                            {communities && communities.joined.map(community => (
                                              <Col xs={12} md={6} lg={6} xl={6} className="px-1" key={community.name}>
                                                <div className="panel w-100 bg-light d-flex align-items-center justify-content-start rounded position-relative p-3">
                                                  <div className="me-3">
                                                    <img src={getImageUrl(providers,community.profileImage,["w_75","c_scale"])} className="object-fit-cover rounded border me-2" />
                                                  </div>
                                                  <div>
                                                    <h5 className="mb-2 mt-1 text-capitalize text-truncate text-nowrap me-2">{community.name}</h5>      
                                                    <div className="text-muted small mb-2 pb-1">Industry - <span className="fw-bold">{community.industry}</span></div>                 
                                                    <div className="d-flex align-items-start">
                                                      <button type="button" onClick={() => {pushUpdates("community",{"action": "Leave", "brandID": community._id})}} className="btn btn-sm btn-danger me-2">Leave</button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Col>
                                            ))}
                                          </> :
                                            <div className="text-muted small">You haven't joined any communities yet.</div>
                                          }
                                        </Row>
                                      </Tab>
                                      <Tab eventKey="pending" title={communities.pending.length > 0 ? `Pending (${communities.pending.length})` : "Pending"}>
                                        <Row>
                                          {communities.pending.length > 0 ? 
                                            <>
                                            {communities && communities.pending.map(community => (
                                              <Col xs={12} md={6} lg={6} xl={6} className="px-1" key={community.name}>
                                                <div className="panel w-100 bg-light d-flex align-items-center justify-content-start rounded position-relative p-3">
                                                  <div className="me-3">
                                                    <img src={getImageUrl(providers,community.profileImage,["w_75","c_scale"])} className="object-fit-cover rounded border me-2" />
                                                  </div>
                                                  <div>
                                                    <h5 className="mb-2 mt-1 text-capitalize text-truncate text-nowrap me-2">{community.name}</h5>      
                                                    <div className="text-muted small mb-2 pb-1">Industry - <span className="fw-bold">{community.industry}</span></div>                 
                                                    <div className="d-flex align-items-start">
                                                      <button type="button" onClick={() => {pushUpdates("community",{"action": "Join", "brandID": community._id})}} className="btn btn-sm btn-success me-2">Join</button>
                                                      <button type="button" onClick={() => {pushUpdates("community",{"action": "Reject", "brandID": community._id})}} className="btn btn-sm btn-secondary me-2">Reject</button>
                                                      <button type="button" onClick={() => {pushUpdates("community",{"action": "Block", "brandID": community._id})}} className="btn btn-sm btn-danger me-2">Block</button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Col>
                                            ))}
                                          </> :
                                            <div className="text-muted small">You don't have any outstanding invitations for a community.</div>
                                          }
                                        </Row>
                                      </Tab>
                                      {communities.blocked.length > 0 &&
                                        <Tab eventKey="blocked" title={`Blocked (${communities.blocked.length})`}>
                                          <Row>
                                            {communities && communities.blocked.map(community => (
                                              <Col xs={12} md={6} lg={6} xl={6} className="px-1" key={community.name}>
                                                <div className="panel w-100 bg-light d-flex align-items-center justify-content-start rounded position-relative p-3">
                                                  <div className="me-3">
                                                    <img src={getImageUrl(providers,community.profileImage,["w_75","c_scale"])} className="object-fit-cover rounded border me-2" />
                                                  </div>
                                                  <div>
                                                    <h5 className="mb-2 mt-1 text-capitalize text-truncate text-nowrap me-2">{community.name}</h5>      
                                                    <div className="text-muted small mb-2 pb-1">Industry - <span className="fw-bold">{community.industry}</span></div>                 
                                                    <div className="d-flex align-items-start">
                                                      <button type="button" onClick={() => {pushUpdates("community",{"action": "Unblock", "brandID": community._id})}} className="btn btn-sm btn-secondary mb-lg-4">Unblock</button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Col>
                                            ))}
                                          </Row>
                                        </Tab>
                                      }
                                    </Tabs>
                                  </Row>
                                }
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="notifications">
                          <div className="pb-2 d-block d-md-none">
                            <div className="d-flex align-items-center mb-2 py-2 px-3">
                              <a className="backArrow" onClick={() => setTabID(null)}>
                                <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                                </svg>
                              </a>
                              <div className="sectionTitle w-100 text-center">Notifications</div>
                            </div>
                          </div>
                          <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                            <div className="py-4 px-3 px-lg-4 bg-white shadow rounded-xl">

                              <h4 className="mt-4 mb-4 ms-4 d-none d-md-block">Notifications</h4>
                              {notificationsChecked.checked && (
                                <>
                                {notificationsChecked.permissions == "denied" || notificationsChecked.permissions == "blocked" ? (
                                  <>
                                  <div className="d-flex align-items-center justify-content-center mb-4 small">
                                    <img src={warning} className="me-2" />
                                  </div>
                                  <div className="text-center mb-4 small">
                                    <span className="fw-500">Notifications are blocked on this device. Please change your settings to allow notifications for this device.</span>
                                  </div>
                                  </>
                                ) : (
                                  <>
                                  { !notificationsChecked.subscription && 
                                    <div className="text-center mb-4 small">
                                      <span><a className="fw-500 text-blue" onClick={() => getRegToken()} >Enable notifications on this device</a></span>
                                    </div>
                                  }
                                  </>
                                )}
                                </>

                              )}
                              {userData.associatedDevices.length > 0 && (
                                <div className="mx-md-4">
                                  <span><strong>Associated Devices</strong></span>

                                  <Row className="pt-2">
                                    <Col className="mb-4">
                                      {userData.associatedDevices.map(device => (
                                        <div key={device.name}>
                                          {device.deviceDetails.browser} on {device.deviceDetails.os} (added on {new Date(device.created*1000).toLocaleString('en-US', { month:"long", year:"numeric" })})
                                        </div>
                                      ))}
                                    </Col>
                                  </Row>
                                </div>
                              )}

                              <div className="mx-md-4">

                                <Row className="pt-2">

                                  <Col sm={12} md={6} className="mb-4">
                                    <h5 className="pb-2">General</h5>
                                    <h6 className="border-top pt-3 mb-2 fw-500">Room Notifications</h6>
                                    <div className="d-flex align-items-start justify-content-between mb-1">
                                      <div>Push Notifications</div>
                                      <Form.Check type="switch" id="pushNotifications" disabled={userData.associatedDevices.length == 0} label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("room.notifications") && userData.notificationPreferences["room.notifications"].includes("mobile")} onChange={(e) => updateUserData({"type":"mobile","value":e.target.checked,"name":"room.notifications"},"notifications")} />
                                    </div>
                                    <div className="d-flex align-items-start justify-content-between mb-2">
                                      <div>Email</div>
                                      <Form.Check type="switch" id="emailNotifications" label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("room.notifications") && userData.notificationPreferences["room.notifications"].includes("email")} onChange={(e) => updateUserData({"type":"email","value":e.target.checked,"name":"room.notifications"},"notifications")} />
                                    </div>
                                  </Col>

                                  <Col sm={12} md={6} className="mb-4">
                                    <h5 className="pb-2">Inbox</h5>
                                    <h6 className="border-top pt-3 mb-2 fw-500">New Message</h6>
                                    <div className="d-flex align-items-start justify-content-between mb-1">
                                      <div>Push Notifications</div>
                                      <Form.Check type="switch" id="pushNotifications" disabled={userData.associatedDevices.length == 0} label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("inbox.newMessage") && userData.notificationPreferences["inbox.newMessage"].includes("mobile")} onChange={(e) => updateUserData({"type":"mobile","value":e.target.checked,"name":"inbox.newMessage"},"notifications")} />
                                    </div>
                                    <div className="d-flex align-items-start justify-content-between mb-2">
                                      <div>Email</div>
                                      <Form.Check type="switch" id="emailNotifications" label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("inbox.newMessage") && userData.notificationPreferences["inbox.newMessage"].includes("email")} onChange={(e) => updateUserData({"type":"email","value":e.target.checked,"name":"inbox.newMessage"},"notifications")} />
                                    </div>
                                  </Col>

                                  {influencers.length > 0 && (
                                    <Col sm={12} md={6} className="mb-4">
                                      <h5 className="pb-2">Bids</h5>
                                      <h6 className="border-top pt-3 mb-2 fw-500">Bid Accepted</h6>
                                      <div className="d-flex align-items-start justify-content-between mb-1">
                                        <div>Push notifications</div>
                                        <Form.Check type="switch" id="pushNotifications" disabled={userData.associatedDevices.length == 0} label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.bidAccepted") && userData.notificationPreferences["campaign.bidAccepted"].includes("mobile")} onChange={(e) => updateUserData({"type":"mobile","value":e.target.checked,"name":"campaign.bidAccepted"},"notifications")} />
                                      </div>
                                      <div className="d-flex align-items-start justify-content-between mb-2">
                                        <div>Email</div>
                                        <Form.Check type="switch" id="emailNotifications" label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.bidAccepted") && userData.notificationPreferences["campaign.bidAccepted"].includes("email")} onChange={(e) => updateUserData({"type":"email","value":e.target.checked,"name":"campaign.bidAccepted"},"notifications")} />
                                      </div>
                                      <h6 className="border-top pt-3 mb-2 fw-500">Bid Declined</h6>
                                      <div className="d-flex align-items-start justify-content-between mb-1">
                                        <div>Push notifications</div>
                                        <Form.Check type="switch" id="pushNotifications" disabled={userData.associatedDevices.length == 0} label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.bidRejected") && userData.notificationPreferences["campaign.bidRejected"].includes("mobile")} onChange={(e) => updateUserData({"type":"mobile","value":e.target.checked,"name":"campaign.bidRejected"},"notifications")} />
                                      </div>
                                      <div className="d-flex align-items-start justify-content-between mb-2">
                                        <div>Email</div>
                                        <Form.Check type="switch" id="emailNotifications" label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.bidRejected") && userData.notificationPreferences["campaign.bidRejected"].includes("email")} onChange={(e) => updateUserData({"type":"email","value":e.target.checked,"name":"campaign.bidRejected"},"notifications")} />
                                      </div>
                                      <h6 className="border-top pt-3 mb-2 fw-500">Bid Completed</h6>
                                      <div className="d-flex align-items-start justify-content-between mb-1">
                                        <div>Push notifications</div>
                                        <Form.Check type="switch" id="pushNotifications" disabled={userData.associatedDevices.length == 0} label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.bidCompleted") && userData.notificationPreferences["campaign.bidCompleted"].includes("mobile")} onChange={(e) => updateUserData({"type":"mobile","value":e.target.checked,"name":"campaign.bidCompleted"},"notifications")} />
                                      </div>
                                      <div className="d-flex align-items-start justify-content-between mb-2">
                                        <div>Email</div>
                                        <Form.Check type="switch" id="emailNotifications" label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.bidCompleted") && userData.notificationPreferences["campaign.bidCompleted"].includes("email")} onChange={(e) => updateUserData({"type":"email","value":e.target.checked,"name":"campaign.bidCompleted"},"notifications")} />
                                      </div>
                                      <h6 className="border-top pt-3 mb-2 fw-500">Bid Completion Rejected</h6>
                                      <div className="d-flex align-items-start justify-content-between mb-1">
                                        <div>Push notifications</div>
                                        <Form.Check type="switch" id="pushNotifications" disabled={userData.associatedDevices.length == 0} label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.bidMarkedAsIncomplete") && userData.notificationPreferences["campaign.bidMarkedAsIncomplete"].includes("mobile")} onChange={(e) => updateUserData({"type":"mobile","value":e.target.checked,"name":"campaign.bidMarkedAsIncomplete"},"notifications")} />
                                      </div>
                                      <div className="d-flex align-items-start justify-content-between mb-2">
                                        <div>Email</div>
                                        <Form.Check type="switch" id="emailNotifications" label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.bidMarkedAsIncomplete") && userData.notificationPreferences["campaign.bidMarkedAsIncomplete"].includes("email")} onChange={(e) => updateUserData({"type":"email","value":e.target.checked,"name":"campaign.bidMarkedAsIncomplete"},"notifications")} />
                                      </div>
                                      <h6 className="border-top pt-3 mb-2 fw-500">Deadline Reminders</h6>
                                      <div className="d-flex align-items-start justify-content-between mb-1">
                                        <div>Push notifications</div>
                                        <Form.Check type="switch" id="pushNotifications" disabled={userData.associatedDevices.length == 0} label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.bidDeadline") && userData.notificationPreferences["campaign.bidDeadline"].includes("mobile")} onChange={(e) => updateUserData({"type":"mobile","value":e.target.checked,"name":"campaign.bidDeadline"},"notifications")} />
                                      </div>
                                      <div className="d-flex align-items-start justify-content-between mb-2">
                                        <div>Email</div>
                                        <Form.Check type="switch" id="emailNotifications" label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.bidDeadline") && userData.notificationPreferences["campaign.bidDeadline"].includes("email")} onChange={(e) => updateUserData({"type":"email","value":e.target.checked,"name":"campaign.bidDeadline"},"notifications")} />
                                      </div>
                                      <h6 className="border-top pt-3 mb-2 fw-500">Campaign Updates</h6>
                                      <div className="d-flex align-items-start justify-content-between mb-1">
                                        <div>Push notifications</div>
                                        <Form.Check type="switch" id="pushNotifications" disabled={userData.associatedDevices.length == 0} label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.updates") && userData.notificationPreferences["campaign.updates"].includes("mobile")} onChange={(e) => updateUserData({"type":"mobile","value":e.target.checked,"name":"campaign.updates"},"notifications")} />
                                      </div>
                                      <div className="d-flex align-items-start justify-content-between mb-2">
                                        <div>Email</div>
                                        <Form.Check type="switch" id="emailNotifications" label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.updates") && userData.notificationPreferences["campaign.updates"].includes("email")} onChange={(e) => updateUserData({"type":"email","value":e.target.checked,"name":"campaign.updates"},"notifications")} />
                                      </div>

                                    </Col>
                                  )}

                                  {influencers.length > 0 && (
                                    <Col sm={12} md={6} className="mb-4">

                                      <h5 className="pb-2">New Campaigns</h5>
                                      <h6 className="border-top pt-3 mb-2 fw-500">All Campaigns</h6>
                                      <div className="d-flex align-items-start justify-content-between mb-1">
                                        <div>Push notifications</div>
                                        <Form.Check type="switch" id="pushNotifications" disabled={userData.associatedDevices.length == 0} label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.createdAllType") && userData.notificationPreferences["campaign.createdAllType"].includes("mobile")} onChange={(e) => updateUserData({"type":"mobile","value":e.target.checked,"name":"campaign.createdAllType"},"notifications")} />
                                      </div>
                                      <div className="d-flex align-items-start justify-content-between mb-2">
                                        <div>Email</div>
                                        <Form.Check type="switch" id="emailNotifications" label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.createdAllType") && userData.notificationPreferences["campaign.createdAllType"].includes("email")} onChange={(e) => updateUserData({"type":"email","value":e.target.checked,"name":"campaign.createdAllType"},"notifications")} />
                                      </div>
                                      <h6 className="border-top pt-3 mb-2 fw-500">Sampling Campaigns</h6>
                                      <div className="d-flex align-items-start justify-content-between mb-1">
                                        <div>Push notifications</div>
                                        <Form.Check type="switch" id="pushNotifications" disabled={userData.associatedDevices.length == 0} label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.createdSamplingType") && userData.notificationPreferences["campaign.createdSamplingType"].includes("mobile")} onChange={(e) => updateUserData({"type":"mobile","value":e.target.checked,"name":"campaign.createdSamplingType"},"notifications")} />
                                      </div>
                                      <div className="d-flex align-items-start justify-content-between mb-2">
                                        <div>Email</div>
                                        <Form.Check type="switch" id="emailNotifications" label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.createdSamplingType") && userData.notificationPreferences["campaign.createdSamplingType"].includes("email")} onChange={(e) => updateUserData({"type":"email","value":e.target.checked,"name":"campaign.createdSamplingType"},"notifications")} />
                                      </div>
                                      <h6 className="border-top pt-3 mb-2 fw-500">Content Campaigns</h6>
                                      <div className="d-flex align-items-start justify-content-between mb-1">
                                        <div>Push notifications</div>
                                        <Form.Check type="switch" id="pushNotifications" disabled={userData.associatedDevices.length == 0} label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.createdContentType") && userData.notificationPreferences["campaign.createdContentType"].includes("mobile")} onChange={(e) => updateUserData({"type":"mobile","value":e.target.checked,"name":"campaign.createdContentType"},"notifications")} />
                                      </div>
                                      <div className="d-flex align-items-start justify-content-between mb-2">
                                        <div>Email</div>
                                        <Form.Check type="switch" id="emailNotifications" label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.createdContentType") && userData.notificationPreferences["campaign.createdContentType"].includes("email")} onChange={(e) => updateUserData({"type":"email","value":e.target.checked,"name":"campaign.createdContentType"},"notifications")} />
                                      </div>
                                      <h6 className="border-top pt-3 mb-2 fw-500">Gifting Campaigns</h6>
                                      <div className="d-flex align-items-start justify-content-between mb-1">
                                        <div>Push notifications</div>
                                        <Form.Check type="switch" id="pushNotifications" disabled={userData.associatedDevices.length == 0} label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.createdGiftingType") && userData.notificationPreferences["campaign.createdGiftingType"].includes("mobile")} onChange={(e) => updateUserData({"type":"mobile","value":e.target.checked,"name":"campaign.createdGiftingType"},"notifications")} />
                                      </div>
                                      <div className="d-flex align-items-start justify-content-between mb-2">
                                        <div>Email</div>
                                        <Form.Check type="switch" id="emailNotifications" label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.createdGiftingType") && userData.notificationPreferences["campaign.createdGiftingType"].includes("email")} onChange={(e) => updateUserData({"type":"email","value":e.target.checked,"name":"campaign.createdGiftingType"},"notifications")} />
                                      </div>
                                      <h6 className="border-top pt-3 mb-2 fw-500">Commerical Campaigns</h6>
                                      <div className="d-flex align-items-start justify-content-between mb-1">
                                        <div>Push notifications</div>
                                        <Form.Check type="switch" id="pushNotifications" disabled={userData.associatedDevices.length == 0} label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.createdCommercialType") && userData.notificationPreferences["campaign.createdCommercialType"].includes("mobile")} onChange={(e) => updateUserData({"type":"mobile","value":e.target.checked,"name":"campaign.createdCommercialType"},"notifications")} />
                                      </div>
                                      <div className="d-flex align-items-start justify-content-between mb-2">
                                        <div>Email</div>
                                        <Form.Check type="switch" id="emailNotifications" label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.createdCommercialType") && userData.notificationPreferences["campaign.createdCommercialType"].includes("email")} onChange={(e) => updateUserData({"type":"email","value":e.target.checked,"name":"campaign.createdCommercialType"},"notifications")} />
                                      </div>
                                      <h6 className="border-top pt-3 mb-2 fw-500">Cause Campaigns</h6>
                                      <div className="d-flex align-items-start justify-content-between mb-1">
                                        <div>Push notifications</div>
                                        <Form.Check type="switch" id="pushNotifications" disabled={userData.associatedDevices.length == 0} label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.createdCauseType") && userData.notificationPreferences["campaign.createdCauseType"].includes("mobile")} onChange={(e) => updateUserData({"type":"mobile","value":e.target.checked,"name":"campaign.createdCauseType"},"notifications")} />
                                      </div>
                                      <div className="d-flex align-items-start justify-content-between mb-2">
                                        <div>Email</div>
                                        <Form.Check type="switch" id="emailNotifications" label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.createdCauseType") && userData.notificationPreferences["campaign.createdCauseType"].includes("email")} onChange={(e) => updateUserData({"type":"email","value":e.target.checked,"name":"campaign.createdCauseType"},"notifications")} />
                                      </div>
                                      <h6 className="border-top pt-3 mb-2 fw-500">Similar Interests Campaigns</h6>
                                      <div className="d-flex align-items-start justify-content-between mb-1">
                                        <div>Push notifications</div>
                                        <Form.Check type="switch" id="pushNotifications" disabled={userData.associatedDevices.length == 0} label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.createdInterestType") && userData.notificationPreferences["campaign.createdInterestType"].includes("mobile")} onChange={(e) => updateUserData({"type":"mobile","value":e.target.checked,"name":"campaign.createdInterestType"},"notifications")} />
                                      </div>
                                      <div className="d-flex align-items-start justify-content-between mb-2">
                                        <div>Email</div>
                                        <Form.Check type="switch" id="emailNotifications" label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.createdInterestType") && userData.notificationPreferences["campaign.createdInterestType"].includes("email")} onChange={(e) => updateUserData({"type":"email","value":e.target.checked,"name":"campaign.createdInterestType"},"notifications")} />
                                      </div>
                                    </Col>
                                  )}

                                  {brands.length > 0 && (
                                    <>
                                    <Col sm={12} md={6} className="mb-4">
                                      <h5 className="pb-2">Campaigns</h5>
                                      <h6 className="border-top pt-3 mb-2 fw-500">New Bid</h6>
                                      <div className="d-flex align-items-start justify-content-between mb-1">
                                        <div>Push notifications</div>
                                        <Form.Check type="switch" id="pushNotifications" disabled={userData.associatedDevices.length == 0} label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.bidCreated") && userData.notificationPreferences["campaign.bidCreated"].includes("mobile")} onChange={(e) => updateUserData({"type":"mobile","value":e.target.checked,"name":"campaign.bidCreated"},"notifications")} />
                                      </div>
                                      <div className="d-flex align-items-start justify-content-between mb-2">
                                        <div>Email</div>
                                        <Form.Check type="switch" id="emailNotifications" label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.bidCreated") && userData.notificationPreferences["campaign.bidCreated"].includes("email")} onChange={(e) => updateUserData({"type":"email","value":e.target.checked,"name":"campaign.bidCreated"},"notifications")} />
                                      </div>
                                      <h6 className="border-top pt-3 mb-2 fw-500">Bid Marked as Complete</h6>
                                      <div className="d-flex align-items-start justify-content-between mb-1">
                                        <div>Push notifications</div>
                                        <Form.Check type="switch" id="pushNotifications" disabled={userData.associatedDevices.length == 0} label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.bidMarkedAsComplete") && userData.notificationPreferences["campaign.bidMarkedAsComplete"].includes("mobile")} onChange={(e) => updateUserData({"type":"mobile","value":e.target.checked,"name":"campaign.bidMarkedAsComplete"},"notifications")} />
                                      </div>
                                      <div className="d-flex align-items-start justify-content-between mb-2">
                                        <div>Email</div>
                                        <Form.Check type="switch" id="emailNotifications" label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.bidMarkedAsComplete") && userData.notificationPreferences["campaign.bidMarkedAsComplete"].includes("email")} onChange={(e) => updateUserData({"type":"email","value":e.target.checked,"name":"campaign.bidMarkedAsComplete"},"notifications")} />
                                      </div>
                                      <h6 className="border-top pt-3 mb-2 fw-500">Campaign Expiry Reminders</h6>
                                      <div className="d-flex align-items-start justify-content-between mb-1">
                                        <div>Push notifications</div>
                                        <Form.Check type="switch" id="pushNotifications" disabled={userData.associatedDevices.length == 0} label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.expiry") && userData.notificationPreferences["campaign.expiry"].includes("mobile")} onChange={(e) => updateUserData({"type":"mobile","value":e.target.checked,"name":"campaign.expiry"},"notifications")} />
                                      </div>
                                      <div className="d-flex align-items-start justify-content-between mb-2">
                                        <div>Email</div>
                                        <Form.Check type="switch" id="emailNotifications" label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("campaign.expiry") && userData.notificationPreferences["campaign.expiry"].includes("email")} onChange={(e) => updateUserData({"type":"email","value":e.target.checked,"name":"campaign.expiry"},"notifications")} />
                                      </div>
                                    </Col>

                                    <Col sm={12} md={6} className="mb-4">
                                    <h5 className="pb-2">Brand</h5>
                                    <h6 className="border-top pt-3 mb-2 fw-500">Campaign Credits Added</h6>
                                    <div className="d-flex align-items-start justify-content-between mb-1">
                                      <div>Push notifications</div>
                                      <Form.Check type="switch" id="pushNotifications" disabled={userData.associatedDevices.length == 0} label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("brand.addCredits") && userData.notificationPreferences["brand.addCredits"].includes("mobile")} onChange={(e) => updateUserData({"type":"mobile","value":e.target.checked,"name":"brand.addCredits"},"notifications")} />
                                    </div>
                                    <div className="d-flex align-items-start justify-content-between mb-2">
                                      <div>Email</div>
                                      <Form.Check type="switch" id="emailNotifications" label="" defaultChecked={userData.notificationPreferences.hasOwnProperty("brand.addCredits") && userData.notificationPreferences["brand.addCredits"].includes("email")} onChange={(e) => updateUserData({"type":"email","value":e.target.checked,"name":"brand.addCredits"},"notifications")} />
                                    </div>
                                    </Col>
                                    </>
                                  )}

                                </Row>
                                <div className="text-end">
                                  <button type="button" onClick={() => pushUpdates("notifications")} className="btn btn-sm btn-primary mb-lg-4">Update Notifications</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="security">
                          <div className="pb-2 d-block d-md-none">
                            <div className="d-flex align-items-center mb-2 py-2 px-3">
                              <a className="backArrow" onClick={() => setTabID(null)}>
                                <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                                </svg>
                              </a>
                              <div className="sectionTitle w-100 text-center">Security & login</div>
                            </div>
                          </div>
                          <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                            <div className="py-4 px-3 px-lg-4 bg-white shadow rounded-xl">

                              <h4 className="mt-4 mb-4 ms-4 d-none d-md-block">Security & login</h4>

                              <div className="mx-md-4">

                                <FloatingLabel label="Email" className="mb-4" >
                                  <Form.Control type="email" readOnly value={userData.email} className="rounded-0 border-0 border-bottom" />
                                </FloatingLabel>

                                <div className="mb-4 mx-4 text-center text-muted">
                                  To change your email address, please <a className="fw-500 text-blue" onClick={() => setTabID("support")}><u>contact support</u></a>
                                </div>

                              <Row>
                                <Col sm={12} md={6}>
                                  <FloatingLabel label="Current Password" className="mb-4" >
                                    <Form.Control type="password" className="rounded-0 border-0 border-bottom" value={passwordForm.current} onChange={(e) => updateUserData({name:"current",value:e.target.value},"password")} />
                                  </FloatingLabel>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={12} md={6}>
                                  <FloatingLabel label="New Password" className="mb-4" >
                                    <Form.Control type="password" className="rounded-0 border-0 border-bottom" value={passwordForm.new} onChange={(e) => updateUserData({name:"new",value:e.target.value},"password")} />
                                  </FloatingLabel>
                                </Col>
                                <Col sm={12} md={6}>
                                  <FloatingLabel label="Retype New Password" className="mb-4" >
                                    <Form.Control type="password" className="rounded-0 border-0 border-bottom" value={passwordForm.confirm} onChange={(e) => updateUserData({name:"confirm",value:e.target.value},"password")} />
                                  </FloatingLabel>
                                </Col>
                              </Row>
                              </div>

                              {passwordForm.new && (
                                <>
                                {passwordMeetsStandard(passwordForm.new).result == false ? (
                                  <div className="mb-4 mx-4 text-center">
                                    Password is invalid for the following reasons:<br />
                                    <ul>
                                    {passwordMeetsStandard(passwordForm.new).reasons.map(reason => (
                                      <li key={reason}>
                                          {reason}
                                      </li>
                                    ))}
                                    </ul>
                                  </div>
                                ) : (
                                  <>
                                  {passwordForm.confirm != passwordForm.new && (
                                    <div className="mb-4 mx-4 text-center">
                                      Passwords do not match
                                  </div>
                                  )}
                                  </>
                                )}
                                </>
                              )}

                              <div className="d-flex align-items-center justify-content-end mx-sm-4 mb-lg-4">
                                  <button type="button" onClick={() => pushUpdates("password")} disabled={passwordForm.new != passwordForm.confirm || passwordMeetsStandard(passwordForm.new).result == false} className="btn btn-sm btn-primary">Update Password</button>
                                </div>

                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="support">
                          <div className="pb-2 d-block d-md-none">
                            <div className="d-flex align-items-center mb-2 py-2 px-3">
                              <a className="backArrow" onClick={() => setTabID(null)}>
                                <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                                </svg>
                              </a>
                              <div className="sectionTitle w-100 text-center">Help & Support</div>
                            </div>
                          </div>
                          <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                            <div className="py-4 px-3 px-lg-4 bg-white shadow rounded-xl">

                              <h4 className="mt-4 mb-4 ms-4 d-none d-md-block">Help & Support</h4>

                              <SupportModal supportModal={supportModal} setSupportModal={setSupportModal} isInternal={true} isPopup={false} relatedIDs={[]}/>

                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="appearance">
                          <div className="pb-2 d-block d-md-none">
                            <div className="d-flex align-items-center mb-2 py-2 px-3">
                              <a className="backArrow" onClick={() => setTabID(null)}>
                                <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                                </svg>
                              </a>
                              <div className="sectionTitle w-100 text-center">Appearance</div>
                            </div>
                          </div>
                          <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                            <div className="py-4 px-3 px-lg-4 bg-white shadow rounded-xl">

                              <h4 className="mt-4 mb-4 ms-4 d-none d-md-block">Appearance</h4>

                              <Row className="mx-md-2 mb-md-3">
                                <Col sm={12} md={4}>
                                  <div className="rounded border p-3 mb-3">
                                    <Form.Check type="radio" name="appearance" className="mt-1" defaultChecked label="Automatic" />
                                  </div>
                                </Col>
                                <Col sm={12} md={4}>
                                  <div className="rounded border p-3 mb-3">
                                    <Form.Check type="radio" name="appearance" className="mt-1" label="Dark" />
                                  </div>
                                </Col>
                                <Col sm={12} md={4}>
                                  <div className="rounded border p-3 mb-3">
                                    <Form.Check type="radio" name="appearance" className="mt-1" label="Light" />
                                  </div>
                                </Col>

                                <Col sm={12} md={12}>
                                  <div className="text-end">
                                    <button className="btn btn-sm btn-primary">Save</button>
                                  </div>
                                </Col>
                              </Row>

                            </div>
                          </div>
                        </Tab.Pane>

                        {influencers.length > 0 && (
                          <Tab.Pane eventKey="referrals">
                            <div className="pb-2 d-block d-md-none">
                              <div className="d-flex align-items-center mb-2 py-2 px-3">
                              <a className="backArrow" onClick={() => setTabID(null)}>
                                <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                                </svg>
                              </a>
                                <div className="sectionTitle w-100 text-center">Refer a Friend</div>
                              </div>
                            </div>
                            <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                              <div className="py-4 px-3 px-lg-4 bg-white shadow rounded-xl">
                                <h4 className="mt-4 mb-4 ms-4 d-none d-md-block">Refer a Friend</h4>
                                <div className="mx-md-4">

                                  <div className="mb-4 mx-4 text-center">
                                    <p>Please feel free to share your referral code with any friends who you think might love The Influence Room as much as you!</p>
                                    
                                    <p>They'll need to apply <Link className="fw-500 text-blue" to={"/Signup/Influencer"}>here</Link> and enter your referral code - it's as easy as that! </p>

                                    <p>By sharing this code you agree to the <a onClick={() => setTabID("termsOfUse")} className="fw-500 text-blue">terms</a>. </p>
                                  </div>

                                  <FloatingLabel label="Share your referral code " className="mb-3" >
                                    <Form.Control type="text" readOnly value={userData.referralCode} className="rounded-0 border-0 border-bottom" />
                                  </FloatingLabel>
                                
                                  <div className="text-end mb-lg-4">
                                    <button className="btn btn-sm btn-primary" onClick={() => {navigator.clipboard.writeText(userData.referralCode); setAlert({"display":"toast","visible":true,"title":"Referral Code Copied","content":"Code copied to clipboard"})}}>Copy & Share</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        )}

                        <Tab.Pane eventKey="termsOfUse">
                          <div className="pb-2 d-block d-md-none">
                            <div className="d-flex align-items-center mb-2 py-2 px-3">
                              <a className="backArrow" onClick={() => setTabID(null)}>
                                <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                                </svg>
                              </a>
                              <div className="sectionTitle w-100 text-center">Terms & Conditions</div>
                            </div>
                          </div>
                          <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                            <div className="py-4 px-3 px-lg-4 bg-white shadow rounded-xl">

                              <h4 className="mt-4 mb-4 ms-4 d-none d-md-block">Terms & Conditions</h4>

                              <div className="mx-md-4">

                                <div className="mb-4">
                                {termsLoaded ? (
                                    <p className="text-wrap" dangerouslySetInnerHTML={{__html: terms}}></p>
                                  ) : (
                                    <FullPageSpinner />
                                  )}
                                </div>

                              </div>

                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="privacyPolicy">
                          <div className="pb-2 d-block d-md-none">
                            <div className="d-flex align-items-center mb-2 py-2 px-3">
                              <a className="backArrow" onClick={() => setTabID(null)}>
                                <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                                </svg>
                              </a>
                              <div className="sectionTitle w-100 text-center">Privacy Policy</div>
                            </div>
                          </div>
                          <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                            <div className="py-4 px-3 px-lg-4 bg-white shadow rounded-xl">

                              <h4 className="mt-4 mb-4 ms-4 d-none d-md-block">Privacy Policy</h4>

                              <div className="mx-md-4">

                                <div className="mb-4">
                                  {policyLoaded ? (
                                    <p align="justify" dangerouslySetInnerHTML={{__html: policy}}></p>
                                  ) : (
                                    <FullPageSpinner />
                                  )}
                                </div>

                              </div>

                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="rules">
                          <div className="pb-2 d-block d-md-none">
                            <div className="d-flex align-items-center mb-2 py-2 px-3">
                              <a className="backArrow" onClick={() => setTabID(null)}>
                                <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                                </svg>
                              </a>
                              <div className="sectionTitle w-100 text-center">The Influence Room Rules</div>
                            </div>
                          </div>
                          <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                            <div className="py-4 px-3 px-lg-4 bg-white shadow rounded-xl">

                              <h4 className="mt-4 mb-4 ms-4 d-none d-md-block">The Influence Room Rules</h4>

                              <div className="mx-md-4">

                                <div className="mb-4">
                                  {rulesLoaded ? (
                                    <p align="justify" dangerouslySetInnerHTML={{__html: rules}}></p>
                                  ) : (
                                    <FullPageSpinner />
                                  )}
                                </div>

                              </div>

                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="influencerTerms">
                          <div className="pb-2 d-block d-md-none">
                            <div className="d-flex align-items-center mb-2 py-2 px-3">
                              <a className="backArrow" onClick={() => setTabID(null)}>
                                <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                                </svg>
                              </a>
                              <div className="sectionTitle w-100 text-center">Influencer Terms & Conditions</div>
                            </div>
                          </div>
                          <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                            <div className="py-4 px-3 px-lg-4 bg-white shadow-sm rounded-xl">

                              <h4 className="mt-4 mb-4 ms-4 d-none d-md-block">Influencer Terms & Conditions</h4>

                              <div className="mx-md-4">

                                <div className="mb-4">
                                {influencerTermsLoaded ? (
                                    <p className="text-wrap" dangerouslySetInnerHTML={{__html: influencerTerms}}></p>
                                  ) : (
                                    <FullPageSpinner />
                                  )}
                                </div>

                              </div>

                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Container>  
              </Tab.Container>       
            </Container>
            <Footer />
          </div>
      );
    }
  }
  else {
    return ( 
      <div className="bg-page homePage">
        <Topbar />
        <Container className="fullscreenWithTopBar px-0" fluid>
          <FullPageSpinner />; 
        </Container>
        <Footer />
      </div>
     )
  }
}