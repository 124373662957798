import React, { useEffect, useState } from "react";
import { Container, Col, Row, Button, Dropdown, DropdownButton, Modal, Form, Table, InputGroup, Badge, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import AdminNav from '../../components/adminnav/AdminNav';
import { useImageContext } from "../../hooks/useImageContext";
import { getImageUrl } from "../../helpers/images";
import { useAuthContext } from "../../hooks/useAuthContext";
import { getAge, withinAgeRange } from "../../helpers/dates";
import { getSocialBadge, getMaxFollowers, getAverageEngagement } from "../../helpers/socials";
import { getSmallNumber } from "../../helpers/stats";
import { apiCall, PageScroller } from "../../helpers/api";
import { getFilter, setFilter } from '../../helpers/filters'
import { Link } from "react-router-dom"
import AlertModal from "../../components/alerts/alert";
import searchBlue from './../../images/search-blue.svg'
import { getIndustries, getCountries, getCounties, getStates } from "../../helpers/dropdowns";
import makeAnimated from 'react-select/animated';
import Select from 'react-select';

import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import './Profiles.css';
import Topbar from "../../components/topbar/Topbar";
import settings from './../../images/settings.svg';

export default function Profiles() {  
  const [showNewQuestionModal, setShowNewQuestionModal] = useState(false);
  const [showEditQuestionModal, setShowEditQuestionModal] = useState(false);
  const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState(false);
  const [showAnswersModal, setShowAnswersModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const { currentProfile } = useAuthContext();
  const [newQuestionData, setNewQuestionData] = useState({
      "question":"",
      "questionType":"",
      "options":["",""],
      "optionsType":"",
      "numberRange":{"minimum":"","maximum":""},
      "relatedCategories": []
  })
  const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
  const [pageStats, setPageStats] = useState({})
  const [answersPageStats, setAnswersPageStats] = useState({})
  const [updateCounter, setUpdateCounter] = useState(0)
  const animatedComponents = makeAnimated();
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [answersQuestionID, setAnswersQuestionID] = useState(null);
  const [editQuestionNum, setEditQuestionNum] = useState(null);
  const [deleteQuestionNum, setDeleteQuestionNum] = useState(null);
  const [editQuestionData, setEditQuestionData] = useState(null);

  const deleteQuestion = async (questionID) => {
    var response = await apiCall(`profile/deleteQuestion?questionID=${questionID}`,{ "method" : "DELETE"});
    if (response["success"]){
      setShowDeleteQuestionModal(false)
      setAlert({"display":"toast","visible":true,"title":"Question Deleted","content":`Question Deleted Successfully`,"className":"bg-success text-white"})
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to find user-related data","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  }

  function updateNewQuestionData(props, id=null, index=null, action=null){
    var tempQuestionData = structuredClone(newQuestionData);

    if (id === "relatedCategories") {
      tempQuestionData[id] = props
    }
    else if (id === "options") {
      if (action === "add") {
        tempQuestionData[id].push("")
      }
      else if (action === "remove") {
        tempQuestionData[id].splice(index,1)
      }
      else {
        tempQuestionData[id][index] = props.target.value
      }
      while (tempQuestionData[id].length < 2) {
        tempQuestionData[id].push("")
      }
    }
    else if (id === "optionsType") {
      tempQuestionData[id] = props
    }
    else if (id === "minVal") {
      tempQuestionData["numberRange"]["minimum"] = props.target.value
    }
    else if (id === "maxVal") {
      tempQuestionData["numberRange"]["maximum"] = props.target.value
    }
    else { 
      tempQuestionData[props.target.id] = props.target.value
    }
    setNewQuestionData(tempQuestionData)
  }

  function updateEditQuestionData(props, id=null, index=null, action=null){
    var tempQuestionData = structuredClone(editQuestionData);

    if (id === "relatedCategories") {
      tempQuestionData[id] = props
    }
    else if (id === "options") {
      if (action === "add") {
        tempQuestionData[id].push("")
      }
      else if (action === "remove") {
        tempQuestionData[id].splice(index,1)
      }
      else {
        tempQuestionData[id][index] = props.target.value
      }
      while (tempQuestionData[id].length < 2) {
        tempQuestionData[id].push("")
      }
    }
    else if (id === "optionsType") {
      tempQuestionData[id] = props
    }
    else if (id === "minVal") {
      tempQuestionData["numberRange"]["minimum"] = props.target.value
    }
    else if (id === "maxVal") {
      tempQuestionData["numberRange"]["maximum"] = props.target.value
    }
    else { 
      tempQuestionData[props.target.id] = props.target.value
    }
    setEditQuestionData(tempQuestionData)
  }

  async function submitNewQuestion(){
    var response = await apiCall('profile/addQuestion',{ "method" : "POST", "data" : newQuestionData });
    if (response["success"]){
        setAlert({"display":"toast","visible":true,"title":"Question Created","content":`Question Created Successfully`,"className":"bg-success text-white"})
        setShowNewQuestionModal(false)
        setNewQuestionData({
          "question":"",
          "questionType":"",
          "options":["",""],
          "optionsType": "",
          "numberRange": {"minimum":"","maximum":""},
          "relatedCategories": []
        })
        questions.push(response["success"]["question"])
        setQuestions(questions)
    }
    else if (response["failure"]) {
        setAlert({"display":"toast","visible":true,"title":"Could not create question","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  }

  async function submitEditQuestion(){
    var response = await apiCall('profile/updateQuestion',{ "method" : "PUT", "data" : editQuestionData });
    if (response["success"]){
        setAlert({"display":"toast","visible":true,"title":"Question Updated","content":`Question Updated Successfully`,"className":"bg-success text-white"})
        setShowEditQuestionModal(false)
        var tempQuestions = structuredClone(questions)
        tempQuestions[editQuestionNum] = editQuestionData
        setQuestions(tempQuestions)
    }
    else if (response["failure"]) {
        setAlert({"display":"toast","visible":true,"title":"Could not update question","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  }

  const setState = async (event,result) => { 
    result = await result;
    if (event.pageData.page >= 1) {
      for (var x in result["questions"]) {
        questions.push(result["questions"][x])  
      }
      setQuestions(questions)
    } 
    else {
      setQuestions(result["questions"])
    }
    setPageStats(result["_paging"])
  }

  const setAnswersState = async (event,result) => { 
    result = await result;
    if (event.pageData.page >= 1) {
      for (var x in result["answers"]) {
        answers.push(result["answers"][x])  
      }
      setAnswers(answers)
    } 
    else {
      if (result == null) {
        setShowAnswersModal(false)
        setAlert({"display":"toast","visible":true,"title":"Failed to find user-related data","content":"No answers found","className":"bg-danger text-white"})
        return
      }
      else {
        setAnswers(result["answers"])
      }
    }
    setAnswersPageStats(result["_paging"])
  }

  function ToggleButtonGroupCategories(value, updateFunction) {    
    return (
        <>
        <ToggleButtonGroup type="checkbox" id="relatedCategories" value={value.relatedCategories} onChange={choice => updateFunction(choice, "relatedCategories")}>
            <ToggleButton id="tbg-btn-1" className="me-2 mb-2" value="Activities">Activities</ToggleButton>
            <ToggleButton id="tbg-btn-2" className="me-2 mb-2" value="Arts & Culture">Arts & Culture</ToggleButton>
            <ToggleButton id="tbg-btn-3" className="me-2 mb-2" value="Automotive">Automotive</ToggleButton>
            <ToggleButton id="tbg-btn-4" className="me-2 mb-2" value="Beauty, Grooming & Fragrance">Beauty, Grooming & Fragrance</ToggleButton>
            <ToggleButton id="tbg-btn-5" className="me-2 mb-2" value="Books & Magazines">Books & Magazines</ToggleButton>
            <ToggleButton id="tbg-btn-6" className="me-2 mb-2" value="Charity & Nonprofit">Charity & Nonprofit</ToggleButton>
            <ToggleButton id="tbg-btn-7" className="me-2 mb-2" value="Children & Baby">Children & Baby</ToggleButton>
            <ToggleButton id="tbg-btn-8" className="me-2 mb-2" value="Entertainment & Media">Entertainment & Media</ToggleButton>
            <ToggleButton id="tbg-btn-9" className="me-2 mb-2" value="Environment">Environment</ToggleButton>
            <ToggleButton id="tbg-btn-10" className="me-2 mb-2" value="Fashion">Fashion</ToggleButton>
            <ToggleButton id="tbg-btn-11" className="me-2 mb-2" value="Food & Drink">Food & Drink</ToggleButton>
            <ToggleButton id="tbg-btn-12" className="me-2 mb-2" value="Health, Wellness & Personal Care">Health, Wellness & Personal Care</ToggleButton>
            <ToggleButton id="tbg-btn-13" className="me-2 mb-2" value="Holidays & Travel">Holidays & Travel</ToggleButton>
            <ToggleButton id="tbg-btn-14" className="me-2 mb-2" value="Home & Garden">Home & Garden</ToggleButton>
            <ToggleButton id="tbg-btn-15" className="me-2 mb-2" value="Lifestyle">Lifestyle</ToggleButton>
            <ToggleButton id="tbg-btn-16" className="me-2 mb-2" value="Pets">Pets</ToggleButton>
            <ToggleButton id="tbg-btn-17" className="me-2 mb-2" value="Restaurants, Bars & Clubs">Restaurants, Bars & Clubs</ToggleButton>
            <ToggleButton id="tbg-btn-18" className="me-2 mb-2" value="Sport & Fitness">Sport & Fitness</ToggleButton>
            <ToggleButton id="tbg-btn-19" className="me-2 mb-2" value="Technology, Gaming & Electronics">Technology, Gaming & Electronics</ToggleButton>
            <ToggleButton id="tbg-btn-20" className="me-2 mb-2" value="Tickets">Tickets</ToggleButton>
        </ToggleButtonGroup>
        </>
    );
  }

  return (
    <div className="bg-page profiles">
    <Topbar />
    <div className="pageName">Profiles</div>
    <Container id="applicant-list-container" className="fullscreenWithTopBar top-0" fluid>
      <AlertModal modal={showAlert} onClose={() => setAlert({"display":"toast","visible":false,"title":"","content":""})} />
      <Row>
        <AdminNav />
        <div className="col-xl-12 col-xxl-10 px-0">
          <div className="ps-4 py-3 py-xl-4 pe-0 bg-white shadow-sm">
            <div className="d-flex align-items-center justify-content-between ms-xl-4">
              <h1 className="pageTitle text-nowrap text-primary tragedyRegularFont fw-700 mb-0 fs-3">Profiles</h1>
              <Button variant="primary" size="sm" className="text-nowrap me-4" onClick={() => setShowNewQuestionModal(true)}>Add <span className='d-none d-sm-inline'>Question</span></Button>
            </div>
          </div>
          {showAnswersModal && (
            <Modal show backdrop="static" centered size="lg" >
              <Modal.Header className="pe-4">
                <Modal.Title className="h5">
                  View answers
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="pb-0">
                <Row>
                  <div className="p-4 pt-0 influencersTable mt-2 mt-xl-4 pb-0">
                    <div className="mx-xl-4">
                      <Table className="border-transparent">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Answer</th>
                          </tr>
                        </thead>
                        <tbody className="small">
                          {answers.map(answer => (
                            <tr key={answer._id}>
                              <td>
                                {answer.name}
                              </td>
                              <td>
                                {answer.email}
                              </td>
                              <td>
                                {!Array.isArray(answer.answer) ? typeof(answer.answer) === "boolean" ? answer.answer ? "Yes" : "No" : answer.answer : answer.answer.map((item, index) => index !== answer.answer.length-1 ? `${item}, ` : item )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Row>
              </Modal.Body>
              <PageScroller className="py-0" endpoint={`profile/answers?questionID=${answersQuestionID}`} setState={setAnswersState} pageSize={20} />
              <Modal.Footer className="border-0 pt-0">
                <Button className="position-relative" variant="outline-secondary" size="sm" onClick={()=>{setShowAnswersModal(false); setAnswers([]); setAnswersQuestionID(null)}}>Close</Button>
              </Modal.Footer>
            </Modal>
          )}
          {showEditQuestionModal && (
            <Modal show backdrop="static" centered size="lg" >
            <Modal.Header className="pe-4">
              <Modal.Title className="h5">
                Edit Question
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-0">
              <Row>
                <Col xs={12} sm={12} md={12}>
                    <Form.Group className="mb-3">
                        <Form.Label className="mb-1">Question <span className="text-danger">*</span></Form.Label>
                        <Form.Control type="question" className="" id="question" value={editQuestionData.question} onChange={(e) => updateEditQuestionData(e)} />
                    </Form.Group>
                </Col>

                <Col xs={12} sm={12} md={12} className="mb-3">
                  <label>Question Type <span className="text-danger">*</span></label>
                    <Select
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        defaultValue={{ label: editQuestionData.questionType, value: editQuestionData.questionType}}
                        options={[{label: "Text", value: "Text"}, {label: "Options", value: "Options"}, {label: "Yes/No", value: "Yes/No"}, {label: "Number", value: "Number"}]} 
                        id="questionType" 
                        onChange={ (choice) => updateEditQuestionData({"target":{"value":choice.value,"id":"questionType"}}) } 
                    />
                </Col>
                
                {editQuestionData.questionType === "Options" &&
                  <Form.Group className="mb-3">
                    <div className="row">
                      <Form.Label className="mb-1">Single or multiple options selection: <span className="text-danger">*</span></Form.Label>
                      <div className="border rounded p-2 mb-3 mx-2 col-4">
                          <Form.Check className="ms-1" type="radio" checked={editQuestionData.optionsType === "Single" ? true : false} onChange={(e)=> updateEditQuestionData("Single", e.target.name)} id="singleSelect" name="optionsType" label="Single" /> 
                      </div>   
                      <div className="border rounded p-2 mb-3 mx-2 col-4">
                          <Form.Check className="ms-1" type="radio" checked={editQuestionData.optionsType === "Multiple" ? true : false} onChange={(e)=> updateEditQuestionData("Multiple", e.target.name)} id="MultipleSelect" name="optionsType" label="Multiple" />    
                      </div>
                    </div>
                    {editQuestionData.options.map((option,index) => (
                      <div key={`Option ${index+1}`}>
                        <Form.Label className="my-1">Option {index+1} <span className="text-danger">*</span></Form.Label>
                        <div className="row">
                          <div className="col-11">
                            <Form.Control type="option" className="" id="options" value={option} onChange={(e) => updateEditQuestionData(e,e.target.id,index)} />
                          </div>
                          <div className="col-1 align-self-center ps-1">
                            <Button variant="outline-danger" size="sm" id="options" onClick={(e)=>{updateEditQuestionData(e,e.target.id,index,"remove")}}>X</Button>
                          </div>
                        </div>
                      </div>
                    ))}
                    <Button variant="outline-success mt-3" size="sm" id="options" onClick={(e)=>{updateEditQuestionData(e,e.target.id,null,"add")}}>Add option</Button>
                  </Form.Group>
                }

                {editQuestionData.questionType === "Number" &&
                  <Form.Group className="mb-3">
                    <div className="row">
                      <div className="col-6">
                        <Form.Label className="mb-1">Minimum Value <span className="text-danger">*</span></Form.Label>
                        <Form.Control type="number" id="minVal" className="" value={editQuestionData.numberRange["minimum"]} onChange={(e) => updateEditQuestionData(e, e.target.id)} />
                      </div>
                      <div className="col-6">
                        <Form.Label className="mb-1">Maximum Value <span className="text-danger">*</span></Form.Label>
                        <Form.Control type="number" id="maxVal" className="" value={editQuestionData.numberRange["maximum"]} onChange={(e) => updateEditQuestionData(e, e.target.id)} />
                      </div>
                    </div>
                  </Form.Group>
                }

                <div className="form-group categoryButton">
                    <>
                      <label>Categories: <span className="text-danger">*</span></label>             
                      {ToggleButtonGroupCategories(editQuestionData, updateEditQuestionData)}
                    </>
                </div>
      
              </Row>
            </Modal.Body>
            <Modal.Footer className="border-0 pt-0">
              <Button variant="outline-secondary" size="sm" onClick={()=>{setShowEditQuestionModal(false);  setEditQuestionNum(null)}}>Cancel</Button>
              <Button disabled={["question","questionType","relatedCategories"].filter(function(field) {return editQuestionData[field] == ""}).length > 0 || (editQuestionData.questionType === "Options" && [...editQuestionData["options"], editQuestionData["optionsType"]].filter(option => option === "").length > 0) || (editQuestionData.questionType === "Number" && [editQuestionData["numberRange"]["minimum"], editQuestionData["numberRange"]["maximum"]].filter(option => option === "").length > 0)} onClick={() => submitEditQuestion()} variant="success" size="sm">Submit</Button>
            </Modal.Footer>
          </Modal>
          )}
          {showNewQuestionModal && (
            <Modal show backdrop="static" centered size="lg" >
            <Modal.Header className="pe-4">
              <Modal.Title className="h5">
                Add New Question
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-0">
              <Row>
                <Col xs={12} sm={12} md={12}>
                    <Form.Group className="mb-3">
                        <Form.Label className="mb-1">Question <span className="text-danger">*</span></Form.Label>
                        <Form.Control type="question" className="" id="question" value={newQuestionData.question} onChange={(e) => updateNewQuestionData(e)} />
                    </Form.Group>
                </Col>
      
                <Col xs={12} sm={12} md={12} className="mb-3">
                  <label>Question Type <span className="text-danger">*</span></label>
                    <Select
                        closeMenuOnSelect={true}
                        isSearchable={false}
                        components={animatedComponents}
                        defaultValue={[]}
                        options={[{label: "Text", value: "Text"}, {label: "Options", value: "Options"}, {label: "Yes/No", value: "Yes/No"}, {label: "Number", value: "Number"}]} 
                        id="questionType" 
                        onChange={ (choice) => updateNewQuestionData({"target":{"value":choice.value,"id":"questionType"}}) } 
                    />
                </Col>
                
                {newQuestionData.questionType === "Options" &&
                  <Form.Group className="mb-3">
                    <div className="row">
                      <Form.Label className="mb-1">Single or multiple options selection: <span className="text-danger">*</span></Form.Label>
                      <div className="border rounded p-2 mb-3 mx-2 col-4">
                          <Form.Check className="ms-1" type="radio" checked={newQuestionData.optionsType === "Single" ? true : false} onChange={(e)=> updateNewQuestionData("Single", e.target.name)} id="singleSelect" name="optionsType" label="Single" /> 
                      </div>   
                      <div className="border rounded p-2 mb-3 mx-2 col-4">
                          <Form.Check className="ms-1" type="radio" checked={newQuestionData.optionsType === "Multiple" ? true : false} onChange={(e)=> updateNewQuestionData("Multiple", e.target.name)} id="MultipleSelect" name="optionsType" label="Multiple" />    
                      </div>
                    </div>
                    {newQuestionData.options.map((option,index) => (
                      <div key={`Option ${index+1}`}>
                        <Form.Label className="my-1">Option {index+1} <span className="text-danger">*</span></Form.Label>
                        <div className="row">
                          <div className="col-11">
                            <Form.Control type="option" className="" id="options" value={option} onChange={(e) => updateNewQuestionData(e,e.target.id,index)} />
                          </div>
                          <div className="col-1 align-self-center ps-1">
                            <Button variant="outline-danger" size="sm" id="options" onClick={(e)=>{updateNewQuestionData(e,e.target.id,index,"remove")}}>X</Button>
                          </div>
                        </div>
                      </div>
                    ))}
                    <Button variant="outline-success mt-3" size="sm" id="options" onClick={(e)=>{updateNewQuestionData(e,e.target.id,null,"add")}}>Add option</Button>
                  </Form.Group>
                }

                {newQuestionData.questionType === "Number" &&
                  <Form.Group className="mb-3">
                    <div className="row">
                      <div className="col-6">
                        <Form.Label className="mb-1">Minimum Value <span className="text-danger">*</span></Form.Label>
                        <Form.Control type="number" id="minVal" className="" value={newQuestionData.numberRange["minimum"]} onChange={(e) => updateNewQuestionData(e, e.target.id)} />
                      </div>
                      <div className="col-6">
                        <Form.Label className="mb-1">Maximum Value <span className="text-danger">*</span></Form.Label>
                        <Form.Control type="number" id="maxVal" className="" value={newQuestionData.numberRange["maximum"]} onChange={(e) => updateNewQuestionData(e, e.target.id)} />
                      </div>
                    </div>
                  </Form.Group>
                }

                <div className="form-group categoryButton">
                    <label>Categories: <span className="text-danger">*</span></label>             
                    {ToggleButtonGroupCategories(newQuestionData, updateNewQuestionData)}
                </div>
      
              </Row>
            </Modal.Body>
            <Modal.Footer className="border-0 pt-0">
              <Button variant="outline-secondary" size="sm" onClick={()=>{setShowNewQuestionModal(false);  setNewQuestionData({"question":"","questionType":"","options":["",""],"optionsType":"","numberRange":{"minimum":"","maximum":""},"relatedCategories":[]})}}>Cancel</Button>
              <Button disabled={["question","questionType","relatedCategories"].filter(function(field) {return newQuestionData[field] == ""}).length > 0 || (newQuestionData.questionType === "Options" && [...newQuestionData["options"], newQuestionData["optionsType"]].filter(option => option === "").length > 0) || (newQuestionData.questionType === "Number" && [newQuestionData["numberRange"]["minimum"], newQuestionData["numberRange"]["maximum"]].filter(option => option === "").length > 0)} onClick={() => submitNewQuestion()} variant="success" size="sm">Submit</Button>
            </Modal.Footer>
          </Modal>
          )}
          {showDeleteQuestionModal && (
            <Modal show backdrop="static" centered size="lg" >
            <Modal.Header className="pe-4">
              <Modal.Title className="h5">
                Are you sure you want to delete this question?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-0">
              <Row className="ms-1">
                Question: {questions[deleteQuestionNum].question}
              </Row>
            </Modal.Body>
            <Modal.Footer className="border-0 pt-0">
              <Button variant="outline-secondary" size="sm" onClick={()=>{setShowDeleteQuestionModal(false)}}>Cancel</Button>
              <Button onClick={() => {deleteQuestion(questions[deleteQuestionNum].questionID)}} variant="danger" size="sm">Delete</Button>
            </Modal.Footer>
          </Modal>
          )}

          { !isLoaded ? <FullPageSpinner /> : (     
            <div className="p-4 pt-0 influencersTable mt-2 mt-xl-4 pb-0">
              <div className="mx-xl-4">
                <div className="tableHeaderFixedBelt"></div>
                <Table className="border-transparent">
                  <thead>
                    <tr>
                      <th>Question</th>
                      <th className="text-nowrap"><span className="d-none d-md-inline">Question</span> Type</th>
                      <th className="text-nowrap">Categories</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {questions.map((question,index) => (
                      <tr key={question.questionID}>
                        <td className="fw-bold">
                            {question.question}
                        </td>
                        <td className="text-nowrap">
                          {question.questionType}
                        </td>
                        <td>
                          {question.relatedCategories.map((category, index) => (index !== question.relatedCategories.length-1 ? category + " | " : category))}
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            <Dropdown className="brandAction">
                              <Dropdown.Toggle variant="default" className="border-0">
                                <img src={settings} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                              <Link onClick={() => {setShowEditQuestionModal(true);setEditQuestionData(questions[index]);setEditQuestionNum(index)}} className="dropdown-item">Edit</Link>
                              <Link onClick={() => {setAnswersQuestionID(question.questionID);setShowAnswersModal(true)}} className="dropdown-item">View Answers</Link>
                              <Link onClick={() => {setShowDeleteQuestionModal(true);setDeleteQuestionNum(index)}} className="dropdown-item">Delete</Link>
                            </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <PageScroller endpoint="profile/questions" setState={setState} pageSize={100} />
            </div>
          )}
          </div>
        </Row>
      </Container>
    </div>
  );
}