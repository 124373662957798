import { ImageContext } from "../context/imageContext";
import { useContext } from "react";

export const useImageContext = () => {
    const context = useContext(ImageContext)

    if (!context) {
        throw Error("useImageContext must be used inside ImageContext")
    }

    return context
}
