import React, { Component } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import { useImageContext } from "./hooks/useImageContext";

import Home from "./pages/home/Home";
import BrowseWall from "./pages/home/BrowseWall";
import List from "./pages/home/List";
import MyBid from "./pages/bids/MyBid";
import BidsList from "./pages/bids/BidsList";
import Inbox from "./pages/inbox/Inbox";
import Notifications from "./pages/notifications/Notifications";
import Settings from "./pages/settings/Settings";
import BrandSignup from "./pages/signup/brand/Signup";
import InfluencerSignup from "./pages/signup/Influencer/Signup";
import AgencySignup from "./pages/signup/agency/Signup";
import Applications from "./pages/applications/Applications";
import Dashboard from "./pages/dashboard/Dashboard";
import Brands from "./pages/brands/Brands";
import BrandSettings from "./pages/brands/BrandSettings";
import AdminSettings from "./pages/settings/AdminSettings";
import BrandDashboard from "./pages/brands/BrandDashboard";
import BrandCommunity from "./pages/brands/BrandCommunity";
import MyCampaign from "./pages/campaigns/MyCampaign";
import MyCampaigns from "./pages/campaigns/MyCampaigns";
import CampaignsPreview from "./pages/campaigns/Preview";
import CampaignDetail from "./pages/campaigns/CampaignDetail";
import CreateCampaigns from "./pages/campaigns/CreateCampaign";
import Influencers from "./pages/influencers/Influencers";
import InfluencerSettings from "./pages/influencers/InfluencerSettings";
import Users from "./pages/users/Users";
import UserSettings from "./pages/users/UserSettings";
import Login from "./pages/login/login";
import FourOhFour from "./pages/errors/404";
import SignUp from "./pages/signup/signup";
import FullPageSpinner from "./components/spinner/FullPageSpinner";
import InfluencerCompleteSignup from "./pages/signup/Influencer/CompleteSignup";
import CompleteBrandSignup from "./pages/signup/brand/CompleteSignup";
import PaymentSuccessful from "./pages/signup/brand/PaymentSuccessful";
import PaymentFailed from "./pages/signup/brand/PaymentFailed";
import NoViews from "./pages/errors/noViews";
import Verify from "./pages/signup/verify";
import PasswordReset from "./pages/passwordReset/PasswordReset";
import Unsubscribe from "./pages/unsubscribe/Unsubscribe";
import Reporting from "./pages/reporting/Reporting";
import Profiles from "./pages/profiles/Profiles";
import CampaignCTADashboard from "./pages/campaigns/CampaignCTADashboard";
import Referrals from "./pages/referral/Referrals";

export const ProtectedRoute = ({ children, viewers = [] }) => {
    const { data, currentProfile } = useAuthContext();

    if (process.env.REACT_APP_DISABLE_AUTH == "true"){
        return children
    }

    // If user exists (i.e. logged in)
    if (!data) {
        return <Navigate to="/Login" replace/>;
    }
    // If the user has a view that matches the route
    else if (!viewers.includes(currentProfile.type)){
        return <Navigate to="/404" replace/>;
    }

    return children;
};   

class AppRoutes extends Component {
    render() {
        return (
            <>
            {(this.props.loading || this.props.providersLoading) && ( 
                <FullPageSpinner />
            )}
            {(!this.props.loading && !this.props.providersLoading) && (
                <BrowserRouter>
                    <Routes>
                        {/* Protected routes for influencers and brands */}
                        <Route exact path="/Home" element={<ProtectedRoute viewers={["influencers"]}><Home /></ProtectedRoute>} />
                        <Route exact path="/List" element={<ProtectedRoute viewers={["influencers"]}><List /></ProtectedRoute>} />
                        <Route exact path="/MyBid" element={<ProtectedRoute viewers={["influencers"]}><MyBid /></ProtectedRoute>} />
                        <Route exact path="/MyBids" element={<ProtectedRoute viewers={["influencers"]}><BidsList /></ProtectedRoute>} />
                        <Route exact path="/Signup/Complete" element={<ProtectedRoute viewers={["brands"]}><CompleteBrandSignup /></ProtectedRoute>} />
                        <Route exact path="/Signup/PaymentComplete" element={<ProtectedRoute viewers={["brands"]}><PaymentSuccessful /></ProtectedRoute>} />
                        <Route exact path="/Signup/PaymentIncomplete" element={<ProtectedRoute viewers={["brands"]}><PaymentFailed /></ProtectedRoute>} />
                        <Route exact path="/Inbox" element={<ProtectedRoute viewers={["influencers","brands","admins"]}><Inbox /></ProtectedRoute>} />
                        <Route exact path="/Notifications" element={<ProtectedRoute viewers={["influencers","brands","admins"]}><Notifications /></ProtectedRoute>} />
                        <Route exact path="/Settings" element={<ProtectedRoute viewers={["influencers","brands","admins"]}><Settings /></ProtectedRoute>} />
                        <Route exact path="/Campaigns" element={<ProtectedRoute viewers={["brands","admins"]}><MyCampaigns /></ProtectedRoute>} />
                        <Route exact path="/Dashboard" element={<ProtectedRoute viewers={["brands","admins"]}><BrandDashboard /></ProtectedRoute>} />
                        <Route exact path="/Community" element={<ProtectedRoute viewers={["brands","admins"]}><BrandCommunity /></ProtectedRoute>} />
                        <Route exact path="/Campaign" element={<ProtectedRoute viewers={["influencers","brands","admins"]}><CampaignDetail /></ProtectedRoute>} />
                        <Route exact path="/Campaign/Manage" element={<ProtectedRoute viewers={["brands"]}><MyCampaign /></ProtectedRoute>} />
                        <Route exact path="/Campaign/CampaignDashboard" element={<ProtectedRoute viewers={["brands","admins"]}><CampaignCTADashboard /></ProtectedRoute>} />
                        <Route exact path="/Campaign/Preview" element={<ProtectedRoute viewers={["brands","admins"]}><CampaignsPreview /></ProtectedRoute>} />
                        <Route exact path="/Campaign/Create" element={<ProtectedRoute viewers={["brands","admins"]}><CreateCampaigns /></ProtectedRoute>} />
                        <Route exact path="/Administration/Influencers" element={<ProtectedRoute viewers={["influencers","brands","admins"]}><Influencers /></ProtectedRoute>} />
                        <Route exact path="/Administration/Influencers/Settings" element={<ProtectedRoute viewers={["influencers","brands","admins"]}><InfluencerSettings /></ProtectedRoute>} />
                        <Route exact path="/Administration/Brands" element={<ProtectedRoute viewers={["influencers","brands","admins"]}><Brands /></ProtectedRoute>} />
                        <Route exact path="/Administration/Brands/Settings" element={<ProtectedRoute viewers={["influencers","brands","admins"]}><BrandSettings /></ProtectedRoute>} />
                        
                        {/* Protected routes for administrators */}

                        <Route exact path="/Administration/Users" element={<ProtectedRoute viewers={["admins"]}><Users /></ProtectedRoute>} />
                        <Route exact path="/Administration/Users/Settings" element={<ProtectedRoute viewers={["admins"]}><UserSettings /></ProtectedRoute>} />
                        <Route exact path="/Administration/Applications" element={<ProtectedRoute viewers={["admins"]}><Applications /></ProtectedRoute>} />
                        <Route exact path="/Administration/Dashboard" element={<ProtectedRoute viewers={["admins"]}><Dashboard /></ProtectedRoute>} />
                        <Route exact path="/Administration/Settings" element={<ProtectedRoute viewers={["admins"]}><AdminSettings /></ProtectedRoute>} />
                        <Route exact path="/Administration/BrowseWall" element={<ProtectedRoute viewers={["admins"]}><BrowseWall /></ProtectedRoute>} />
                        <Route exact path="/Administration/Reporting" element={<ProtectedRoute viewers={["admins"]}><Reporting /></ProtectedRoute>} />
                        <Route exact path="/Administration/Profiles" element={<ProtectedRoute viewers={["admins"]}><Profiles /></ProtectedRoute>} />
                        <Route exact path="/Administration/Referrals" element={<ProtectedRoute viewers={["admins"]}><Referrals /></ProtectedRoute>} />

                        {/* Unprotected routes anyone can reach */}
                        <Route exact path="/Signup" element={<SignUp />} />
                        <Route exact path="/Signup/Brand" element={<BrandSignup />} />
                        <Route exact path="/Signup/Influencer" element={<InfluencerSignup />} />
                        <Route exact path="/Signup/Finish" element={<InfluencerCompleteSignup />} />
                        <Route exact path="/Signup/Agency" element={<AgencySignup />} />
                        <Route exact path="/Verify" element={<Verify />} />
                        <Route exact path="/PasswordReset" element={<PasswordReset />} />
                        <Route exact path="/Unsubscribe" element={<Unsubscribe />} />

                        {/* Routes just for logged in users */}
                        {(this.props.user != null || process.env.REACT_APP_DISABLE_AUTH == "true") && (
                            <>
                            { (this.props.currentProfile.type == "influencers" && ( <Route exact path="/" element={<Home />} /> )) || (this.props.currentProfile.type == "brands" && <Route exact path="/" element={<MyCampaigns />} /> ) || (this.props.currentProfile.type == "admins" && <Route exact path="/" element={<Dashboard />} />) || <Route exact path="/*" element={<NoViews />} /> }
                            <Route exact path="/*" element={<FourOhFour />} />
                            </>
                        )}
                        {/* Routes for just logged out users */}
                        {this.props.user == null && (
                            <>
                            <Route exact path="/Login" element={<Login />} />
                            {process.env.REACT_APP_DISABLE_AUTH == "false" && (<Route exact path="/*" element={<Login />} />) && (<Route exact path="/" element={<Login />} />)}
                            </>
                        )}
                    </Routes>
                </BrowserRouter>
                )}
            </>
        )
    }
}

export default function (props) {
    const { data, views, currentProfile, loading } = useAuthContext();
    const { providersLoading } = useImageContext();
    return <AppRoutes loading={loading} providersLoading={providersLoading} user={data} views={views} currentProfile={currentProfile} />;
}
  