import DOMPurify from 'dompurify';

export function formatText(text) {
    var linkRegex = /(https?:\/\/[^\s]+)/gi;
    var replacedText = text.replaceAll("\n"," <br> ").replaceAll(linkRegex,'<a href="$1" target="_blank">$1</a>');
    return <div dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(replacedText,{ ALLOWED_TAGS: ['b','br','a'], ADD_ATTR: ['target'] } ) } }></div>
}

export function capitalise(text) {
    var textChunks = text.split(" ")
    for (var i = 0; i < textChunks.length; i++){
        textChunks[i] = textChunks[i].charAt(0).toUpperCase() + textChunks[i].slice(1)
    }
    return textChunks.join(" ")
}