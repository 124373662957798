import React, { useState } from "react";
import { apiCall } from './../../helpers/api';
import { Container, Row, FloatingLabel, Col } from 'react-bootstrap';
import './PasswordReset.css';
import Form from 'react-bootstrap/Form';
import SignupFooter from "../../components/footer/SignupFooter";
import AlertModal from "../../components/alerts/alert";
import { passwordMeetsStandard } from "../../helpers/user"
import theRoom from './../../images/theRoom.svg';
import theRoomIcon from './../../images/theRoom-icon.svg';

export default function CompleteSignup() {
    const [signupData, setSignupData] = useState({
        "password":"",
        "passwordConfirm":""
    });
    const [changeCounter, setChangeCounter] = useState(0);
    const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
    var currentTime = new Date().getTime() / 1000;
    let urlParams = new URLSearchParams(window.location.search);

    async function submit() {
        var response = await apiCall('resetPassword?'+urlParams.toString(),{ "method" : "POST", "data" : {"new":signupData["password"],"confirm":signupData["passwordConfirm"]} } );
        if (response["success"]){
            setAlert({"display":"toast","visible":true,"title":"Password Confirmed","content":`Your password has been updated! We'll take you back to the login screen now in 3 seconds`,"className":"bg-success text-white"})
            var timeLeft = 2;
            var redirectTimer = setInterval(function(){
                if(timeLeft <= 0){
                    clearInterval(redirectTimer);
                    window.location.href=`/Login?username=${response["success"]["username"]}`;
                }
                setAlert({"display":"toast","visible":true,"title":"Password Confirmed","content":`Your password has been updated! We'll take you back to the login screen now in ${timeLeft} seconds`,"className":"bg-success text-white"})
                timeLeft -= 1;
            }, 1000);
        }
        else if (response["failure"]){
            setAlert({"display":"toast","visible":true,"title":"Could not confirm password","content":response["failure"]["error"],"className":"bg-danger text-white"})
        }
    }

    function updateSignupDataState(props, id=null) {
        var tempSignupData = Object.assign({},signupData);
        tempSignupData[props.target.id] = props.target.value;
        setSignupData(tempSignupData); 
        setChangeCounter(changeCounter + 1)
    }   

    if (signupData) {
        return (
        <div className="login-wrapper">
            <Container fluid className="loginBody">
                <AlertModal modal={showAlert} onClose={() => setAlert({"display":"toast","visible":false,"title":"","content":""})} />
                <Row className='h-100'>
                    <div className='col-md-6 col-lg-5 h-100 desktopView'>
                        <div className='d-lg-flex align-items-center justify-content-end h-100'>
                        <img src={theRoom} height="80px" className='me-5 pe-5' />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-7 loginBox bg-white h-100 d-lg-flex align-items-center justify-content-start">     
                        <div className="mb-4 py-2 px-0 mt-4 position-relative mobileView">
                        <div className='w-100 text-center position-relative'>
                            <img src={theRoomIcon} height="50px" />
                        </div>
                        <div className="sectionTitle text-truncate"></div>
                        </div>       
                        {urlParams.get("expiry") > currentTime || !urlParams.get("expiry") ? (   
                        <div className='d-block ms-lg-5 ps-lg-5 mx-lg-0 mx-4'>     
                            <form>
                                <h3 className='mb-4 pb-2 text-navy'>Password Reset</h3>    
                                <div className="text-start">
                                    <label className='mb-3'>
                                        <div className='text-start'>Your New Password</div>
                                        <input type="password" className='form-control bg-gray border-gray' id="password" value={signupData.password} onChange={(e) => updateSignupDataState(e)} />
                                    </label>
                                    <label className='mb-3'>
                                        <div className='text-start'>Retype Your New Password</div>
                                        <input type="password" className='form-control bg-gray border-gray' id="passwordConfirm" value={signupData.passwordConfirm} onChange={(e) => updateSignupDataState(e)} />
                                    </label>
                                    <button type="button" onClick={() => submit()} disabled={signupData.passwordConfirm == "" || signupData.password == "" || signupData.passwordConfirm != signupData.password} className="btn btn-primary w-auto">Set Password</button>
                                </div>
                                {signupData.password && (
                                    <>
                                    {passwordMeetsStandard(signupData.password).result == false ? (
                                        <div className="mt-4 small">
                                        <strong>Password is invalid for the following reasons:</strong><br />
                                        <ul>
                                        {passwordMeetsStandard(signupData.password).reasons.map(reason => (
                                            <li key={reason}>
                                                {reason}
                                            </li>
                                        ))}
                                        </ul>
                                        </div>
                                    ) : (
                                        <>
                                        {signupData.passwordConfirm != signupData.password && (
                                        <div className="mt-4 small text-danger">
                                            Passwords do not match
                                        </div>
                                        )}
                                        </>
                                    )}
                                    </>
                                )}
                            </form>
                        </div>
                        ) : (           
                        <form>
                            <div className='d-block ms-lg-5 ps-lg-5 mx-lg-0 mx-4'>
                                <h2 className='mb-4 pb-2 text-navy'>This Link Has Expired</h2>  
                                <a href="/" className="btn btn-sm btn-outline-primary">Back to Home</a>
                            </div>
                        </form>
                        )}
                    </div>
                </Row>
            </Container>    
            <SignupFooter />
        </div>
        );
    }
}