import React from 'react';
import { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import {  NavLink } from 'react-router-dom'
import { Container } from 'react-bootstrap';
import { useAuthContext } from "../../hooks/useAuthContext";

import './Footer.css';

class Footer extends Component {
  render() {
    if (this.props.currentProfile.type != "admins"){
      return (
        <footer className="mobileFooter">
          <Container fluid>
            <div className="d-flex justify-content-around">
              {this.props.currentProfile.type == "influencers" && (
                <NavLink activeclassname="active" to="/Home">
                  <div className="footerItem">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M576 256c0-4.435-1.83-8.841-5.422-12l-272-240c-3.016-2.656-6.797-3.997-10.58-3.997S280.4 1.344 277.4 4l-272 240C1.83 247.2-.0005 251.6-.0005 256c0 8.924 7.241 15.99 16.05 15.99c3.758 0 7.521-1.313 10.53-3.993L64 234.1V464C64 490.5 85.53 512 112 512h80.01C218.5 512 240 490.5 240 464l.006-112h95.1L336 464c0 26.47 21.53 48 48 48H464c26.47 0 48-21.53 48-48V234.1L549.4 268C552.5 270.7 556.2 272 560 272C568.7 272 576 264.9 576 256zM480 208v256c0 8.812-7.172 16-16 16H384c-8.828 0-16-7.188-16-16V352c0-17.66-14.36-32-32-32h-96c-17.64 0-32 14.34-32 32v112C208 472.8 200.8 480 192 480H112C103.2 480 96 472.8 96 464v-256c0-.377-.1895-.6914-.2148-1.062L288 37.34l192.2 169.6C480.2 207.3 480 207.6 480 208z" fill="#A7A7A7" /></svg>
                    <span>Home</span>
                  </div>
              </NavLink>
              )}
              {this.props.currentProfile.type == "influencers" && (
                <NavLink activeclassname="active" to="/MyBids">
                  <div className="footerItem bids">
                    <svg width="22" height="22" viewBox="0 0 983 1014" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="389.378" cy="430.596" r="210.62" stroke="#A7A7A7" strokeWidth="62"/>
                      <circle cx="800.164" cy="213.134" r="138.134" stroke="#A7A7A7" strokeWidth="62"/>
                      <path d="M751.817 333.951V452.761C751.817 453.865 752.713 454.761 753.817 454.761H846.465C847.57 454.761 848.465 453.865 848.465 452.761V333.951M679.331 720.542C679.331 662.759 567.374 632.166 510.799 623.981C510.397 623.923 509.998 623.994 509.634 624.175C413.562 671.931 317.588 648.202 269.169 624.185C268.782 623.993 268.34 623.939 267.914 624.017C57.1881 662.713 35.2937 847.265 50.8415 936.396C51.0076 937.348 51.8298 938 52.7963 938H846.465C847.57 938 848.465 937.104 848.465 936V553.408C848.465 552.304 847.57 551.408 846.465 551.408H681.331C680.227 551.408 679.331 552.304 679.331 553.408V720.542Z" stroke="#A7A7A7" strokeWidth="62"/>
                    </svg>
                    <span>My Bids</span>
                  </div>
                </NavLink>)}
              {this.props.currentProfile.type == "brands" && (
                <NavLink activeclassname="active" to="/Campaigns">
                  <div className="footerItem bids">
                    <svg width="22" height="22" viewBox="0 0 983 1014" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="389.378" cy="430.596" r="210.62" stroke="#A7A7A7" strokeWidth="62"/>
                      <circle cx="800.164" cy="213.134" r="138.134" stroke="#A7A7A7" strokeWidth="62"/>
                      <path d="M751.817 333.951V452.761C751.817 453.865 752.713 454.761 753.817 454.761H846.465C847.57 454.761 848.465 453.865 848.465 452.761V333.951M679.331 720.542C679.331 662.759 567.374 632.166 510.799 623.981C510.397 623.923 509.998 623.994 509.634 624.175C413.562 671.931 317.588 648.202 269.169 624.185C268.782 623.993 268.34 623.939 267.914 624.017C57.1881 662.713 35.2937 847.265 50.8415 936.396C51.0076 937.348 51.8298 938 52.7963 938H846.465C847.57 938 848.465 937.104 848.465 936V553.408C848.465 552.304 847.57 551.408 846.465 551.408H681.331C680.227 551.408 679.331 552.304 679.331 553.408V720.542Z" stroke="#A7A7A7" strokeWidth="62"/>
                    </svg>
                    <span>Campaigns</span>
                  </div>
                </NavLink>
              )}
              <NavLink activeclassname="active" to="/Inbox">
                <div className="footerItem">             
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M448 64H64C28.65 64 0 92.65 0 128v256c0 35.35 28.65 64 64 64h384c35.35 0 64-28.65 64-64V128C512 92.65 483.3 64 448 64zM64 96h384c17.64 0 32 14.36 32 32v36.01l-195.2 146.4c-17 12.72-40.63 12.72-57.63 0L32 164V128C32 110.4 46.36 96 64 96zM480 384c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V203.1L208 336c14.12 10.61 31.06 16.02 48 16.02S289.9 346.6 304 336L480 203.1V384z" fill="#A7A7A7" /></svg>
                  <span>Inbox</span>
                </div>
              </NavLink>
              <NavLink activeclassname="active" to="/Notifications">
                <div className="footerItem">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M433.4 334.8c-28-26.5-49.38-54.38-49.38-148.9c0-79.63-63.38-144.5-144-152.4V16C240 7.125 232.9 0 224 0S208 7.125 208 16v17.5C127.4 41.38 63.1 106.3 63.1 185.9c0 94.5-21.38 122.4-49.38 148.9c-14 13.38-18.38 33.5-11.25 51.25C10.62 404.3 28.12 416 47.99 416h352c19.88 0 37.38-11.75 44.63-29.1C451.8 368.3 447.4 348.1 433.4 334.8zM400 384H47.99c-14.25 0-21.38-16.5-11.38-25.1c34.88-33.25 59.38-70.38 59.38-172.1C95.1 118.5 153.2 64 224 64s128 54.5 128 121.9c0 101.4 24.25 138.8 59.38 172.1C421.4 367.6 414.1 384 400 384zM272.1 448c-7.438 0-14.36 4.146-16.9 10.88C250.6 471.2 238.3 480 223.1 480s-26.61-8.824-31.25-21.12C190.2 452.1 183.3 448 175.8 448c-10.61 0-18.37 9.998-15.08 19.72C169.4 493.4 194.5 512 223.1 512c29.47 0 54.56-18.63 63.22-44.28C290.5 457.1 282.8 448 272.1 448z"  fill="#A7A7A7" /></svg>
                  <span>Notifications</span>
                </div>
              </NavLink>
              <NavLink activeclassname="active" to="/Settings">
                <div className="footerItem">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 480c-47.24 0-91.04-14.78-127.2-39.84C132.9 390.9 173.8 352 224 352h64c50.25 0 91.14 38.94 95.21 88.16C347 465.2 303.2 480 256 480zM411.7 416.7C397.6 361.3 347.7 320 288 320H224c-59.73 0-109.6 41.3-123.7 96.72C58.27 375.1 32 319 32 256c0-123.5 100.5-224 224-224s224 100.5 224 224C480 319 453.7 375.1 411.7 416.7zM256 128C211.8 128 176 163.8 176 208C176 252.2 211.8 288 256 288s80-35.82 80-80C336 163.8 300.2 128 256 128zM256 256C229.5 256 208 234.5 208 208S229.5 160 256 160s48 21.53 48 48S282.5 256 256 256z" fill="#A7A7A7" /></svg>
                  <span>Settings</span>
                </div>
              </NavLink>
            </div>
          </Container>
        </footer>
      )
    }
    else { return <></> }
  }
}

export default function (props) {
  const { views, currentProfile } = useAuthContext();
  const navigate = useNavigate();
  return <Footer navigate={navigate} views={views} currentProfile={currentProfile}/>;
}
